@import '../abstracts/variables';

.singleproductdetails {
    border-top: 4px solid #F5F4F3;
    margin-top: 0.5vw;

    .css-1qw96cp {
        padding-right: 0px;
    }

    .newdelivery {
        margin-top: 1vw;

        .newdatetext {
            font-weight: bold;
        }

        .newavailable {
            padding-left: 10px;
        }
    }

    .color-section {
        .MuiGrid-grid-sm-10.MuiGrid-grid-md-3 {
            padding-left: 0px;
        }

        .MuiSvgIcon-root {
            width: 1.5vw;
            height: 1.5vw;
        }
    }

    .css-79xub:hover {
        text-decoration: none;
        background-color: transparent;
        border: 0;
    }

    .personalization-section {
        .MuiGrid-grid-sm-10.MuiGrid-grid-md-3 {
            padding-left: 0px;
        }

        .MuiSvgIcon-root {
            width: 1.5vw;
            height: 1.5vw;
        }
    }

    .option-heading-warning {
        text-align: left;
        align-items: center;
        display: flex;
        font-size: 1.04vw;
        line-height: 1.197vw;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-family: $asap-font-regular;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
        margin-bottom: 1.04vw;
    }

    .search-product-section {
        margin: 1.56vw 0 0vw 2.07vw;
    }

    .MuiPaper-elevation1 {
        box-shadow: 3px 3px 12px #00000029 !important
    }

    .search-heading {
        font-size: 0.937vw;
        line-height: 1.0937vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: #ADADAD;
        text-align: left;
        margin-bottom: 1.197vw;

        .search-product-name {
            font-weight: bold;
        }
    }

    .selectbox .select-field {
        text-transform: capitalize;
    }

    .desktopview {
        .selectbox .select-field {
            background-color: #363240;
            color: #FFF;
            height: 2.5vw;
            line-height: 2.5vw;
            padding: 1vw;
        }
    }

    .product-card {
        padding: 1.926vw 1.553vw 0vw 2.08vw !important;
        border-radius: 2.758vw;
    }

    .headingmarbtm {
        display: flex;
        justify-content: space-between;
    }

    .image-container {
        width: 100%;
        min-width: 180px;
        min-height: 150px;
        margin-bottom: 1vw;
        position: relative;
        border: none;
    }

    .unpublished1 {
        background: #F4F5F6 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        z-index: 1;
        color: #000000;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        position: absolute;
        top: 13.5px;
        right: 12.17px;
        font-family: "Asap";
        font-weight: normal;
        font-style: normal;
        width: 3.17vw;
        height: 2.76vw;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .img-row {
        height: 45.156vw;
        width: 100%;
        min-height: 150px;
        border-radius: 2.0833vw;
        object-fit: cover;
        cursor: pointer;
        border: none;
        box-sizing: unset !important;
        object-position: top;
        min-width: 100%;
        min-height: 45.156vw;
    }

    .catbtn {
        top: 13.5px;
        right: 84%;
        font-family: "Helvetica Neue" !important;
        padding: 0.701vw 1.2vw !important;
        color: #707070 !important;
    }

    .productcard .catbtn {
        // right: 68.5%;
        right: 3.5%;
    }

    .MuiSvgIcon-root {
        color: #949D8C;
        width: 1.3vw;
        height: 1.3vw;
    }

    .image-container-gram {
        width: 25%;
        min-height: 150px;
        padding: 0 1.5%;
        display: inline-block !important;
        margin-bottom: 1.0937vw;
        position: relative;

    }

    .img-number {
        position: absolute;
        bottom: 53px;
        left: 62px;
        font-size: 2.3958vw;
        line-height: 2.7604vw;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-family: "GT-Super-Text-Regular-Trial";
        letter-spacing: 0px;
        color: #F4F5F6;
        opacity: 1;
        cursor: pointer;
    }

    .img-row-gram {
        height: 10.31vw;
        width: 100%;
        border-radius: 2.0833vw;
        box-shadow: 3px 3px 12px #00000029;
        opacity: 1;
        object-fit: cover;
        cursor: pointer;
        object-position: top;
    }

    .img-number {
        position: absolute;
        bottom: 53px;
        left: 62px;
        font-size: 2.3958vw;
        line-height: 2.7604vw;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-family: "GT-Super-Text-Regular-Trial";
        letter-spacing: 0px;
        color: #F4F5F6;
        opacity: 1;
        cursor: pointer;
    }

    .single_product_content {
        padding-left: 3vw;
    }

    .stock-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
        margin-top: 10px;
    }

    .heading21 {
        font-size: 1.25vw;
        line-height: 1.35vw;
    }

    .seller-heading {
        text-align: left;
        color: #949D8C;
        cursor: pointer;
    }

    .stock-text {
        color: #363240;
        text-align: end;
        margin-bottom: 1.04vw;
        margin-right: 14px;

    }

    .price1 {
        text-decoration: line-through;
        padding-left: 0.5vw;
        color: #DEDEDE;
        font-weight: 600;
    }


    .product-name {
        text-align: left;
        font-family: $gt-font-regular;
        ;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        opacity: $opcity;
        letter-spacing: $letter-spacing;
        font-size: 2.3958vw;
        line-height: 2.7604vw;
        font-weight: normal;
        color: #2F2B23;
        max-width: 300px;
        margin: 0;
    }

    .price {
        text-align: right;
        font-family: $gt-font-regular;
        ;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        opacity: $opcity;
        letter-spacing: $letter-spacing;
        font-weight: normal;
        font-size: 1.875vw;
        line-height: 2.1875vw;
        color: #949D8C;
        margin-right: 11px;
    }

    .price1 {
        text-align: right;
        font-family: $gt-font-regular;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        opacity: $opcity;
        letter-spacing: $letter-spacing;
        font-weight: normal;
        font-size: 1.4vw;
        line-height: 2.1875vw;
        color: #DEDEDE;
    }

    .price3 {
        display: flex;
        justify-content: end;
        margin-right: 8px;
        font-size: 1.04vw;
        color: rgb(89, 89, 89);
    }

    .star-section {
        margin-top: 0.520vw;
        margin-bottom: 21.5px;
        align-items: center;
        display: flex;

        .star {
            color: black !important;
            width: 24px;
            height: 23px;
        }

        .review {
            font-family: "Asap";
            font-weight: normal;
            font-family: $asap-font-regular;
            ;
            font-style: $btn-font-style;
            font-variant: $btn-font-variant;
            opacity: $opcity;
            letter-spacing: $letter-spacing;
            color: $color707070;
            font-size: 0.7291vw;
            line-height: 0.833vw;
            padding-left: 0.52vw;
        }

        .read-text {
            font-family: $asap-font-regular;
            font-weight: bold;
            font-style: $btn-font-style;
            font-variant: $btn-font-variant;
            opacity: $opcity;
            letter-spacing: $letter-spacing;
            color: $color707070;
            font-size: 0.7291vw;
            line-height: 0.833vw;
            padding-left: 0.52vw;
        }
    }

    .delivery {
        margin-top: 0.52vw;
        text-align: right;
        font-weight: normal;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        color: #949D8C;
        margin-right: 1.472vw;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        opacity: $opcity;
        letter-spacing: $letter-spacing;
        color: $color707070;
    }

    .para {
        font-weight: normal;
        font-family: "Asap";
        font-size: 1vw;
        line-height: 1.14vw;
        padding-right: 3.6041vw;
        opacity: 0.9;
        margin-top: 1.56vw;
        margin-bottom: 1.510vw;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        opacity: $opcity;
        letter-spacing: $letter-spacing;
        color: $color707070;
    }

    // .image-part {
    //     display: flex;
    //     justify-content: space-between;
    // }

    .product-value {
        text-align: left;
        font-family: "GT-Super-Text-medium";
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-size: 1.04vw;
        line-height: 1.195vw;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
    }

    .shop-icon {
        width: 3.02vw;
        height: auto;
        margin-right: 0.7812vw;
        margin-top: 1.432vw;
    }

    .product_value_icon {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        justify-content: flex-start;

    }

    .icon-name {
        text-align: center;
        font-size: 0.85vw;
        line-height: 1vw;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-family: "GT-Super-Text-Regular-Trial";
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
        margin-top: 5.01px;
        margin-right: 0;
    }

    .icon-section {
        display: inline-block;
        text-align: center;
        min-width: 16.5%;
        width: 16.5%;
        padding: 1%;
    }

    .shop-icon {
        width: 85%;
        height: auto;
        margin-right: 0;
        margin-top: 1.432vw;
    }

    .color-section {
        margin-top: 1.3552vw;
        //align-items: center;
        align-items: baseline;
    }

    .personalization-section {
        margin-top: 1.3552vw;
        // align-items: center;
    }

    .option-heading {
        text-align: left;
        align-items: center;
        display: flex;
        font-weight: bold;
        color: #949D8C;
    }

    .select-field-textarea {
        border: none;
        width: 100%;
        background-color: transparent;
        border-radius: 2.5vw;
        padding: 1.04vw 1.432vw;
        background-color: #F5F4F3;
        font-weight: normal;
        resize: none;
        display: block;

    }

    textarea.select-field-textarea:focus-visible {
        outline: none;
    }

    .sub_check_box {
        margin-top: 0.833vw;
        margin-bottom: 0.833vw;

        .MuiFormControlLabel-root {
            margin-left: 10px;
        }
    }

    .cycle {
        color: #9b9b9b;
        font-size: 1vw;
        line-height: 1.25vw;
        margin-top: 0.7vw;
    }

    .input-field {
        width: 100%;
    }

    .button-section {
        display: flex;
        justify-content: space-between;
        margin-top: 2.316145vw;
    }

    .MuiTypography-body1 {
        font-size: 1.09375vw;
        line-height: 1.24vw;
        font-family: "Helvetica Neue";
        letter-spacing: 0px;
        color: #707070;
        font-weight: bold;
        margin-left: 0.5vw;
    }

    .setup-btn,
    .now-btn {

        margin-right: 1.7348vw;
    }

    .selection-text {
        margin: 1.04vw 0;
    }

    .social-icon {
        width: 1.432vw;
        height: 1.423vw;
        margin-left: 1.197vw;
        cursor: pointer;
    }

    .last-text {
        text-align: left;
        font-size: 0.9895vw;
        line-height: 1.145vw;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-weight: normal;
        letter-spacing: $letter-spacing;
        color: #363240;
        opacity: $opcity;
        margin-top: 1.955729vw;
    }

    //You Might also like section 
    .youmightsection {
        .trending-heading {
            font-size: 1.82vw;
            color: #2F2B23;
        }

        .darkbtn {
            background-color: transparent !important;
            color: #949D8C !important;
            font-size: 1.09375vw !important;
        }
    }

    .might-section {
        display: flex;
        justify-content: space-between;

        .basket-btn.darkbtn {
            color: #949D8C !important;
            background-color: transparent;
        }

        .buy-btn {
            margin-right: 1.7348vw;
        }
    }

    //latest arrivals 
    .latestArrivals {
        margin-top: 3.75vw;

        .darkbtn {
            min-width: 10.986vw !important;
        }

        .desktopbg {
            background-size: 76%;
            background-position-y: 4vw;
        }

        .saller-img {
            display: block;
            margin-bottom: 1vw;
            width: 5.5208333vw;
            height: 3.80208vw;
        }

        .slick-slider {
            padding-top: 3vw;
        }

        .latest-section {
            opacity: 1;
            padding: 3.708vw 0 5.09vw;
        }

        .slick-next,
        .slick-prev {
            top: 48%;
        }
    }

    .product-card {
        .buy-btn.lightbtn {
            min-width: 8.854vw !important;
        }

        .darkbtn {
            min-width: 10.104166vw !important;
        }
    }

    .buy-btn.lightbtn {
        margin-right: 20px;
        color: #F5F4F3 !important;
    }

    .darkbtn {
        color: #F5F4F3 !important;
        text-transform: initial !important;
    }

    .latestArrivals .latest-para {
        padding-right: 5vw;
    }

    .dexktophide {
        display: none;
    }

    .reviews {
        .star-section {
            .MuiSvgIcon-root {
                color: #E4E1D9 !important;
                width: 24px;
                height: 23px;
            }
        }
    }

    .desktopview {
        .selectbox .select-field {
            background-image: none !important
        }

        .selectbox .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
            padding-right: 0px !important;
            padding: 6px 8px !important;
        }

        .selectbox .css-qiwgdb.css-qiwgdb.css-qiwgdb {
            padding-right: 0px !important;
            padding: 6px 8px !important;
        }

    }


}

@media (max-width:280px) {
    .singleproductdetails {
        .input-field {
            height: 50px !important;
            font-size: 14px !important;
            line-height: 16px !important;
            border-radius: 10vw !important;
            padding: 15px !important;
        }
      
        .option-heading {
            font-size: 16px !important;
            line-height: 20px !important;
        }

        .option-heading-warning {
            font-size: 14px !important;
            line-height: 18px !important;
        }

        .select-field-textarea {
            height: 100px;
            font-size: 14px !important;
            line-height: 18px !important;
        }

        .button-section .basket-btn,
        .button-section .buy-btn {
            font-size: 16px !important;
        }

        .image-container {
            margin-bottom: 10px;
        }

        .img-row-gram {
            min-height: 80px !important;
            max-height: 80px !important;
        }

        .para {
            font-size: 14px !important;
            line-height: 18px !important;
        }

        .icon-name {
            font-size: 10px !important;
            line-height: 14px !important;
        }

        .product-name {
            font-size: 20px !important;
            line-height: 25px !important;
        }

        button.catbtn {
            font-size: 12px !important;
        }

        .star-section {
            display: block !important;
        }

        .star-section .review {
            margin-left: 0 !important;
        }

        .selectbox .select-field {
            font-size: 14px !important;
            line-height: 18px !important;
        }

        .MuiSelect-select {
            padding: 15px !important;
        }

        .single_pro_price_set {
            display: block !important;
            text-align: left;
        }

        .price3 {
            font-size: 13px !important;
            line-height: 30px !important;
            display: block !important;
        }

        .productcard .title-price {
            margin-bottom: 20px;
        }
    }
}

@media (max-width:600px) {
    .singleproductdetails {
        border-top: 2px solid #F5F4F3;
        margin-top: 10px;
        overflow-x: hidden;

        .single_pro_price_set {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            align-items: center;
        }
        .innerpages{
            display: none ;
          }
        .css-isbt42 {
            margin-top: 0px;
        }

        .img-number {
            font-size: 21px;
            left: 43px;
            line-height: 25px;
        }

        .color-section {
            align-items: center;
        }

        .option-heading-warning {
            font-size: 16px;
            line-height: 18px;
            margin-top: 10px;
        }

        .desktopview {
            .selectbox .select-field {
                height: 10vw;
                font-size: 14px !important;
                line-height: 20px !important;
                background-image: none !important
            }

            .selectbox .selectboxwidth {
                width: auto;

            }

            .selectbox .css-qiwgdb.css-qiwgdb.css-qiwgdb {
                padding-right: 6px !important;
            }
        }

        .social-icon {
            width: 14px;
            height: 14px;
            margin-left: 14px;
        }

        .MuiPaper-elevation1 {
            box-shadow: none !important;
        }

        .basket-btn,
        .buy-btn {
            font-size: 21px !important;
            line-height: 27px !important;
        }

        .unpublished1 {
            width: 9.17vw;
            height: 8.76vw;
            border-radius: 20px;

        }

        .image-container-gram {
            width: 33.33%;
            min-height: auto;
        }

        .img-row-gram {
            min-height: 120px;
            max-height: 120px;
            border-radius: 20px;
        }

        .img-row {
            height: 92.156vw;
            border-radius: 20px;
            min-height: 92.156vw;
        }

        .MuiSvgIcon-root {
            color: #949D8C;
            width: 6.5vw !important;
            height: 6.5vw !important;
            padding-top: 2px;
        }

        .tabsection .MuiButtonBase-root {
            margin-right: 0;
        }

        .tabsection .MuiButtonBase-root.Mui-selected:after {
            content: " ";
            border-bottom: 2px solid;
            width: 80%;
            display: block;
            margin: 0 auto;
            margin-top: 6px;
        }

        .tabsection .MuiButtonBase-root:after {
            content: " ";
            border-bottom: 2px solid;
            width: 63%;
            display: block;
            margin: 0 auto;
            margin-top: 6px;
        }

        .catbtn {
            top: 14px;
            right: 69%;
            padding: 2.5vw 4.8vw !important;
            font-size: 18px !important;
            padding: 2.5vw 4.8vw !important;
            font-size: 18px !important;
            color: #000000 !important;
            font-family: 'Asap' !important;
        }

        .input-field {
            height: 60px;
            font-size: 21px;
            line-height: 25px;
        }

        .selectbox .select-field {
            font-size: 21px;
            line-height: 25px;
            background-position: 93%;
        }

        .search-heading {
            font-size: 13px;
            line-height: 15px;
        }

        .seller-heading {
            font-size: 16px;
            line-height: 18px;
        }

        .stock-text {
            font-size: 12px;
        }

        .product-name {
            font-size: 27px;
            line-height: 31px;
            padding: 10px 0;
        }

        .price {
            font-size: 23px;
            line-height: 32px;
            font-weight: 600;
            text-align: left;
        }

        .para {
            font-size: 18px;
            line-height: 24px;
            padding-right: 0;
        }

        .product-value {
            font-size: 20px;
            line-height: 23px;
            margin-top: 14px;
        }

        .icon-section {
            display: inline-block;
            width: 25%;
            padding: 5px;
        }

        .icon-name {
            font-size: 15px;
            line-height: 18px;
        }

        .star-section .review {
            font-size: 12px;
            line-height: 14px;
            margin-left: 10px;
        }

        .star-section .read-text {
            font-size: 12px;
            line-height: 14px;
            margin-left: 10px;
        }

        .price1 {
            font-size: 18px;
            line-height: 21px;
        }

        .delivery {
            font-size: 18px;
            line-height: 21px;
        }

        .price3 {
            font-size: 15px;
            line-height: 21px;
        }

        .option-heading {
            font-size: 20px;
            line-height: 23px;
            margin-top: 4px;
        }

        .select-field-textarea {
            width: 100%;
            height: 120px;
            font-size: 20px;
            line-height: 23px;
            padding: 10px 20px;
            border-radius: 20px;
        }

        .MuiTypography-body1 {
            font-size: 15px;
        }

        .sub_check_box {
            padding-left: 0px;
            margin: 10px 0;

            .MuiSvgIcon-root {
                color: #707070;
                width: 6.5vw;
                height: 6.5vw;
                padding-top: 2px;
            }

        }

        .MuiTypography-body1 {
            margin-left: 8px;
        }

        .cycle {
            color: #9b9b9b;
            font-size: 12px;
            line-height: 20px;
            margin-top: 10px;
            letter-spacing: 0.3px;
        }

        .btn_div_n {
            width: 100%;
        }

        .latestArrivals {
            margin-top: 10.75vw;

            .trending-heading {
                text-align: center;
                font-size: 30px;
                line-height: 35px;
            }

            .slick-slider {
                padding-top: 10vw;
                margin-bottom: 10vw;
            }

            .desktopbg {
                background-size: 100% 72%;
                background-position-y: 25vw;
                padding: 0vw 0 5.09vw;
            }

            .darkbtn {
                width: auto;
            }

            .con-responsive-wm {
                width: 100% !important;
                margin-left: 0px !important;
            }
        }

        .con-responsive-wm {
            width: 100% !important;
            margin-left: 0px !important;
        }

        .latestArrivals .saller-img {
            display: none;
        }

        .latestArrivals .latest-section {
            opacity: 1;
            padding: 0vw 0 5.09vw;
        }

        .youmightsection .trending-heading {
            font-size: 8.5vw;
            color: #2F2B23;
            text-align: center;
        }

        .lightbtn,
        .darkbtn {
            width: 100%;
            font-weight: 500 !important;
        }

        .darkbtn {
            margin-top: 0 !important;
        }

        .last-text{
            font-size: 18px;
            line-height: 24px;
            margin: 15px 0;
        }
      
        .selection-text {
            font-size: 12px;
            line-height: 20px;
            margin: 15px 0;
        }

        .css-pwngrv-MuiPaper-root-MuiCard-root {
            box-shadow: none;
        }

        .product-card {
            padding: 0 !important;
            box-shadow: none;
        }

        .product-card .css-mhc70k-MuiGrid-root {
            margin: 0px !important;
            width: 100% !important;
        }

        .css-46bh2p-MuiCardContent-root {
            padding: 0px;
        }

        .MuiPaper-rounded {
            border-radius: 0px !important;
        }

        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            padding-left: 0px;
        }
    }
}

@media (min-width:601px) and (max-width:920px) {
    .singleproductdetails {

        // .img-row {
        //     height: 92.156vw;
        //     min-height: 92.156vw;
        // }
        .latestArrivals .darkbtn {
            width: 50%;
            min-width: 50%;
        }

        form .checkbox svg {
            width: 16px !important;
            height: 16px !important;
            margin-top: -10px;
        }

        .latestArrivals .saller-img {
            display: none;
        }

        .catbtn {
            top: 15px;
            right: 78%;
            font-size: 10px !important;
            line-height: 14px !important;
        }

        .search-heading {
            font-size: 1.5vw;
            line-height: 1.8vw;
        }

        .seller-heading {
            font-size: 14px;
        }

        .stock-text {
            font-size: 12px;
        }

        .price {
            text-align: left;
            font-size: 3.875vw;
            line-height: 5.1875vw;
        }

        .star-section {
            margin-top: 0.52vw;
            margin-bottom: 0px;
            align-items: center;
            display: flex;
        }

        .star-section .review,
        .star-section .read-text {
            font-size: 10px;
            line-height: 14px;
        }

        .price1 {
            font-size: 18px;
            line-height: 21px;
        }

        .delivery {
            font-size: 12px;
            line-height: 14px;
        }

        .price3 {
            font-size: 10px;
            line-height: 12x;
        }

        .para {
            font-size: 12px;
            line-height: 20px;
            padding-right: 0px;
        }

        .product-value {
            font-size: 12px;
            line-height: 18px;
        }

        .icon-section {
            min-width: 20%;
            width: 20%;
        }

        .icon-name {
            font-size: 10px;
            line-height: 16px;
            margin: 0px;
        }

        .option-heading {
            text-align: left;
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 8px;
        }

        .select-field-textarea {
            min-height: 120px;
            font-size: 12px;
            line-height: 20px;
            padding-left: 24px;
        }

        .cycle,
        .last-text,
        .selection-text {
            font-size: 12px;
            line-height: 20px;
        }

        .basket-btn,
        .buy-btn {
            width: 100%;
            margin: 10px 0 !important;
        }

        .button-section {
            display: block;
        }

        .img-row {
            height: 40vw;
        }

        .unpublished1 {
            width: 3.17vw;
            height: 3vw;

            .MuiSvgIcon-root {
                font-size: 14px;
                margin-top: 2px;
            }
        }

        .tabsection .tab-para {
            text-align: left;
            font-size: 14px;
            line-height: 18px;
        }

        .youmightsection .trending-heading {
            margin: 0;
            font-size: 30px;
            line-height: 35px;
        }

        .tabsection .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
        .tabsection .MuiButtonBase-root {

            font-size: 16px;
            line-height: 19px;
        }

    }
}


@media (min-width:921px) and (max-width:1280px) {
    .singleproductdetails {
        .para {
            font-size: 13px;
            line-height: 18px;
        }

        .product-value {
            font-size: 14px;
            line-height: 16px;
        }

        .option-heading {
            font-size: 12px;
            line-height: 14px;
        }

        .icon-name {
            font-size: 12px;
            line-height: 16px;
            margin: 0px;
        }

        .select-field-textarea {
            min-height: 80px;
        }

        .buy-btn.lightbtn {
            margin-right: 20px;
        }
    }
}

@media (min-width:921px) and (max-width:1100px) {
    .singleproductdetails {
        .catbtn {
            top: 13.5px;
            right: 32.5vw;
        }

        .productcard .catbtn {
            right: 12.5vw;
        }
    }
}

@media (min-width:1930px) and (max-width:6000px) {
    .singleproductdetails {
        .color-section {
            align-items: center;
        }
         .social-icon {
         
            margin-left: 0.8vw;
        
        }
        .price {
            display:contents;
        }
        .latestArrivals{
            .price{
                display: none;
            }
        }
        .gitftsection {
            align-items: baseline !important;
        }

        .button-section {
            align-items: center;
        }

        .product-card .buy-btn.lightbtn {
            min-width: 6.854vw !important;
        }

        .product-card .darkbtn {
            min-width: 5.104166vw !important;
            padding: 0.729vw 1vw !important;
        }

        .product-name {
            font-size: 1.5vw;
            line-height: 1.8vw;
            max-width: 100%;
        }

        .icon-section {
            display: inline-block;
            text-align: center;
            min-width: 20%;
            width: 20%;
            padding: 1%;
        }

        .icon-name {
            font-size: 0.7vw;
            line-height: 0.9vw;
            margin: 0px;
            margin-top: 0.4vw;
        }

        .select-field-textarea {
            min-height: 8vw;
            border-radius: 1.5vw
        }

        .buy-btn.lightbtn {
            margin-right: 20px;
        }

        .input-field {
            width: 100%;
        }

        .catbtn {
            right: 79%;
            top: 1vw;
            font-size: 1vw !important;
        }

        .unpublished1 {
            top: 1vw;
            right: 1vw;
        }

        .productcard .catbtn {
            right: 56%;
            top: 1vw;
        }

        .productcard .basket-btn,
        .productcard .buy-btn {
            font-size: 0.75vw !important;
        }

        .MuiSvgIcon-root {
            width: 1.3vw;
            height: 1.3vw;
        }

        .img-row {
            height: 30vw;
        }

        .img-row-gram {
            height: 7.5vw;
        }

        .tabsection .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,
        .tabsection .MuiButtonBase-root {
            text-transform: capitalize;
            min-width: fit-content;
        }

        .reviews .star-section .star1 {
            color: #E4E1D9 !important;
            width: 50px;
            height: 50px;
        }

        .product-card {
            padding: 1.926vw 1.553vw 1.926vw 2.08vw !important
        }

    }
}

@media (min-width:5000px) and (max-width:6000px) {
    .singleproductdetails {
        .catbtn {
            top: 1vw !important;
            right: 78% !important;
            font-size: 1vw !important;
        }

        .productcard .catbtn {
            right: 54% !important;
        }

        .unpublished1 {
            top: 1vw !important;
            right: 0.8vw !important;
        }

        .latestArrivals .desktopbg {
            background-size: 64%;
            background-position-y: 4.5vw;
        }

    }
}

@media (min-width:2500px) {
    .singleproductdetails {
        border: 0px;
        margin-top: 0px;

        .all-screen-container {
            border-top: 4px solid #F5F4F3;
            margin-top: 0.5vw;
        }
    }
}

ul.MuiList-root li.MuiMenuItem-root {
    display: block;

}

// selller other product section css 

@media (min-width: 2000px) and (max-width: 2599px) {
    .singleproductdetails {
        .latestArrivals .desktopbg {
            background-size: 67% !important;
            background-position-y: 5vw !important;
        }

        ul.MuiList-root li.MuiMenuItem-root {
            font-size: 1vw;

        }
    }

}

@media (min-width: 2600px) and (max-width: 3999px) {
    .singleproductdetails {
        .latestArrivals .desktopbg {
            background-size: 62% !important;
            background-position-y: 5.2vw;
        }
    }
}

@media (min-width: 4000px) and (max-width: 4300px) {
    .singleproductdetails {
        .latestArrivals .desktopbg {
            background-size: 72%;
        }
    }
}

@media (min-width: 4301px) and (max-width: 5000px) {
    .singleproductdetails {
        .latestArrivals .desktopbg {
            background-size: 69%;
            background-position-y: 5vw;
        }
    }
}

@media (min-width: 5000px) and (max-width: 5500px) {
    .singleproductdetails {
        .latestArrivals .desktopbg {
            background-size: 63%;
            background-position-y: 5.5vw;
        }

    }
}

@media (min-width: 5501px) and (max-width: 6000px) {
    .singleproductdetails {
        .latestArrivals .desktopbg {
            background-size: 60%;
            background-position-y: 5.5vw;
        }

    }
}

