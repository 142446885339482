@import "../abstracts/variables";

.customer-saved-card {
    padding-bottom: 8.33vw;

    .saved-card {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #F5F4F3;
        padding: 4.622vw 0 2.6041vw;
        align-items: center;
    }

    .card-text {
        font-size: 1.979vw;
        line-height: 2.265vw;
        font-family: $gt-font-regular;
        color: #363240;
        opacity: 1;
        text-transform: capitalize;

    }

    .plusimg {
        font-size: 2.8125vw;
        width: 2.166vw;
        height: 2.125vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .add-card {
        display: flex;
        cursor: pointer;

    }

    .add-card-text {
        text-align: center;
        font-size: 1.3541vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: 0px;
        color: #363240;
        opacity: 1;
        margin-left: 0.52vw;
    }

    .card-section {
        background: #00000003 0% 0% no-repeat padding-box;
        border-radius: 1.875vw;
        opacity: 1;
        width: 13.177vw;
        height: 7.6041vw;
        object-fit: contain;

        .mastercard {
            width: 13.177vw;
            height: 7.6041vw;
            object-fit: cover;
        }

        .visacard {
            width: 13.177vw;
            height: 7.6041vw;
            object-fit: contain;
        }

        // img {
        //     width: 13.177vw;
        //     height: 7.6041vw;
        // }
    }

    .customer-card-section {
        padding: 2.1875vw 1.302vw 2.1875vw;
        border-bottom: 1px solid #F5F4F3;
        align-items: center;
    }

    .card-holder-name {
        text-align: left;
        font-size: 1.3541vw;
        line-height: 1.65vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 0.9;
        margin-bottom: 0.80729vw;
    }

    .card-number {
        text-align: left;
        font-size: 1.875vw;
        line-height: 2.1875vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 0.9;
    }

    .delete-icon {
        color: $color707070;
        font-size: 1.65vw;
        margin-left: 1.04vw;
        vertical-align: middle;
        cursor: pointer;
    }

    .delete-section {
        display: flex;
        justify-content: end;
    }

}
@media(max-width:395px) {
    .customer-saved-card {
        .add-card-text {
            font-size: 16px !important;
            line-height: 20px !important;
            padding-left: 4px !important;
        }
        .plusimg {
            height: 27px !important;
            width: 27px !important;
        }
        .card-text {
            font-size: 24px !important;
            line-height: 30px !important;
            margin-bottom: 10px !important;
        }
        .delete-icon {
            font-size: 20.8px !important;
        }
        div.card-holder-name, div.card-number {
            font-size: 13px;
            line-height: 18px;
        }
        .card-section img.visacard {
            width: 13.5vw;
        }
    }
}

@media(max-width: 920px) {
    .customer-saved-card {
        .card-text {
            font-size: 34px;
            line-height: 39px;
            margin-bottom: 10px;
        }

        .plusimg {
            width: 31px;
            height: 30px;
        }

        .add-card-text {
            font-size: 18px;
            line-height: 21px;
            padding-left: 23px;
            padding-left: 12px;
        }

        .card-section {
            width: 20.177vw;
            height: 12.6041vw;

            // img {
            //     width: 20.177vw;
            //     height: 12.6041vw;
            // }
            .mastercard {
                width: 20.177vw;
                height: 12.6041vw;
                object-fit: cover;
            }

            .visacard {
                width: 20.177vw;
                height: 12.6041vw;
                object-fit: contain;
            }
        }

        .card-holder-name,
        .card-number {
            font-size: 16px;
            line-height: 18px;
        }

        .delete-icon {
            font-size: 24.8px;
        }

        .css-1oqqzyl-MuiContainer-root {
            max-width: 100% !important;
        }
    }
}