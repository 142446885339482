@import "../abstracts//variables";
.revieworderdialogs{
.review-order-text{
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.03125vw;
    font-family: $gt-font-regular;
    letter-spacing: $letter-spacing;
    color: #363240;
    opacity: $opcity;
    margin-bottom: 1vw;
    margin-top: 0;
}

.product-img{
    width: 7.40625vw;
    height: 7.71875vw;
    object-fit: cover;
    box-shadow: 3px 3px 12px #00000029;
    border-radius: 1.04vw;
    opacity: 1;
  

}
.product-price{
    font-size: 1.302083vw;
    line-height: 1.5104166vw;
    color: #949d8c;
    font-family: $gt-font-regular;
    margin-bottom: 1.63541vw;
}
.product-section{
    display: inline-block;
    margin: 1% 1.2%;
}
.item-detail{
    font-size: 1.2vw;
    line-height: 1.5vw;
    color: #363240;
    font-family: $asap-font-regular;
}
.order-info{
    display: flex;
    justify-content: space-between;

    .item-detail{
        margin: 5px 0 0;
    }
}
.rate{
    font-size: 1.2vw;
    line-height: 1.8vw;
    color: #363240;
    font-family: $asap-font-regular; 
    font-weight: normal;
}
.lastbtn{
  text-align: center;
}
.input-field-textarea {
    background: #f5f4f3 0% 0% no-repeat padding-box;
    border-radius: 1vw;
    opacity: $opcity;
    padding: 1.00833vw 1.15937vw 0.917187vw;
    text-align: left;
    font-size: 1.09375vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
    color: #707070;
    border: $btn-border;
    margin-bottom: 1.426vw;
    display: block;
    width: 100%;
    resize: none;
    min-height: 6vw;
  }
  textarea.input-field-textarea:focus-visible {
    outline: none;
  }
  .closepart2 {
   text-align: end;
  }
}


@media (max-width: 600px) {
    .revieworderdialogs{
        .review-order-text{
        font-size: 23px;
        line-height: 26px;
        margin-bottom: 23.71px;
      }
.product-img{
    width: 92px;
    height: 80px;
    border-radius: 10px;
}
      .product-price{
        font-size: 20px;
        line-height: 25px;
      }
      .item-detail, .rate {
        font-size: 18px;
        line-height: 21px;
      }
   
      .input-field-textarea {
        min-height: 70px;
        font-size: 16px;
        line-height: 21px;
        padding: 10px;
        border-radius: 10px;
      }
      
  }
  }
  @media (min-width: 600px) and (max-width: 920px) {
    .revieworderdialogs{
        .review-order-text{
        font-size: 26px;
        line-height: 29px;
        margin-bottom: 25.71px;
      }
      .product-img{
        width: 92px;
        height: 80px;
        border-radius: 10px;
    }
      .product-price{
        font-size: 25px;
        line-height: 30px;
      }
      .item-detail, .rate {
        font-size: 21px;
        line-height: 24px;
      }
   
   
  }
  }
  @media (min-width: 920px) and (max-width: 1280px) {
    .revieworderdialogs{
        .review-order-text{
        font-size: 30px;
        line-height: 35px;
      }
      .product-img{
        width: 92px;
        height: 80px;
        border-radius: 10px;
    }
      .product-price{
        font-size: 25px;
        line-height: 30px;
      }
      .item-detail, .rate {
        font-size: 21px;
        line-height: 24px;
      }
   
  
      
  }
  }


  @media (min-width:1921px) and (max-width:6000px){
    .revieworderdialogs{
    .reviewpadd{
      padding: 0.5vw 1vw;
    }
  }
  }