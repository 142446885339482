@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;1,400&display=swap');
@import '../scss/base/fonts';
// 1. Configuration and helpers
@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';

// 2. Vendors
// @import "vendors/normalize";

// 3. Base stuff
@import 'base/base', 'base/fonts', 'base/typography', 'base/helpers';

// 4. Layout-related sections
@import 'layout/header', 'layout/footer';

// 5. Components
@import 'components/_button';
@import 'components/signin';
@import 'components/PageComponents/singleproducttab';
// 6. Page-specific styles
@import 'pages/home';

@import 'pages/_wishlist';
@import 'components/confirmRegistration';
// 7. Themes
@import 'themes/default';
@import 'components/register';
@import 'components/resendPassword';
@import 'universal/inputField';
@import 'universal/productSlider';
@import 'universal/blogcard';
@import 'pages/imagesection';
@import 'pages/ethicalbrand';
@import 'pages/exclusiveblogs';
@import 'pages/shopCollection';
@import 'pages/customerSection';
@import 'pages/savedaddresses';
@import 'pages/products';
@import 'pages/shopCollectionPage';
@import 'pages/retailers';
@import 'pages/searchproduct';
@import '../scss/universal/filters';
@import 'pages/basket';
@import 'pages/customersavedcard';
@import '../scss/universal/innderbanner';
@import 'pages/trendingcollection';
@import './universal/selectbox';
@import './universal/reviews';
@import './pages/sellerStorefront';
@import './components/addAddress';
@import './pages/customerAccount';
@import './components/giftVoucher';
@import './components//referaFriend';
@import './components/redeemVoucher';
@import './components/guestcheckout';
@import './pages/orderComplete';
@import './pages/checkout';
@import './components/paymentMethod';
@import './pages/myorderseeall';
@import './pages/mysaveditemsseeall';
@import './pages/mysubscriptionsseeall';
@import './components/viewOrderDetails';
@import './universal/emptyBasket';
@import './pages/customerAccountMessage';
@import './universal/pagination';
@import './components/addCard';
@import './components/RemoveProduct';
@import './components/deliveryAddress';
@import './components/ageConfirm';
@import './universal/customerEmptyBasket';
@import './components/imageGallery';
@import './universal/sweetalertbox';
@import './pages/resetPassword';
@import './universal/sellerHeader';
@import './universal/sellerFooter';
@import './components/skeleton/skeleton';
@import './components/skeleton/singleproductskeleton';
@import './components/skeleton/storeskeleton';
@import './components/skeleton/tableskeleton';
@import './universal/searchHistoryPop';
@import './components/skeleton/checkoutskeleton';
@import './components/skeleton/savedcardskeleton';
@import './components/skeleton/savedaddressskeleton';
@import './components/skeleton/subscriptionskeleton';
@import './components/skeleton/reviewskeleton';
@import './components/skeleton/shopbyvaluefilterskeleton';
@import './universal/outofstock';
@import './components/outofstockdialog';
@import './components/notifyProduct';
@import 'pages/orderFailed';
@import 'components/raiseDispute';
@import './components/skeleton/customeraccount';
@import './pages/notFound';
@import './universal/subcategoryaccordion';
@import './pages/NewCheckout/newCheckoutPage';
@import './components/addGiftMessage';
@import './pages/NewCheckout/checkouheader';
@import './pages/NewCheckout/guestCheckout';

@import './pages/NewCheckout/checkoutmain';
@import './pages/NewCheckout/_LoginCheckout';
@import './pages/NewCheckout/_registercheckout';
@import './pages/NewCheckout/MobileCheckoutFlow/checkoutEmail';
@import './pages/NewCheckout/MobileCheckoutFlow/checkoutPassword';
@import './pages/NewCheckout/MobileCheckoutFlow/checkoutRegister';
@import './universal/onOffSwitch';
@import './components/skeleton/newcheckoutpage';
@import './components/reviewOrder';
@import './universal/candleSection';
@import './universal/featuredCollectionsNew';

::-webkit-input-placeholder {
  /* Edge */
  color: #707070;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #707070;
}

::placeholder {
  color: #707070;
  font-weight: 500;
}

.css-177ic5c,
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  // width: 5vw;
  max-height: 15vw !important;
}

.myordertable {
  .multiquantity {
    .css-qiwgdb {
      min-width: 30px !important;
      text-align: center;
    }
  }
}

.myordertable {
  .multiquantity {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
      min-width: 30px !important;
      text-align: center;
    }
  }
}

h1.main-heading40.graycolor949d8ce3.textcenter {
  margin: 0;
}

.imgnone {
  background-image: none !important;
}

.main-heading40 {
  font-size: $fontsize-heading40;
  line-height: $lineheight-heading46;
  font-family: $gt-font-regular;
  margin: 0;
}

.dialog-heading34 {
  font-size: $fontsize-heading34;
  line-height: $lineheight-heading39;
  font-family: $gt-font-regular;
  $letter-spacing: 0px;
  $btn-font-style: normal;
  $btn-font-variant: normal;
}

.graycolor949d8ce3 {
  color: $graycolor949d8ce3;
  font-family: $gt-font-regular;
  font-weight: 600;
}

.MuiSvgIcon-root {
  color: #8b8b8b;
}

.blackcolor2F2B23 {
  color: $blackcolor2F2B23;
  font-weight: 500;
}

.sectionpadding3p5vw {
  padding: $sectionpadding3p5vw;
}

.paddingleft14vw {
  padding-left: $paddingleft14vw;
}

.textcenter {
  text-align: $textcenter !important;
}

.textright {
  text-align: $textright;
}

.textleft {
  text-align: $textleft;
}

.pera-text18 {
  font-size: $pera-fontsize18;
  line-height: $pera-lineheight21;
  font-family: $asap-font-regular;

  letter-spacing: $letter-spacing;
  font-style: $btn-font-style;
  font-variant: $btn-font-variant;
  margin: 0;
}

.headingmarbtm {
  margin-bottom: $headingmarbtm;
}

.heading20 {
  font-size: $fontsize-heading20;
  line-height: $lineheight-heading23;
  font-family: $asap-font-regular;
  letter-spacing: $letter-spacing;
  font-style: $btn-font-style;
  font-variant: $btn-font-variant;
}

.bold {
  font-weight: $font-weight-bold;
}

.heading21 {
  font-size: $fontsize-heading21;
  line-height: $lineheight-heading24;
  font-family: $asap-font-regular;
  $letter-spacing: 0px;
  $btn-font-style: normal;
  $btn-font-variant: normal;
  color: $color707070;
}

.quantity {
  font-size: $fontsize-heading21;
  line-height: $lineheight-heading24;
  font-family: $asap-font-regular;
  $letter-spacing: 0px;
  $btn-font-style: normal;
  $btn-font-variant: normal;
  opacity: 0.9;
  color: $color707070;
}

.slick-next:before {
  content: '';
  background: url('../assets/images/home/arrowpre.png');
  background-size: contain;
  width: 1.2vw;
  height: 1.4vw;
  background-repeat: no-repeat;
  display: block;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.slick-prev:before {
  content: '';
  background: url('../assets/images/home/arrow.png');
  background-size: contain;
  width: 1.2vw;
  height: 1.4vw;
  margin-right: 0 !important;
  margin: 0 auto;
  background-repeat: no-repeat;
  display: block;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 1;
}

.slick-next,
.slick-prev {
  top: 40%;
}
.featuredCollectionsNew{
  .slick-next,
  .slick-prev {
    top: 28%;
  }
}
.fromthegram {

  .slick-next,
  .slick-prev {
    top: 50%;
  }
}

.blogsection {

  .slick-next,
  .slick-prev {
    top: 31%;
  }
}

.mobilehide {
  display: block;
}

.mobileshow {
  display: none;
}

.header {
  .mobilehide {
    display: block;
  }

  .mobileshow {
    display: none;
  }
}

.footer {
  .mobilehide {
    display: block;
  }

  .mobileshow {
    display: none;
  }
}

.ethicalbrand {
  .mobilehide {
    display: block;
  }

  .mobileshow {
    display: none;
  }
}

.css-1oqqzyl-MuiContainer-root {
  max-width: 90% !important;
}

.all-screen-container {
  max-width: 90% !important;
}

.MuiPaper-rounded {
  border-radius: 2.0833vw !important;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 360px;
  min-height: 201px;
  max-width: 80vw !important;
  pointer-events: auto;
  width: 29.6354vw !important;
}

.css-uhb5lp {
  min-width: 360px;
  min-height: 201px;
  max-width: 80vw !important;
  pointer-events: auto;
  width: 29.6354vw !important;
}

.view-order-details .css-uhb5lp {
  min-width: 360px;
  min-height: 201px;
  max-width: 80vw !important;
  pointer-events: auto;
  width: 90.1031vw !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-radius: 0 !important;
}

.MuiDrawer-modal .MuiDrawer-paper {
  width: 100% !important;
}

ul.MuiList-root {
  padding: 0.8vw 1vw;

  li.MuiMenuItem-root {
    width: 100%;
    justify-content: flex-start;
  }
}

.nointernet {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 40%;
  right: 0;
}

.ahh-text {
  text-align: center;
  font-size: 1.56vw;
  line-height: 2.395vw;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  font-family: $asap-font-regular;
  letter-spacing: 0px;
  color: #000;
}

.result-text {
  text-align: center;
  font-size: 1.25vw;
  line-height: 1.56vw;
  font-weight: bold;
  font-style: normal;
  font-variant: normal;
  font-family: $asap-font-regular;
  letter-spacing: 0px;
  color: #707070;
}

.emptydata {
  padding-top: 8vw;
}

.img-row,
.img-row1 {
  background-color: #f0f0f0;
}

// .singleproductdetails .img-row{
//   background-color: transparent;
// }
@media only screen and (max-width: 600px) {
  .homepage27 .headingmarbtm {
    align-items: center;
  }

  .result-text {
    line-height: 15px;
    margin-top: 7px;
    margin-bottom: 35px;
    font-size: 12px;
  }

  .css-177ic5c,
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    max-height: 200px !important;
    border-radius: 10px !important;
  }

  .ahh-text {
    font-size: 20px;
    line-height: 25px;
    margin-top: 25px;
  }

  .MuiPaper-rounded {
    border-radius: 40px !important;
  }

  ul.MuiList-root {
    padding: 4.8vw 4vw;
  }

  .mobilemenu {
    .MuiPaper-rounded {
      border-radius: 0px !important;
    }
  }

  // .mobilesliderdesign {
  //   padding: 10px 40px;
  // }
  .css-1oqqzyl-MuiContainer-root {
    max-width: 100% !important;
  }

  .all-screen-container {
    max-width: 100% !important;
  }

  .slick-prev:before,
  .slick-next:before {
    width: 3.4vw;
    height: 4vw;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1930px) {
  ul.MuiList-root li.MuiMenuItem-root {
    font-size: 1vw;
  }

  .all-screen-container {
    // max-width: 1770px !important;
    max-width: 1880px !important;
  }

  .slick-prev {
    left: -2vw;
  }

  .slick-next {
    right: -2vw;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 1.5vw;
  }

  .slick-next,
  .slick-prev {
    width: 1.5vw;
    height: 1.5vw;
  }
}

@media only screen and (min-width: 3001px) {
  .all-screen-container {
    max-width: 2200px !important;
  }
}

@media only screen and (min-width: 4000px) {
  .all-screen-container {
    max-width: 3200px !important;
  }
}

//universal css
@media only screen and (max-width: 600px) {

  .scrollenable .slick-slider {
    margin-bottom: 54.85px;
  }

  .scrollenable {
    .trendingcollection {
      .slick-slider {
        margin-bottom: 8.85px;
      }
    }
  }

  .newcheckout,
  .wishlist,
  .basket {
    .scrollenable .slick-slider {
      margin-bottom: 8.85px;
    }
  }

  .scrollenable .shopcollectionsection .slick-slider,
  .scrollenable .favouritebrands .slick-slider {
    margin-bottom: 27.85px;
  }

  .scrollenable .shopbyvalue .slick-slider {
    margin-bottom: 0px;
  }

  // .scrollenable .slick-dots li button:focus:before,
  // .slick-dots li button:hover:before {
  //   opacity: .25;
  //   color: #000;
  // }

  .slick-dots {
    bottom: -44px;
  }

  .latestArrivals .slick-dots {
    bottom: -31px;
  }

  .shopcollectionsection .slick-dots,
  .favouritebrands .slick-dots {
    bottom: -7px;
  }

  .scrollenable .MuiContainer-root {
    padding-left: 16px;
    padding-right: 16px;
  }

  .scrollenable .slick-dots li button:before {
    font-size: 10px;
  }

  .scrollenable {
    .slick-dots li {
      margin: 0px;
      width: 15px;
      height: 20px;
      margin: 0;
    }

 
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #2F2B23;
      font-size: 11px;
     
    }

    // .slick-dots li button:before {
    //   color: #757061;
    //   font-size: 8px;
    // }
  
    .scrollenable .slick-dots li button:focus:before{
      color: #2F2B23  !important;
      opacity: 1 !important;
      font-size: 11px;
   }

  }

  .header {
    .mobilehide {
      display: none;
    }

    .mobileshow {
      display: block;
    }
  }

  .footer {
    .mobilehide {
      display: none;
    }

    .mobileshow {
      display: block;
    }
  }

  .ethicalbrand {
    .mobilehide {
      display: none;
    }

    .mobileshow {
      display: block;
    }
  }
}

@media only screen and (max-width: 919px) {
  .mobilehide {
    display: none;
  }

  .mobileshow {
    display: block;
  }
}

@media only screen and (max-width: 919px) {
  .slick-arrow {
    display: none !important;
  }

  .main-heading40 {
    font-size: 34px;
    line-height: 39px;
    margin: 3vw 0 !important;
    letter-spacing: -0.3px;
    font-weight: 500;
    font-style: normal;
    font-family: 'GT-Super-Text-Medium';
    //text-shadow: 1px 1px 2px #949d8ce3;
  }
  .homepage27{
   .main-heading40 {
    font-size: 23.239px;
    line-height: 28px;
    margin: 3vw 0 !important;
    letter-spacing: -0.3px;
    font-weight: 500;
    font-style: normal;
    text-align: left !important;
    font-family: 'GT-Super-Text-Medium';
    // padding-left: 16px;
  }
  .dynamiccontent .main-heading40{
    font-size: 22.6px;
  }
}
//   .paddleft16{
//   padding-left: 16px !important;
// }
  .graycolor949d8ce3{
    font-weight: 600 !important;
  }
  .homepage27 .shopbyvalue .main-heading40 {
    text-align: center !important;
  }

  .testimonials {
    .main-heading40 {
      margin-bottom: 19.5px !important;
    }
  }

  .blackcolor2F2B23 {
    font-family: 'GT-Super-Text-Regular';
  }

  .paddingleft14vw {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 413px) {
  .main-heading40 {
    font-size: 8vw;
    line-height: 9vw;
  }

  .mobilesliderdesign {
    padding: 3vw 5vw;
  }

  .blogsection {
    .mobilesliderdesign {
      padding: 0;
    }
  }
}

@media (min-width: 601px) and (max-width: 919px) {

  .slick-prev:before,
  .slick-next:before {
    width: 2.4vw;
    height: 2.4vw;
  }
}


@media (min-width: 920px) and (max-width: 1280px) {
  .main-heading40 {
    // font-size: 2.4vw;
    // line-height: 2.395vw;
    font-size: 3vw;
    line-height: 3.2vw;
  }


  .slick-prev:before,
  .slick-next:before {
    width: 2.2vw;
    height: 2.2vw;

  }

  .slick-next,
  .slick-prev {
    top: 38%;
  }

  .pera-text18 {
    font-size: 1.2vw;
    line-height: 1.5vw;
  }

  .homepage27 {
    .pera-text18 {
      font-size: 1.76vw;
      line-height: 2.1vw;
    }
  }
}

.cursordefult {
  cursor: default !important;
}

.cursorpointer {
  cursor: pointer !important;
}

.cursordefult {
  cursor: default !important;
}

.placeholder-img {
  background-color: #f0f0f0;
  border-radius: 50%;
}

@media only screen and (min-width: 3000px) {
  .MuiSvgIcon-root {
    width: 1.3vw !important;
    height: 1.3vw !important;
  }
}

@media only screen and (max-width: 360px) {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 95% !important;
    min-height: 201px;
    max-width: 80vw !important;
    pointer-events: auto;
    width: 95% !important;
  }
  .homepage27 .main-heading40 {
    font-size: 21.9px !important;
    line-height: 25px !important;
    margin: 3vw 0 !important;
    // letter-spacing: -0.3px;
    font-weight: 500;
    font-style: normal;
    text-align: left !important;
    font-family: 'GT-Super-Text-Medium';
  }
  .homepage27 .dynamiccontent .main-heading40{
   font-size: 20.8635px !important;
  }
  .css-uhb5lp {
    min-width: 95% !important;
    min-height: 201px;
    max-width: 80vw !important;
    pointer-events: auto;
    width: 95% !important;
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .trendingproducts {
    padding-top: 24px !important;
  }

  .slick-next:before,
  .slick-prev:before {
    opacity: 1 !important;
  }

  .customeraccount .all-screen-container,
  .css-1oqqzyl-MuiContainer-root {
    max-width: 95% !important;
  }

  .head-btn button {
    margin-right: 0.9vw !important;
  }

  .registercheckout {

    div.all-screen-container,
    .css-1oqqzyl-MuiContainer-root {
      max-width: 95% !important;
    }
  }

  .guestcheckoutnew {

    div.all-screen-container,
    .css-1oqqzyl-MuiContainer-root {
      max-width: 95% !important;
    }
  }
}

@media only screen and (max-width: 280px) {
  .css-ypiqx9-MuiDialogContent-root {
    padding: 20px 18px !important;
  }
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 'none';
  -webkit-transition: 'all 0.2s ease-out';
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

.css-177ic5c,
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 10px !important;
}

.favouritebrands {
  .main-heading40.trending-heading {
    color: #000000;
    font-weight: 500;
  }
}

.swal2-container .swal2-actions {
  justify-content: center;
}

@media (min-width:280px) and (max-width:375px){
  .homepage27 .dynamiccontent .main-heading40 {
    font-size: 21.5px;
}
}