.trendingcollection {
    padding: 3vw 0;

    .shopcollection {
        position: relative;
        width: 100%;
        padding: 2%;
        display: inline-block;
        height: auto;
    }

    .main-heading40.trending-heading {
        color: #0a0a0a;
    }

    .basket-btn,
    .buy-btn {
        padding-right: 0px !important;
        min-width: 1vw !important;

    }

    .img-row {
        height: 16vw;
        width: 100%;
        padding: 1%;
        min-height: 150px;
        border-radius: 2.083vw;
        object-fit: cover;
        cursor: pointer;
        background-color: transparent
    }

    .unpublished {
        background: #F4F5F6 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        z-index: 999;
        color: #000000;
        width: auto;
        text-align: center;
        position: absolute;
        width: auto;
        bottom: 2.2vw;
        right: 0;
        left: 0;
        max-width: 70%;
        margin: 0 auto;
        padding: 10px 13px;
        font-family: "Asap";
        font-weight: normal;
        font-style: normal;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        padding: 0.651vw 1.25vw 0.614vw 1.2822vw;
      
    }

    .basket-btn.darkbtn {
        background-color: transparent;
        color: #504D59 !important;
    }

    .slick-next,
    .slick-prev {
        top: 50%;
    }
}

@media (min-width:600px) and (max-width:920px) {
    .trendingcollection {
        .trending-heading {
            display: block !important;
            text-align: center;
        }

        .shopcollection {
            position: relative;
            width: 100%;
            padding: 0.5%;
            display: inline-block;
            height: auto;
        }

        .img-row {
            height: 24vw;
            width: 100%;
            min-height: 150px;
            border-radius: 2.083vw;
            object-fit: cover;
            cursor: pointer;
        }

        .unpublished {
            font-size: 1.65vw;
            line-height: 1.8vw;
            padding: 10px 13px;
            bottom: 20px;
            max-width: 130px;
        }
       
    }
}

@media (min-width:280px) and (max-width:600px) {
    .trendingcollection {
        .trending-heading {
            display: block !important;
            text-align: center;
        }

        .shopcollection {
            position: relative;
            width: 100%;
            padding: 0.5%;
            display: inline-block;
            height: auto;
        }

        .img-row {
            height: 38vw;
            width: 100%;
            min-height: 150px;
            border-radius: 20px;
            object-fit: cover;
            cursor: pointer;
        }

        .unpublished {
            font-size: 14px;
            line-height: 16px;
            padding: 10px 13px;
            max-width: fit-content;
            min-width: 100px;
            border-radius: 40px;
            bottom: 20px;
        }
        .basket-btn.darkbtn {
            background-color: #363240 ;
            color: #FFF !important;
        }
        .basket-btn, .buy-btn{
            padding: 13px 33px !important;
            min-height: 10vw !important;
        }
    }
}

@media (min-width:1930px) {
    .trendingcollection {

        .unpublished {
            background: #F4F5F6 0% 0% no-repeat padding-box;
            border-radius: 2.5vw;
            opacity: 1;
            z-index: 999;
            color: #000000;
            width: auto;
            text-align: center;
            position: absolute;
            width: auto;
            bottom: 1.2vw;
            right: 0;
            left: 0;
            max-width: fit-content;
            margin: 0 auto;
            padding: 10px 13px;
            font-family: "Asap";
            font-weight: normal;
            font-style: normal;
            font-size: 1vw;
            line-height: 1.25vw;
            padding: 0.651vw 1.25vw 0.614vw 1.2822vw;
        }
    }
}