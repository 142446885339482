@import "../abstracts/variables";

.resetpassword{
    .password-section {
        max-width: 829px;
        margin: auto;
        padding-top: 2.2342vw;
        margin-bottom: 6.78020vw;
    }
    .visibilitypassword{
        position: absolute;
        right: 31%;
        top: 10%;
    }
    .visibilityconfirmpassword{
        position: absolute;
        right: 31%;
        top: 10%; 
    }
    .reset-password-heading{
        text-align: center;
    font-size: 72px;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    font-family: $gt-font-regular;
    }
    .password-para{
        text-align: center;
    font-size: 16px;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    font-family: $asap-font-regular;
    margin-top: 49px;
    margin-bottom: 67px;
    }
    .email-input-field {
        text-align: left;
        font-size: 24px;
        letter-spacing: 0px;
        color: #949d8c;
        opacity: 1;
        font-family:$asap-font-regular;
        border: none;
        width: 40%;
        min-width: 200px;
    }
    .css-a88p61-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before{
        border-bottom: 1px solid #949d8c !important;
    }
    .css-a88p61-MuiInputBase-root-MuiInput-root:after{
        border-bottom: 1px solid #949d8c !important;
    }
    .css-ercmji:hover:not(.Mui-disabled)::before{
        border-bottom: 2px solid #949d8c !important; 
    }
    .css-ercmji::after{
        border-bottom: 2px solid #949d8c !important; 
    }
    .mrgbottom{
        margin-bottom: 30px;
        position: relative;
    }
    .password-icon{
        position: relative; 
    }
    .basket-btn, .buy-btn{
        width: 153px;
        height: 55px;
        margin-bottom: 91px;
        margin-top: 74px;
    }
}