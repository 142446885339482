.shopcollection{
     .img-row{
        height: 16vw;
        width: 100%;
        min-height: 150px;
        border-radius: 2.083vw;
        object-fit: cover;
        cursor: pointer;

    }
     .unpublished{
        background: #F4F5F6 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        z-index: 999;
        color: #000000;
        width: auto;
        text-align: center;
        position: relative;
        bottom: 3.5vw;
        right: 0;
        left: 0;
        max-width: 13.375vw;
        margin: 0 auto;
        padding: 10px 13px;
        font-family: "Asap";
        font-weight: 500;
        font-style: normal;
        font-size: 1.122vw;
        line-height: 1.25vw;
        padding: 0.651vw 1.25vw 0.614vw 1.2822vw;
    }

}
@media only screen and (max-width: 280px){
.shopcollection .img-row {
    height: 80vw !important;
    border-radius: 20px;
}
}
@media only screen and (max-width: 919px) {
    .shopcollection{
        .img-row{
            // height: 42vw;
            height: 140px;
            border-radius: 17.5px;
        }
        .unpublished{
            bottom:45px;
            padding: 11px 23px;
            font-size: 11px;
            line-height: 12px;
            max-width: 150px;
            background: #ffffff;
            font-weight: 600;
            border-radius: 40px;
        }
        .basket-btn, .buy-btn{
            margin: 0px 0 15px!important;
        }
    }
}
@media (min-width:601px) and (max-width: 919px) {
.shopcollection{ 
    .img-row {
    height: 24vw;
}
}
}
@media only screen and (max-width: 350px){
.shopcollectionsection{
.unpublished {
    bottom: 45px;
    padding: 2.5vw 3vw;
    font-size: 4.5vw;
    line-height: 6vw;
    max-width: 110px;
}
 .img-row{
    min-height: 120px;
}
}
}
@media (min-width:920px) and (max-width: 1280px) {
  .shopcollectionsection{
        .unpublished {
            max-width: 155px;
            font-size: 1.4vw;
            line-height: 1.4vw;
            background: #ffffff;
            padding: 1vw 1.25vw;
            bottom: 5.2vw;
        }
        .slick-next, .slick-prev {
            top: 50%;
        }
}
.shopcollection{

   .unpublished{
        // max-width: 155px;
        font-size: 1.4vw;
        line-height: 1.4vw;
        background: #ffffff;
        padding: 1.4vw 1.4vw;
        bottom: 1.2vw;
        position: absolute;
    }
}
}
@media only screen and (min-width: 1930px) {
   
   .shopcollection{
        .img-row {
            height: 12vw !important;
            min-height: auto;
        }
        .unpublished{
            font-size: 0.833vw;
            max-width: 8vw;
            padding: 0.5vw 0vw;
            bottom: 3vw;
        }
    }
 }