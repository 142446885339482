.out-of-stock-dialog{
    // .MuiDialog-paper{width:65% !important;}
    .colorheading{
        text-align: left;
    font-size: 1.04vw;
    line-height: 1.35vw;
    font-family: "GT-Super-Text-Regular";
    font-weight: 600;
    font-style: normal;
    font-variant: normal;
    letter-spacing: 0px;
    color: red;
    opacity: 1;
    margin-bottom: 1.21833vw;
    }
    .midcontent ul li{
        font-size: 1.0937vw;
        line-height: 1.25vw;
        font-family: " Asap";
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
        margin-bottom: 0.56vw;
    }
    .messagetext{
        font-size: 1.15vw;
        line-height: 1.4vw;
        font-family: " Asap";
        font-weight: 800;
        font-style: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #2f2b23;
        opacity: 1;
        margin-top: 1.056vw;
        margin-bottom: 1.8vw;
    }
    .popbtn{
        display: flex;
        justify-content: flex-end;
        .darkbtn{
            margin-right: 12px !important;
        }
    }
}
@media  (max-width: 919px) {
    .out-of-stock-dialog{

            .colorheading{
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 25px;
            }
            .midcontent ul li{
                font-size: 21px;
                line-height: 24px;
                margin-bottom: 10px;
            }
            .messagetext{
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 30px;
            }
   
    }
}