.newcheckout {
  padding-top: 3.75vw;
  // margin-bottom: 8.1944vw;
  border-top: 2px solid #e7e7e759;
  margin-top: 1vw;

  .latestArrivals .desktopbg {
    margin-top: 8.1944vw;
    background-size: 75%;
  }
  
  div#panel1a-header {
    margin-bottom: 0vw;
  }

  .summary-section {
    padding-right: 3vw;
  }

  .MuiAccordionSummary-root {
    display: inline-flex;
  }

  .MuiAccordion-rounded .Mui-expanded {
    margin: 0px !important;
    min-height: 45px;
    align-items: center;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0px !important;
  }

  .quantity {
    font-size: 1.237vw !important;
    line-height: 1.25vw;
    font-family: 'ASAP';
  }

  .multiquantity .quantity-btn-multi {
    border: none !important;
    margin-left: 0 !important;
    padding: 6px 0px 0px !important;
    font-size: 1.2vw;
    color: #757575 !important;

    .MuiInputBase-input {
      padding: 16.5px 14px 16.05px 0px;
      text-align: left;
      -webkit-text-fill-color: #afaeae !important;
      color: #afaeae !important;
    }
  }

  .multiquantity {
    .MuiFormControl-root {
      margin: 0px !important;
    }

    .newcheckout .size-part span {
      padding: 0;
    }
  }

  // .quantity span::before{
  //   content: ":";
  //   top: 12px;
  //   align-items: center;
  //   position: relative;
  //   padding-right: 5px;
  // }

  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.25em;
    height: 1.25em;
  }

  .exclusive-box {
    border: 1px solid #eeeeee;
    border-radius: 1.04vw;
    padding: 1.319vw 0.833vw 1.18vw;
    margin-bottom: 2.5vw;
    margin-left: 2vw;
  }

  .exclusive-text {
    font-family: $asap-font-regular;
    font-style: $btn-font-style;
    // font-weight: 600;
    font-weight: bold;
    font-size: 1.2vw;
    line-height: 1.25vw;
    // color: #2f2b23;
    color: #020202;
    // letter-spacing: 0.2px;

    span {
      // text-decoration: underline;
      border-bottom: 1px solid #2f2b23;
      cursor: pointer;
      margin: 0px 4px 0px 5px;
    }
  }

  .latestArrivals .main-heading40.trending-heading {
    // font-weight: 600 !important;
    font-weight: bold !important;
  }

  .mobile-button-section {
    text-align: center;
    margin-top: 31px;

    .darkbtn {
      width: 100%;
      font-size: 1.111111vw !important;
      // letter-spacing: 0.5px !important;
      // font-weight: 600 !important;
      font-weight: bold !important;
      line-height: 1.3194vw !important;
      color: #ffffff !important;
      background-color: #2f2b23;
      min-height: 4.375vw !important;
    }
  }

  .margintext {
    margin-top: 0.486vw;
    font-weight: 500;
  }

  .basket-heading {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 1.527vw;
    line-height: 1.73vw;
    // margin-bottom: 1.3vw;
    color: #2f2b23;
    // padding-left: 3vw;
    padding-left: 5vw;
  }

  .newcheckouttable {
    width: 100%;
    display: block;

    tbody {
      width: 100%;
      display: block;
    }
  }

  .newcheckouttable tr {
    border-bottom: 1px solid #e4e1d9;
    display: block;
    width: 100%;
    // align-items: center;
    padding-bottom: 5.9vw;
    border-bottom: 1.5px solid #e8e8e8;
    // margin-top: 0.98vw;
    margin-top: 1.3vw;
  }

  .MuiSvgIcon-root {
    color: #2f2b23;

    path {
      color: #2f2b23;
    }
  }

  table {
    .MuiSvgIcon-root {
      color: #2f2b23;

      path {
        color: #d8d8d8;
      }
    }

    .Mui-checked {
      path {
        color: #2f2b23 !important;
      }
    }
  }

  .checkoutimg {
    width: 10.277vw;
    height: 10.277vw;
    cursor: pointer;
    object-position: top;
    object-fit: cover;
  }

  .table_product_img {
    // width: 25%;
    padding-right: 1.666vw;
  }

  td {
    vertical-align: top;
  }

  .table_checkbox {
    display: table-cell;
    padding-right: 2.47vw;
    min-width: 5vw;

    .checkbox {
      margin-top: 2.5vw;

      .MuiCheckbox-root {
        padding: 0 !important;
      }
    }
  }

  .product-text {
    font-family: $asap-font-regular;
    font-style: normal;
    // font-weight: 600;
    font-weight: bold;
    font-size: 1.18vw;
    line-height: 1.319vw;
    color: #2f2b23;
    margin-bottom: 0.48vw;
    cursor: pointer;
  }

  // .quantityspace {
  //   margin-left: 3.4027vw;
  // }

  .sub-product-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.18vw;
    line-height: 1.319vw;
    // color: #757575;
    color: #afaeae;
    margin-bottom: 0.625vw;
  }

  .size-section {
    display: flex;
    align-items: center;
    margin-top: 0.7vw;
    // margin-bottom: 1.597vw;
    margin-bottom: 1.2vw;
  }

  .button-section {
    display: flex;
    align-items: center;
  }

  .size-part {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 1.21vw;
    line-height: 1.25vw;
    text-align: center;
    // color: #757575;
    color: #afaeae;
    margin-right: 1.4027vw;

    span {
      padding-left: 0.45vw;
    }

    .quantity {
      opacity: 1;
      color: #afaeae;
    }

    .multiquantity .quantity-btn-multi {
      // color: #757575 !important;
      color: #afaeae;
    }

    .quantityspace {
      margin-left: 2vw;
    }
  }

  .gift-section {
    display: flex;
    align-items: center;
  }

  .summary-text {
    font-family: $asap-font-regular;
    font-style: normal;
    // font-weight: 600;
    font-weight: bold;
    font-size: 1.66vw;
    line-height: 1.94vw;
    color: #2f2b23;
    // margin-bottom: 1.11vw;
    // letter-spacing: 0.5px;
    // margin-bottom: 7px;
  }

  .inputfield {
    border: 1px solid #eaeaea;
    border-radius: 0.69vw;
    min-height: 3.5416vw;
    width: 100%;
  }

  .input-field {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 1.1805vw;
    line-height: 1.3194vw;
    color: #2f2b23;
    border: 1px solid #eaeaea;
    border-radius: 0.69vw;
    min-height: 3.5416vw;
    width: 13.8vw;
    background-color: transparent;
  }

  .applybtn {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3194vw;
    line-height: 1.5277vw;
    text-align: center;
    border: 1px solid #cdcdcd;
    border-radius: 1.3888vw;
    min-height: 3.5416vw;
    color: #b8b8b8;
    min-width: 6.66vw;
    background-color: transparent;
    cursor: pointer;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #b8b8b8;
    font-family: 'Asap';
    font-weight: 400;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b8b8b8;
    font-family: 'Asap';
    font-weight: 400;
  }

  ::placeholder {
    color: #b8b8b8;
    font-family: 'Asap';
    font-weight: 400;
  }

  .applybtnactive {
    background-color: #949d8c;
    color: #ffffff;
  }

  .MuiAccordionDetails-root {
    display: block;
    padding: 0 !important;
  }

  .btn-section {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .promo-code {
    font-family: $asap-font-regular;
    font-style: normal;
    // font-weight: 600;
    font-weight: bold;
    font-size: 1.18vw;
    line-height: 1.3194vw;
    color: #2f2b23;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordion-root:before {
    background-color: transparent;
  }

  .MuiAccordionSummary-root {
    padding: 0px;
  }

  .price-section {
    margin-top: 0.5vw;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
  }

  .total-price-section {
    margin-top: 1.25vw;
  }

  .subtotal {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 1.215vw;
    line-height: 1.388vw;
    color: #2f2b23;
    margin-bottom: 1.38vw;
  }

  .margbott {
    margin-bottom: 1.8vw;
  }

  .total {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 700;
    font-size: 1.3194vw;
    line-height: 1.5277vw;
    text-align: center;
    color: #2f2b23;
    // margin-bottom: 1.3vw;
    margin-bottom: 1.2vw;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
}

@media (max-width: 920px) {
  .newcheckout {
    .innerpages{
      display: none ;
    }
    .latestArrivals .main-heading40.trending-heading {
      // font-size: 30px;
      // line-height: 35px;
      padding-top: 15px;
      text-align: center!important;
    }
    .latestArrivals .buttontext_section{
      display: block;
    }
    .mobilehide {
      display: none !important;
    }
    .moblespacetop {
      margin-top: 96px !important;
      padding-top: 0px !important;
    }

    .input-field {
      font-size: 16px;
      line-height: 18px;
    }
    .latestArrivals .main-heading40.trending-heading {
      // font-weight: 600;
      font-weight: bold;
    }

    .latestArrivals .shopbyvaluecard .icon-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiSvgIcon-root {
      width: 48.33px;
      height: 28.29px;
    }

    .latestArrivals .darkbtn {
      font-size: 16px !important;
    }

    .quantity span::before {
      content: ':';
      top: 3px;
      align-items: center;
      position: relative;
      padding-right: 5px;
    }

    .checkbox span.Mui-checked {
      svg {
        border: none;
      }
    }

    table .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 1px solid #d8d8d8;

      path {
        color: #fff;
        width: 28px;
        height: 28px;
        font-size: 28px;
      }
    }

    .MuiAccordionSummary-root {
      display: inline-flex;
    }

    .basket-heading {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 8px;
      padding-left: 0vw;
      margin-top: 20px;
      letter-spacing: 0px;
      color: #2f2b23f0;
    }

    .top-basket-heading {
      // margin-top: 30px;
      margin-bottom: 52px;
      text-align: center;
    }

    .multiquantity .quantity-btn-multi {
      border: none !important;
      height: 12px;
    }

    .item-text {
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
      color: #757575;
      text-transform: capitalize;

      .item-price {
        color: #2f2b23;
      }

      span {
        padding: 0 2px 0 0px;
      }
    }

    .exclusive-text {
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0;
    }

    // .margintext {
    //   margin-top: 4px;
    // }
    .exclusive-text span {
      margin-left: 5px;
    }

    .exclusive-box {
      padding: 22px 19px 19px;
      border-radius: 15px;
      margin-left: 0px;
    }

    .newcheckouttable tr:after {
      content: '';
      height: 1px;
      background: #e4e1d9;
      width: 105%;
      position: absolute;
      bottom: 0;
      left: -8px;
      right: 0;
    }

    tbody:before {
      content: '';
      height: 1px;
      background: #d8d8d8;
      width: 105%;
      position: absolute;
      top: 0;
      left: -8px;
      right: 0;
    }

    .newcheckouttable tr,
    tbody {
      position: relative;
      border: none;
    }

    .summary-text {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 7px;
    }

    .MuiAccordion-rounded .Mui-expanded {
      margin: 0px !important;
      min-height: 37px;
      align-items: center;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin-top: 0px !important;
    }

    .latestArrivals .desktopbg {
      margin-top: 0;
    }

    .promo-code {
      font-size: 17px;
      line-height: 19px;
    }

    .applybtn {
      font-size: 14px;
      line-height: 16px;
      border-radius: 20px;
      min-height: 48px;
      min-width: 78px;
      margin-left: 10px;
    }

    .input-field {
      border-radius: 10px;
      min-height: 48px;
      width: 100%;
      min-width: 68vw;
    }

    .subtotal {
      font-size: 17px;
      line-height: 19px;
      margin-bottom: 11px;
    }

    .subtotal-price {
      text-align: end;
    }

    .total {
      font-size: 19px;
      line-height: 22px;
      margin-bottom: 11px;
    }

    .latestArrivals .mobilebg {
      background: none !important;
    }

    .table-price-text {
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 54px;
      padding-top: 5px;
    }

    .table_price {
      text-align: center;
    }

    .checkoutimg {
      width: 100%;
      min-width: 80px;
      height: 90px;
      margin: 0 0px 0 0;
    }

    .table_product_img {
      width: 24%;
      padding-right: 0px;
    }

    .product-text {
      font-size: 17px;
      line-height: 19px;
      margin-bottom: 35px;
      font-weight: 500;
      margin-left: 15px;
      padding-top: 5px;
    }

    .quantity {
      font-size: 16px !important;
      line-height: 18px !important;
    }

    .multiquantity .quantity-btn-multi {
      width: 70px;
      height: 25px;
      font-size: 16px;
      line-height: 18px;
    }

    .sub-product-text {
      display: none;
    }

    .color-text {
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: #757575;
      margin-bottom: 7px;
    }

    .size-section {
      display: block;
      padding-left: 15px;
    }

    .size-part {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 7px;
      text-align: left;
    }

    .quantityspace {
      margin-left: 0;
    }

    .newcheckouttable tr {
      vertical-align: top;
      margin-top: 23px;
      display: inline-table;
      padding-bottom: 38px;
    }

    .gift-option-dialog {
      font-size: 16px;
      line-height: 18px;
      margin-right: 20px;
      border-bottom: 0.9px solid #2f2b23;
      color: #2f2b23;
      // text-decoration: underline 1.5px;
    }

    .gift-section {
      margin-top: 40px;
      padding-left: 15px;
    }

    .summary-section {
      padding-top: 38px !important;
      padding-right: 0vw;
    }

    .price-section {
      border-bottom: none !important;
      margin-top: 20px;
    }

    .total-price-section {
      margin-top: 14px;
    }

    tbody {
      // border-top: 1.5px solid #D8D8D8;
      margin-top: 19px;
    }

    .mobile-button-section {
      text-align: center;
      background: #ffffff;
      // padding: 23px 45px 20px;
      padding: 2px 45px 4px;
      position: fixed;
      bottom: 0px;
      width: 100%;
      border-top: 1px solid #eaeaea;
      z-index: 999999;
      position: -webkit-sticky;
      position: sticky;
      margin-top: 32px;

      .darkbtn {
        width: 100%;
        min-height: 65px !important;
        font-size: 20px !important;
        font-weight: bold !important;
        // letter-spacing: 0.2px;
      }
    }

    .logincheckout {
      text-align: center;
      background: #ffffff;
      padding: 23px 45px 20px;
      position: fixed;
      bottom: 0px;
      width: 100%;
      z-index: 999999;
      position: fixed;

      .darkbtn {
        font-size: 20px !important;
        width: 100%;
        min-height: 65px !important;
        color: #ffffff !important;
        background-color: #2f2b23;
        margin: 0px 0px 15px !important;
        font-weight: bold !important;
      }
    }

    .checkoutoverlay {
      background-color: rgba(0, 0, 0, 0.2);
      height: 100vh;
      z-index: 9999;
      width: 100%;
      position: relative;
      position: fixed;
      top: 0;
    }
  }
}

@media (max-width: 375px) {
  .newcheckout {
    .gift-option-dialog {
      font-size: 14px !important;
    }
  }
}

@media (min-width: 930px) and (max-width: 1280px) {
  .newcheckout {
    .MuiSvgIcon-root {
      fill: currentColor;
      height: 1em;
      width: 1em;
    }

    .total-price-section {
      border-bottom: 1px solid #e8e8e8 !important;
    }

    .input-field {
      width: 13vw;
    }

    .subtotal-price {
      text-align: end;
    }
  }
}

@media (min-width: 1150px) and (max-width: 1300px) {
  .newcheckout .input-field {
    width: 14vw;
  }
}

@media (min-width: 1500px) and (max-width: 1519px) {
  .newcheckout {
    .input-field {
      width: 14vw;
    }
  }
}
@media (min-width: 1520px) and (max-width: 1600px) {
  .newcheckout {
    .input-field {
      width: 10.2vw;
    }
  }
}
@media (min-width: 1601px) and (max-width: 1929px) {
  .newcheckout {
    .input-field {
      width: 11.2vw;
    }
  }
}
@media (min-width: 1930px) {
  .newcheckout {
    .checkoutimg {
      width: 9.277vw;
      height: 9.277vw;
    }
    .basket-heading {
      padding-left: 2.5vw;
    }
    .summary-text {
      margin-bottom: 0.7vw;
    }
    .product-text {
      width: 34vw;
    }
    .size-section{
      align-items: baseline;
      width: 34vw;
    }
    .size-part{
      text-align: left;
    }
    .exclusive-text {
      font-size: 0.99vw;
      span {
        border-bottom: 0.1vw solid #2f2b23;
        margin: 0 0.2vw 0 0.23vw;
      }
    }
    .table_checkbox {
      padding-right: 0vw;
      min-width: 2vw;
    }
    .summary-section {
      padding-right: 0vw;
    }
    .input-field {
      width: 10vw !important;
      font-size: 1vw !important;
      margin-bottom: 0.948vw;
    }

    .btn-section {
      margin-top: 1vw;
    }
    .latestArrivals .desktopbg {
      background-size: 70% !important;
    }
    .applybtn {
      min-width: 5.66vw;
    }

    .MuiSvgIcon-root {
      width: 1.7vw;
      height: 1.7vw;
    }

    .table_product_img {
      width: 24%;
    }
  }
}
