@import "../abstracts/variables";
.shopbyvaluecard .price{
   font-size: 1.51770vw;
   line-height: 1.71875vw;
   color: #9CA595;
   font-family: "Asap";
   margin-top: 0.8vw;
   display: none;
   font-weight: 600;
}
.homepage27{
   .latestArrivals{
      margin-bottom: 7.1875vw;
   }
}
.latestArrivals{
   margin-top: 0.64vw;
   background: #F5F4F3 0% 0% no-repeat padding-box;
   .latest-section{
    
    opacity: 1;
    padding: 7.708vw 0 5.09vw;
   } 
   .latest-para{
    color: #848484;
   //   opacity: .7;
     margin-bottom: 2.552vw;
     padding-right: 3vw;
   }
   .saller-img{
      display: none;
   }
   .main-heading40.trending-heading{
   font-size: 1.5625vw;
    line-height: 1.8229vw;
   //  opacity: 0.9;
    margin-bottom: 1.8229vw;
    color: #0A0A0A;
   }
   .shopbyvaluecard .shop-icon {
      width: 100%;
      height: 8.02vw;
      height: 12.656vw;
      border-radius: 2.08vw;
      margin-bottom: 0.9635vw;
      background-size: cover;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      object-fit: cover;
   }
   .shopbyvaluecard .icon-name{
      color: #302d37;
      line-height: 1.04vw;
      font-family: $asap-font-regular;
      font-size: 0.8854vw;
   }
  
   .shopbyvaluecard {
      padding: 0 0.855vw;
  }
  
  .desktopbg{
     background: url('../../assets/images/home/latest-arrivals-bg-line.webp');
     background-size: 80%;
    background-position-x: right;
    background-position-y: 4vw;
    background-repeat: no-repeat;
  }
}
@media (min-width:1500px) and (max-width:2000px) {
   .latestArrivals{
      .slick-slider{
         margin-right: 20px;
        }
   }
}
@media (min-width:920px) and (max-width:1280px) {
   .latestArrivals{
      .desktopbg{
         background :transparent;
      }
   }
}
@media (min-width:920px) and (max-width:1280px) {
   .latestArrivals{
      .desktopbg{

      }
      .shopbyvaluecard{
         .icon-name{
            line-height: 1vw;
            font-size: 1.2vw;
         }
         .shop-icon{
            height:16.26vw;
         }
      }
      .ls97inch{
         padding-right: 0.7vw;
         padding-left:6vw !important;
      }
      .main-heading40.trending-heading {
         font-size: 2.7vw;
         line-height: 2.8229vw;
         margin-bottom: 2.33vw;
      }
      button.basket-btn.darkbtn{
         font-size: 1.5vw !important;
         line-height: 2vw !important;
         padding: 1.2vw 2.4vw !important;
      }
      .latest-para{
         margin-bottom: 3.5vw;
      }
       .desktopbg {
         background: none
      }
   }
 
}
@media only screen and (max-width: 280px){
   .latestArrivals {
      .main-heading40.trending-heading {
         font-size: 8vw !important;
      }
      .latest-para {
         font-size: 14px !important;
         line-height: 16px !important;
      }
      .shopbyvaluecard .shop-icon {
         height:70vw !important;
      }
      .darkbtn{
         margin-top:15px !important;
      }
      .latest-section.mobilebg{
         background-size: 95% 80% !important;
         background-position-y: 50% !important;
      }
   }
}
@media (min-height:280px) and (max-width: 350px) {
   .latestArrivals {
      .mobilebg {
         background-size: 100%;

         background-position-y: 35% !important;
      }
   }
}

@media only screen and (max-width: 919px) {
   .shopbyvaluecard .price{
      font-size: 15px;
      line-height: 18px;
      padding-left: 10px !important;
   }
 
   .latestArrivals{
      .latest-section{
         padding-top: 0;
      }
      .buttontext_section{
         display: flex;
         align-items: center;
         justify-content: space-between;
      }
      .mobilebg{
         background: none !important;
      }
      .main-heading40.trending-heading {
         text-align:left;
         font-size: 22px;
         line-height: 26px;
         margin-bottom: 0 !important;
      }
      .latest-para{
         text-align: center;
         font-size: 15.5px;
         line-height: 18px;
         margin-top: 10px;
         padding: 0px;
         padding: 0 3% 0 1%;
         font-weight: 500;
     }
     .shopbyvaluecard .shop-icon {
         height: 42vw;
         border-radius: 20px;
      }
      .shopbyvaluecard .icon-name {
         font-size: 16px;
         line-height: 18px;
         margin-left: 7px !important;
         // font-weight: 600;
         margin:8px 0;
         word-break: break-all;
         word-break: break-all;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
     }
  
     .darkbtn{
        margin:35px 0  ;
     }
         .mobilebg {
            background: url('../../assets/images/home/LatestArrivals-bg-arrow-mobile.webp');
            background-size: 95%;
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: center;
      }
   }

   .homepage27{
      .latestArrivals{
         .latest-para{
            text-align: left !important;
         }
         .buttontext_section{
            padding-top: 10px;
         padding-bottom: 10px;
         }
         .main-heading40.trending-heading{
            font-size: 24px;
       line-height: 26px;
       margin-bottom: 0 !important;
        margin-top: 0 !important;
         }
         .latest-section {
            opacity: 1;
            padding-bottom: 0vw;
        }
        .homepagesbtn{
         display: none;
        }
        
      }
   }
}


@media only screen and (min-width:1930px){
   .latestArrivals{ .shopbyvaluecard .shop-icon {
      height: 9.4vw;
  }
      .shopbyvaluecard {
      padding: 0 0.5vw;
   }
}
}
@media (min-width:601px) and (max-width: 919px) {
.latestArrivals {.mobilebg {
   background-size: 90% 77%  !important;
   background-position: center !important;
}
// .darkbtn{
//    width: 40%;
//    min-width: 40%;
// }
.shopbyvaluecard .shop-icon {
   height: 24vw;
   border-radius: 20px;
}
}
}
@media (min-width:2000px) and (max-width: 2599px) {
   .latestArrivals .desktopbg {
      background-size: 72%;
  }
}
@media (min-width:2600px) and (max-width: 3999px) {
   .latestArrivals .desktopbg {
      background-size: 66% !important;
  }
}
@media (min-width:4000px) and (max-width: 4300px) {
   .latestArrivals .desktopbg {
      background-size: 75%;
  }
}
@media (min-width:4301px) and (max-width: 5000px) {
   .latestArrivals .desktopbg {
      background-size: 70%;
  }
}
@media (min-width:5000px) and (max-width: 5500px) {
   .latestArrivals .desktopbg {
      background-size: 66%;
  }
}
@media (min-width:5500px) and (max-width: 6000px) {
   .latestArrivals .desktopbg {
      background-size: 64%;
  }
}
