.newcheckoutpageskeleton {
  .offerbar {
    height: 5vw;
    border-radius: 1vw;
    margin-bottom: 2vw;
  }
  .titleme {
    width: 25%;
    margin-bottom: 1vw;
  }
  .proimg {
    width: 100%;
    height: 10vw;
  }
  .sptext {
    width: 60%;
  }
  .twoval {
    display: flex;
    .sptext {
      width: 25%;
      margin-right: 1vw;
      margin-top: 0.8vw;
    }
  }
  .titlemesm {
    width: 50%;
    height: 3.5vw;
    margin-bottom: 1vw;
  }
  .sptext80 {
    width: 80%;
  }
  .twovalsm {
    display: flex;
    justify-content: space-between;
    .sptext {
      width: 30%;
    }
  }
  .twoval_total {
    display: flex;
    justify-content: space-between;
    margin: 0.5vw 0;
    padding: 0.5vw 0;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    .sptext {
      width: 30%;
    }
  }
  .sum_btn {
    margin-top: 2vw;
    height: 4vw;
    border-radius: 2.5vw;
  }
}
