@import '../abstracts/variables';

.innerbanner {
  .buy-heading {
    text-align: left;
    font-size: 3.541vw;
    line-height: 4.11vw;
    font-weight: normal;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-family: "GT-Super-Text-Medium";
    letter-spacing: 0px;
    color: #2F2B23;
    margin-bottom: 1.61vw;
    // padding-right: 218px;
  }

  .image-section {
    padding: 2.75vw 0 2.75vw 0vw;
    width: 50%;
  }

  .image-banner-home .buy-para {
    text-align: left;
    font-weight: normal;
    color: #2F2B23;
    opacity: 1;
    margin-bottom: 3.385vw;
    padding-right: 231px;
  }

  .baanerbgimg {
    min-height: 17.18vw;
    background-size: cover;
    background-color: #f5f4f3;
    background-repeat: no-repeat;
    background-position: center;

  }
  .innerbannerimg{
    width:100%;
    max-width: 100%;
    max-height: 24.5vw;
    object-fit: cover;
    object-fit: fill;
    width: 100%;
    min-height: 20vw;
    display: block;
  }
  .baanerbgimg{
    position: relative;
  }
  .image-section{
    position: absolute;
    top:0;
  }

}

@media only screen and (max-width: 280px) {
.innerbanner .baanerbgimg, .innerbanner .innerbannerimg {
  min-height: 280px !important;
}
}
@media only screen and (max-width: 600px) {
  .innerbanner {
    .baanerbgimg, .innerbannerimg {
      min-height: 335px;
      object-fit: cover;
    }
    
    .image-section {
      width: 100%;
      display: inline-grid;
      align-items: center;
      min-height: 280px;
      max-width: 90%;
    }

    .buy-heading {
      font-size: 36px;
      line-height: 38px;
      margin-bottom: 20px;
      padding-right: 0;
    }

    .buy-para {
      margin-bottom: 20px;
      padding-right: 0;
      font-size: 18px;
      line-height: 24px;
      min-height: 220px;
    }
  }
}

@media only screen and (max-width: 413px) {
  .innerbanner {
    .image-section {
      padding: 7.75vw 0 2.75vw 0vw;
      width: 100%;
      display: inline-grid;
      align-items: center;
      min-height: 280px;
  
    }

    // .baanerbgimg .innerbannerimg {
    //   min-height: 81vw;
    // }

    .buy-heading {
      font-size: 8vw;
      line-height: 9vw;
      margin-bottom: 4vw;
      padding-right: 0;
    }

    .buy-para {
      margin-bottom: 20px;
      padding-right: 0;
      font-size:18px;
      line-height: 24px;
    }
  }
}

@media (min-width:601px) and (max-width: 959px) {
  .innerbanner {
    .image-section {
      width: 57%;
    }

    .buy-heading {
      font-size: 30px;
      line-height: 35px;
      margin-top:0px;
    }

    .buy-para {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 2vw;
    }

    .baanerbgimg {
      height: auto;
    }
  }
}
@media only screen and (min-width: 1930px) {
  .innerbanner .image-section {
    padding: 2.75vw 0 2.75vw 0vw;
    width: 44%;
  }
 
}
@media  only screen and (min-width: 2500px){

.products .all-screen-container {
  padding-top: 0px;

  }
}