.onoffswitch{
    .MuiSwitch-root{
        width:auto !important;
        height:auto !important;
        .MuiSwitch-switchBase{
            color:#fff;
        }
    }
    .Mui-checked{
        // margin-right: -5px;
        color:#fff !important;
    }
    .Mui-checked+.MuiSwitch-track{
        background-color: #7F6A86!important;
        opacity: 1 !important;
        
    }
}

.onoffswitch{
   .MuiSwitch-thumb {
    box-shadow: none !important;
    height: 24px !important;
    margin: 0px 0px 0px 0px !important;
    width: 24px !important;
    
}
.MuiSwitch-track {
    border-radius: 15px !important;
    width: 52px !important;
    height: 27px !important;
    margin-left: 0px;
}
.MuiSwitch-switchBase.Mui-checked{
    margin-left: 5px;
}
}