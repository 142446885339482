.customeraccountskeleton{
    .profileimg {
        width: 10vw;
        height: 10vw;
        margin-right: 30px;
    }
    .profilename {
        width: 14vw;
        height: 4vw;
    }
    .threebtnskl {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .messagebtn {
        width: 5vw;
        height: 3vw;
        border-radius: 2vw;
        margin-right: 30px;
    }
    .savecard {
        width: 10vw;
        height: 3vw;
        border-radius: 2vw;
        margin-right: 30px;
    }
    .saveaddress {
        width: 14vw;
        height: 3vw;
        border-radius: 2vw;
    }
    .alignitem{
        align-items: center;
        margin-bottom: 30px;
    }
    .mr-right{
        margin-right: 30px;
    }
    
}