@import "../abstracts/variables";
.refer-friend {
  .refer-img {
    width: 13.1352vw;
    height: 7.3125vw;
    margin-bottom: 1.3229vw;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
  .refer-friend-img {
    text-align: center;
  }
  .buy-btn.lightbtn {
    color: #FFFFFF !important;
    min-width: 8.4895vw !important;
}
  .refer-heading {
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.0312vw;
    font-family: $gt-font-regular;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 1.322vw;
  }

  .credit-text {
    text-align: center;
    font-size: 1.354vw;
    line-height: 1.56vw;
    font-family: $gt-font-regular;
    color: #2f2b23;
    opacity: $opcity;
    margin-bottom: 1.1979vw;
  }
  .credit-para {
    text-align: center;
    font-size: 0.98958vw;
    line-height: 1.145vw;
    font-family: $asap-font-regular;
    color: $color707070;
    opacity: 0.9;
    margin-bottom: 1.50625vw;
  }

  .input-field-textarea {
    background: #f5f4f3 0% 0% no-repeat padding-box;
    border-radius: 1.875vw;
    opacity: 1;
    width: 100%;
    padding: 1.00833vw 1.159375vw 0.91718vw;
    font-size: 1.09375vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
    border: $btn-border;
    margin-bottom: 0.90625vw;
    resize: none;
    color: #707070;
  }
  textarea.input-field-textarea:focus-visible {
    outline: none;
}
  .input-field {
    font-size: 1.09375vw;
    color: $color707070;
    width: 100%;
  }
  .MuiPaper-rounded {
    border-radius: 40px;
    width: 24.427082vw;
  }
  .send-invite-btn {
    text-align: center;
  }
}

.referfriend {
  .refer-friend-btn {
    background: #949d8c;
    border-radius: 2.5vw;
    font-family: $asap-font-regular;
    color: rgba(255, 255, 255, 0.929412);
    min-width: 10.989vw;
    min-height: 2.7vw;
    margin-left: 0.8854vw;
    font-size: 1.09375vw;
    line-height: 0.937vw;
    padding-top: 0px;
    text-transform: capitalize;
  }
}

@media (max-width: 600px) {
  .refer-friend {
  .buy-btn.lightbtn{
    font-size: 16px !important;
    line-height: 18px !important;
    padding: 10.5px 30px !important;
    }
    .refer-img {
      width: 39.1352vw;
    height: 20.3125vw;
      margin-bottom: 2.3229vw;
    }
    .refer-friend-img {
      text-align: center;
    }
    .refer-heading {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 15px;
      font-weight: 600;
      color: #7e7e7e;
    }

    .credit-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 15px;
    }
    .credit-para {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 21.16px;
    }

    .input-field-textarea {
      font-size: 18px;
      line-height: 21px;
      border-radius: 18px;
      padding-left: 15.18px;
      margin-bottom: 0px;
    }
    .input-field {
      font-size: 4.09375vw;
      color: #707070;
      width: 100%;
      height: 41px;
      margin-bottom: 6.66px;
      padding-left: 15.18px;
      border-radius: 48px;
      font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    }
    .MuiPaper-rounded {
      border-radius: 40px;
      width: 69.427082vw;
    }
    .send-invite-btn {
      text-align: center;
    }
  }
}

@media (min-width: 600px) and (max-width: 920px) {
  .refer-friend {
    .refer-img {
      width: 25.1352vw;
      height: 20.3125vw;
      margin-bottom: 2.3229vw;
    }
    .refer-friend-img {
      text-align: center;
    }
    .refer-heading {
      font-size: 26px;
      line-height: 29px;
    }

    .credit-text {
      font-size: 4.354vw;
      line-height: 3.56vw;
      font-family: "GT-Super-Text-Regular-Trial";
      margin-bottom: 2.1979vw;
    }
    .credit-para {
      font-size: 16px;
      line-height: 18px;
    }

    .input-field-textarea {
      font-size: 18px;
      line-height: 21px;
      border-radius: 18px;
      padding-left: 15.18px;
      margin-bottom: 6.66px;
    }
    .input-field {
      font-size: 18px;
      line-height: 21px;
      color: #707070;
      width: 100%;
      height: 41px;
      margin-bottom: 6.66px;
      padding-left: 15.18px;
    }
    .MuiPaper-rounded {
      border-radius: 40px;
      width: 60.427082vw;
    }
    .send-invite-btn {
      text-align: center;
    }
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .refer-friend {
    .refer-img {
      width: 180px;
      height: 150px;
      margin-bottom: 0px;
    }
    .refer-friend-img {
      text-align: center;
    }
    .refer-heading {
      font-size: 27px;
      line-height: 30px;
    }

    .credit-text {
      font-size: 28px;
      line-height: 32px;
      font-family: "GT-Super-Text-Regular-Trial";
      margin-bottom: 20px;
    }
    .credit-para {
      font-size: 16px;
      line-height: 18px;
    }

    .input-field-textarea {
      font-size: 16px;
      line-height: 21px;
      border-radius: 18px;
      padding-left: 15.18px;
      margin-bottom: 6.66px;
    }
    .input-field {
      font-size: 18px;
      line-height: 21px;
      color: #707070;
      width: 100%;
      height: 41px;
      margin-bottom: 6.66px;
      padding-left: 15.18px;
    }
    .MuiPaper-rounded {
      border-radius: 40px;
      width: 41.427082vw;
    }
    .send-invite-btn {
      text-align: center;
    }
  }
}
