@import '../abstracts/variables';

.emptybasket {
    .basket-heading {
        color: #949D8C;
        padding: 2.7343vw 0;
        text-align: center;
    }

    .empty-heading {
        text-align: center;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: #2F2B23;
        opacity: $opcity;
        border-top: 2px solid #F5F4F3;
        padding-top: 5.2vw;
        margin-bottom: 1.609vw;
    }

    .empty-para {
        text-align: center;
        font-size: 0.9375vw;
        left: 1.0935vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: #848484;
        opacity: $opcity;
        margin-bottom: 2.518vw;
        padding: 0px 26%;
    }

    .btn-part {
        text-align: center;
        margin-bottom: 20.339vw;
    }
}

@media (min-width: 280px) and (max-width: 600px) {
    .emptybasket {
        .empty-heading {
            font-size: 34px;
            line-height: 39px;
            padding-top: 68.66px;
            margin-bottom: 29.54px;
        }

        .empty-para {
            font-size: 16px;
            line-height: 18px;
            padding: 0px 13px;
            margin-bottom: 55px;
        }

        .basket-btn,
        .buy-btn {
            opacity: 1;
            width: auto !important;
        }
    }
}