@import '../abstracts/variables';

.reviews {
  padding: 2.844vw 0vw 7.2677vw;

  .review-topset {
    margin-top: 0px !important;
  }
  .star-section {
    display: flex;
    align-items: center;
}
  .load-more {
    text-align: center;
    font-family: $asap-font-regular;
    font-size: 1.04vw;
    line-height: 1.197vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .plus-icon {
    margin-right: 0.5vw;
    width: 24px;
    height: 24px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .review-heading {
    text-align: left;
    font-family: $gt-font-regular;
    font-style: $btn-font-style;
    font-size: 1.458vw;
    line-height: 1.718vw;
    font-weight: normal;
    font-variant: $btn-font-variant;
    letter-spacing: $letter-spacing;
    color: #707070;
    opacity: $opcity;
  }

  .noavailable {
    font-family: $asap-font-regular;
    font-size: 20px;
    line-height: 22px;
    color: #363240;
    margin-top: 25px;
  
  }

  .line {
    border: 1px solid #ccc;
    opacity: 1;
    margin-bottom: 1.65vw
  }

  .selectbox .select-field {
    width: 9.218vw;
  }

  .card {
    background: #363240 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 12px #00000029;
    border-radius: 2.0833vw;
    opacity: 1;
    padding: 1.430vw 2.0833vw 2.015vw 1.534vw;
  }

  .average {
    text-align: left;
    font-family: $gt-font-regular;
    font-style: normal;
    font-size: 1.302vw;
    line-height: 1.510vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    letter-spacing: 0px;
    color: #E4E1D9;
    opacity: 1;
    margin-bottom: 1.056vw;
  }

  .star-section {
    mat-icon {
      vertical-align: middle;
      width: 24px;
      height: 23px;
    }

    .star1 {
      color: #E4E1D9 !important;
      width: 24px;
      height: 23px;
    }

    span {
      font-family: " Helvetica Neue";
      font-size: 0.729vw;
      line-height: 0.833vw;
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      letter-spacing: 0px;
      color: #E4E1D9;
      opacity: 1;
      margin-left: 0.7458vw;
    }
  }

  .card-para {
    font-family: 'Asap';
    font-size: 0.677vw;
    line-height: 0.78125vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    letter-spacing: 0px;
    color: #E4E1D9;
    opacity: 1;
    margin-top: 1.8229vw;
  }

  .user-icon {
    width: 3.02vw;
    height: 3.02vw;
    border-radius: 50%;
    object-fit: cover;
  }

  .buyer-name {
    font-family: $gt-font-medium;
    font-size: 1vw;
    margin-bottom: 0.312vw;
  }

  .purchased-name {
    text-align: left;
    font-family: initial;
    font-size: 0.833vw;
    line-height: 0.9375vw;
    font-weight: normal;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    letter-spacing: $letter-spacing;
    color: $color707070;
  }

  .css-mhc70k-MuiGrid-root {
    align-items: center;
  }

  .buyer-para {
    color: #707070f0;
    opacity: $opcity;
    margin-bottom: 1.3093vw;
  }

  .user-icon-section {
    margin-bottom: 1.875vw;
   
    opacity: 1;
    margin-bottom: 1.65vw;
  }
  .linebottom{
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
  }
  .product-img {
    width: 6.51041vw;
    height: 6.822916vw;
    object-fit: cover;
    border-radius: 2.083vw;
  }

  .item-name {
    font-family: $gt-font-medium;
    font-size: 0.8333vw;
    line-height: 0.989vw;
    font-weight: normal;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
  }
}
@media (max-width: 280px){
  .reviews{
    .review-heading {
      font-size: 10vw !important;
      line-height: 12vw !important;
    }
    .average {
      font-size: 8vw !important;
      line-height: 10vw !important;
    }
    .star-section span{
      margin-left:0 !important;
    }
    .user-icon {
      width: 100% !important;
      height: auto !important;
  }
  .buyer-name {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-left:10px;
}
.purchased-name {
  font-size: 14px !important;
  line-height: 16px !important;
  margin-left:10px;
}
.buyer-para {
  font-size: 14px !important;
  line-height: 18px !important;
}
.product-img {
  width: 100% !important;
  height: auto !important;
}
.item-name {
  font-size: 12px !important;
  line-height: 17px !important;
}
  }
}
@media (max-width:600px) {
  .reviews {

    padding: 2.844vw 0vw 7.2677vw 0vw;

    .load-more {
      font-size: 16px;
      line-height: 18px;
    }
.noavailable{
  text-align: center;
}
    .plus-icon {
      margin-right: 10px;
    }

    .review-heading {
      text-align: center;
      font-size: 34px;
      line-height: 39px;
      color: #363240;
      margin: 45px 0 31px;
    }

    .card {
      padding: 27px 26px 38px;
      border-radius: 40px !important;
    }

    .select-mobile {
      display: flex;
      justify-content: center;
      margin-top: 31px;
    }

    .selectbox .select-field {
      width: 30vw;
      height: 43px !important;
    }

    .average {
      font-size: 25px;
      line-height: 23px;
      margin-bottom: 20.28px;
    }

    .star-section .star1 {
      color: #E4E1D9 !important;
      width: 27px;
      height: 29px;
    }

    .star-section span {
      margin-left: 14.32px;
      font-size: 14px;
      line-height: 16px;
    }

    .card-para {
      font-size: 13px;
      line-height: 15px;
    }

    .user-icon {
      width: 58px;
      height: 58px;
      // margin-top: 35px;
    }

    .buyer-name {
      font-size: 20px;
      line-height: 23px;
    }

    .purchased-name {
      font-size: 16px;
      line-height: 18px;
    }

    .buyer-para {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 21px;
    }

    .product-img {
      width: 78px;
      height: 78px;
      border-radius: 5.083vw;
    }

    .item-name {
      font-size: 16px;
      line-height: 21px;
    }

    .MuiGrid-item {
      padding: 0px !important;
    }

    .user-icon-section {
      margin-bottom: 3.875vw;
      margin-left: 0;
      width: 100%;
      margin-top: 5px;
    }

    .card {
      margin-bottom: 4vw;
    }
  }
}

@media (min-width:601px) and (max-width:920px) {
  .reviews {
    .tabshow {
      display: block !important;
    }

    .tabhide {
      display: none !important;
    }

    .average {
      font-size: 14px;
      line-height: 16px;
    }

    .card-para {
      font-family: 'Asap';
      font-size: 10px;
      line-height: 12px;
    }

    .buyer-name {
      font-size: 14px;
      line-height: 16px;
    }

    .purchased-name {
      font-size: 12px;
      line-height: 14px;
    }

    .buyer-para {
      font-size: 10px;
      line-height: 12px;
    }

    .product-img {
      width: 100px;
      height: 100px;
    }

    .item-name {
      font-size: 14px;
      line-height: 16px;
    }

    .review-heading {
      font-size: 34px;
      line-height: 39px;
    }

    .css-480o17-MuiGrid-root {
      margin-top: 0px;
    }
  }
}
@media (min-width:1921px) and (max-width:6000px) {
.reviews .plus-icon {
  margin-right: 0.5vw;
  width: 2vw;
  height: 2vw;}
}