@import "../abstracts//variables";

.view-order-details {
  z-index: 999;

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 360px;
    min-height: 201px;
    max-width: 80vw !important;
    pointer-events: auto;
    width: 90.1031vw !important;
  }
  .confirm-btn {
    display: flex;
  }
  .shopcollectionimg {
    position: relative;
  }

  .subscription {
    color: #707070;
    font-family: ASAP;
    font-size: 1.04vw;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.145vw;
    margin-bottom: 0.83229vw;
    opacity: 1;
    text-align: left;
}
  .catbtn {
    top: 2vw;
    right: 23.5vw;
    font-family: "Helvetica Neue" !important;
    padding: 0.701vw 1.2vw !important;
    position: absolute !important;
  }

  .seller-name {
    color: #949d8c;
    font-size: 1.0465vw;
    line-height: 1.197916vw;
    font-family: $asap-font-regular;
    margin-bottom: 0.4166vw
  }

  .product-name {
    font-size: 2.552083vw;
    line-height: 2.7604166vw;
    color: #2f2b23;
    font-family: $gt-font-regular;
    width: 250px;
    margin-bottom: 0.2989vw;
  }

  .review {
    font-size: 0.79vw;
    line-height: 0.833vw;
    color: #707070;
    font-family: $asap-font-regular;
    margin-right: 10px;
  }

  .read-review {
    font-size: 0.79vw;
    line-height: 0.833vw;
    font-family: $asap-font-regular;
    font-weight: bold;
    color: #707070;
    margin-right: 10px;
  }
.review-part{
  display: flex;
  margin-top: 1vw;
}
  .product-price {
    font-size: 1.302083vw;
    line-height: 1.5104166vw;
    color: #949d8c;
    font-family: $gt-font-regular;
    margin-bottom: 1.63541vw;
  }

  .order-details {
    font-size: 1.354166vw;
    line-height: 1.5625vw;
    color: #363240;
    font-weight: bold;
    margin-bottom: 1.04vw
  }

  .orderdate,
  .reference {
    text-align: left;
    font-size: 1.04vw;
    line-height: 1.145vw;
    font-weight: normal;
    font-style: normal;
    font-weight: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 0.83229vw
  }

  .optionheading {
    display: flex;
    margin-bottom: 1.04vw;
    align-items: center;
  }

  .gift-section {
    display: flex;
    align-items: center;

    // .msg {
    //   padding-left: 0.5vw;
    // }
  }

  option.option {
    font-size: 1.04166vw;
    line-height: 1.1979166vw;
    color: #949d8c;
    font-weight: bold;
    font-family: $asap-font-regular;
  }

  .MuiPaper-rounded {
    border-radius: 40px;
    width: 100%;
  }

  .product-img {
    width: 100%;
    height: 33.31vw;
    width: 100%;
    object-fit: cover;
    border-radius: 48px;
    object-position: top;
  }

  .closepart2 {
    position: absolute;
    right: 21px;
    margin-top: 2px;
  }

  .product-review {
    display: flex;
    justify-content: space-between;
  }

  .order-date {
    display: flex;
    justify-content: space-between;
    font-size: 19px;
    color: #707070;
    line-height: 22px;
    font-family: "Asap";
  }

  .option-heading {
    display: flex;
    align-items: center;
  }

  option.selected-option {
    padding-left: 3.125vw;
    font-size: 20px;
    line-height: 24px;
    color: #707070;
    font-family: Helvetica Neue;
  }

  .gift-message {
    display: flex;
    justify-content: flex-start;
    padding-top: 1.04166vw;
    padding-bottom: 1.04166vw;
  }

  .msg {
    font-size: 1.04166vw;
    line-height: 1.1979166vw;
    color: #949d8c;
    font-weight: bold;
    font-family: "Asap";
  }

  .msg-para {
    font-size: 1.04166vw;
    line-height: 1.1979166vw;
    color: #707070;
    font-family: "Asap";
    padding-left: 3.125vw;
  }

  .basket-btn.darkbtn {
    background-color: #363240;
    margin-left: 1.71875vw;
    margin-right: 1vw;
  }

  .redeem-voucher {
    padding-top: 3.4958vw;
    padding-left: 1.04vw;
    //  padding-right: 2.5vw;
  }

  .lastpayment {
    font-size: 1.04vw;
    line-height: 1.145vw;
    font-weight: normal;
    font-style: normal;
    font-weight: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 0.83229vw
  }

  .nextpayment {
    text-align: left;
    font-size: 1.04vw;
    line-height: 1.145vw;
    font-weight: normal;
    font-style: normal;
    font-weight: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-bottom: 0.83229vw
  }

  .buy-btn.lightbtn {
    background-color: #949d8c;
  }

  .staricon {
    color: #949d8c;
    margin-right: 10px;
    margin-top: -7px;
  }

  .mobileshow {
    display: none;
  }
}

@media (max-width: 280px) {
  .view-order-details{
     .catbtn {
    padding: 6px 14px !important;
    right: 47.5vw !important;
    top: 14vw !important;
  }
  .product-img {
    height: 280px !important;
    border-radius: 20px !important;
    
}
.review-part {
  display: block !important;
}
}
}

@media (max-width: 600px) {

  .view-order-details {
    .review-part {
      margin-top: 20px;
    }
    .Confirm-btn {
      text-align: center;
  }
    .view-order-details .catbtn {
      top: 7vw;
      right: 66.5vw;
    }

    .basket-btn,
    .buy-btn {
      padding: 13px 28px !important;
      font-size: 13px !important;
    }

    .confirm-btn {
      display: flex;
    }

    .mobileshow {
      display: block !important;
    }

    .seller-name {
      font-size: 18px;
      line-height: 21px;
      // padding-left: 10px;
    }

    .product-name {
      font-size: 36px;
      line-height: 42px;
    }

    .product-price {
      display: none;
    }

    .order-details {
      font-size: 18px;
      line-height: 21px;
      padding-top: 5.04166vw;
      padding-bottom: 1.78125vw;
      // padding-left: 10px;
    }

    .reference {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
      // padding-left: 10px;
    }

    .optionheading {
      padding-top: 2.96875vw;
      padding-bottom: 2.78125vw;
      // padding-left: 8px;
    }

    option.option {
      font-size: 18px;
      line-height: 21px;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 100%;
    }

    .product-img {
      width: 100%;
      height: 320px;
      border-radius: 40px;
      margin-top: 25px;
    }
    .catbtn {
      padding: 10px 20px!important;
      right: 54.5vw;
      top: 11vw;
    }
    .product-review {
      display: block;
      // padding-left: 10px;
    }

    .order-date {
      display: block;
      // padding-left: 10px;
    }

    .option-heading {
      display: flex;
      align-items: center;
      padding-bottom: 3.78125vw;
    }

    option.selected-option {
      font-size: 16px;
      line-height: 18px;
    }

    .gift-message {
      display: flex;
      justify-content: flex-start;
      padding-top: 1.04166vw;
      padding-bottom: 1.04166vw;
      // padding-left: 7px;
      align-items: center;
    }

    .msg {
      font-size: 16px;
      line-height: 18px;
    }

    .msg-para {
      font-size: 16px;
      line-height: 18px;
    }

    .basket-btn.darkbtn {
      background-color: #363240;
      margin-left: 10px !important;
    }

    .mobilehide {
      display: none !important;
    }

    .lastpayment {
      font-size: 18px;
      line-height: 22px;
    }

    .nextpayment {
      font-size: 19px;
      line-height: 22px;
    }

    .buy-btn.lightbtn {
      background-color: #949d8c;
      margin-left: 10px !important;
    }

    .staricon {
      color: #949d8c;
    }

    .orderdate {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
    }

    .subscription {
      font-size: 16px;
      line-height: 18px;
      // padding-left: 10px;
    }

    .order-para {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
    }
    .review {

      font-size: 12px;
      line-height: 15px;
    }
    .read-review{
      font-size: 12px;
      line-height: 15px;
    }
    .password {
      padding-left: 10px;
    }
  }
}

@media (min-width: 600px) and (max-width: 920px) {

  .view-order-details {
    .review-part {
      margin-top: 20px;
    }
    .seller-name {
      font-size: 18px;
      line-height: 21px;
      // padding-left: 10px;
    }

    .product-name {
      font-size: 36px;
      line-height: 42px;
      width: 100%;
    }

    .catbtn {
      position: absolute !important;
      padding: 1.701vw 3.2vw !important;
      right: 57.5vw;
      top: 10vw;
    }

    .shopcollectionimg {
      position: relative;
    }

    
    .review {

      font-size: 12px;
      line-height: 15px;
    }
    .read-review{
      font-size: 12px;
      line-height: 15px;
    }
    .product-price {
      display: none;
    }

    .order-details {
      font-size: 18px;
      line-height: 21px;
      padding-top: 5.04166vw;
      padding-bottom: 1.78125vw;
      padding-left: 10px;
    }

    .reference {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
      padding-left: 10px;
    }

    .optionheading {
      padding-top: 2.96875vw;
      padding-bottom: 2.78125vw;
      padding-left: 8px;
    }

    option.option {
      font-size: 18px;
      line-height: 21px;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 100%;
    }

    .product-img {
      width: 100%;
      height: auto;
      border-radius: 48px;
      padding: 0px 10px 0px 10px;
    }

    .product-review {
      display: block;
      // padding-left: 10px;
    }

    .order-date {
      display: block;
      padding-left: 10px;
    }

    .option-heading {
      display: flex;
      align-items: center;
      padding-bottom: 3.78125vw;
    }

    option.selected-option {
      font-size: 16px;
      line-height: 18px;
    }

    .gift-message {
      display: flex;
      justify-content: flex-start;
      padding-top: 1.04166vw;
      padding-bottom: 1.04166vw;
      // padding-left: 7px;
      align-items: center;
    }

    .msg {
      font-size: 2.9375vw;
      line-height: 7.09375vw;
    }

    .msg-para {
      font-size: 16px;
      line-height: 18px;
    }

    .basket-btn.darkbtn {
      background-color: #363240;
      margin-left: 3.1vw;
    }

    .redeem-voucher {
      padding-top: 50px;
    }

    .lastpayment {
      font-size: 18px;
      line-height: 22px;
    }

    .nextpayment {
      font-size: 19px;
      line-height: 22px;
    }

    .buy-btn.lightbtn {
      background-color: #949d8c;
      margin-left: 10px;
    }

    .staricon {
      color: #949d8c;
    }

    .orderdate {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
    }

    .subscription {
      font-size: 16px;
      line-height: 18px;
      // padding-left: 10px;
    }

    .order-para {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
    }

    .password {
      padding-left: 10px;
    }
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .view-order-details {
    .seller-name {
      font-size: 18px;
      line-height: 21px;
      // padding-left: 10px;
    }
    .review-part{
      margin-top: 15px;
    }
    .product-name {
      font-size: 30px;
      line-height: 36px;
      width: 150px;
    }

    .product-price {
      display: none;
    }

    .order-details {
      font-size: 18px;
      line-height: 21px;
      padding-top: 2.04166vw;
      padding-bottom: 1.78125vw;
      padding-left:0px;
    }

    .reference {
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 1.78125vw;
    }

    .optionheading {
      padding: 0;
      padding-top: 1.04166vw;
      padding-bottom: 1.04166vw;
      margin-bottom: 0;
    }

    option.option {
      font-size: 18px;
      line-height: 21px;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 100%;
    }

    .product-img {
      width: 100%;
      height: 350px;
      border-radius: 25px;
      padding: 0px 10px 0px 10px;

    }

    .catbtn {
      top: 10px;
      left: 20px;
    }

    .product-review {
      // display: block;
      // padding-left: 10px;
      // align-items: center;
    }

    .redeem-voucher {
      padding-left: 0vw;
    }

    // .order-date {
    //   display: block;
    //   padding-left: 10px;
    // }
    .option-heading {
      display: flex;
      align-items: center;
      padding-bottom: 3.78125vw;
    }

    option.selected-option {
      font-size: 16px;
      line-height: 18px;
    }

    .gift-message {
      display: flex;
      justify-content: flex-start;
      padding-top: 1.04166vw;
      padding-bottom: 1.04166vw;
      padding-left: 7px;
      align-items: center;
    }

    .msg {
      font-size: 18px;
      line-height: 20px;
    }

    .msg-para {
      font-size: 16px;
      line-height: 18px;
    }

    .basket-btn.darkbtn {
      background-color: #363240;
      margin-left: 3.1vw;
    }

    .redeem-voucher {
      padding-top: 50px;
    }

    .lastpayment {
      font-size: 18px;
      line-height: 22px;
    }

    .nextpayment {
      font-size: 19px;
      line-height: 22px;
    }

    .staricon {
      color: #949d8c;

      margin-top: -7px;

    }

    .orderdate {
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 1.78125vw;
    }

    .subscription {
      font-size: 14px;
      line-height: 16px;
    }

    .order-para {
      font-size: 16px;
      line-height: 18px;
      padding-bottom: 1.78125vw;
    }

    .password {
      margin-top: 20px;
    }
  }
}
@media (min-width: 1750px) and (max-width: 1920px) {
.view-order-details .product-name{
width: 23vw;
}
.view-order-details .catbtn {
  right: 25vw;
  top: 1vw;
}
}
@media (min-width: 1921px) and (max-width: 6000px) {
  .view-order-details {
    .product-name {
      width: 21vw;
    }
.closepart2{
    margin-top: 1vw;
    right: 1.5vw;
  .MuiSvgIcon-root{
    width: 1.5vw;
    height: 1.5vw;
    font-size: 1.5vw;
  }
}
.order-date{
  padding-right: 2vw;
}
    .shopcollectionimg {
      position: relative;
      padding: 1vw 2vw;
    }

    .product-review {
      padding-right: 2vw;

    }

    .product-img {
      border-radius: 2.5vw;
    }
  }
}