@import '../abstracts/variables';


.exclusiveblog {
    width: 45.68% !important;
    margin: auto;
    min-width: 300px;
    padding: 5.37vw 0;
    font-family: $asap-font-regular;

    .submit-btn-section {
        margin-top: 2.8vw;
    }

    .blog-text {
        text-align: center;
        font-size: 1.45vw;
        height: auto;
        letter-spacing: 0px;
        // color: #2f2b23;
        color: #000000;
        font-family: $gt-font-regular;
        font-weight: 600;

        .sitename {
            color: #757061;
            cursor: pointer;
            padding-left: 4px;
        }
    }

    .email-input-field {
        min-width: 60%;
        font-size: 1.25vw !important;

        label {
            width: 100%;
        }

        .textcenter {
            margin-bottom: 2.22vw !important;
        }

        input {
            text-align: center !important;
            text-transform: capitalize
        }
    }

    .form-part {
        margin-top: 4.318vw;
    }

    .darkbtn {
        background: #2f2b23 0% 0% no-repeat padding-box !important;
        font-size: 0.9375vw !important;
        line-height: 1.09375vw !important;
        color: #ffffff !important;

    }

    .css-a88p61-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
        border-bottom: 2px solid #949d8c !important;
    }

    .css-a88p61-MuiInputBase-root-MuiInput-root:after {
        border-bottom: 2px solid #949d8c !important;
    }

    .css-a88p61-MuiInputBase-root-MuiInput-root:before {
        border-bottom: 2px solid #949d8c !important;
    }

    .css-ercmji:hover:not(.Mui-disabled)::before {
        border-bottom: 2px solid #949d8c !important;
    }

    .css-ercmji::after {
        border-bottom: 2px solid #949d8c !important;
    }
}

@media only screen and (max-width: 280px) {
    .exclusiveblog .blog-text {
        font-size: 8vw !important;
        line-height: 11vw !important;
    }
}

@media (min-width:280px) and (max-width:600px) {
    .exclusiveblog {
        .form-part {
            margin-top: 0vw;
        }

        .email-input-field input {
            font-size: 24px;
            font-weight: bold;
        }

        ::-webkit-input-placeholder {
            /* Edge */
            color: #949D8C;
            font-family: "asap";
            font-weight: 500;
        }

        :-ms-input-placeholder {
            /* Internet Explorer */
            color: #949D8C;
            font-family: "asap";
            font-weight: 500;
        }

        ::placeholder {
            color: #949D8C;
            font-family: "asap";
            font-weight: 500;
        }
    }
}

@media (min-width:601px) and (max-width:919px) {
    .exclusiveblog {
        .form-part {
            margin: 0 22%;
        }

        h2.blog-text {
            font-size: 20px;
            line-height: 25px;
            height: auto;
            margin: 0 10%;
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 919px) {
    .exclusiveblog {
        min-width: 100%;

        .blog-text {
            font-weight: 500;
            font-size: 14.9117px;
            line-height: 22px;
            padding: 0 20px;
            height: auto;
        }

        .email-input-field {
            min-width: 100%;
            font-size: 24px !important;
            line-height: 27px !important;
            color: rgba(0, 0, 0, 0.87);
            margin: 20px 0;
        }

        .email-input-field input {
            font-size: 24px;
        }

        .darkbtn {
            width: auto;
            height: auto;
            font-size: 18px !important;
            line-height: 21px !important;
            border-radius: 48px !important;
            margin-top: 25px !important;
            padding: 13px 33px !important;
            color: #e8e8e8 !important;
        }
    }
}

@media (min-width:920px) and (max-width:1280px) {
    .exclusiveblog {
        width: 80% !important;

        .blog-text {
            font-size: 2.6vw;
            line-height: 3.3vw;
        }

        .email-input-field input {
            font-size: 2.4vw;
            line-height: 2.8vw;
        }

        .basket-btn.darkbtn,
        .buy-btn.lightbtn {
            font-size: 1.8vw !important;
            padding: 1.7vw 4.8vw !important;
            line-height: 2vw !important;
        }

        .submit-btn-section {
            margin-top: 5.2vw;
        }

        .form-part {
            margin-top: 8vw;
        }
    }
}

@media only screen and (min-width: 1930px) {
    .exclusiveblog {
        .email-input-field {
            min-width: 60%;
            font-size: 1.02vw !important;
        }

        .textcenter {
            margin-bottom: 2.822vw !important;
        }

        //  .darkbtn{
        //     margin-top: 38px;
        //  }
        .submit-btn-section {
            margin-top: 38px;
        }

    }
}

@media only screen and (min-width: 1930px) {
    .exclusiveblog {
        .darkbtn {
            margin-top: 58px;
            width: 7.968vw;
            height: 2.864vw;
        }
    }
}