.checkoutskeleton{
    .shippingsk,.subtotalsk,.totalsk{
        display: flex;
        padding: 1vw 0;
        border-top:2px solid #f6f5f4;
        justify-content: flex-end;
        align-items: center;
    }
    .giftsk{
        display: flex;
        padding: 1vw 0;
        border-top:2px solid #f6f5f4;
        align-items: center;
    }
    .gfttext{
        width:7vw;
        height: 2vw; 
    }
    .inputgift{
        width:18vw;
        height: 3vw;
        margin-left: 1.5vw;
        border-radius: 2.08vw;
    }
    .giftbtn{
        width:8vw;
        height: 3vw;
        margin-left: 1.5vw;
        border-radius: 2.08vw;
    }
    .tableskeleton .delbox {
        width: 8vw;
        height: 2vw;
    }
    .tableskeleton .probtn{display: none;}
    .sptext{
        width:7vw;
        height: 2vw;
    }
    .sptype{
        width:7vw;
        height:3vw;
        margin-left: 2vw;
    }
    .subprice,.totalprice{
        width:7vw;
        height:3vw;
        margin-left: 2vw;
    }
    .subtext,.totaltext{
        width:7vw;
        height:3vw;
        
    }
}