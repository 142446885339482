.shopcollectionpage{
    padding: 3vw 0 0;
    .skeleton{width:25% !important;}
    .pricesktn{display: none;}
    .trending-heading{
        padding: 0.5vw 0px 1.5vw;   
    }
    .shopcollection {
        position: relative;
        width: 25%;
        padding: 0;
        padding: 10px 6px;
        display: inline-block;
        height: auto;
        vertical-align: top;
    }
    .img-row{
       width: 100%;
       min-height: 150px;
       border-radius: 2.083vw;
       object-fit: cover;
       cursor: pointer;
       min-height: 14vw;
      min-width: 100%;
   }
    .unpublished{
        background: #F4F5F6 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        z-index: 999;
        color: #000000;
        width: auto;
        text-align: center;
        position: absolute;
        width: auto;
        bottom: 1.85vw;
        right: 0;
        left: 0;
        max-width: 180px;
        margin: 0 auto;
        padding: 10px 13px;
        font-family: "Asap";
        font-weight: normal;
        font-style: normal;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        padding: 0.651vw 1.25vw 0.614vw 1.2822vw;
   }
  .ethical-container {
    margin-top: 7.013541vw;
    //  background-size: auto; 
    // padding: 33vw 0px 14.36vw;
    width: 100%;
    background-position: center;
    object-fit: cover;
    padding: 5.2vw 0px 5.36vw;
}


}
@media only screen and (max-width: 280px){
        .shopcollectionpage {
        .shopcollection {
            position: relative;
            width: 100% !important;
            
        }
        .img-row{
            border-radius: 10.083vw !important;
            }
        .unpublished{
            font-size: 14px !important;
            line-height: 18px !important;
            border-radius: 10vw !important;
            padding: 10px;
            bottom: 10.85vw;
        }
    }
    }
@media (min-width:280px) and (max-width:600px) {
    .shopcollectionpage {
        .skeleton{
            width: 50% !important;
            .imgcard {
                height:180px !important;
                border-radius: 40px;
            }
        }
    }
}
@media (min-width:600px) and (max-width:920px) {
    .shopcollectionpage{
        .shopcollection {
            position: relative;
            width: 33.33%;
            padding: 0.5%;
            display: inline-block;
            height: auto;
        }
        .img-row{
           height: 24vw;
           width: 100%;
           min-height: 150px;
           border-radius: 2.083vw;
           object-fit: cover;
           cursor: pointer;
       }
       .unpublished {
        font-size: 1.65vw;
        line-height: 1.8vw;
        padding: 10px 13px;
        bottom: 20px;
        max-width: 130px;
        }
    }
}
@media (min-width:280px) and (max-width:600px) {
    .shopcollectionpage{
        .shopcollection {
            position: relative;
            width: 50%;
            padding: 0.5%;
            display: inline-block;
            height: auto;
            vertical-align: top;
        }
        .img-row{
           height: 42vw;
           width: 100%;
           min-height: 42vw;
           border-radius: 20px;
           object-fit: cover;
           cursor: pointer;
           min-width: 100%;
       }
       .unpublished {
        font-size: 14px;
        line-height: 16px;
        padding: 10px 13px;
        max-width: fit-content;
        min-width: 100px;
        border-radius: 40px;
        bottom: 20px;
    }
    }
}
@media (min-width:920px) and (max-width:1280px){
    .shopcollectionpage{
        .trending-heading{
            font-size: 2vw;
        }
        .img-row{
            height: 20vw;
            border-radius: 3.2vw;
        }
        .unpublished{
            width: fit-content;
            bottom: 2.2vw;
        }
    }
}
@media (min-width:1800px) and (max-width:1920px){
    .shopcollectionpage .unpublished {
        max-width: 245px;
    }
    }
@media (min-width:1930px) {
    .shopcollectionpage{
        .shopcollection{
            padding: 0.5vw 0.3vw;
        }
        .img-row {
            height: 13vw;
            width: 100%;
            // padding: 1vw;
            border-radius: 2.5vw;
        }
     .unpublished {
        background: #F4F5F6 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        z-index: 999;
        color: #000000;
        width: auto;
        text-align: center;
        position: absolute;
        bottom: 2vw;
        width: auto;
        min-width: 12vw;
        right: 0;
        left: 0;
        max-width: fit-content;
        margin: 0 auto;
        padding: 10px 13px;
        font-family: "Asap";
        font-weight: normal;
        font-style: normal;
        font-size: 1vw;
        line-height: 1.25vw;
        padding: 0.651vw 1.25vw 0.614vw 1.2822vw;
    }
}
}