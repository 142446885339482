@import '../../../scss/abstracts/variables';
.basketcarddesign{
    border-radius: 2.08vw;
}
.skeleton{
    .bannerskeleton{
        width: 100%;
        height:350px;
    }
    .loopskeleton{
        display: inline-block;
        width:25%;
        padding:1.5%;
    }
    .imgcard{
        border-radius: 2.04vw;
    }
    .headingskeleton{
        width:30%;
        height:80px;
        margin:0 auto;
        margin-top:20px;
    }
}

@media (min-width:280px) and (max-width:600px) {
    .skeleton{
        .headingskeleton{
            width:70%;
            height:80px;
            margin:0 auto;
            margin-top:20px;
        }
        .loopskeleton{
            display: inline-block;
            width:50%;
            padding:1%;
        }
    }
}
