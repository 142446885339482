@import "../abstracts/variables";

.ethicalbrand{
    
.ethical-icon{
    height: 9.791vw;
}
.ethical-heading{
    text-align: left;
    font-family:$gt-font-regular;
    font-size: 2.083vw;
    line-height: 2.604vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    letter-spacing: 0px;
    color: #2f2b23e8;
    opacity: 1;
    margin-bottom: 1.197vw;
    margin-top: 0;
}
.ethical-para{
    text-align: left;
    font-family: "Asap";
    font-size: 0.9375vw;
    line-height: 1.09375vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    letter-spacing: 0.5px;
    color: #70706ec2;
    opacity: 1;
    margin-bottom: 2.0833vw;
    margin-top: 0;
}
.basket-btn, .buy-btn{
    color: #f5f4f3 !important;
    opacity: 0.9;
    min-width: 10.36vw !important;
    min-height: 2.76vw;
    text-transform: initial !important;
    
}
.ethical-container{
    background-size: cover;
    width: 100%;
    background-position: center;
    object-fit: cover;
    padding: 7.18vw 0px 5.36vw;
}
.diffHeading {
    color: #949d8ce3;
}

}


@media only screen and (max-width: 600px) {
    .ethicalbrand{
        .basket-btn, .buy-btn{
            margin: 0px 0 15px !important;
          }
        .ethical-container {
            padding: 24vw 0px 14.36vw;
            background-size: 145% 100%;
            image-rendering: -moz-crisp-edges;
            image-rendering: -o-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
        }
        .ethical-icon {
            width: 100%;
            height:auto;
        }
        .ethical-heading{
            font-size: 28px;
            line-height: 33px;
        }
        .ethical-para{
            font-size: 18px;
            line-height: 24px;
            padding-right: 15px;
            color: #2F2B23;
            letter-spacing: 0;
        }
        .lightbtn{
            font-size: 24px;
            width: auto;
            height: auto;
            border-radius: 40px;
            line-height: 27px;
            padding: 13px 22px;
        }
        .mobiletextright{text-align: right;}
    }
}

@media (min-width:920px) and (max-width:1280px){
    .ethicalbrand{
        .ethical-para{
    font-size: 1.6vw;
    line-height: 1.9vw;
    padding-right: 7vw;
    letter-spacing: 0;
    min-height: 4vw;
    margin-bottom: 1.4vw;
        }
        .ethical-container {
            padding: 7.18vw 0px 3.5vw;
        }
    }
}
@media only screen and (min-width: 1930px){
    .ethicalbrand{
        .ethical-heading{
            font-size: 1.5vw;
        }
        .ethical-para{
            font-size: .88vw;
        }
        .ethical-icon {
            height: auto;
            width: 100%;
        }
        .basket-btn, .buy-btn{
            font-size: 1.0937vw !important;
        }
        .big27scree{
            justify-content: space-between;
        }
    }
}
@media only screen and (max-width: 413px){
.ethicalbrand{
 .ethical-container {
    padding: 33vw 0px 14.36vw !important;
}
// .ethical-heading {
//     font-size: 8vw;
//     line-height: 9vw;
// }
.ethical-para {
    // font-size: 5vw;
    // line-height: 6vw;
   padding-right: 0px;
}
}
}
@media (min-width:601px) and (max-width: 1920px) {
    .ethicalbrand{
        .all-screen-container{
            max-width:100% !important;
            padding: 0;
        }
 }
 }