.mobilecheckoutregister {
  margin-top: 21px;
  padding: 0 32px;

  .forgot-text,
  .email-text,
  .continuing-text {
    padding-left: 11px;
  }

  .radio-section {
    .MuiSvgIcon-root {
      path {
        color: #b4b4b4;
      }
    }
  }

  .email-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 28px;
    color: #2f2b23;
    margin-bottom: 19px;
  }

  .MuiFormControl-root .MuiSvgIcon-root {
    width: 18px;
    height: 19px;
  }

  .MuiInputLabel-root {
    font-size: 16px;
    line-height: 27px;
    left: 7px;
    font-family: 'Asap';
  }
  .MuiCheckbox-root {
    padding: 0 !important;
  }
  .MuiInputLabel-root.Mui-focused {
    color: #777777 !important;
    font-size: 16px;
    line-height: 27px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2f2b23 !important;
    border: 1px solid !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-input {
    // min-width: 100%;
    min-height: 56px;
    padding: 0 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 15px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .legend {
    height: auto;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #a6a194;
  }

  .inputicon {
    position: relative;
  }

  .visibility-icon {
    position: absolute;
    left: 87%;
    // top: 20%;
    top: 4.2vw;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  ::placeholder {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .MuiInputLabel-formControl {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .continuing-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2f2b23;
  }

  .forgot-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 32px;
    color: #9f9f9f;

    span {
      // text-decoration-line: underline;
      border-bottom: 1px solid #2f2b23;
      color: #2f2b23;
      margin-left: 9px;
      cursor: pointer;
      font-weight: 500;
    }
  }

  // .inputicon .MuiSvgIcon-root {
  //     display: none;
  // }

  .darkbtn {
    min-height: 63px;
    font-size: 16px !important;
    min-width: 100% !important;
    padding: 17px 86px !important;
    border-radius: 35px;
    color: #ffffff !important;
    background-color: #2f2b23;
    font-weight: bold !important;
  }

  .btn-section-new {
    text-align: center;
    margin-bottom: 80px;
    margin-top: 50px;
  }

  .name-section {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-top: 24px;
    margin-bottom: 19px;
  }

  .inputicon {
    padding: 0 5px;
  }

  .radio-section {
    padding-left: 40px;
    margin-top: 5px;
    margin-bottom: 16px;

    .MuiTypography-body1 {
      padding-left: 5px;
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 26px;
      color: #2f2b23;
    }
  }

  .MuiFormControlLabel-root {
    padding-right: 0px;
    margin-right: 0;
  }

  .keep-text {
    margin-top: 23.5px;
    padding-left: 35px;

    // svg.MuiSvgIcon-root {
    //     color: #fff;
    //     border: 1px solid #8b8b8b;
    //     border-radius: 4px;
    //     width: 19px;
    //     height: 19px;
    //     margin-left: 5px;
    // }

    // .Mui-checked svg {
    //     border: none;
    //     color: #8b8b8b;
    //     width: 24px;
    //     height: 24px;
    // }

    .MuiTypography-body1 {
      font-family: $asap-font-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 15.5px;
      line-height: 19px;
      color: #949494;
      padding-left: 15px;
    }
  }
  .inputiconme {
    position: absolute;
    top: 17px;
    right: 18px;
    color: #74ce83 !important;
  }
}

@media (min-width: 264px) and (max-width: 390px) {
  .mobilecheckoutregister {
    padding: 0 22px;

    .keep-text .MuiTypography-body1 {
      font-family: 'ASAP';
      font-style: normal;
      font-weight: 500;
      font-size: 13.5px;
    }
  }
}
