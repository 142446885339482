.pagination{
    margin-top: 4.1145vw;
    margin-bottom: 2vw;
    .css-wjh20t-MuiPagination-ul {
        display: inline-block;
        /* justify-content: space-between; */
        width: 100%;
        text-align: center;
    }
    .MuiButtonBase-root.Mui-selected {
         background-color: transparent;
        color: #707070 !important;
        font-weight: bold;
        min-width: 31px !important; 
    }
  .MuiPaginationItem-previousNext:before {
    content: 'Next';
    }

    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled:before {
        content: 'Previous ';
   
    }
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled{
        opacity: 1;
    }
    .css-1xr9krm.Mui-disabled{
        opacity: 1;
    }
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root:hover{
        background: transparent;
    }
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected:hover{
        background: transparent; 
    }

    .MuiSvgIcon-root{
        display: none;
    }
    button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-previousNext.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
        background: #F5F4F3;
        width: 5.3645vw;
        height: 2.656vw;
        border-radius: 2.5vw;
        font-family: 'Asap';
        color: #707070 !important;
        font-size: 0.98vw;
        line-height: 1.14vw;
    }
   
    .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
        color: #707070 !important;
    }
    .mypagination{
        ul li:first-child .MuiPaginationItem-previousNext:before{
            content:'Previous'
        }
        ul li:first-child{
            float: left;
        }
        ul li{
            text-align: center;
            display: inline-block;
        }
    }
    .mypagination{
        ul li:last-child .MuiPaginationItem-previousNext:before{
            content:'Next'
        }
        ul li:last-child{
            float: right;
        }
        button.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-text.MuiPaginationItem-circular.MuiPaginationItem-previousNext.css-1xr9krm{
            background: #F5F4F3;
            width: 5.3645vw;
            height: 2.656vw;
            border-radius: 2.5vw;
            font-family: 'Asap';
            color: #707070 !important;
            font-size: 0.98vw;
            line-height: 1.14vw;
        }
        .css-1xr9krm.Mui-selected {
            background-color: transparent;
            color: #707070;
            font-weight: 600;
        }
        .css-1xr9krm{
            color: #707070; 
            min-width: 31px !important; 
        }
        .MuiPagination-ul{
            text-align: center;
            display: block;
        }
    }
}
@media (max-width:280px) {
    .pagination{
        .mypagination{
        button.MuiButtonBase-root.MuiPaginationItem-previousNext, button.MuiPaginationItem-previousNext{
            width: auto !important;
            height: auto !important;
           padding: 15px 34px !important;
        }
    } 
}
}
@media (max-width:600px) {
    .pagination{
        .mypagination{
        button.MuiButtonBase-root.MuiPaginationItem-previousNext, button.MuiPaginationItem-previousNext{
            width: 17.3645vw !important;
            height: 7.656vw !important;
            border-radius: 13.5vw !important;
            font-family: 'Asap';
            color: #707070 !important;
            font-size: 12px !important;
            line-height: 15px !important;
            font-weight: 500;
        }
    }
    .MuiButtonBase-root.Mui-selected {
       min-width: 22px !important; 
   }
   .css-1xr9krm{
    color: #707070; 
    min-width: 22px !important; 
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root{
    min-width: 22px;
}
    }
}
@media (min-width:1800px) and (max-width:2000px) {
    .pagination{
        .MuiPagination-ul li button{
            font-size:1vw;
        }
    }
}
@media (min-width:1930px) {
    .pagination{
        margin-top: 1vw;
        .mypagination  li .MuiButtonBase-root.MuiPaginationItem-page{
            padding-top: 1.2vw;
            font-size: 1.1vw;
            line-height: 1.5vw;
            min-width: 82px !important;
        }
    }
}

@media (min-width:1921px) and (max-width:6000px) {
    .pagination{
        .MuiButtonBase-root.Mui-selected {
           min-width: 2vw !important; 
       }
       .css-1xr9krm{
        min-width: 2vw !important; 
    }
    }

}