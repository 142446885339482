.checkbox{
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root:hover{
        background-color: transparent !important;
    }
    .css-ldvwv0-MuiButtonBase-root-MuiCheckbox-root:hover{
        background-color: transparent ; 
    }
    .css-ldvwv0-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-ldvwv0-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
        color: #2f2b23;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
        color: #707070 !important;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
        color: #707070 !important; 
    }
}