@import '../abstracts/variables';

.imagegallery {

    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
        height: 120px;
        width: 25px;
    }

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        width: 35.6354vw !important;
    }

    .css-uhb5lp {
        width: 35.6354vw !important;
    }

    .image-gallery-icon:hover {
        color: #707070;
    }
}

@media (max-width: 600px) {
    .imagegallery {

        .image-gallery-fullscreen-button .image-gallery-svg,
        .image-gallery-play-button .image-gallery-svg {
          display: none;
        }
    }
}

@media (min-width: 1931px){
    .imagegallery {

        .image-gallery-left-nav .image-gallery-svg,
        .image-gallery-right-nav .image-gallery-svg {
            height: 15vw;
            width: 1.5vw;
        }
        .image-gallery-fullscreen-button .image-gallery-svg, .image-gallery-play-button .image-gallery-svg {
            height: 1.5vw;
            width: 1.5vw;
        }
    }
}