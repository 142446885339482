.checkoutpagenew {
  padding-right: 60px;
  margin-bottom: 22.43vw;

  .deliveryarea .usecredit {
    padding-left: 1.2vw !important;
  }

  .deliveryarea .payment-title-disable .mobilehide {
    display: contents !important;
  }

  .inputicon {
    position: relative;
  }

  .select-payment {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1vw;
    line-height: 1.5vw;
    color: #2f2b23;
    margin-bottom: 1.87vw;
  }

  div.selectpayment {
    span.MuiFormControlLabel-label {
      border: none !important;
      padding: 0 !important;
    }

    .MuiRadio-root {
      display: block !important;
      padding: 0;
    }

    .MuiRadio-root.Mui-checked .MuiSvgIcon-fontSizeMedium {
      display: block !important;
    }

    .MuiRadio-root.Mui-checked {
      border: none !important;
      color: #2f2b23 !important;
    }

    label {
      margin-bottom: 0;
      padding: 1.4vw 1vw;
    }
  }

  // .mobileshow {
  //     display: none !important;
  // }
  .dvhide {
    display: contents;
  }

  .dvshow {
    display: none !important;
  }

  .iconwithsearch {
    position: relative;

    svg {
      position: absolute;
      top: 1.4vw;
      bottom: 0;
      left: 0.6vw;
      font-size: 1.25vw;
      line-height: 1.5vw;
    }

    .address-input-checkout {
      padding-left: 2vw !important;
    }
  }

  .btnalignright {
    // .darkbtn {
    //   margin-right: 10px;
    // }
  }

  .deliveryareagrid {
    padding-right: 2vw;
  }

  .product-btn {
    margin-bottom: 1.04vw !important;
  }

  .basketarea {
    padding-right: 2.5vw;

    .MuiGrid-item {
      margin-top: 16px;
      padding-top: 0px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #9f9f9f;
    font-family: 'Asap';
    font-weight: 400;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9f9f9f;
    font-family: 'Asap';
    font-weight: 400;
  }

  ::placeholder {
    color: #9f9f9f;
    font-family: 'Asap';
    font-weight: 400;
  }

  .MuiInputLabel-formControl {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .marginbott {
    margin-bottom: 2.2966vw;
  }

  .cardfield {
    .inputiconme {
      margin-top: 0.4vw;
    }

    .input-error-message {
      margin-top: 0.5vw;
    }
  }

  .inputfeilds {
    legend {
      span {
        font-size: 14px !important;
      }
    }

    .usecredit {
      .MuiCheckbox-root {
        padding: 0 !important;
      }
    }

    .delivery-address-new {
      font-size: 1.11vw;
      line-height: 1.5vw;
      font-family: 'Asap';
      color: #2f2b23;
      font-weight: 500;
      // margin-bottom: 6px;
    }

    span {
      font-size: 1.11vw;
      line-height: 1.5vw;
      font-family: 'Asap';
      color: #2f2b23;
      font-weight: 500;
      // margin-bottom: 6px;
    }
  }

  .termsandcondition-text {
    font-size: 1.21vw;
    line-height: 1.7vw;
    font-family: 'Asap';
    color: #757575;
    font-weight: 400;
    margin-bottom: 6px;

    span {
      border-bottom: 1px solid #757575;
      cursor: pointer;
      margin: 0 5px;
    }
  }

  .address-input-checkout {
    // border:'1px solid red'
    margin-bottom: 1.9vw;
    border-radius: 1vw;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: rgba(0, 0, 0, 0.23);
    padding: 19px 14px !important;
    color: #9f9f9f;
    font-size: 1.11vw;
    font-weight: 400;
  }

  .address-input-checkout::before {
    content: '../../../assets/images/header/search-icon.webp';
    position: absolute;
  }

  .inputiconme {
    position: absolute;
    margin-top: 1vw;
    right: 1vw;
    color: #74ce83 !important;
  }

  .checkoutpageinner {
    padding-top: 5vw;

    .card-p {
      display: flex;
      align-items: center;

      .cardiconset {
        width: 2vw;
        margin-right: 1.25vw;
      }
    }

    .basketarea.MuiPaper-elevation1 {
      box-shadow: none;

      .MuiAccordionDetails-root {
        padding: 0 !important;
        display: block;
      }

      .MuiAccordionSummary-root {
        padding: 0;
      }

      .MuiAccordionSummary-content.Mui-expanded {
        margin: 0;
      }

      .baskettitleedit p {
        display: none;
      }
    }

    h2 {
      text-align: center;
    }

    .checkouttitle {
      font-family: 'Asap';
      font-style: normal;
      // font-weight: 600;
      font-weight: bold;
      font-size: 1.5vw;
      line-height: 1.7vw;
      letter-spacing: 0.5px;
      margin: 0;
      color: #2f2b23f2;
      position: relative;
    }

    .payment-title-disable {
      color: #757575;
    }

    .fieldandcart {
      padding-top: 3.8vw;

      .baskettitleedit {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-family: 'Asap';
          font-style: normal;
          font-weight: 500;
          font-size: 0.96vw;
          line-height: 1vw;
          text-decoration-line: underline;
          color: #2f2b23;
          margin: 0;
          cursor: pointer;
        }
      }

      .inputfeilds {
        padding-top: 3.4vw;
        border-bottom: 1px solid #d8d8d8;

        .MuiTextField-root {
          margin-bottom: 1.87vw;
        }

        .MuiFormControl-root {
          width: 100%;
        }

        .MuiOutlinedInput-root {
          border-radius: 1.04vw;
        }

        .MuiInputLabel-root {
          font-size: 1.12vw;
          line-height: 1.5vw;
          left: 8px;
        }

        .MuiOutlinedInput-input {
          padding-left: 20px;
          padding-right: 45px;
        }

        .MuiInputLabel-root.Mui-focused {
          color: #777777;
          font-size: 1.1vw;
          line-height: 1.4vw;
        }

        .Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #2f2b23;
          border: 1px solid;
        }

        .addressmanually {
          p {
            font-family: 'Asap';
            font-style: normal;
            font-weight: 400;
            font-size: 1.1vw;
            line-height: 1.5vw;
            color: #757575;
            margin-bottom: 1.3vw;
            // border-bottom: 1px solid #757575;
            border-bottom: 1px solid #585858;
            width: fit-content;
            cursor: pointer;
            margin-top: 0;
          }
        }

        .btnalignright {
          text-align: right;

          .darkbtn {
            margin-bottom: 1.25vw;
            line-height: 2vw !important;
            color: #ffffff !important;
            background-color: #2f2b23;
            // margin-top: 1.4vw;
            padding: 0.95vw 2vw !important;
            min-width: 11vw !important;
            margin-left: 1.04vw !important;
            margin-right: 0 !important;
          }

          .product-btn,
          .retailers-btn {
            margin-bottom: 1.25vw;
            line-height: 2vw !important;
            // margin-top: 1.4vw;
            padding: 0.95vw 2vw !important;
            min-width: 11vw !important;
            color: #757575 !important;
            background: #e5e5e5 !important;
            // font-weight: 600 !important;
            font-weight: bold !important;
            margin-left: 1.04vw !important;
            margin-right: 0 !important;
          }
        }
      }

      .exp-text {
        font-size: 1.11vw;
        line-height: 1.5vw;
        font-family: 'Asap';
        color: #848484;
        font-weight: 400;
        margin-bottom: 0;
        margin-right: 0.25vw;
      }

      .billingcontry {
        font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1vw;
        line-height: 1.5vw;
        color: #2f2b23;

        p {
          color: #848484;
          margin: 0 0 0.24vw;
        }

        .email-text-inner {
          margin-top: 0.6vw;
        }

        p.phone-text-inner {
          margin-bottom: 4.1vw;
        }

        margin-bottom: 1.87vw;
      }

      .usecredit {
        font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1vw;
        line-height: 1.5vw;
        color: #2f2b23;

        span.amount {
          color: #2f2b23;
          font-weight: bold;
          margin-left: 10px !important;
        }

        margin-bottom: 1.8vw;

        .MuiFormControlLabel-root {
          margin-left: 0;
        }

        .MuiTypography-root {
          margin-left: 1vw;
        }
      }
    }

    svg {
      color: #979797;
    }

    .Mui-checked svg {
      color: #2f2b23 !important;
      border-radius: 5px;
    }
  }

  .paymentarea {
    span.amount {
      margin-left: 0.6vw !important;
      margin-bottom: 0px !important;
    }
  }

  .paymentmethod {
    label {
      padding: 1.4vw 2vw;

      svg {
        color: #2f2b23;
      }

      .MuiTypography-root {
        margin-left: 1vw;
        font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1vw;
        line-height: 1.5vw;
        color: #2f2b23;
      }
    }
  }

  .cardbox {
    margin-top: 2.8vw;
    padding: 1.45vw 1.4vw;
    background-color: #f7f7f7b0;
    border-radius: 0.9vw;
    background-image: url('../../../assets/images/leaf-card.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 27%;

    .addcard {
      font-family: 'Asap';
      font-style: normal;
      // font-weight: 600;
      font-weight: bold;
      font-size: 1.2vw;
      line-height: 1.5vw;
      margin: 0;
      color: #2f2b23 !important;
    }

    .cardfield {
      padding: 4vw 0;

      p {
        margin: 0;
        font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1vw;
        line-height: 1.5vw;
        color: #2f2b23 !important;
        margin-bottom: 0.35vw;
      }

      input {
        padding: 0.6vw 1.2vw;
        border-radius: 0.5vw;
        width: 100%;
        border: 0.6px solid #a6a194;
        font-size: 0.85vw;
        min-height: 2.6097vw;
      }

      input:focus-visible {
        outline: none;
      }
    }
  }
}

.paymentarea {
  padding-top: 2.5vw;

  .inputfeilds {
    padding-top: 2.5vw;
  }
}

.samebillingadd {
  .billingcontry p {
    line-height: 1.8vw;
  }
}

.deliveryarea {
  .selectedaddress span.MuiFormControlLabel-label {
    border: 2px solid #2f2b23 !important;
    border-radius: 0.5vw !important;
  }

  .mobileshow {
    position: relative;
    display: contents;
  }

  .billingcontry {
    // margin-top: 2.5vw;
    line-height: 1.8vw;

    label {
      margin-bottom: 0.7vw;
    }

    span.MuiFormControlLabel-label {
      width: 100%;
      padding: 1.1vw !important;
      display: block !important;

      border: 2px solid #f5f5f5;
      border-radius: 0.5vw;
      margin-right: 0;
    }

    span.MuiFormControlLabel-label:hover {
      border: 2px solid #2f2b23;
    }

    .MuiRadio-root {
      display: none !important;
    }

    .mtextbtnset {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.1vw;
      line-height: 1.6vw;
    }

    .editbtnall {
      font-family: 'Asap';
      font-style: normal;
      font-size: 1.1vw;
      line-height: 1.4vw;
      text-decoration-line: underline;
      color: #2f2b23;
      margin: 0;
      cursor: pointer;
    }

    .addnewaddbtn {
      text-align: right;
      text-decoration-line: underline;
      color: #2f2b23;
      cursor: pointer;
      margin-top: 0.3vw;
    }
  }

  .btnalignright {
    text-align: right;
  }
}

.paymentarea {
  .selectedaddress span.MuiFormControlLabel-label {
    border: 2px solid #2f2b23 !important;
    border-radius: 0.5vw !important;
  }

  .billingcontry {
    // margin-top: 2.5vw;
    line-height: 1.8vw;

    label {
      margin-bottom: 0.7vw;
    }

    span.MuiFormControlLabel-label {
      width: 100%;
      padding: 1.1vw !important;
      display: block !important;
      border: 2px solid #f5f5f5;
      border-radius: 0.5vw;
      margin-right: 0;
    }

    span.MuiFormControlLabel-label:hover {
      border: 2px solid #2f2b23;
    }

    .MuiRadio-root {
      display: none !important;
    }

    .mtextbtnset {
      display: flex;
      justify-content: space-between;
      font-size: 1.1vw;
      line-height: 1.4vw;
    }

    .deletebtn {
      font-family: 'Asap';
      font-style: normal;
      font-size: 1vw;
      line-height: 1.2vw;
      text-decoration-line: underline;
      color: #2f2b23;
      margin: 0;
      cursor: pointer;
    }

    .addnewaddbtn {
      text-align: right;
      text-decoration-line: underline;
      color: #2f2b23;
      cursor: pointer;
      margin-top: 0.3vw;
    }
  }
}

.deliverttitleedit,
.paymenttitleedit {
  p {
    font-size: 1.1vw !important;
    line-height: 1.4vw !important;
  }
}

.orderreviewarea {
  padding-top: 2.5vw;

  .btnalignright {
    margin-bottom: 1vw;
  }
}

.inputiconmeinner {
  position: absolute;
  margin-left: 5vw;
  color: #74ce83 !important;
}

.basketcontent {
  margin-top: 2.5vw;

  .subttotal,
  .estdelivery {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 1.18vw;
    line-height: 1.5vw;
    margin-top: 0.7638vw;

    p {
      margin: 0;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Asap';
    font-style: normal;
    font-weight: bold;
    font-size: 1.45vw;
    line-height: 1.8vw;

    p {
      margin-bottom: 0px;
    }
  }
}

.productcardcheckout {
  margin-top: 3.19vw;

  .leftcartitem {
    padding-left: 12px;
  }

  .arrivestitle {
    font-family: 'Asap';
    font-style: normal;
    font-size: 1.25vw;
    line-height: 1.6vw;
    color: #2f2b23;
    // font-weight: 600;
    font-weight: bold;
    margin-bottom: 1.5vw;
  }

  .productdetails {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25vw;
    line-height: 1.6vw;
    color: #2f2b23;

    div {
      margin-bottom: 2.08vw;
      font-weight: 500;
    }

    p {
      // color: #848484;
      // margin: 0;
      font-size: 1.15vw;
      line-height: 1.5vw;
      color: #757575;
      margin: 0;
      font-size: 1.21vw;
      font-weight: 400;
      line-height: 1.5vw;
      margin-bottom: 0.2vw;
    }
  }

  .cardproimg {
    width: 100%;
    max-height: 4.89vw;
    max-width: 4.89vw;
    object-fit: cover;
    object-position: center;
    margin: 4px auto 0;
  }
}

.mobilecartitem {
  display: none;
}

@media (min-width: 260px) and (max-width: 920px) {
  .checkoutpagenew {
    padding-right: 0px;
    padding-top: 19px;
    margin-bottom: 0;
  //   .checkoutpageinner svg {
  //     color:#757575   !important;
   
  // }
    .inputfeilds .usecredit .MuiCheckbox-root {
      padding: 0 0px 0 10px !important;
    }

    .termsandcondition-text {
      font-size: 16.5px;
      line-height: 26px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    .dvhide {
      display: none;
    }

    .dvshow {
      display: block !important;
    }

    .baskettopmobile {
      padding-top: 35.5px !important;

      .MuiAccordion-root.Mui-expanded {
        margin-top: 0px;
      }

      .baskettitleedit {
        margin-bottom: 0 !important;
      }

      svg {
        font-size: 26px;
      }

      .MuiAccordionSummary-root.Mui-expanded {
        min-height: auto;
      }

      .MuiAccordion-root:before {
        content: none;
      }

      .MuiAccordion-root {
        padding-bottom: 29px;
        border-bottom: 1.8px solid #d8d8d8;
        border-radius: 0 !important;
      }

      .MuiAccordion-root.Mui-expanded {
        padding-bottom: 0px;
        border-bottom: 0px solid #d8d8d8;
        border-radius: 0 !important;
      }

      .MuiAccordionSummary-root {
        .MuiAccordionSummary-content {
          margin: 0 !important;
        }
      }
    }

    .deliveryareagrid {
      padding-right: 0vw;
    }

    .basketarea {
      padding-right: 0vw;
    }

    .all-screen-container {
      max-width: 94% !important;
    }

    .checkoutpageinner {
      .card-p {
        display: flex;
        align-items: center;

        .cardiconset {
          width: 29px;
          margin-right: 16px;
        }
      }

      .payment-title-disable {
        color: #2f2b23;
      }

      .inputiconmeinner {
        // margin-left: 10px;
        right: -32px;
        top: 4px;
        font-size: 20px;
      }

      .checkouttitle {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
      }

      .mobilecartitem {
        display: block;
        font-family: 'Asap';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #2f2b23;
        text-align: center;
        padding-bottom: 29px;
        border-bottom: 1.5px solid #cdcdcd;
        margin-top: 9px;

        span {
          color: #757575;
        }
      }

      .deliveryarea {
        .selectedaddress span.MuiFormControlLabel-label {
          border: 2px solid #2f2b23 !important;
          border-radius: 10px !important;
        }

        .billingcontry {
          label {
            margin-bottom: 14px;
            margin-left: 0;
            margin-right: 0;
          }

          span.MuiFormControlLabel-label {
            width: 100%;
            padding: 12px !important;
            display: block !important;

            border: 2px solid #f5f5f5;
            border-radius: 8px;
            margin-right: 0;
          }

          span.MuiFormControlLabel-label:hover {
            border: 2px solid #2f2b23;
          }

          .MuiRadio-root {
            display: none !important;
          }

          .mtextbtnset {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 24px;
          }

          .editbtnall {
            font-family: 'Asap';
            font-style: normal;
            font-size: 16px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #2f2b23;
            margin: 0;
            cursor: pointer;
          }

          .addnewaddbtn {
            text-align: right;
            text-decoration-line: underline;
            color: #2f2b23;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }

      .paymentarea {
        padding-top: 29px;

        .usecredit {
          padding-left: 0 !important;
        }

        .selectedaddress span.MuiFormControlLabel-label {
          border: 2px solid #2f2b23 !important;
          border-radius: 8px !important;
        }

        .billingcontry {
          margin-bottom: 28px;

          label {
            margin-bottom: 10px;
            margin-left: 0;
            margin-right: 0;
          }

          span.MuiFormControlLabel-label {
            width: 100%;
            padding: 12px !important;
            display: block !important;

            border: 2px solid #f5f5f5;
            border-radius: 8px;
            margin-right: 0;
          }

          span.MuiFormControlLabel-label:hover {
            border: 2px solid #2f2b23;
          }

          .MuiRadio-root {
            display: none !important;
          }

          .mtextbtnset {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 24px;
          }

          .deletebtn {
            font-family: 'Asap';
            font-style: normal;
            font-size: 16px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #2f2b23;
            margin: 0;
            cursor: pointer;
          }

          .addnewaddbtn {
            text-align: right;
            text-decoration-line: underline;
            color: #2f2b23;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }

      .orderreviewarea {
        padding-top: 29px;

        .inputfeilds {
          border-bottom: none !important;
        }

        .basketcontent {
          padding-bottom: 29px;
        }
      }

      .fieldandcart {
        margin-top: 0px;
        flex-direction: column-reverse;
        padding-top: 0;

        .paymentmethod {
          font-weight: 700;
          margin-top: 15px !important;

          label {
            padding: 18px 20px 10px;

            .MuiTypography-root {
              font-size: 16.5px;
              line-height: 19px;
              margin-left: 20px;
            }
          }
        }

        .billingcontry {
          font-size: 16.5px;
          line-height: 26px;
          margin-top: 0px;
          margin-bottom: 31px;
        }

        .usecredit {
          font-size: 16.5px;
          line-height: 19px;
          margin-bottom: 18px;
          //   padding-left: 18px !important;

          .MuiTypography-root {
            margin-left: 12px;
          }

          .fieldandcart {
            margin-top: 0px;
            flex-direction: column-reverse;
            padding-top: 0;

            .paymentmethod {
              // font-weight: 700;
              font-weight: bold;
              margin-top: 15px !important;

              label {
                padding: 18px 20px 10px;

                .MuiTypography-root {
                  font-size: 16.5px;
                  line-height: 19px;
                  margin-left: 20px;
                }
              }
            }

            .billingcontry {
              font-size: 16.5px;
              line-height: 26px;
              margin-top: 0px;
              margin-bottom: 31px;
            }

            .usecredit {
              font-size: 16.5px;
              line-height: 19px;
              margin-bottom: 18px;
              // padding-left: 18px !important;

              .MuiTypography-root {
                margin-left: 12px;
              }

              label {
                align-items: flex-start;

                svg {
                  font-size: 24px;
                }
              }
            }

            .baskettitleedit {
              margin-bottom: 15px;

              p {
                font-size: 16.5px !important;
                line-height: 19px !important;
              }
            }

            .inputfeilds {
              .inputicon {
                p {
                  margin-bottom: 0 !important;
                }
              }

              padding-top: 20px;

              span {
                font-size: 16px;
                line-height: 22px;
              }

              legend span {
                font-size: 12px;
                line-height: 14px;
              }

              .MuiGrid-container {
                margin-top: 0;
              }

              .MuiInputLabel-root {
                font-size: 16px;
                line-height: 27px;
              }

              .MuiInputLabel-root.Mui-focused {
                font-size: 15px;
                line-height: 27px;
              }

              .MuiGrid-item {
                padding-top: 0;
              }

              .MuiTextField-root {
                margin-bottom: 28px;
              }

              .MuiOutlinedInput-root {
                border-radius: 15px;
              }

              .address-input-checkout {
                border-radius: 15px;
                font-size: 15px;
                line-height: 19px;
                padding-left: 30px !important;
              }

              .iconwithsearch {
                position: relative;

                svg {
                  position: absolute;
                  top: 30%;
                  bottom: 0;
                  left: 8px;
                  font-size: 20px;
                  line-height: 24px;
                }
              }

              .addressmanually {
                margin-top: 5px;

                p {
                  font-size: 18px;
                  line-height: 27px;
                  margin-bottom: 24px;
                }

                .MuiGrid-container {
                  margin-top: 0;
                }
              }

              .btnalignright .darkbtn {
                background-color: #e5e5e5 !important;
                color: #757575 !important;
                padding: 14px 20px !important;
                width: 100%;
                font-size: 15px !important;
                line-height: 29px !important;
              }
            }

            .cardbox {
              margin-top: 35px;
              padding: 20px;
              background-color: #f7f7f7;
              border-radius: 10px;
              background-image: none;
              padding-bottom: 65px;

              .addcard {
                font-size: 17px;
                line-height: 19px;
                color: #2f2b23;
                font-weight: bold;
              }

              .cardfield {
                p {
                  font-size: 15px;
                  line-height: 22px;
                  // color: #2F2B23;
                }

                input {
                  font-size: 15px;
                  line-height: 18px;
                  padding: 14px 18px;
                  padding-right: 35px;
                  border: 1px solid #a6a194;
                  border-radius: 10px;
                  color: #9f9f9f;
                }

                .MuiGrid-item {
                  margin-bottom: 18px;
                }
              }
            }
          }

          label {
            align-items: flex-start;

            svg {
              font-size: 24px;
            }
          }
        }

        .baskettitleedit {
          margin-bottom: 15px;

          p {
            font-size: 16.5px !important;
            line-height: 19px !important;
          }
        }

        .inputfeilds {
          .inputicon {
            p {
              margin-bottom: 0 !important;
            }
          }

          padding-top: 20px;

          span {
            font-size: 16px;
            line-height: 22px;
            padding: 0 !important;
          }

          legend span {
            font-size: 12px;
            line-height: 14px;
          }

          .MuiGrid-container {
            margin-top: 0;
          }

          .MuiInputLabel-root {
            font-size: 16px;
            line-height: 27px;
          }

          .MuiInputLabel-root.Mui-focused {
            font-size: 15px;
            line-height: 27px;
          }

          .MuiGrid-item {
            padding-top: 0;
          }

          .MuiTextField-root {
            margin-bottom: 28px;
          }

          .MuiOutlinedInput-root {
            border-radius: 15px;
          }

          .address-input-checkout {
            border-radius: 15px;
            font-size: 15px;
            line-height: 18px;
            padding-left: 30px !important;
          }

          .iconwithsearch {
            position: relative;

            svg {
              position: absolute;
              top: 30%;
              bottom: 0;
              left: 8px;
              font-size: 20px;
              line-height: 24px;
            }
          }

          .addressmanually {
            margin-top: 5px;

            p {
              font-size: 18px;
              line-height: 27px;
              margin-bottom: 24px;
            }

            .MuiGrid-container {
              margin-top: 0;
            }
          }

          .btnalignright .darkbtn {
            // background-color: #e5e5e5 !important;
            // color: #757575 !important;
            padding: 14px 20px !important;
            width: 100%;
            font-size: 15px !important;
            line-height: 29px !important;
            font-weight: bold !important;
            margin-left: 0vw !important;
            margin-right: 0 !important;
          }

          .btnalignright .product-btn,
          .retailers-btn {
            background-color: #e5e5e5 !important;
            color: #757575 !important;
            padding: 14px 20px !important;
            width: 100%;
            font-size: 15px !important;
            border-radius: 35px !important;
            margin-bottom: 19px !important;
            line-height: 29px !important;
            margin-top: 12px;
            font-weight: bold !important;
            margin-left: 0vw !important;
            margin-right: 0 !important;
          }
        }

        .cardbox {
          margin-top: 35px;
          padding: 20px;
          background-color: #f7f7f7;
          border-radius: 10px;
          background-image: none;
          padding-bottom: 65px;

          .addcard {
            font-size: 17px;
            line-height: 19px;
            color: #2f2b23;
            font-weight: bold;
          }

          .cardfield {
            p {
              font-size: 15px;
              line-height: 22px;
              color: #2f2b23;
            }

            input {
              font-size: 15px;
              line-height: 18px;
              padding: 14px 18px;
              padding-right: 35px;
              border: 1px solid #a6a194;
              border-radius: 10px;
              color: #9f9f9f;
            }

            .MuiGrid-item {
              margin-bottom: 18px;
            }
          }
        }
      }

      .samebillingadd .billingcontry p {
        line-height: 24px;
      }
    }

    .productcardcheckout {
      padding-bottom: 34px;
      border-bottom: 1px solid #d8d8d8;
      margin-top: 15px;

      .MuiGrid-root {
        margin-top: 0px;
        padding-top: 0;
      }

      .cardproimg {
        min-width: 64px;
        max-width: 64px;
        max-height: 64px;
        height: 64px;
      }

      .productdetails {
        font-size: 17px;
        line-height: 19px;

        p {
          font-size: 16px;
          line-height: 28px;
        }

        div {
          margin-bottom: 20px;
        }
      }

      .arrivestitle {
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 4.89px;
        font-weight: bold;
      }
    }

    .basketcontent {
      margin-top: 14px;
      padding-bottom: 35px;
      // border-bottom: 1px solid #d8d8d8;

      .subttotal,
      .estdelivery {
        font-size: 17px;
        line-height: 28px;
      }

      .total {
        margin-top: 25px;
        font-size: 19px;
        line-height: 22px;

        p {
          margin: 0;
        }
      }
    }

    .inputiconme {
      margin-top: 19px !important;
      right: 14px;
      color: #74ce83 !important;
      font-size: 20px;
    }

    .cardfield {
      .inputiconme {
        margin-top: 14px !important;
      }
    }

    .gridsetmobile {
      flex-direction: column-reverse;
      margin-top: 0;
    }

    .deliveryareagrid {
      padding-top: 30px !important;
    }

    .orderreviewarea {
      .arrivestitle {
        display: none;
      }

      .productcardcheckout {
        border: none;
        padding-bottom: 25px;
      }
    }

    .checkoutpageinner .fieldandcart .orderreviewarea .inputfeilds .btnalignright .darkbtn {
      background-color: #2f2b23 !important;
      color: white !important;
      width: 90%;
      margin: 16px 5% !important;
    }

    .checkoutpageinner .fieldandcart div.selectpayment {
      span.MuiFormControlLabel-label {
        border: none !important;
        padding: 0 !important;
      }

      .MuiRadio-root {
        display: block !important;
        padding: 0;
        width: 20px !important;
        height: 23px !important;
      }

      .MuiRadio-root.Mui-checked .MuiSvgIcon-fontSizeMedium {
        display: block !important;
      }

      .MuiRadio-root.Mui-checked {
        border: none !important;
        color: #2f2b23 !important;
      }

      label {
        margin-bottom: 0;
        padding: 16px 10px 0;
      }
    }
  }
}

@media (min-width: 260px) and (max-width: 395px) {
  .checkoutpagenew .checkoutpageinner .fieldandcart .usecredit span.amount {
  padding-left: 27px !important;
  }
}

@media (min-width: 930px) and (max-width: 1280px) {
  .checkoutpagenew {
    .checkoutpageinner {
      .card-p {
        display: flex;
        align-items: baseline;
      }

      .inputfeilds .MuiOutlinedInput-input {
        padding: 11.5px 14px 15.5px;
      }

      .fieldandcart .inputfeilds .MuiInputLabel-root {
        font-size: 1.42vw;
        line-height: 1.5vw;
        left: 2px;
        top: 3px;
      }

      .paymentmethod label .MuiTypography-root {
        margin-left: 2vw;
      }

      .inputfeilds span {
        margin-top: -3px !important;
      }

      .cardbox .cardfield input {
        padding: 0.8vw 1.2vw;
        font-size: 0.95vw;
        min-height: 3vw;

      }

      .cardbox .cardfield .inputiconme {
        margin-top: 0.6vw;
        width: 0.8em;
        height: 0.8em;
      }
    }

    .address-input-checkout {
      padding: 16px 2vw 18px !important;
      // font-size: 1.12vw;
      line-height: 1.5vw !important;
    }

    .iconwithsearch svg {
      top: 1.6vw;
    }

    .inputfeilds legend span {
      font-size: 11px !important;
    }
  }

  .deliveryarea .billingcontry label {
    margin-top: 0.7vw;
  }
}

@media (min-width: 1600px) {
  .checkoutpagenew {
    .inputiconme {
      position: absolute;
      margin-top: 1.2vw;
      right: 1.2vw;
      color: #74ce83 !important;
      font-size: 1.6vw;
    }

    .checkoutpageinner {
      .inputiconmeinner {
        position: absolute;
        margin-left: 4.7vw;
        color: #74ce83 !important;
        font-size: 1.6vw;
      }

      .fieldandcart {
        .usecredit {
          .MuiTypography-root {
            font-size: 1.1vw;
          }

          svg {
            font-size: 1.8vw;
          }
        }

        .paymentmethod label svg {
          font-size: 1.8vw;
        }

        .inputfeilds {
          .MuiInputLabel-root {
            font-size: 1.12vw;
            line-height: 3vw;
          }

          .MuiInputLabel-root.Mui-focused {
            font-size: 1.1vw;
            line-height: 1.3vw;
          }

          .MuiOutlinedInput-root {
            font-size: 1.1vw;
            line-height: 1.3vw;

            input {
              min-height: 2.3vw;
              padding: 1.12vw 1.2vw !important;
            }
          }

          .address-input-checkout {
            padding: 1.7vw 1.2vw !important;
            min-height: 2.3vw;
            font-size: 1vw;
          }
        }
      }
    }
  }
}

@media (min-width: 1930px) {
  .checkoutpagenew {
    .checkoutpageinner {
      .fieldandcart .inputfeilds .MuiInputLabel-root {
        font-size: 1.12vw;
        font-size: 1.12vw;
        // line-height: 1.5vw;
        // top: -5px;
      }
      .MuiInputLabel-root.MuiInputLabel-shrink{
        font-size: 1.20vw !important;
      line-height: 1.5vw !important;
      left: 2px;
      top: -5px;

      }
     .fieldandcart .inputfeilds .address-input-checkout {
        padding: 1.5vw 2.2vw !important;
        min-height: 2.3vw;
        font-size: 1vw;
      }
      .fieldandcart .inputfeilds .MuiOutlinedInput-root input {
        min-height: 2.3vw;
        padding: 1vw 1.2vw 0.8vw !important;
      }

    }

    .inputfeilds legend span {
      font-size: 1.1vw !important;
    }

    .basket-btn,
    .buy-btn {
      font-size: 1.0937vw !important;
    }
  }
}

@media (min-width: 3000px) {
  .checkoutpagenew {
    .checkoutpageinner {
      .fieldandcart {
        .inputfeilds {
          .MuiInputLabel-root {
            font-size: 1.1vw;
            line-height: 3vw;
            margin-left: 1vw;
          }

          .MuiInputLabel-root.Mui-focused {
            margin-left: 0.3vw;
            font-size: 1.02vw;
            line-height: 1vw;
          }

          .MuiOutlinedInput-root input {
            // min-height: 1.5vw;
          }

          .btnalignright .darkbtn {
            font-size: 1vw !important;
          }
        }
      }
    }
  }
}