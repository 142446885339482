@import "../abstracts/variables";

.Orderfailed {
    .order-section {
        max-width: 629px;
        margin: auto;
        padding-top: 4.2342vw;
        margin-bottom: 6.78020vw;
    }

    .order-heading {
        text-align: center;
        font-weight: normal;
        letter-spacing: $letter-spacing;
        // color: #949D8C;
        margin-bottom: 2vw;
        font-weight: bold;
    }

    .order-number {
        text-align: center;
        font-size: 1.632vw;
        line-height: 1.91vw;
        font-weight: bold;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: #2F2B23;
        opacity: 1;
        margin-bottom: 1.922vw;
        padding: 0 28%;
    }


    .tree-text {
        text-align: center;
        font-size: 1.25vw;
        line-height: 1.611vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
        margin-top: 1.9614vw;
        margin-bottom: 4.66458vw;
        padding: 0px 9.854vw;
    }

    .back-btn {
        text-align: center;

        .buy-btn.lightbtn {
            color: #FFFFFF !important;
            padding: 0.684375vw 2.06718vw 0.66979vw 2.0833vw !important;
            font-size: 1.25vw !important;
            line-height: 1.40625vw !important;
            text-transform: inherit !important;

        }

    }


}

@media  (max-width: 600px) {
    .Orderfailed {
        .order-section {
            max-width: 300px;
            margin: auto;
        }

        .order-heading {
            font-size: 34px;
            line-height: 39px;
            margin-bottom: 25px;
        }

        .order-number {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 30px;
            padding: 0 8%;
        }

    
        .tree-text {
            font-size: 18px;
            line-height: 23px;
            padding: 0px;
        }

        .shop-btn {

            font-size: 16px;
            line-height: 24px;
            border-radius: 20px;
            margin-bottom: 30px;

        }

        .back-btn {
            text-align: center;

            .buy-btn.lightbtn {
                font-size: 16px !important;
                line-height: 19px !important;
                border-radius: 40px !important;
                margin: 15px 0 !important;
                padding: 3vw 5vw !important;
                min-width: 125px !important;

            }

        }
    }
}

@media (min-width:600px) and (max-width: 920px) {
    .Orderfailed {
        .order-section {
            max-width: 300px;
            margin: auto;
        }

        .order-heading {
            font-size: 34px;
            line-height: 39px;
            margin-bottom: 10px;
        }

        .order-number {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 20px;
            padding: 0 8%;
        }

   

        .tree-text {
            font-size: 18px;
            line-height: 23px;
            padding: 0px;
        }

        .shop-btn {

            font-size: 22px;
            line-height: 35px;
            border-radius: 20px;
            margin-bottom: 30px;

        }
    }
}

@media (min-width:920px) and (max-width: 1280px) {
    .Orderfailed {
        .order-section {
            max-width: 300px;
            margin: auto;
        }

        .order-heading {
            font-size: 37px;
            line-height: 42px;
            margin-bottom: 10px;
        }

        .order-number {
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 20px;
        }

        .animation-card {
            height: 38.0729vw;
        }

        .gif {
            height: 37vw;
        }

        .tree-text {
            font-size: 20px;
            line-height: 23px;
            padding: 0px 0px;
            color: #2F2B23;
        }

        .shop-btn {

            font-size: 25px;
            line-height: 35px;
            border-radius: 20px;
            margin-bottom: 30px;

        }
    }
}

@media (min-width:1930px) {
    .Orderfailed {
        .tree-text {
            padding: 0px;
        }

        .order-heading {
            font-size: 1.4vw;
        }
    }
}