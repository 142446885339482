.reviewskeleton{
    .heading{
        width:8vw;
        height:3.5vw;
        margin-bottom:2vw;
    }
    .reviewbox{
        width:100%;
        height:14vw;
        border-radius: 2.08vw;
    }
    .saller-stock{
        display: flex;
    }
    .usericon{
        width:4vw;
        height:4vw;
        margin-right: 1.5vw;
    }
    .usrename{
        width:8vw;
    }
    .datetime{
        width:16vw;
        height: 2vw;
    }
    .productname-img{
        display: flex;
        align-items: center;
    }
    .productimg{
        width: 9vw;
        height: 12vw;
        border-radius: 2.08vw;
        margin-right: 1.5vw;
    }
    .productname{
        width:15vw;
    }
}