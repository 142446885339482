@import '../abstracts/variables';

.filters {
  margin-top: 2.1vw;
  div.accordianbox.categoryfilters {
    padding: 1vw 0 !important;
  }
  .iconset .collapsIconWrapper svg {
    transform: rotate(180deg) !important;
  }
  .iconset {
    .expandIconWrapper,
    .collapsIconWrapper {
      max-height: 22px;
    }
  }
  .addspace label {
    margin-bottom: 0.5vw !important;
  }
  .bordertop {
    border-top: 1px solid #dbdbdb;
    border-radius: 0 !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    display: block;
    width: 100%;
  }
  .marginleft20 {
    margin-left: 30px;
  }
  .marginleft40 {
    margin-left: 60px;
  }
  .mainfilterheading {
    margin-bottom: 1.5vw !important;
  }
  .MuiAccordionSummary-content {
    margin: 0;
    display: block;
    width: 100%;
  }
  label {
    width: 100%;
    margin-bottom: 0vw !important;
  }
  .firstcat2 {
    // margin-left: 1.8vw;
    // margin-bottom: 0.5vw;
  }
  .firstcat3 {
    // margin-left: 3.6vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }
  .accordianbox.MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiAccordion-root:before {
    height: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    svg {
      transform: rotate(180deg);
    }
  }

  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    svg {
      transform: rotate(180deg);
    }
  }
  .MuiAccordionSummary-root,
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 26px;
  }
  div#panel1a-header {
    margin-bottom: 0.5vw;
  }
  .firstcat3 .MuiFormControlLabel-root {
    margin-bottom: 0.5vw !important;
  }

.MuiAccordionSummary-root,
.MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}
div#panel1a-header {
  margin-bottom: 0.5vw;
}
.firstcat3 .MuiFormControlLabel-root {
  margin-bottom: 0.5vw !important;
}
// .accordianbox {
//   border-bottom: 2px solid #E4E1D9;
//   padding-bottom: 0.7812vw;
//   margin-bottom: 0.7812vw;
// }

.filterheading {
  font-size: 1.302vw;
  line-height: 1.51vw;
  color: #999999;
  color: #707070;
  font-family: $gt-font-regular;
}

.filterbody label {
  width: 100%;
margin-bottom: 0.8vw !important;
  letter-spacing: 0px;
  color: #707070;
  font-weight: 500;
  margin-left: 0px;

  span {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    font-size: 0.95vw !important;
  }
}
.marginbott label {
  margin-bottom: 1.5vw !important;
}
.filterbody label span.MuiRadio-root {
  margin-right: 6px;
  font-size: 0.95vw !important;
  line-height: 1.04vw;
}

.MuiRadio-root {
  width: 1.5vw;
  height: 1.5vw;
}
.MuiRadio-root.Mui-checked {
  color: #ffffff;
  border: 0.5vw solid #949d8c;
  border-radius: 40px;
  width: 1.5vw;
  height: 1.5vw;

  .MuiSvgIcon-fontSizeMedium {
    display: none;
  }
}
.MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}
ul.catfilter {
  list-style: none;
  padding: 0;
  margin: 0;
}
.MuiAccordionDetails-root {
  padding: 0px 0px 0 0px !important;
  display: block;
  .MuiSvgIcon-root {
    fill: currentColor;
    width: 1.1em;
    height: 1.1em;
  }
}
ul.catfilter li {
  width: 24%;
  padding: 1.2%;
  display: inline-block;
  vertical-align: text-top;
}

ul.catfilter li img {
  width: 100%;
  min-height: 60px;
  border-radius: 50%;
}

ul.catfilter li p.catname {
  text-align: center;
  font-size: 0.729vw;
  line-height: 0.833vw;
  letter-spacing: 0px;
  color: #949d8c;
  opacity: 1;
  margin-top: 4px;
}

.MuiSvgIcon-root {
  color: #b7b6b6 !important;
  font-size: 1.7vw;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  color: #b7b6b6 !important;
}
.MuiAccordionSummary-root {
  padding: 0px;
}
.MuiPaper-rounded {
  border-radius: 0px !important;
}
}
@media (min-width: 280px) and (max-width: 920px) {
  .filters {
    margin-top: 0px;
    .MuiAccordionDetails-root{
      padding: 0 !important;
    }
    div.accordianbox.categoryfilters {
      padding: 5vw 0 !important;
    }
  label {
   
      margin-bottom: 10px !important;
  }
    .MuiRadio-root {
      width: 20px;
      height: 20px;
    }
    .firstcat2 {
      // margin-left: 20px;
      margin-bottom: 5px;
    }
    .firstcat3 {
      margin-left: 15px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .addspace label {
      margin-bottom: 10px !important;
    }
    .firstcat3 .MuiFormControlLabel-root {
      margin-bottom: 5px !important;
    }
    .mainfilterheading {
      margin-bottom: 15px !important;
      align-items: flex-start;
    }

    .iconset {
      .expandIconWrapper,
      .collapsIconWrapper {
        max-height: 22px;
      }
    }
    .MuiRadio-root.Mui-checked {
      border: 6px solid #949d8c;
    }
    .filterbody label span {
      font-size: 15px !important;
      line-height: 20px !important;
    }
    .MuiSvgIcon-root {
      font-size: 23px;
    }
    .filterheading {
      font-size: 18px;
      line-height: 20px;
    }

    ul.catfilter li p.catname {
      font-size: 12px;
      line-height: 13px;
      font-family: 'GT-Super-Text-medium';
    }

    .MuiRadio-root.Mui-checked {
      width: 20px;
      height: 20px;
    }
    .mobilefilter {
      display: flex;
      justify-content: space-between;
  
      .basket-btn,
      .buy-btn {
        opacity: 1;
        width: auto;
        height: auto;
        text-align: center;
        font-size: 14px !important;
        line-height: 27px !important;
        border-radius: 40px !important;
        padding: 5px 23px !important;
        margin: 1% 3% !important;
      }
    }
  }

  
}
@media (min-width: 921px) and (max-width: 1280px) {
  .filters {
    ul.catfilter li p.catname {
      text-align: center;
      font-size: 1vw;
      line-height: 1.2vw;
    }
    .MuiAccordionDetails-root {
      padding: 0px !important;
    }
  }
}
@media (min-width: 1000px) and (max-width: 1300px) {
  .filters {
   
    .MuiRadio-root {
      width: 20px;
      height: 20px;
    }
    .MuiRadio-root.Mui-checked {
      width: 20px;
      height: 20px;
    }
  }
}

@media (min-width: 1930px) and (max-width: 6000px) {
  .filters ul.catfilter li img{
    min-height: 3.5vw;
  }
}