@import '../abstracts/variables';

.sellerfooter{
    background: #ffffff;
    height: 156px;
    padding-top: 40px;
    width: 80%;
    margin: auto;
    
.footer-section{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo-img{
        height: 51px;
        width: 206px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }
    .link-section{
        display: flex;
    }
    .privacy-link {
        cursor: pointer;
        padding-left: 14px;
        margin-left: 32px;
        color: #2f2b23;
        font-size: 16px;
        font-weight: 300;
        font-family: $asap-font-regular;
    }
    .icon-section{
        display: flex;
    }
    .icon-img{
        width: 22px;
        height: 22px;
        margin-left: 28px;
        cursor: pointer;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }
}
@media (min-width:280px) and (max-width:600px){
.sellerfooter{
    height: auto;
    padding-top: 40px;
    width: 80%;
    margin: auto;
    margin-bottom: 25px;
.footer-section {
    flex-direction: column !important;
}
.icon-section {
    display: flex;
    margin-top: 20px !important;
    margin: auto;
}
.link-section {
    display: flex;
    margin-top: 20px;
}
}
}