.mobilecheckoutpassword {
  margin-top: 21px;
  padding: 0 45px;

  .email-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #2f2b23;
    margin-bottom: 13px;
  }

  .MuiInputLabel-root {
    font-size: 16px;
    line-height: 27px;
    left: 7px;
    font-family: 'Asap';
  }

  .MuiInputLabel-root.Mui-focused {
    color: #777777 !important;
    font-size: 16px;
    line-height: 27px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2f2b23 !important;
    border: 1px solid !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-input {
    // min-width: 100%;
    min-height: 56px;
    padding: 0 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 15px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .legend {
    height: auto;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #a6a194;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  ::placeholder {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .MuiInputLabel-formControl {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .continuing-text {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5px;
    line-height: 19px;
    color: #9f9f9f;
    margin-top: 13px;
    margin-bottom: 28px;

    span {
      text-decoration-line: underline;
      color: #2f2b23;
      margin-left: 9px;
      text-align: left;
      display: inline-block;
      font-weight: 500;
    }
  }
  legend span {
    font-size: 12px;
    line-height: 14px;
  }
  .forgot-text {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5px;
    line-height: 19px;
    margin-top: 27px;
    margin-bottom: 26px;
    text-decoration-line: underline;
    color: #2f2b23;
    text-align: left !important;
    // border-bottom: 1px solid #2f2b23;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  ::placeholder {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .MuiInputLabel-formControl {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .inputicon {
    position: relative;
  }

  .visibility-icon {
    position: absolute;
    left: 87%;
    top: 4.2vw;
  }

  // .inputicon .MuiSvgIcon-root {
  //     display: none;
  // }
  .darkbtn {
    min-height: 63px;
    font-size: 16px !important;
    min-width: 100% !important;
    padding: 17px 86px !important;
    border-radius: 35px;
    color: #ffffff !important;
    background-color: #2f2b23;
    font-weight: bold !important;
  }

  .btn-section-new {
    text-align: center;
    margin-bottom: 80px;
  }
}

@media (min-width: 264px) and (max-width: 350px) {
  .mobilecheckoutpassword {
    margin-top: 21px;
    padding: 0 30px;

    .email-text {
      font-weight: 500;
      font-size: 25px;
    }
  }
}
