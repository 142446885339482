@import "../abstracts//variables";
.delet-card {
  .voucher-text {
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.03125vw;
    font-family: $gt-font-regular;
    letter-spacing: $letter-spacing;
    color: #363240;
    opacity: $opcity;
    margin-bottom: 1.691vw;
  }
  .voucher-para {
    text-align: center;
    font-size: 1.19375vw;
    line-height: 1.375vw;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 1.1802vw;
  }
  .purchase-btn {
    display: flex;
    justify-content: space-between;
    padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
  }

  .MuiPaper-rounded {
    border-radius: 40px;
    width: 100%;
  }
}


@media (max-width: 600px) {
  .delet-card {
    .voucher-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 23.71px;
    }
    .voucher-para {
      font-size: 18px;
      line-height: 21px;
    }
 
  .MuiPaper-rounded {
    border-radius: 40px;
    width: 27.447916vw;
  }
  .buy-btn.lightbtn {
    font-size: 18px !important;
  }
  .basket-btn.darkbtn {
    font-size: 15px !important;
  }
}
}
@media (min-width: 600px) and (max-width: 920px) {
  .delet-card {
    .voucher-text {
      font-size: 26px;
      line-height: 29px;
      margin-bottom: 25.71px;
    }
    .voucher-para {
      font-size: 21px;
      line-height: 24px;
    }
 
  .MuiPaper-rounded {
    border-radius: 40px;
    width: 27.447916vw;
  }
}
}
@media (min-width: 920px) and (max-width: 1280px) {
  .delet-card {
    .voucher-text {
      font-size: 30px;
      line-height: 35px;
    }
    .voucher-para {
      font-size: 21px;
      line-height: 24px;
    }
 
  .MuiPaper-rounded {
    border-radius: 40px;
    width: 27.447916vw;
  }
  .basket-btn.darkbtn {
    background-color: #363240;
    font-size: 21px !important;
  }
  .buy-btn.lightbtn {
    background-color: #949d8c;
    font-size: 21px !important;
    height: 43px;
  }
}
}
