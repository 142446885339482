@import '../../abstracts/variables';

.guestcheckoutnew {
    padding-top: 9.236vw;
    padding-bottom: 42.916vw;

    .checkout-width {
        max-width: 56%;
        margin: auto;
    }

    .right-side-text {
        min-height: 7vw;
    }

    .guest-checkout-sec {
        padding-left: 3.055vw !important;
        padding-right: 2vw;
    }

    .checkout-heading {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 2.5vw;
        line-height: 2.847vw;
        color: #000000;
        text-align: center;
        margin-bottom: 2.666vw;
    }

    .member-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.388vw;
        line-height: 1.597225vw;
        text-align: center;
        color: #000000;
        margin-bottom: 1.5972vw;
    }

    .borderclass {
        border-right: 1px solid #D8D8D8;
        // padding-right: 3.888vw;
        padding-right: 3vw;
    }

    .credential-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.0416vw;
        line-height: 1.1805;
        text-align: center;
        color: #000000;
        padding-bottom: 3.3333vw;
    }

    .darkbtn {
        // min-width: 22.2916vw !important;
        min-width: auto;
        width: 100%;
        min-height: 4.375vw;
        margin-bottom: 0.5vw;
        font-size: 1.111vw !important;
        color: #FFFFFF !important;
        background-color: #2F2B23;

    }

    .checkout-btn-align {
        text-align: center;
    }
}
@media (min-width: 930px) and (max-width:1100px){
    .guestcheckoutnew {
        .checkout-width {
            max-width: 57%;
        }
    }
}
@media (min-width: 2000px) {
    .guestcheckoutnew {
        .checkout-width {
            max-width: 74%;
        }

        .borderclass {
            padding-right: 3.888vw;
        }

        .guest-checkout-sec {
            padding-left: 3.055vw !important;
            padding-right: 0;
        }

        .darkbtn {
            font-size: 1.11vw !important;
        }
    }
}
@media (min-width: 4000px) {
    .guestcheckoutnew {
        .checkout-width {
            max-width: 62%;
        }
    }
}
