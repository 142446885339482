@import '../abstracts/variables';

.products {
    margin: 0.5vw 0;
    border-top: 3px solid #F5F4F3;

    .MuiGrid-root.MuiGrid-container {
        align-items: flex-start;
    }

    .buy-para {
        max-height: 5.1vw;
        overflow: auto;
    }

    .productSearchResult {
        padding-top: 1.927vw;

    }

    .title-data {
        padding-left: 1.8vw;
    }

    .product-btn,
    .retailers-btn {
        margin-left: 1.25vw !important;
    }

    .headbtn-section {
        display: flex;
        justify-content: space-between;
        margin-top: 2.6vw;
        align-items: baseline;
        padding-left: 1.8vw;
    }

    .productcard .product-name1 {
        opacity: 0.9;
    }

    .main-heading40.trending-heading {
        color: #999999;
    }

    .display-title {
        color: $color707070;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        font-family: "Asap";
        margin-right: 0.8vw;
    }

    .productcard {
        width: 33.33%;
        display: inline-block;
        margin-bottom: 20px;
        vertical-align: top;
        min-height: 30vw;
        padding-bottom: 0px;
    }

    .productcard:hover .button-section {
        margin-top: 3.5vw;
    }

    .displayresult .display-title {
        color: #999999;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        margin-top: 1.8229vw;
        font-family: "Asap";
        margin-left: 1.582vw;
        font-weight: 500;
    }

    .MuiTypography-body1 {
        font-size: 0.8854vw;
        font-family: "Helvetica Neue";
    }

    .make-my-account-section {
        background-color: #363240;
        text-align: center;
        margin: auto;
        margin-top: 1vw;
    }

    .accountimg {
        text-align: left;

        img {
            width: 90%;
        }
    }

    .accountcontent {
        position: relative;
        top: 2.2vw;

        .trending-heading {
            color: #e9e9e9;
            margin-bottom: 1.8229vw;
        }

        .account-info {
            font-size: 0.833vw;
            line-height: 1.09375vw;
            font-family: "Asap";
            padding-bottom: 2.44791vw;
            max-width: 36.1770833vw;
            margin: auto;
            color: #c5c5c5;
        }

        .register-btn-section .buy-btn.lightbtn {
            color: #ededed !important;
            min-width: 11.82291vw !important;
            min-height: 2.658vw;
        }

    }
}

.products.productborder {
    border-top: 0px !important;
}

@media (min-width:280px) and (max-width:600px) {
    .products {
        .skeleton {
            width: 50% !important;

            .imgcard {
                height: 180px !important;
                border-radius: 40px;
            }
        }
    }
}

@media (min-width:280px) and (max-width:319px) {
    .products {
        .productcard {
            width: 100% !important;
            display: inline-block;
        }

    }
}

@media (max-width:280px) {
    .products {
        .productcard {
            width: 100% !important;
            display: inline-block;
        }

        button.product-btn,
        button.retailers-btn {
            font-size: 18px !important;
            line-height: 22px !important;
        }
    }
}

@media (max-width:920px) {
    .products {
        .buy-para {
            max-height: 35vw;
            overflow: auto;
        }

        .make-my-account-section {
            padding-bottom: 40px;
            margin-top: 30px;

            .trending-heading {
                display: block !important;
                font-size: 28px;
                line-height: 33px;
                text-align: center;
            }
        }

        .accountcontent {
            top: 0;

            .account-info {
                font-size: 18px;
                line-height: 24px;
                width: 100%;
                max-width: 100%;
                margin: 20px 0px;
                padding: 0 20px;
            }
        }

        .accountimg img {
            width: 78%;
        }

        .productcard {
            width: 50%;
            display: inline-block;
        }

        .headbtn-section {
            display: block;
            margin: 15px 0;
            text-align: center;
        }

        .productcard .img-row1 {
            height: 45vw;
        }

        .search-heading {
            font-size: 14px;
            line-height: 16px;
        }

        .main-heading40 {
            margin: 14px 0 11px !important;
            text-align: left;
        }

        .headbtn-section {
            .main-heading40 {
                text-align: left;
                color: #0a0a0a;
                margin-top: 30px !important;
            }

        }

        .product-btn,
        .retailers-btn {
            font-size: 27px !important;
            line-height: 30px !important;
            background: transparent !important;
            border-radius: 0 !important;
            margin: auto 14px !important;
        }

        .product-btn {
            border-bottom: 3px solid #ccc;
        }

        .retailers-btn {
            color: #949D8C !important;
            border-bottom: 3px solid #949D8C;
        }

        .displayresult {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mobilefilter {
            color: #707070;
            font-size: 12px;
            visibility: visible;
            margin-left: 5px;
        }

        .displayresult .display-title {
            color: #707070;
            font-size: 14px;
            line-height: 16px;
            font-family: "Asap";
            margin-right: 10px;
        }

        .mobilefilter {
            display: flex;
            align-items: center;
        }

        .productcard .catbtn {
            display: none !important;
        }
    }
}

@media (min-width:280px) and (max-width:324px) {
    .products {

        .product-btn,
        .retailers-btn {
            font-size: 23px !important;
            line-height: 26px !important;
            background: transparent !important;
            border-radius: 0 !important;
            margin: auto 10px !important;
        }
    }
}

@media (min-width:601px) and (max-width:920px) {
    .products {
        .buy-para {
            max-height: 7vw;
            overflow: auto;
        }

        .productcard {
            width: 33.33%;
            display: inline-block;
            margin-bottom: 20px;
        }

        .main-heading40 {
            margin: 0vw 0 !important;
        }

        .displayresult {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mobilefilter {
            color: #707070;
            font-size: 12px;
            visibility: visible;
            margin-left: 5px;
        }

        .displayresult .display-title {
            visibility: visible;
            color: #707070;
            font-size: 14px;
            line-height: 16px;
            font-family: "Asap";
            margin-right: 10px;
        }

        .mobilefilter {
            display: flex;
            align-items: center;
        }

        .productcard .catbtn {
            display: none !important;
        }
    }
}

@media (min-width:901px) and (max-width:1280px) {
    .products {
        .MuiAccordionSummary-root {
            padding: 0px;
        }

        .MuiAccordionDetails-root {
            padding: 8px 0px 16px;
        }

        .MuiTypography-body1 {
            font-size: 1.04vw;
        }

        ul.catfilter li p.catname {
            font-size: 0.8vw;
        }
    }
}

@media (min-width:1930px) {
    .products {

        .productcard {
            min-height: 24vw;
        }

        .headbtn-section {
            margin-top: 3vw;
        }

        .MuiTypography-body1 span.MuiTypography-body1 {
            margin-left: 1vw;
        }

        .productcard .img-row1 {
            height: 15vw !important;
        }
      
        .filters .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
            width: 1.5vw;
            height: 1.5vw;
            border: 0.5vw solid gray;
        }
    }
}

@media (min-width:2500px) {
    .products {
        padding: 0vw 0;
        border-top: 0px;
        margin-top: 0vw;

        .all-screen-container {
            padding: 0.5vw 0;
            border-top: 4px solid #F5F4F3;

        }

    }

    .trendingcollection .all-screen-container {
        border-top: 0px !important;
    }
}