@import '../abstracts/variables';

// Simple quantity css 
.simplequantity {
    .quantity-btn {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: $btn-border-radius;
        text-align: center;
        opacity: 1;
        padding: 0.754vw 2vw 0.754vw 2vw;
        margin-left: 0.9vw;
    }
}

//Dropdown quantity css 
ul li.widthsetup {
    width: 100%;
    padding: 4px;
}

.multiquantity {

    .MuiSvgIcon-root {
        display: none;
    }

    .MuiOutlinedInput-notchedOutline {
        display: none;
    }

    .quantity {
        vertical-align: middle;
        align-items: center;
        display: flex
    }

    .quantity-btn-multi {

        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 2.5vw;
        font-size: $fontsize-heading21;
        line-height: $lineheight-heading24;
        font-family: $asap-font-regular;
        $letter-spacing: 0px;
        $btn-font-style: normal;
        $btn-font-variant: normal;
        opacity: 0.9;
        color: $color707070;
        opacity: 1;
        width: 4.5vw;
        height: 2.7604vw;
        margin-left: 0.9vw;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../../../src/assets/images/dropdown.webp) 84%/15% no-repeat;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
        display: none;
    }
}



@media (min-width:280px) and (max-width:319px) {
    .multiquantity {}
}

@media (max-width:600px) {
    .multiquantity {
        .quantity {
            font-size: 16px !important;
            line-height: 18px !important;
        }

        .quantity-btn-multi {
            width: 70px;
            height: 40px;
            border-radius: 40px;
            font-size: 18px;
            line-height: 24px;
        }

        .css-1869usk-MuiFormControl-root {
            margin: 0 8px;
        }
    }
}

@media (min-width:600px) and (max-width:920px) {
    .multiquantity {}
}

@media (min-width:1930px) {
    .multiquantity {}
}

@import '../abstracts/variables';

// Simple quantity css 
.simplequantity {
    .quantity-btn {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: $btn-border-radius;
        text-align: center;
        opacity: 1;
        padding: 0.754vw 2vw 0.754vw 2vw;
        margin-left: 0.9vw;
    }
}


//Dropdown quantity css 
ul li.widthsetup {
    width: 100%;
    padding: 4px;
}

.multiquantity {
    .MuiOutlinedInput-notchedOutline {
        display: none;
    }

    .quantity {
        vertical-align: middle;
        align-items: center;
        display: flex;

        .MuiFormControl-root {
            min-width: auto;
        }

    }


    .quantity-btn-multi {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 2.5vw;
        font-size: $fontsize-heading21;
        line-height: $lineheight-heading24;
        font-family: $asap-font-regular;
        $letter-spacing: 0px;
        $btn-font-style: normal;
        $btn-font-variant: normal;
        opacity: 0.9;
        color: $color707070;
        opacity: 1;
        width: 4.5vw;
        height: 2.7604vw;
        margin-left: 0.9vw;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../../../src/assets/images/dropdown.webp) 84%/15% no-repeat;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.MuiSelect-iconOpen.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
        display: none;
    }
}

@media(max-width:280px) {
    .multiquantity {
         div.quantity {
            font-size: 14px !important;
            line-height: 16px !important;
        }
        .quantity-btn-multi {
            width: 60px !important;
            height: 36px !important;
        }
    }
}

@media(max-width:600px) {
    .multiquantity {
        .quantity {
            font-size: 16px !important;
            line-height: 18px !important;
        }

        .quantity-btn-multi {
            width: 70px;
            height: 40px;
            border-radius: 40px;
            font-size: 18px;
            line-height: 24px;
        }

        .css-1869usk-MuiFormControl-root {
            margin: 0 8px;
        }
    }

    .simplequantity {
        .quantity {
            font-size: 16px !important;
            line-height: 18px !important;
        }

        .quantity-btn {
            border-radius: 40px;
            font-size: 18px !important;
            height: 40px;
            line-height: 35px !important;
            display: block !important;
            width: 60px !important;
        }

        .quantity {
            align-items: center;
            display: flex;
        }
    }
}

@media (min-width:600px) and (max-width:920px) {
    .multiquantity {
        .quantity {
            font-size: 14px !important;
            line-height: 16px !important;
            display: block ;
        }

        .quantity-btn-multi {
            font-size: 2.0937vw;
            line-height: 3.25vw;
            width: 5.5vw;
            height: 3.7604vw;

        }
    }
}

@media (min-width:1930px) {
    .multiquantity {
        .quantity-btn-multi {
            text-align: center;
        }
    }

    ul.css-6hp17o-MuiList-root-MuiMenu-list li {
        width: 100%;
        padding: 1vw;
        font-size: 1vw;
    }

    .css-1km1ehz {
        width: 100%;
        padding: 1vw;
        font-size: 1vw;
    }
}