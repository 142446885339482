// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

$gt-font-regular : 'GT-Super-Text-Regular';
$gt-font-bold : 'GT-Super-Text-Bold';
$gt-font-medium:'GT-Super-Text-Medium';
$textcenter : center;
$textright : right;
$textleft:left;
$sectionpadding3p5vw: 3.5vw 0 ;

$headingmarbtm:2.4192vw;

$paddingleft14vw:14.5vw !important;

$graycolor949d8ce3 : #949d8ce3;
$blackcolor2F2B23 : #2F2B23;
$background-F5F5F5: #F5F5F5 ;
$fontsize-heading40 : 2.083vw;
$lineheight-heading46 : 2.3958vw;

$pera-fontsize18 : 1.04vw;
$pera-lineheight21 : 1.25vw;
$fontsize-heading20:1.04vw;
$lineheight-heading23:1.197vw;
$fontsize-heading21:1.0937vw;
$lineheight-heading24: 1.25vw;
$fontsize-heading34:1.77vw;
$lineheight-heading39: 2.031vw;
$asap-font-regular : 'ASAP';
$font-weight-bold:bold;
$color707070: #707070;
$btn-background-black :#363240 ;
$btn-border-radius:2.5vw;
$btn-color-white: #d7d6d7;
$gray-btn-color: #FFFFFF;
$letter-spacing: 0px;
$opcity:1;
$cursor-pointer:pointer;
$btn-font-style: normal;
$btn-font-variant: normal;
$btn-border:  none; 
$btn-background-gray :#949D8C ;
/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: rgb(229, 0, 80) !default;

/// Light grey
/// @type Color
$light-grey: rgb(237, 237, 237) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(153, 153, 153) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;




/// Container's maximum width
/// @type Length
$max-width: 1180px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
  'ex-large': 1280px,
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'https://cdn.example.com/assets/';
$base-url: '/assets/' !default;
