@import '../../abstracts/variables';
.tabsection{
    margin: 5.122vw 0 7.86vw;
    padding: 0 2vw;
    .MuiButtonBase-root{
        margin-right: 5.15vw;
        color: #B1B1B1;
        font-size: 1.4vw;
        line-height: 1.718vw;
        font-family: $gt-font-regular;
        text-transform: capitalize !important;
    }   
    .MuiButtonBase-root.Mui-selected {
        color: #2F2B23;
        font-size: 1.4vw;
        line-height: 1.718vw;
        font-family: $gt-font-regular;
    }
    .MuiTabs-indicator{
        height: 4px;
        background-color:#363240;
    }
    .css-1gsv261{
        border-bottom: 0px;
    }
    .css-19kzrtu{
        padding: 1.99vw 0 0;
    }
    .tab-para{
        color: #848484;
        font-size: 1vw;
        line-height: 1.4vw;
        font-family: "Helvetica Neue";
        font-weight: normal;
    }
    .tab-para h2{
        color: rgba(0, 0, 0, 0.87);
        line-height: 22px;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: normal;
    }
}
@media (max-width: 280px){
.tabsection button.MuiButtonBase-root {
    font-size: 22px !important;
    line-height: 25px !important;
    text-transform: capitalize;
    margin-top: 0px !important;
}
}

@media  (max-width:600px){
    .tabsection{
        .MuiTabs-flexContainer{
            flex-direction: column;
        }
        .MuiButtonBase-root{
            font-size: 31px !important;
            line-height: 40px !important;
            text-transform: capitalize;
            margin-top: 12px;
        }
        .MuiButtonBase-root-MuiTab-root.Mui-selected:after{
            content: '';
            border-bottom: 4px solid #000;
            width: 70%;
        }
        .MuiTabs-indicator{
            display:none;
        }
        .tab-para{
            font-size: 18px;
            line-height: 24px;
            margin-top: 60px;
        }
    }
}
@media (min-width:1000px) and (max-width:1300px) {
    .tabsection{
        .tab-para{
            font-size: 14px;
            line-height: 18px;
        }
    }
}