@import '../abstracts/variables';


.blogcard {

    padding: 0 0.795vw;

    .img-row {
        height: 14.79vw;
        width: 100%;
        min-height: 150px;
        border-radius: 2.0833vw;
        object-fit: cover;
        margin-bottom: 2vw;
        cursor: pointer;
    }

    .card-heading {
        text-align: center;
        font-size: 1.56vw;
        line-height: 1.822vw;
        font-family: "GT-Super-Text-Regular";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
        margin-top: 2vw;
        margin-bottom: 1.2744vw;
        padding: 0px 2.5vw;
        cursor: pointer;
    }

    .card-para {
        color: var(--unnamed-color-2f2b23);
        text-align: center;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        font-family: "Asap";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #2F2B23;
        padding: 0px 1.822vw;
        margin: 0;
    }

    .read-heading {
        color: #949D8C !important;
        font-weight: 500 !important;
        cursor: pointer;
    }
}

.blogsection {
    background-color: #F5F4F2;
    padding: 3.583vw 0 3.608vw;
    margin-top: 3.99vw;

    .mobilebgline {
        display: none;
    }

    .head-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .slick-dots {
        display: none !important;
    }

}


@media only screen and (max-width: 919px) {
    .blogcard {
        .card-para {
            font-size: 14px !important;
            line-height: 18px !important;
        }
        .img-row {
            height: 97px;
            width: 100%;
            min-height: 96px;
            border-radius: 12.7px;
            object-fit: cover;
            margin-bottom: 2vw;
        }

        .card-heading {
            font-size: 14.13px;
            line-height: 16.28px;
            margin-bottom: 19px;
            font-family: "Asap";
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .card-para {
            font-size: 18px;
            line-height: 24px;
            padding: 0px 1.822vw;
            display: none;
        }

    }
 
    .blogsection {
        position: relative;
        margin-top: 3.99vw;
        padding: 20.5px 0 0px;

        .mobilebgline {
            position: absolute;
            object-fit: cover;
            position: absolute;
            width: 100%;
            bottom: 0;
            display: block;
            height: 90%;
        }

        .mobilesliderdesign {
            .slick-dots {
                display: block !important;
            }
        }

        .head-btn .MuiGrid-container {
            justify-content: center;
            align-items: baseline;
            margin-bottom: 10px
        }

        .slick-slider {
            margin-bottom: 72.85px !important;
        }

        .slick-dots li button:focus:before,
        .slick-dots li button:hover:before {
            opacity: .25;
            color: #000;
        }

        .slick-dots {
            bottom: -30px;
        }

        .MuiContainer-root {
            padding-left: 18px;
            padding-right: 18px;
        }

        .slick-dots li button:before {
            font-size: 10px;
        }

        .slick-dots li {
            margin: 0px;
            width: 15px;
            height: 20px;
            margin: 0;
        }

        .slick-dots li.slick-active button:before {
            color: #2F2B23;
        }

        .slick-dots li.slick-active button:before {
            // opacity: 1;
            // color: #ffff;
            color: #2F2B23;
            // opacity: 0.75;
        }

        .slick-dots li button:before {
            color: #757061;
        }
    }
}





@media (min-width:920px) and (max-width:1280px) {
    .blogcard {
        padding: 0 1.4vw;

        .img-row {
            height: 21.72vw;
            margin-bottom: 2.6vw;
        }

        .card-para {
            font-size: 1.45vw;
            line-height: 1.7vw;
            padding: 0 3vw;
        }

        .card-heading {
            font-size: 2vw;
            line-height: 2.2vw;
            padding: 0px 7.5vw;
            margin-bottom: 2vw;
        }
    }
}

@media only screen and (min-width: 1930px) {
    .blogcard {
        .img-row {
            height: 12.79vw;
        }
    }
}