@import "../abstracts/variables";
.deliveryAddress{
    .payment-text {
        text-align: center;
        font-size: 1.77vw;
        line-height: 2.031vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 1;
        margin-bottom: 0.63317vw;
    }
    .payment-para{
    text-align: center;
    font-weight: normal;
    color: $color707070;
    margin-bottom: 1.5vw;
    }
    .edit-text{
        text-align: left;
        font-size: 1.25vw;
        line-height: 1.51vw;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 1;
        margin-right: 1.354vw;
        cursor: pointer;
    }
    .card-name {
        text-align: left;
        font-weight: bold;
        color: $color707070;
        opacity: 1;
    }
    .card-address{
        font-weight: normal;
        color: $color707070;  
    }
    .cardName{
        display: flex;
    justify-content: space-around;
    margin-top: 0vw;
    }
}


@media (max-width: 600px) {
    .deliveryAddress{
        .payment-text {
        font-size: 23px;
        line-height: 33px;
        margin-bottom: 5.13px;
      }
      .edit-text {
        font-size: 16px ;
        line-height: 18px ;
      }
      .pera-text18 {
        font-size: 12px;
        line-height: 15px;  
     
      }
      
    }
  }
  