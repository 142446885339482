@import '../abstracts/variables';

.input-field {
    background: #F5F4F3 0% 0% no-repeat padding-box;
    border-radius: $btn-border-radius;
    opacity: $opcity;
    width: 25.01vw;
    height: 3.177vw;
    font-size: 0.9375vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
    font-style: $btn-font-style;
    font-variant: normal;
    letter-spacing: $letter-spacing;
    color: $color707070;
    border: $btn-border;
    padding-left: 1.157vw;
    margin-bottom: 0.648vw;
}

.input-error-message {
    color: #fc4b6c !important;
    font-size: 1.04vw;
    line-height: 1.1vw;
    margin-top: 1.04vw;
    font-family: $asap-font-regular;
}
.visibility-icon{
    position: absolute;
    left: 89%;
    top: 0.8vw;
   
}
.icon-password{
    position: relative;
}

//google field 

.social-field {
    border-radius: 2.5vw;
    width: 100%;
    height: 3.177vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    letter-spacing: 0px;
    color: #707070;
    text-align: center;
    border: 1px solid #707070;
    opacity: $opcity;
    margin-bottom: 0.588vw;
    cursor: pointer;

    button {
        -webkit-appearance: button;
        background: transparent;
        border: transparent;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
}

.google-icon {
    vertical-align: middle;
    margin-left: 0.5vw;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
}

.input-field:focus-visible {
    border: none;
    outline: none;
}
@media (max-width:280px) {
    .input-field{
        font-size: 14px !important;
        line-height: 18px !important;
    }
    .visibility-icon{
        left:88% !important;
        top: 5vw !important;
        .MuiSvgIcon-root{
            font-size: 16px !important;
        }
    }
}
@media (max-width:600px) {
    .visibility-icon {
        top: 2vw;
    }
    .input-field {
        background: #F5F4F3 0% 0% no-repeat padding-box;
        border-radius: 40px;
        opacity: 1;
        width: 100%;
        height: 41px;
        font-size: 18px;
        line-height: 21px;
        font-family: "ASAP";
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #707070;
        border: none;
        padding-left: 20px;
        margin-bottom: 6.5px !important;
    }

    .social-field {
        font-size: 17px;
        line-height: 20px;
        height: 41px;
        margin-bottom: 10.25px;
        border-radius: 48px;
    }
    .input-error-message{
        font-size: 12px;
        line-height: 15px;
    }
}


@media (min-width:601px) and (max-width:920px) {

    .input-field {
        height: 40px;
        font-size: 12px;
        line-height: 20px;
        width: 100%;
        padding-left: 24px;
    }

    .social-field {
        font-size: 17px;
        line-height: 20px;
        height: 41px;
        margin-bottom: 10.25px;
        border-radius: 48px;
    }

}
@media (min-width:921px) and (max-width:1280px) {
    .input-field {
        width: 100%;
    } 
}
@media (min-width:1930px) {
    .input-field {
        width: 100%;
        margin-bottom: 0.948vw;
    }

    .social-field {
        .MuiSvgIcon-root {
            width: 1.8vw;
            height: 1.8vw;
        }
    }
    .visibility-icon{
    .MuiSvgIcon-root{
        width: 1.8vw !important;
    height: 1.8vw !important;
    font-size: 1.8vw;
        }
    }
}