@import '../abstracts/variables';

.basket {
  padding: 3vw 0 0;
  margin-top: 0.9895vw;
  border-top: 3.5px solid #fafaf9;

  .latestArrivals .desktopbg {
    background-size: 76%;
  }
  td.table_product_img {
    width: 10%;
  }
  td.table_checkbox {
    width: 3%;
  }

  table {
    width: 100%;
  }

  .quantity {
    display: flex;
    align-items: center;
  }

  .product-name {
    font-size: 1.041vw;
    line-height: 1.3vw;
    font-family: $gt-font-medium;
    padding-bottom: 0.6744vw;
    color: $color707070;
  }

  .product-name-color {
    text-align: left;
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #949d8c;
    opacity: 1;
    margin-bottom: 0.3104vw;
    // padding-right: 3px;
  }

  .subscription-text {
    text-align: left;
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: $color707070;
    opacity: 1;
    margin-bottom: 0.8vw;
  }

  .subscription-text-ans {
    font-weight: normal;
    padding-left: 8px;
  }

  .emptybasket {
    .btn-part {
      margin-bottom: 3.5vw !important;
    }

    .lightbtn {
      padding: 0.7552vw 1.979166vw 0.7552vw 2.03125vw !important;
      text-transform: inherit !important;
      color: #fff !important;
    }
  }

  .price-text {
    font-size: 1.302vw;
    line-height: 1.51vw;
    font-family: $gt-font-regular;
    color: #949d8c;
    margin-bottom: 0.364vw;
  }

  .size-text {
    text-align: left;
    font-size: 1.04vw;
    line-height: 1.197vw;
    font-weight: bold;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #949d8c;
    opacity: 1;
  }

  .cardsection {
    border-bottom-right-radius: 2.0833vw;
    border-bottom-left-radius: 2.0833vw;
  }

  .cardsectionlogin {
    display: none;
  }

  .delivery-text {
    font-size: 0.77812vw;
    line-height: 0.855vw;
    font-family: $asap-font-regular;
    color: #949d8c;
  }

  .variant-name {
    font-size: 0.7912vw;
    line-height: 0.8854vw;
    font-family: $asap-font-regular;
    color: $color707070;
    margin-bottom: 0.3104vw;
  }

  .quantity .MuiSelect-select {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 18px !important;
  }

  .option-heading {
    font-size: 1.04vw;
    line-height: 1.197vw;
    font-weight: bold;
    font-family: $asap-font-regular;
    color: #949d8c;
  }

  .latestArrivals .trending-heading {
    text-align: left;
  }

  .item-card {
    border-radius: 2.0833vw;
    width: 19.79vw;
    text-align: center;
    margin-left: 1.04vw;

    .lightbtn {
      width: 13.437vw;
      text-transform: initial !important;
    }
  }

  .itemcardsecition {
    padding: 2.447vw 0.7812vw 2.6vw;
    background: #363240 0% 0% no-repeat padding-box;
    border-top-right-radius: 2.0833vw;
    border-top-left-radius: 2.0833vw;
  }

  .loginsection {
    padding: 0.1vw 0.7812vw 1.61vw;
    background: #f5f4f3 0% 0% no-repeat padding-box;
    border-bottom-right-radius: 2.0833vw;
    border-bottom-left-radius: 2.0833vw;
  }

  .item-heading {
    font-size: 1.145vw;
    line-height: 1.354vw;
    font-weight: bold;
    font-family: $asap-font-regular;
    color: #ffffff;
    margin-bottom: 0.26vw;
  }

  .price-heading {
    font-size: 2.708vw;
    line-height: 3.125vw;
    font-family: $gt-font-regular;
    color: #ffffff;
  }

  .delivery-heading {
    color: #949d8c;
    font-size: 0.937vw;
    line-height: 1.0937vw;
    font-family: $asap-font-regular;
    margin-bottom: 0.9015vw;
  }

  .table_product_size_color {
    width: 10%;
  }

  .table_product_name {
    width: 25%;
  }
  .table_product_name_color {
    width: 25%;
  }

  .total-text {
    font-size: 1.04vw;
    line-height: 1.197vw;
    font-family: $asap-font-regular;
    color: #ffffff;
    margin-bottom: 1.69vw;
  }

  .basket-img {
    width: 6.093vw;
    height: 6.354vw;
    text-align: center;
    object-fit: cover;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .wishlist-img {
    width: 6.093vw;
    height: 6.854vw;
    border-radius: 1.04vw;
    margin: 0.98vw 1.2vw 0.98vw 0vw;
    cursor: pointer;
    object-fit: cover;
    object-position: top;
  }

  td.table_delete_btn {
    color: #707070;
  }

  table.mat-elevation-z8 tr {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    display: block;
  }

  table.mat-elevation-z8 td {
    padding: 5px;
  }

  .lightbtn {
    margin-bottom: 1.044vw;
    color: #f5f4f3 !important;
    padding: 0.9vw 1.165vw !important;
    line-height: 1.25vw !important;
    font-size: 1.05vw !important;
  }

  .Add all to basket {
    width: 50px;
  }

  .product-btn {
    letter-spacing: 0px;
    min-width: 13.437vw !important;
    min-height: 2.6vw;
    margin-bottom: 2.656vw;
    margin-right: 0px !important;
  }

  .input-field {
    width: 100%;
    margin-bottom: 0.751vw;
    min-height: 2.76vw;
    background-color: #ffffff;
  }

  .checkoutLogin {
    margin-bottom: 1vw;
  }

  .trending-heading {
    text-align: center;
    color: #949d8c;
    margin-bottom: 2.734vw;
  }

  select.num-btn1 {
    border-radius: 2.5vw;
    width: 5.31vw;
    height: 2.7vw;
    text-align: center;
    font-size: 1.0937vw;
    font-family: $asap-font-regular;
    color: $color707070;
    padding: 0.754vw 1.5vw 0.754vw 1.5vw;
    width: 5.3125vw;
    height: 2.7604vw;
    margin-left: 4.9vw;
  }

  .product-btn {
    color: #2f2b23 !important;
  }

  .checkout-text {
    text-align: center;
    font-size: 1.145vw;
    line-height: 1.3541vw;
    font-weight: $font-weight-bold;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: #363240;
    opacity: $opcity;
    margin-top: 2.576vw;
    margin-bottom: 1.5vw;
  }

  .account-text {
    font-size: 0.8854vw;
    line-height: 1.04vw;
    font-weight: $font-weight-bold;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: #949d8c;
    opacity: $opcity;
    margin-top: 0.338vw;
    margin-bottom: 0.726vw;
    cursor: pointer;
  }
}

@media (max-width: 280px) {
  .basket {
    .variant-name {
      font-size: 12px !important;
      line-height: 15px !important;
    }
    .wishlist-img {
      height: 50px !important;
    }
    .product-name,
    .product-name-color {
      font-size: 15px !important;
    }
    .price-text,
    .size-text {
      font-size: 16px !important;
    }
    .item-card button.lightbtn,
    .item-card button.product-btn {
      min-height: 42px !important;
      font: normal normal normal 14px/18px Asap !important;
    }
    .table_delete_btn {
      top: 8vw !important;
    }
  }
}

@media (max-width: 600px) {
  .basket {
    .latestArrivals .mobilebg {
      background: none;
    }
    .innerpages{
      display: none ;
    }
    .loginsection {
      padding: 15px 10px;
      border-bottom-right-radius: 40px;
      border-bottom-left-radius: 40px;
    }

    .cardsection {
      border-bottom-right-radius: 40px;
      border-bottom-left-radius: 40px;
    }

    .subscription-text {
      font-size: 14px;
      line-height: 20px;
    }

    .itemcardsecition {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      padding: 34px 0;
      width: 100%;
    }

    .basket .loginsection {
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      padding: 24px 22.27px 37.82px 22.27px;
      width: 100%;
    }

    .checkout-text {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 1.5vw;
    }

    .account-text {
      font-size: 15px;
      line-height: 20px;
    }

    .basket-btn.darkbtn {
      background-color: #363240;
      font-size: 16px !important;
      line-height: 22px !important;
    }

    table.mat-elevation-z8,
    table.mat-elevation-z8 td,
    table.mat-elevation-z8 tr {
      width: 100%;
      display: block;
    }

    table.mat-elevation-z8 tr {
      border-top: 1px solid #e5e5e5 !important;
      height: auto;
      clear: both;
    }

    .table_checkbox {
      width: 8% !important;
      float: right;
      margin-top: 15px;
    }

    .table_product_img {
      width: 28% !important;
      float: left;
    }

    .table_product_name {
      width: 64% !important;
      float: right;
    }

    .table_product_name_color {
      width: 72% !important;
      float: right;
    }

    .table_product_size_color,
    .table_quantity,
    .table_btn {
      width: 72% !important;
      float: right;
    }

    .table_delete_btn {
      width: 10% !important;
      float: left;
      position: relative;
      top: 0vw;
      left: 8vw;
      top: 27px;
    }

    .wishlist-img {
      cursor: pointer;
      width: 90%;
      height: 80px;
      border-radius: 13px;
      margin: 15px 0px;
    }

    .product-name,
    .product-name-color {
      font-size: 16px;
      line-height: 19px;
      margin-top: 15px;
      cursor: pointer;
      word-break: break-all;
    }

    .price-text,
    .size-text {
      font-size: 18px;
      margin: 5px 0;
      font-weight: 700;
      text-align: left;
      line-height: 20px;
    }

    .delivery-text {
      font-size: 12px;
      line-height: 14px;
      display: inline-block;
      padding-left: 10px;
    }
    .price-text {
      display: inline-block;
    }
    .variant-name {
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 10px;
    }

    .option-heading {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 10px;
    }

    // .table_btn .darkbtn {
    //     font-size: 3vw !important;
    //     line-height: 4vw !important;
    //     border-radius: 30px !important;
    //     height: 8vw;
    //     width: 25vw;
    //     padding: 0px !important;
    //     margin-bottom: 10px !important;
    // }

    .btnsettable {
      display: flex;
      justify-content: space-between;
    }

    .item-card {
      padding: 34px 5px 34px 0px;
      width: 100%;
      border-radius: 20px;
      margin-left: 0.65vw;
    }

    .item-heading {
      font: normal normal bold 18px/21px Asap;
      margin-bottom: 10px;
    }

    .price-heading {
      font: normal normal normal 35px/43px GT-Super-Text-Regular;
      margin-bottom: 10px;
    }

    .delivery-heading {
      font: normal normal normal 16px/18px Asap;
      margin-bottom: 10px;
    }

    .total-text {
      font: normal normal normal 18px/21px Asap;
      margin-bottom: 10px;
    }

    .item-card .lightbtn,
    .item-card .product-btn {
      min-width: 192px !important;
      text-transform: initial !important;
      width: auto;
      padding: 2px 14px !important;
      min-height: 48px;
      margin-top: 5px;
      font: normal normal normal 16px/18px Asap !important;
      border-radius: 30px !important;
    }

    .lightbtn {
      color: #ffffff !important;
    }

    .product-btn {
      color: #2f2b23 !important;
    }
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  .basket {
    .table_btn .darkbtn {
      font-size: 12px !important;
      line-height: 20px !important;
      height: auto;
      width: 90%;
      margin: 4px 0 !important;
      padding: 5px !important;
    }

    .product-name,
    .product-name-color {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      width: 90%;
      cursor: pointer;
    }

    .price-text,
    .size-text {
      text-align: left;
      font-size: 18px;
      line-height: 20px;
    }

    .delivery-text {
      font-size: 10px;
      line-height: 12px;
      font-family: 'Asap';
      color: #949d8c;
    }

    .variant-name,
    .option-heading {
      font-size: 12px;
      line-height: 14px;
    }

    .item-card {
      width: 70%;
      margin: 0 auto !important;
      padding: 6.447vw 2.708vw 6.61vw;
    }

    .table_quantity {
      max-width: 90px;
      text-align: center;
    }

    .css-1869usk-MuiFormControl-root {
      min-width: auto;
    }

    .item-card .product-btn,
    .item-card .lightbtn {
      font-size: 16px !important;
      line-height: 24px !important;
      height: auto;
      width: 60%;
      margin: 4px 0 !important;
      padding: 5px !important;
    }

    .item-card .product-btn {
      color: #2f2b23 !important;
    }

    .item-card .lightbtn {
      color: #fff !important;
    }

    .item-heading {
      font-size: 22px;
      line-height: 40px;
    }

    .price-heading {
      font-size: 30px;
      line-height: 50px;
    }

    .delivery-heading {
      font-size: 16px;
      line-height: 26px;
    }

    .total-text {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media (min-width: 900px) and (max-width: 959px) {
  .basket {
    .darkbtn {
      opacity: 1;
      width: auto;
      height: auto;
      text-align: center;
      border-radius: 40px !important;
      margin: 4px 0 !important;
      font-size: 10px !important;
      height: auto;
      line-height: 15px !important;
      margin: 9px 11px;
      opacity: 1;
      padding: 8px !important;
      text-align: center;
      width: auto;
      margin-left: 10px !important;
    }
  }
}

@media (min-width: 900px) and (max-width: 1930px) {
  .basket {
    .table_btn .darkbtn,
    .table_btn .lightbtn {
      width: 11vw;
      margin-bottom: 0px;
    }
  }
}

@media (min-width: 1930px) {
  .basket {
    .darkbtn {
      width: auto;
    }

    .MuiSvgIcon-root {
      font-size: 1.5vw;
    }
  }
}

@media (min-width: 3000px) {
  .basket .item-card .lightbtn {
    width: 13.437vw;
    padding: 0.9vw 1.165vw !important;
    min-height: 2.6vw;
    font-size: 1.0937vw !important;
    line-height: 1.35vw !important;
  }
}

@media (min-width: 2000px) and (max-width: 2599px) {
  .basket .latestArrivals .desktopbg {
    background-size: 71%;
  }
}

@media (min-width: 2600px) and (max-width: 3999px) {
  .basket.latestArrivals .desktopbg {
    background-size: 66%;
  }
}

@media (min-width: 4000px) and (max-width: 4300px) {
  .basket .latestArrivals .desktopbg {
    background-size: 75%;
  }
}

@media (min-width: 4301px) and (max-width: 5000px) {
  .basket .latestArrivals .desktopbg {
    background-size: 70%;
  }
}

@media (min-width: 5000px) and (max-width: 5500px) {
  .basket .latestArrivals .desktopbg {
    background-size: 66%;
  }
}

@media (min-width: 5500px) and (max-width: 6000px) {
  .basket .latestArrivals .desktopbg {
    background-size: 64%;
  }
}
