body {
  margin: 0;
  font-family: "GT-Super-Text-Medium";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "ASAP";
  src: local("ASAP"),
   url('./assets/font/asap.bold.ttf') format("ttf");
  font-weight: 100;
  font-display: swap;
}
// @font-face {
//   font-family: 'Asap-SemiBold';
//   src: url('./assets/font/Asap-Bold.ttf') format("ttf");
//   font-weight: bold;
//   font-display: swap;
// }
@font-face {
  font-family: "GT-Super-Text-Regular";
  src: local("GT-Super-Text-Regular"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Regular.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT-Super-Text-Bold";
  src: local("GT-Super-Text-Bold"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Bold.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT-Super-Text-Medium";
  src: local("GT-Super-Text-Medium"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Medium.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: "GT-Super-Text-Black";
  src: local("GT-Super-Text-Black"),
   url('./assets/font/GTFont/Web/GT-Super-Text-Black.woff2') format("woff");
  font-weight: normal;
  font-display: swap;
}


  .onlylandscaptab{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
    text-align: center;
    img{
      width:320px;
    }
    .rotatedivce{
      width:180px;
    }
  }
