@import "../abstracts/variables";

.gift-voucher {
  .voucher-text {
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.03125vw;
    font-family: $gt-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 0.691vw;
  }

  .buy-btn.lightbtn {
    color: #e9e9e9 !important;
    text-transform: inherit !important;
  }

  .purchase-btn {
    display: flex;
    justify-content: center;
  }

  .carddata {
    display: flex;
  }

  .voucher-para {
    text-align: center;
    font-size: 0.833vw;
    line-height: 1.09375vw;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 1.1802vw;
  }

  .send-text {
    text-align: center;
    font-size: 1.197vw;
    line-height: 1.3541vw;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: #707070eb;
    opacity: 0.9;
    margin-bottom: 0.691vw;
  }

  .redeem-text {
    text-align: center;
    font-size: 1.197vw;
    line-height: 1.3541vw;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: 0.19;
    margin-bottom: 1.851vw;
    cursor: pointer;
  }

  .input-field {
    width: 100%;
  }

  .input-field-textarea {
    background: #f5f4f3 0% 0% no-repeat padding-box;
    border-radius: 1.875vw;
    opacity: $opcity;
    padding: 1.00833vw 1.15937vw 0.917187vw;
    text-align: left;
    font-size: 1.09375vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
    color: #707070;
    border: $btn-border;
    margin-bottom: 1.426vw;
    display: block;
    width: 100%;
    resize: none;
  }

  textarea.input-field-textarea:focus-visible {
    outline: none;
  }

  .payment-text {
    text-align: center;
    font-size: 1.09375vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
    font-weight: bold;
    letter-spacing: $letter-spacing;
    color: #949d8c;
    opacity: $opcity;
    margin-bottom: 1.091145vw;
  }

  img.editimg {
    cursor: pointer;
    width: 1.1937vw;
    height: 1.25vw;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .card-img-visa {
    width: 71px;
    height: 41px;
    border-radius: 6px;
    object-fit: contain;
  }

  .name {
    font-size: 0.7269vw;
    font-family: $asap-font-regular;
    font-weight: bold;
    letter-spacing: $letter-spacing;
    color: $color707070;
    margin-right: 25px;
  }

  .card-number {
    font-size: 0.833vw;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
  }

  .edit-icon {
    font-size: 1.09375vw;
    color: #848484;
  }

  .edit-img {
    width: 29px;
    height: 26px;
    color: #848484;
  }

  .total-price {
    display: flex;
    justify-content: space-between;
  }

  .price-text {
    text-align: right;
    font-size: 1.302vw;
    line-height: 1.51vw;
    font-family: $gt-font-regular;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-weight: bold;
    letter-spacing: 0px;
    color: #949D8C;
    opacity: 1;
  }

  .total-text {
    text-align: center;
    font-size: 1.1975vw;
    line-height: 1.3541vw;
    font-family: $asap-font-regular;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-weight: bold;
    letter-spacing: 0px;
    color: $color707070;
    opacity: 1;
  }

  .cardName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    border-bottom: 1.5px solid #e7e7e7;
  }
}
@media  (max-width: 280px) {
  .gift-voucher .carddata{
    margin-left: 0px !important;
  }
}

@media  (max-width: 600px) {
  .gift-voucher {
    ::-webkit-input-placeholder {
      /* Edge */
      color: #707070;
      font-weight: 500;
      font-family: "ASAP";
    }
    
    :-ms-input-placeholder {
      /* Internet Explorer */
      color: #707070;
      font-weight: 500;
      font-family: "ASAP";
    }
    
    ::placeholder {
      color: #707070;
      font-weight: 500;
      font-family: "ASAP";
    }
    
    .buy-btn.lightbtn {
      padding: 13.5px 30px !important;
      font-size: 16px !important;
      line-height: 18px !important;
    }

    .total-text {
      font-size: 17px;
      line-height: 21px;
      opacity: 0.9;
    }

    .carddata {
      display: block;
      margin-left: -41px;
    }

    .price-text {
      font-size: 20px;
      line-height: 23px;
    }

    img.editimg {
      width: 21px;
      height: 22px;
    }

    .voucher-text {
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 22px;
      font-weight: 600;
      color: #7e7e7e;
    }

    .voucher-para {
      text-align: center;
      font-size: 18px;
      line-height: 21px;
      font-family: "Asap";
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      margin-bottom: 1.1802vw;
    }

    .send-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 13.3px;
    }

    .redeem-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 38.27px;
    }

    .input-field {
      width: 100%;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 8px;
      padding-left: 15.8px;
      border-radius: 48px;
      height: 41px;
    }

    .input-field-textarea {
      font-size: 18px;
      line-height: 21px;
      border-radius: 17px;
      padding-left: 15.8px;
      padding-top: 11.36px;
      margin-bottom: 21.21px;
      width: 100%;
    }

    .payment-text {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 24px;
      margin-bottom: 38.27px;
      color: #949d8c;
    }

    .card-img-visa {
      width: 71px;
      height: 41px;
      border-radius: 6px;
      object-fit: contain;
    }

    .name {
      font-size: 14px;
      line-height: 16px;
      font-family: "Asap";
      font-weight: bold;
      letter-spacing: 0px;
      color: #707070;
      margin-right: 0px;
    }

    .card-number {
      font-size: 13px;
      font-family: "Asap";
      letter-spacing: 0px;
      color: #707070;
    }

    .edit-icon {
      font-size: 1.09375vw;
      color: #848484;
    }

    img.edit-img {
      width: 29px;
      height: 26px;
      color: #848484;
    }

    .total-price {
      display: flex;
      justify-content: space-between;
    }

    .purchase-btn {
      text-align: center;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 100%;
    }
  }
}

@media (min-width: 600px) and (max-width: 920px) {
  .gift-voucher {
    .voucher-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 22px;
      font-weight: 600;
      color: #7e7e7e;
    }

    .voucher-para {
      text-align: center;
      font-size: 19px;
      line-height: 21px;
      font-family: "Asap";
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      margin-bottom: 1.1802vw;
    }

    .send-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 13.3px;
    }

    .redeem-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 38.27px;
    }

    .input-field {
      width: 100%;
      font-size: 19px;
      line-height: 22px;
      margin-bottom: 8px;
      padding-left: 15.8px;
      border-radius: 48px;
      height: 46px;
    }

    .input-field-textarea {
      font-size: 19px;
      line-height: 22px;
      border-radius: 17px;
      padding-left: 15.8px;
      padding-top: 11.36px;
      margin-bottom: 21.21px;
      width: 100%;
    }

    .payment-text {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 24px;
      margin-bottom: 38.27px;
      color: #949d8c;
    }

    .card-img-visa {
      width: 71px;
      height: 41px;
      border-radius: 6px;
      object-fit: contain;
    }

    .name {
      font-size: 1.7269vw;
      font-family: "Asap";
      font-weight: bold;
      letter-spacing: 0px;
      color: #707070;
    }

    .card-number {
      font-size: 1.833vw;
      font-family: "Asap";
      letter-spacing: 0px;
      color: #707070;
    }

    .edit-icon {
      font-size: 1.09375vw;
      color: #848484;
    }

    img.edit-img {
      width: 29px;
      height: 26px;
      color: #848484;
    }

    .total-price {
      display: flex;
      justify-content: space-between;
    }

    .purchase-btn {
      text-align: center;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 100%;
    }
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .gift-voucher {

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper,
    .css-uhb5lp {
      width: 38.6354vw !important;
    }
    .total-text{
      font-size: 20px;
      line-height: 28px;
    }
    .price-text {
      font-size: 18px;
      line-height: 26px;
    }
    .voucher-text {
      font-size: 26px;
      line-height: 28px;
      margin-bottom: 22px;
      font-weight: 600;
      color: #7e7e7e;
    }

    .voucher-para {
      text-align: center;
      font-size: 20px;
      line-height: 21px;
      font-family: "Asap";
      letter-spacing: 0px;
      color: #707070;
      opacity: 1;
      margin-bottom: 1.1802vw;
    }

    .send-text {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 13.3px;
    }

    .redeem-text {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 38.27px;
    }

    .input-field {
      width: 100%;
      font-size: 19px;
      line-height: 22px;
      margin-bottom: 8px;
      padding-left: 15.8px;
      border-radius: 48px;
      height: 46px;
    }

    .input-field-textarea {
      font-size: 19px;
      line-height: 22px;
      border-radius: 17px;
      padding-left: 15.8px;
      padding-top: 11.36px;
      margin-bottom: 21.21px;
      width: 100%;
    }

    .payment-text {
      font-size: 20px;
      letter-spacing: 0.2px;
      line-height: 24px;
      margin-bottom: 38.27px;
      color: #949d8c;
    }

    .card-img-visa {
      width: 71px;
      height: 41px;
      border-radius: 6px;
      object-fit: contain;
    }

    .name {
      font-size: 13px;
      font-family: "Asap";
      font-weight: bold;
      letter-spacing: 0px;
      color: #707070;
    }

    .card-number {
      font-size: 1.833vw;
      font-family: "Asap";
      letter-spacing: 0px;
      color: #707070;
    }

    .edit-icon {
      font-size: 1.09375vw;
      color: #848484;
    }

    img.edit-img {
      width: 29px;
      height: 26px;
      color: #848484;
    }
    img.editimg {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    .total-price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .purchase-btn {
      text-align: center;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 100%;
    }
  }
}

@media (min-width: 1921px) and (max-width: 6000px) {
  .gift-voucher .card-img-visa {
    width: 6.5vw;
    height: 2.8vw;
    margin-bottom: 1vw;
  }

  .gift-voucher .cardName {
    margin-bottom: 1vw;
  }
}