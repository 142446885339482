@import '../abstracts/variables';

.mysubscriptionsseeall{
    padding: 4vw 0;
    .product-text {
        font-size: 1.1458vw;
        line-height: 1.25vw;
        font-family: $gt-font-medium;
        color: #707070;
        opacity: 1;
        margin-right: 1.5625vw;
    }

    .table_btn {
        text-align: right;
    }

    .lightbtn,
    .darkbtn {
        min-width: 8.375vw !important;
    }

    img.myorderimg {
        width: 6.40625vw;
        height: 6.71875vw;
        object-fit: cover;
        box-shadow: 3px 3px 12px #00000029;
        border-radius: 1.04vw;
        opacity: 1;
    }

    .css-46bh2p-MuiCardContent-root {
        padding: 0px !important;
    }

    .mysubscriptionstable {
        margin-top: 2vw;
        display: block;
        width: 100%;

        tbody {
            width: 100%;
            display: block;
        }

        tr {
            padding: 1.5vw 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #d6d6d6;
        }
        td.table_product_img {
            width: 10%;
        }
        td.table_product_name {
            width: 19%;
            padding-right: 1%;
        }
        td.orderid {
            width: 13%;
        }
        td.paymentdate {
            width: 13%;
        }
        .table_btn {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            width: 26%;
            .ConfirmYourAge .lightbtn{
                background-color: #363240;
                color: #fff !important;
        //         font-size: 1.5vw !important;
        //  min-width: 12vw !important;
        //    padding: 1.5vw 1vw !important;
            }
        }
.table_reviewbtn{
    width: 12%;

    .lightbtn{
        margin-left: 0 !important;
    }
}
        .myorderimg {
            margin-right: 1.5625vw;
        }
        .lightbtn {
            margin-left:1.2vw;
        }
    }

    .price {
        font-size: 1.302vw;
        line-height: 1.5104vw;
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-family: $gt-font-medium;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
    }

    .id-text {
        text-align: left;
        font-size: 1.1458vw;
        line-height: 1.302vw;
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-family: $gt-font-regular;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
        margin-bottom: 0.255vw;
    }

    .id-price {
        text-align: left;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        font-weight: bold;
        font-variant: normal;
        font-style: normal;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
    }

    
}
@media (max-width:280px) {
    .mysubscriptionsseeall{
        padding-top:10vw !important;
        .MuiContainer-root{
            padding:0px;
        }
        .mysubscriptionstable{
            .product-text{
                font-size: 14px !important;
    line-height: 17px !important;
            }
            .price{
                font-size: 13px !important;
                line-height: 18px !important;
            }
            .id-text,.id-price{
                font-size: 10px !important;
            }
        }
    }
}
@media(max-width:600px) {
    .mysubscriptionsseeall{
        .lightbtn,
        .darkbtn {
            min-width: 31vw !important;
            font-size: 4.2vw !important;
            padding: 3vw 5vw !important;
        }

        .mobilesection {
            text-align: center;
        }

        .setlingmentbtn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btncenter {
            text-align: center;
        }

        .credit-heading {
            text-align: center;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;

            .available {
                font-size: 16px;
                line-height: 18px;
                color: #707070;
                margin-left: 20px;
            }
        }
       
        .mysubscriptionstable {
            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: block;
            }

            .table_product_img {
                width: 30%;
                float: left;
            }
            
            td.table_product_name {
                width: 70%;
                float: right;
                text-align: left;
                min-height: 27vw;
            }
            td.orderid,td.paymentdate,td.paymentdate{
                width: 70%;
                float: right;
            }
            .table_btn {
                width: 100%;
                clear: both;
                text-align: center;
                display: flex;
                justify-content: space-evenly;

            }
            .table_reviewbtn{
                width: 100%;
                clear: both;
                text-align: center;
                display: flex;
                justify-content: space-evenly;
            }
            .myorderimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 2vw;
            }
            .price{
                font-size: 5vw;
                line-height: 6vw;
            }
            tr {
                padding: 5vw 2vw !important;
                clear: both;
                display: block !important;
            }
            .see-all{
                clear: both;
            }
            .id-text,.id-price{
                font-size: 13px;
                line-height: 15px;
                text-align: left;
                width: 49%;
                display: inline-block;
            }

        }
    }
}

@media (min-width:601px) and (max-width:959px) {
    .mysubscriptionsseeall{
        .lightbtn,.darkbtn{
            font-size: 1.8vw !important;
            line-height: 2vw !important;
            padding: 1.2vw 2vw !important;
            min-width: 15vw !important;
        }
        .mysubscriptionstable .table_btn{
            display: block;
        }
        .available{
            font-size: 14px;
            line-height: 16px;
        }
        .alignsection{
            align-items: center;
        }
        .trending-heading{
            margin:1vw 0 !important;
        }
        img.myorderimg {
            width: 8.2vw;
            height: 8.2vw;
        }
        .mysubscriptionstable{
            tr{
                padding: 2vw 0 !important;
            }
            .lightbtn,.darkbtn{
              
                margin: 7px 0 !important;
            }
            .table_product_img{
                width:20%;
            }
            .product-text{
                font-size: 1.7vw;
                line-height: 2.5vw;
            }
            .table_product_name{
                width:20%;
            }
            .table_quantity .quantity{
                display: flex !important;
            }
            .table_btn{
                width: 20%;
            }
            .price{
                font-size: 1.7vw;
                line-height: 2.5vw;
            }
            
                .id-text,.id-price {
                    font-size: 1.5vw ;
                    line-height: 1.6 ;
               
            }
        }
    }
}
@media (min-width:1930px) and (max-width:6000px) {
    .mysubscriptionsseeall{
        .price{
            font-size: 0.933vw;
            line-height: 1.4vw;
            margin-right: 0.5625vw;
        }
    .number-card {
        right: 62.5%;
    }
    .num {
        width: 2vw;
        padding-top: 0.4vw;
        font-size: 0.95vw;
    }
    .lightbtn,.darkbtn {
        min-width: 8vw !important;
        font-size: 0.9vw !important;
        line-height: 1vw !important;
    }
    
    img.myorderimg {
        width: 4.40625vw;
        height: 4.71875vw;
    }
    .product-text{
        font-size: 0.833vw;
        line-height: 1.4vw;
        margin-right: 0.5625vw;
    }
    .quantity {
        text-align: center;
        display: flex;
        font-size: 0.9vw;
    }
    .quantity-btn-multi{
        width: 3.5vw;
        height: 2vw;
        margin-left: 0;
        font-size: 0.9vw;
    }
    .trending-heading{
        font-size: 1.9vw;
        line-height: 2.2vw;

    }
    .mysubscriptionstable {
        tr{
            padding:1.5vw 0 !important;
        }
        .table_btn{
            width:20%;
        }
        .lightbtn{
            margin-top: 1vw;
        }
        .table_product_name{
            width: 25%;
        }
    }
}
.mysubscriptionsseeall .mysubscriptionstable td.table_product_name {
    width: 15%;
    padding-right: 1%;
}
.mysubscriptionsseeall .mysubscriptionstable td.table_product_img{
    width: 8%;
}
}

@media (min-width:601px) and (max-width:1040px) {
    .mysubscriptionsseeall{
        .mysubscriptionstable .table_btn{
            display: block;
        }
        .mysubscriptionstable{
            .lightbtn,.darkbtn{
              
                margin: 7px 0 !important;
            }
        }
         .lightbtn, .darkbtn {
            font-size: 1.8vw !important;
            line-height: 2vw !important;
            padding: 1.2vw 2vw !important;
            min-width: 15vw !important;
        }
    }
}