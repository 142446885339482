.outofstock{
    border: 1px solid #707070 !important;
    border-radius: 2.5vw !important;
    color: #707070 !important;
    padding: 0.5vw 1.2vw !important;
    padding: 6px 14px !important;
    font-size: 0.8vw !important;
    margin-bottom: 25px !important;
    margin-top: 26px !important;
}

@media (max-width:600px) {
    .outofstock{
        border-radius: 40px !important;
        padding: 6px 14px !important;
        font-size: 12px !important;
    line-height: 22px !important;
        margin: 15px 0 !important;
    }
}
@media (min-width:601px) and (max-width:1280px) {
    .outofstock{
        font-size:1.02vw !important;
        border-radius: 40px !important;
        padding: 6px 14px !important;
    }
}

@media (min-width:1930px) and (max-width:6000px) {
    .outofstock{
        border-radius: 2.5vw !important;
        padding: 0.6vw 1.2vw !important;
        font-size: 0.588vw !important;
    
    
    }
}