@import '../abstracts/variables';
.retailers{
    padding:3vw 0;
    border-top: 4px solid #F5F4F3;
    margin-top: 1vw;
    .skeleton{width:25% !important;}
    .pricesktn{display: none;}
    .trending-heading{
        color: #707070;
        font-size: 2.0vw;
    }
    .headbtn-section{
        display: flex;
        justify-content: space-between;
        margin-top: 3.802vw;
        margin-bottom: 4.466vw;
        align-items: baseline;
    }
    .shopbyvaluecard .icon-name{
        text-align: left;
        font-size: 1.35vw;
        line-height: 1.5625vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        color: $blackcolor2F2B23;
        margin-top: 1vw;
        margin-bottom: 1vw;
        font-family: $asap-font-regular;
    }
    .shopbyvaluecard{
        width: 25%;
        min-width: 25%;
        min-height: 150px;
        padding: 0.5%;
        margin:0px;
        position: relative;
        display: inline-block !important;
        vertical-align: top;
    }
    .shopbyvaluecard .shop-icon{
        height: 15.26vw;
        width: 100%;
        min-height:15.26vw;
        border-radius: 2.0833vw;
        object-fit: fill;
        cursor: pointer;
        min-width: 100%;
    }
}
@media (max-width:280px) {
    .retailers{
        .shopbyvaluecard .shop-icon {
            height: 80vw !important;
            min-height: 80vw;
        }
        button.product-btn, button.retailers-btn {
            font-size: 18px !important;
            line-height: 22px !important;
        }
    }
}
@media (max-width:600px) {
    .retailers {
        .skeleton{
            width: 50% !important;
            .imgcard {
                height:180px !important;
                border-radius: 40px;
            }
        }
    }
}
@media (max-width:920px) {
    .retailers{
        .shopbyvaluecard {
            position: relative;
            width: 33.33%;
            padding: 1%;
            display: inline-block;
            height: auto;
        }
        .icon-name {
            font-size: 1.8vw !important;
            line-height: 2vw !important;
        }
        .main-heading40 {
            margin: 0vw 0 !important;
        }
    }
}
@media(max-width:319px) {
    .retailers{
        .shopbyvaluecard {
            position: relative;
            width: 100% !important;
            padding: 0% !important;
            display: inline-block;
            height: auto;
        }

    }
}
@media(max-width:600px) {
    .retailers{
       .shopbyvaluecard .shop-icon{
            height: 38vw;
            border-radius: 20px;
            min-height: 38vw;
        }
        .headbtn-section {
            display: flex;
            justify-content: center;
            margin-top: 35.6px;
            margin-bottom: 34.6px;
        }
        .search-heading {
            font-size: 14px;
            line-height: 16px;
        }
        .product-btn, .retailers-btn{
            font-size: 27px !important;
            line-height: 30px !important;
            background: transparent !important;
            border-radius: 0 !important;
            margin: auto 14px !important;
        }
        .product-btn{
            border-bottom: 3px solid #ccc;
        }
        .retailers-btn{
            color: #949D8C !important;
            border-bottom: 3px solid #949D8C;
        }
        .trending-heading{
            display: none;
        }
        .shopbyvaluecard {
            position: relative;
            width: 50%;
            padding: 1%;
            display: inline-block;
            height: auto;
        }
         .shopbyvaluecard .icon-name{
            font-size: 16px !important;
            line-height: 18px !important;
            margin-bottom: 18px;
            margin-top: 7px;
        }
    }
}
@media(max-width:324px) {
    .retailers {

        .product-btn,
        .retailers-btn {
            font-size: 23px !important;
            line-height: 26px !important;
            background: transparent !important;
            border-radius: 0 !important;
            margin: auto 10px !important;
        }
    }
}


@media (min-width:1930px) {
    .retailers{
       
        .shop-icon{
            height: auto !important;
        }
    }
}


@media (min-width:2500px) {
.retailers{
    padding:0vw 0;
    border-top: 0px ;
    margin-top: 0vw;

.all-screen-container{
     padding:3vw 0;
        border-top: 4px solid #F5F4F3;
        margin-top: 1vw;
}
}
}
