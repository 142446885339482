@import '../abstracts/variables';

.checking-out-guest {
    .voucher-text {
        text-align: center;
        font-size: 1.7708vw;
        line-height: 2.03125vw;
        font-family: $gt-font-regular;
        letter-spacing: 0px;
        color: $color707070;
        margin-bottom: 1.21833vw;
    }

    .voucher-para {
        font-family: $asap-font-regular;
        letter-spacing: 0px;
        color: $color707070;
        opacity: 1;
        text-align: center;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        margin-bottom: 2.056vw;
        padding: 0px 3.125vw;
    }

    .purchase-btn {
        text-align: center;
        padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
    }

    .input-field {
        background: #F5F4F3 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        width: 100%;
        height: 3.177vw;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        font-family: $asap-font-regular;

    }

    .basket-btn.darkbtn {
        min-width: 8.489vw !important;
        height: 2.656vw;
    }

}

.checkingoutguest {

    .refer-friend-btn {
        background: #363240;
        border-radius: 2.5vw;
        font-family: $asap-font-regular;
        color: rgba(255, 255, 255, 0.929412);
        min-width: 10.989vw;
        min-height: 2.7vw;
        margin-left: 0.8854vw;
        font-size: 1.09375vw;
        line-height: .937vw;
        text-transform: capitalize;
    }
}

@media  (max-width: 600px) {

    .checking-out-guest {
        .voucher-text {
            font-size: 23px;
            line-height: 26px;
            margin-bottom: 23.7px;
        }

        .voucher-para {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 18.27px;
            padding: 0;
        }

        .purchase-btn {
            text-align: center;
            padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
        }

        .input-field {
            border-radius: 48px;
            width: 100%;
            height: 41px;
            font-size: 16px;
            line-height: 18px;
            padding-left: 24px;

        }

        .basket-btn.darkbtn {
            min-width: 8.489vw !important;
            font-size: 16px !important;
            height: auto;
            padding: 11.5px 36px !important;
            line-height: 18px !important;
        }


    }
}


@media (min-width:600px) and (max-width: 920px) {

    .checking-out-guest {
        .voucher-text {
            font-size: 25px;
            line-height: 28px;
        }

        .voucher-para {
            font-size: 20px;
            line-height: 24px;

        }

        .purchase-btn {
            text-align: center;
            padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
        }

        .input-field {
            border-radius: 48px;
            width: 100%;
            height: 51px;
            font-size: 19px;
            /* line-height: 20px; */
            padding-left: 24px;

        }

        .MuiPaper-rounded {
            border-radius: 40px;
            width: 100%;
        }

    }
}


@media (min-width:920px) and (max-width: 1280px) {

    .checking-out-guest {
        .voucher-text {
            font-size: 26px;
            line-height: 29px;
        }
        .basket-btn.darkbtn {
            height: auto !important;
        }
        .voucher-para {
            font-size: 21px;
            line-height: 26px;

        }

        .purchase-btn {
            text-align: center;
            padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
        }

        .input-field {
            border-radius: 48px;
            width: 100%;
            height: 52px;
            font-size: 18px;
            line-height: 60px;
            padding-left: 24px;

        }

        .MuiPaper-rounded {
            border-radius: 40px;
            width: 100%;
        }

    }
}