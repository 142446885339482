@import "../abstracts/variables";
.confirmregistration{
    .register-heading
    {
    font-size: 1.77vw;
    line-height: 2.031vw;
    font-weight: normal;
    font-style:$btn-font-style ;
    font-variant: $btn-font-variant;
    font-family: $gt-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: 1;
    margin-bottom: 1.915vw;
    }
    .btn-section{
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 919px) {
.confirmregistration .register-heading {
    font-size: 23px;
    line-height: 26px;
    margin-bottom: 28.77px;
}
.confirmregistration .basket-btn, .confirmregistration.buy-btn {
color: #f5f4f3 !important;
    font-size: 16px !important;
    line-height: 19px !important;
}
}
