// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

@import "../../scss/abstracts/variables";

.footer {
  .footer1 {
    border-top: 1px solid #e4e1d9;
    opacity: 1;
    padding: 4.154vw 0px 2.387vw 7.29vw;
  }
  .rightalign {
    display: flex;
    justify-content: flex-end;
  }
  .footer2 {
    border-top: 1px solid #e7e7e7;
    padding: 1.25vw 7.29vw;
  }
  .footer-heading {
    text-align: left;
    font-size: 1.354vw;
    line-height: 1.562vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $gt-font-regular;
    letter-spacing: 0px;
    color: #949d8c;
    opacity: 0.9;
    margin-bottom: 1.25vw;
  }

  ul {
    list-style-type: none;
    padding: 0px !important;
    margin: 0px !important;
  }

  li {
    text-align: left;
    cursor: pointer;
    font-size: 1.04vw;
    line-height: 1.927vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 0.9;
  }

  .copyright-text {
    cursor: pointer;
    text-align: left;
    font-size: 1.145vw;
    line-height: 1.354vw;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #949d8c;
    font-weight: bold;
  }

  .copyright-para {
    color: #2f2b23;
    font-weight: normal;
  }

  .facebook-icon {
    height: 1.40625vw;
    padding-right: 0.85vw;
    cursor: pointer;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .feedback-section {
    border: none;
    background: transparent;
    margin-left: 1.01vw;
  }

  .feedback-text {
    writing-mode: vertical-rl;
    padding: 0.963vw 0.8072vw;
    line-height: 1.562vw;
    font-size: 1.354vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $gt-font-regular;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background: #949d8c 0% 0% no-repeat padding-box;
    border-radius: 0px 20px 20px 0px;
    opacity: 1;
    -webkit-transform: scale(-1, -1);
    cursor: pointer;
  }
  .basket-btn.darkbtn {
    background-color: #363240;
    min-height: 2.656vw;
    min-width: 6.6145vw !important;
    color: #f1f0f0 !important;
  }

  .feedback-cart-menu {
    position: absolute;
    background: #949d8c !important;
    border-color: #707070;
    right: 0px;
    top: 0rem;
    display: none;
    width: 29.061vw;
    padding-bottom: 1.09vw;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 1.197vw;
    opacity: 1;
  }

  .myfooter:hover .feedback-cart-menu {
    display: block;
  }

  .myfooter .feedback-text {
    cursor: pointer;
  }

  .myfooter {
    position: relative;
    display: inline-block;
  }

  .feed-back {
    text-align: end;
  }

  .input-field {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 1.4062vw;
    font-size: 0.833vw;
    line-height: 0.9375vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    padding-left: 0.833vw;
    letter-spacing: 0px;
    color: #bebebe;
    border: none;
    opacity: 1;
    margin-top: 1.35vw;
    width: 12.88vw;
    height: 2.3958vw;
    margin-bottom: 0.833vw;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
  }

  .select-field-textarea {
    font-size: 0.833vw;
    line-height: 0.9375vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    padding-left: 0.833vw;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #bebebe;
    border: none;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 1.4062vw;
    opacity: 1;
    width: 27vw;
    margin: auto;
    resize: none;
    display: block;
    margin-bottom: 1.458vw;
    padding-top: 10px;
  }
  textarea.select-field-textarea:focus-visible {
    outline: none;
  }
  .emoji-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.409vw;
    margin: 0px 2.2vw;
    align-items: center;
  }

  .doing-text {
    text-align: left;
    font-size: 0.833vw;
    list-style: 0.9375vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .send-btn {
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-weight: normal;
    height: 2.656vw;
    width: 6.6145vw;
  }

  .face-class {
    color: #ffffff;
    width: 1.6vw;
    height: 1.6vw;
  }
  .emojibutton {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
  }
  .face-class2 {
    color: #ffffff;
    // opacity: 0.5;
    width: 1.6vw;
    height: 1.6vw;
    margin-left: 0.5vw;
  }

  .feedback-error-message {
    font-size: 0.83vw;
    color: #fff;
    margin-bottom: 0.5vw;
  }

  .feedback-input-section {
    display: flex;
    justify-content: center;
  }

  .doing-section {
    display: flex;
    justify-content: space-between;
    padding: 0.5vw 1.8vw;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    padding: 4.15vw 4.15vw 0;
    border-top: 1px solid #e7e7e7;

    .MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiCollapse-entered {
      border-top: 1px solid #e7e7e7;
    }
    .footer-heading {
      font-size: 23px;
      line-height: 28px;
      margin-bottom: 3.25vw;
      font-weight: 600;
      opacity: 0.9;
    }

    li {
      font-size: 18px;
      line-height: 30px;
      padding-left: 20px;
      margin-bottom: 5px;
    }

    .MuiSvgIcon-root {
      width: 9vw;
      height: 9vw;
      font-size: 9vw;
    }

    .follow-section {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #e7e7e7;
      padding-top: 10px;
      padding: 10px 0px 0 15px;
    }

    .feedback-text {
      font-size: 12px;
      line-height: 15px;
      padding: 10px;
    }

    .feedback-cart-menu {
      border-radius: 12px;
      width: 61.061vw;
      right: -4px;
    }

    .input-field {
      width: 22.656vw;
      height: 5.3958vw;
      font-size: 10px;
      line-height: 13px;
      border-radius: 20px;
      margin-top: 15px;
      padding-left: 7px;
      margin-right: 5px;
    }

    .select-field-textarea {
      width: 49.041vw;
      font-size: 10px;
      line-height: 13px;
      border-radius: 8px;
      margin-top: 10px;
      padding-left: 7px;
    }

    .select-field-textarea:focus-visible {
      border: none;
      outline: none;
    }

    .feedback-error-message {
      font-size: 10px;
      margin-bottom: 10px;
    }

    .basket-btn,
    .buy-btn {
      opacity: 1;
      width: auto;
      height: auto;
      text-align: center;
      font-size: 13px !important;
      line-height: 15px !important;
      border-radius: 40px !important;
      margin: 15px 0 !important;
      padding: 6px 13px !important;
    }

    .send-btn {
      height: 5.656vw;
      border-radius: 20px;
      width: 12.6145vw;
      font-size: 10px;
      line-height: 15px;
    }

    .face-class {
      width: 12px;
      height: 12px;
    }

    .face-class2 {
      width: 12px;
      height: 12px;
    }

    .doing-text {
      font-size: 10px;
      line-height: 12px;
    }

    img.facebook-icon {
      width: 28px;
      height: auto !important;
      margin-right: 9px;
    }
    .social_icon {
      margin-bottom: 20px;
    }
    .MuiAccordionSummary-root {
      padding: 0px 0 0 16px;
    }
    .feedback-section {
      border: none;
      background: transparent;
      margin-left: 1.01vw;
    }

    .feedback-input-section {
      margin-bottom: 0.9375vw;
    }

    .doing-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.409vw;
      margin: 0px 2.2vw;
      align-items: center;
    }

    .last-footer {
      border-top: 1px solid #e7e7e7;
      padding: 20px 0vw;
    }

    .copyright-text {
      font-size: 18px;
      line-height: 21px;
    }
  }
}

@media (min-width: 601px) and (max-width: 959px) {
  .footer {
    .MuiSvgIcon-root {
      width: 5vw;
      height: 5vw;
      font-size: 10vw;
    }
  }
}
@media only screen and (min-width: 1921px) {
  .footer .feedback-text {
    border-radius: 0px 40px 40px 0px;
  }
}
