.savecardtableskeleton{
    .savecardproimg{
        width:30%;
    }
    .tbimg{
        height: 8.6041vw;
        width: 15.177vw;
    }
    .savecardproname{
        width:68%;
    }
    .name{
        width:20%;
    }
    .pricessvcard{
        display: flex;
    }
    .price{
        width:8%;
        margin-right: 1vw;
    }
    .savecarddelbtn{
        width: 2%;
        text-align: right;
    }
    .delbox {
        width: 100%;
        height: 2.5vw;
        border-radius: 5px;
    }
}