@import "../abstracts//variables";

.payment-method {
    .voucher-text {
        text-align: center;
        font-size: 1.7708vw;
        line-height: 2.03125vw;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        margin-bottom: 0.691vw;
    }

    .voucher-para {
        text-align: center;
        color: $color707070;
        opacity: $opcity;
        margin-bottom: 3.3468vw;
    }

    .cardName {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.666vw;
        padding-bottom: 1.04vw;
        border-bottom: 1px solid #E4E1D9;
    }

    .edit-icon {
        font-size: 1.09375vw;
        color: #848484;
    }

    .edit-img {
        width: 27px;
        height: 24px;
        color: #848484;
    }

    .name {
        font-size: 0.8333vw;
        line-height: 0.9375vw;
        font-family: $asap-font-regular;
        font-weight: bold;
        letter-spacing: $letter-spacing;
        color: #707070;
    }

    .card-img-visa {
        width: 8.3333vw;
        height: 4.79166vw;
        border-radius: 1.04vw;
        object-fit: contain;
    }

    .card-number {
        font-size: 1.302083vw;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        line-height: 1.45833vw;
    }

    .MuiPaper-rounded {
        border-radius: 40px;
        width: 428px;
    }
}

@media (max-width: 280px) {
    .payment-method {
        .voucher-text {
            font-size: 23px !important;
            line-height: 33px;
            font-family: 'GT-Super-Text-Medium' !important;
        }

        .card-img-visa {
            width: 60px !important;
        }

        .card-number {
            font-size: 14px !important;
            line-height: 16px !important;
        }

        .name {
            font-size: 14px !important;
            line-height: 18px !important;
        }
    }

}


@media (max-width: 600px) {
    .payment-method {
        .voucher-text {
            font-size: 28px;
            line-height: 33px;
        }

        .voucher-para {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 19.75px;
        }

        .edit-icon {
            font-size: 1.09375vw;
            color: #848484;
        }

        .edit-img {
            width: 27px;
            height: 24px;
            color: #848484;
        }

        .name {
            font-size: 16px;
            line-height: 18px;
        }

        .card-img-visa {
            width: 69px;
            height: 40px;
        }

        .card-number {
            font-size: 20px;
            line-height: 23px;
            margin-top: 9px;
        }

        .MuiPaper-rounded {
            border-radius: 40px;
        }

        .cardName {
            margin-bottom: 18px;
            padding-bottom: 18px;
        }
    }


}

@media (min-width: 600px) and (max-width: 920px) {

    .payment-method {
        .voucher-text {
            font-size: 28px;
            line-height: 33px;
        }

        .voucher-para {
            font-size: 16px;
            line-height: 18px;
        }

        .edit-icon {
            font-size: 1.09375vw;
            color: #848484;
        }

        .edit-img {
            width: 27px;
            height: 24px;
            color: #848484;
        }

        .name {
            font-size: 16px;
            line-height: 18px;
        }

        .card-img-visa {
            width: 69px;
            height: 40px;
        }

        .card-number {
            font-size: 20px;
            line-height: 23px;
            margin-top: 9px;
        }

        .MuiPaper-rounded {
            border-radius: 40px;
        }

        .cardName {
            margin-bottom: 18px;
            padding-bottom: 18px;
        }
    }
}

@media (min-width: 920px) and (max-width: 1280px) {

    .payment-method {
        .voucher-text {
            font-size: 30px;
            line-height: 35px;
        }

        .voucher-para {
            font-size: 18px;
            line-height: 21px;
        }

        .edit-icon {
            font-size: 1.09375vw;
            color: #848484;
        }

        .edit-img {
            width: 27px;
            height: 24px;
            color: #848484;
        }

        .name {
            font-size: 16px;
            line-height: 18px;
        }

        .card-img-visa {
            width: 69px;
            height: 40px;
        }

        .card-number {
            font-size: 1.04166vw;
            line-height: 1.197916;
        }

        .MuiPaper-rounded {
            border-radius: 40px;
        }
    }

}

@media (min-width: 1921px) and (max-width: 6000px) {

    .payment-method {

            .MuiSvgIcon-root {
                width: 1.5vw !important;
                height: 1.5vw !important;
                font-size: 1.5vw;
            }

    }
}