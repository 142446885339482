@import '../abstracts/variables';

.selectbox{
    .select-field{
        height: 3.177vw;
        border-radius: 2.5vw;
        padding: 1.052vw 2.432vw 1.052vw 1.432vw;
        background-color: #F5F4F3;
        color: #707070;
        font-size: 0.9895vw;
        line-height: 1.145vw;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 92%;
        .MuiOutlinedInput-notchedOutline{
            border:none !important;
        }
    }
    .select-field:hover .MuiOutlinedInput-notchedOutline{
        border:none !important;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border: none;
    }
    
    .selectboxwidth{
        width: 100%;
    }
    svg{
        display:none;
    }
    
}
@media (max-width:600px){
.selectbox{
    .select-field {
        height: auto;
        border-radius: 40px;
        padding: 0px;     
        padding-left: 15px;
        background-color: #F5F4F3;
        color: #707070;
        font-size: 20px;
        line-height: 23px;
        background-repeat: no-repeat;
        background-size: 14px;
        background-position: 92%;
    }
}
}
@media (min-width:601px) and (max-width:920px){
    .selectbox{
        .select-field{
            height: 40px;
            font-size: 12px;
            line-height: 20px;
        }
        
    }
}
@media (min-width:3000px) and (max-width:6000px){
.selectbox .select-field {
    background-size: 0.9vw;
}
.selectbox ul.MuiList-root li.MuiMenuItem-root{
font-size: 1.5vw;
line-height: 2vw;
}
}