@import "../abstracts/variables";

.sellerStorefront {
 
    .titlesection {
        width: 61%;
    }
    .popular-product-section {
        padding: 3.049vw 0 2.968vw;
        background: #F5F4F3;
        margin-top: 5.76vw;
    }
    .image-container {
        margin-top: 1vw;
    }
    .popular-product-section .head-btn .lightbtn{
        background: transparent !important;
        color: #363240 !important;
    }
.seller-name{
    color: #949D8C;
    font-size: 2.395vw;
    font-family:$gt-font-regular;
    line-height: 2.76vw;
    font-weight: normal ;
    margin-bottom: 0.468vw;
}
.products{
    padding:  0vw;
    border: none !important;
}
#Store{
    border-top: 4px solid #F5F4F3;
    margin-top: 4vw;
}
.storetag{
    font-size: 1.354vw;
    color: #2F2B23;
    line-height: 1.562vw;
    font-family: $asap-font-regular;
}
.product-rating{
    text-align: right;
}
.rating-section{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 0.625vw;
}
.review{
    font-size: 0.8854vw;
    color: $color707070;
    line-height: 1.04vw;
    margin-right: 1.01vw;
    font-family: $asap-font-regular;
    display: inline-block;
}
.star{
    width: 1.875vw;
    height: 1.77vw;
    vertical-align: middle;
    text-align: center;
    color: #363240 ;
}
.read-reviews{
    font-family: $asap-font-regular;
    color: #363240;
    font-size: 0.885vw;
    line-height: 1.04vw;
    text-align: right;
    padding-right: 3px
}
.seller-storefront-info{
    margin-top: 3.2718vw;
}
.about-tab{
    text-align: right;
}
.skip-btn{
    text-align: left;
    font-weight: normal;
    color: #949D8C;
    opacity: 0.9;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding-right: 2.864vw;
}
.product-btn{
color: #707070 !important;
}
.about-title{
    color: #949D8C;
    font-size: 2.395vw;
    line-height: 2.76vw;
    margin-bottom: 1.2994vw;
    font-family: $gt-font-regular;
}
.about-sub-title{
    font-size: 1.8229vw;
    color: $color707070;
    font-family: $gt-font-regular;
    margin-bottom: 1.2994vw;
    line-height: 2.0833vw;
}
.aboutsub-info{
    color:  #848484;;
}
.seller-info{
    margin-top: 3.7927vw;
}
.about-img{
    width: 100%;
    height: auto;
    padding-right: 2vw;
}

.about-btn.cdk-focused{
    color: #fff;
    background-color: #2F2B23;
}
    .shop-policies-section {
        background-color: #F5F4F3;
        padding-top: 8.6177vw;
    }

    .shoppolicies-heading {
        font-size: 1.302vw;
        line-height: 1.51vw;
        color: #363240;
        opacity: 1;
        font-family: $gt-font-regular;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        margin-bottom: 5.3125vw;
    }

    .shoppolicies-para {
        color: #707070;
        margin-bottom: 5.3125vw;
    }

    .BreadcrumbList {
        display: flex;
        padding-bottom: 1.8135vw !important;
    }

    .breadcrumb-title {
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        color: #ADADAD;
        font-family: $asap-font-regular;
        padding-right: 0.26vw;
    }

    .logobox {
     
        border-radius: 40px;
        max-height: 6.82vw;
        img{
            height: 6vw;
            width: 100%;
            object-fit: contain;
        }
    }
    .titlereviewsection{
        display: flex;
        justify-content: space-between;
    }
    .about-tab{
        display: flex;
        justify-content: flex-end;
        .darkbtn {
            padding: 1.1vw 1.165vw !important;
            margin-right: 1.25vw !important;
        }
        .product-btn, .retailers-btn{
            margin-left:0px !important;
        }
    }
    .btnmobile a:last-child button{
        margin-right: 0 !important;
    }
    .catbtn{
        top: 1.3vw;
        right: 1.2vw;
        font-weight: normal !important;
    }
    .productcard .product-price1{
        color: #949D8C;
    }
}
@media screen and (max-width: 280px) {
    .sellerStorefront {  
    .rating-section {
        display: block !important;
    }
    .star-rating .MuiSvgIcon-root{
        width: 20px;
        height: 20px;
    }
    .about-title{
        font-size: 10vw;
        line-height: 11vw; 
    }
 .about-sub-title{
    font-size: 8vw;
    line-height: 10vw; 
    }
    .aboutsub-info  {
        font-size: 14px;
        line-height: 18px;
    }
}
}

@media screen and (max-width: 600px) {
    .sellerStorefront{ 
        .css-isbt42{
            margin-top: 0px;
        }
        .reviews {
            padding: 2.844vw 0vw 7.2677vw 8vw !important;
        }
        .read-reviews{
       display: none ;
        }
        .products .headbtn-section{
            display: block;
            text-align: center;
        }
        .products .product-btn{
            border-bottom: 0;
        }
        .about-tab{
            display: block;
            .darkbtn{
                background-color: #363240 !important;
                color: #f5f4f3 !important;
                width: auto;
                height: auto !important;
                height: auto !important;
                font-size: 5.3vw !important;
                line-height: 6.3vw !important;
                padding: 11px 23px !important;
                border-radius: 44px !important;
                margin: auto 7px !important;
                margin-left: 7px !important;
                min-width: 30vw !important;
            }
        }
        .products .display-title{
            font-size: 3.4vw;
            line-height: 4.5vw;
        }
        .breadcrumb-title{
            font-size: 10px;
            line-height: 15px;
        }
        .titlereviewsection{
            display: block;
            justify-content: space-between;
        }
        .rating-section {
            display: flex;
            width: 100%;
            margin-bottom: 0.625vw;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
        }
        .sellerStorefront .logobox img {
            height: 87px;
            width: 100%;
            object-fit: contain;
        }
        .logobox img {
            height: 87px;
            border-radius: 28px;
            background-color: #F5F4F3;
        }
        .seller-name{
            font-size: 18px;
            line-height: 21px;
            font-weight: bold;
        }
        .storetag{
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
        }
        .invermobile{
            flex-direction: column-reverse;
        }
        .star{
            width:18px;
            height: 18px;
        }
        .review{
            font-size: 10px;
            line-height: 11px;
            margin-left:8px;
            margin-top: -4px;
        }
        .css-mhc70k-MuiGrid-root{
            margin-top: 0px;
        }
        .read-reviews {
            font-family: "ASAP";
            color: #363240;
            font-size: 8px;
            line-height: 10px;
            text-align: left;
            padding-right: 3px;
        }
        .logobox {
            border-radius: 40px;
            max-height: fit-content;
        }
        .sellerStorefront .about-tab {
            text-align: left;
        }
        .skip-btn {
            width: 100%;
            text-align: center;
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 17px;
        }
        .btnmobile {
            display: flex;
            // overflow-y: scroll;
        }
       .about-tab .product-btn{
            background-color: #363240 !important;
            color: #f5f4f3 !important;
            width: auto;
            height: auto !important;
            height: auto !important;
            font-size: 5.3vw !important;
            line-height: 6.3vw !important;
            padding: 11px 23px !important;
            border-radius: 44px !important;
            margin: auto 4px !important;
            margin-left: 4px !important;
            min-width: 30vw !important;
        }
        .about-title{
            font-size: 34px;
            line-height: 39px;
            color: #363240;
            text-align: center;
        }
        .about-sub-title{
            font-size: 21px;
            line-height: 24px;
            color: #2F2B23;
            text-align: center;
        }
        .aboutsub-info{
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }
        .about-img{
            // height: 66.166vw;
            height: auto;
        }
        .shoppolicies-heading{
                font-size: 30px;
                line-height: 35px;
                text-align: center;
                margin-bottom: 0px;
        }
        .shoppolicies-para{
            font-size: 16px;
            line-height: 20px;
            text-align: center;
        }
    }
}

@media (min-width: 601px) and (max-width: 920px){
    .sellerStorefront{
    .about-tab{
        display: flex;
        justify-content: flex-end;
    }
    .product-btn{
            border-radius: 6.5vw !important;
            width: 11.541vw;
            height: 4.489vw !important;
            line-height: 16px;
            font-size: 14px !important;
            background-color: #f5f4f3 !important;
            margin:  0 0 0 1.04vw !important;
            border-bottom:none !important;
    }
    .about-title{
        font-size: 32px;
        line-height: 39px;
    }
    .skip-btn{
        font-size: 14px;
        line-height: 16px;
    }
    .about-sub-title{
        font-size: 20px;
        line-height: 34px;
    }
    .aboutsub-info{
        font-size: 14px;
        line-height: 20px;
    }
    .about-img{
        height: 390px;
        border-radius: none;
    }
    .shoppolicies-heading{
        color: #363240;
        font-size: 25px;
        line-height: 39px;
        margin-bottom: 0px;
    }
    .shoppolicies-para{
        color: #707070;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0px;
    }
}
}

@media 
(min-width:1800px) and (max-width:2000px) {
    .sellerStorefront{
        
        .review{
            font-size: 1.1vw;
        }
        .read-reviews{
            font-size: 1vw;
        }
        .star-rating{
            .MuiSvgIcon-root{
                font-size: 2rem;
            }
        }
    }
}
@media (min-width: 2550px) and (max-width: 6000px){
    .sellerStorefront{
        .star-rating{
            .MuiSvgIcon-root{
               width: 1.2vw;
                height: 1.2vw;
            }
        }
        .popular-product-section .head-btn .lightbtn{
            font-size: 1.1vw;
        }
        .products .all-screen-container{
            border-top: 0px;
        }
        .storetag {
            width: 31vw;
            margin-bottom: 2vw
        }
        .about-tab .darkbtn {
            font-size: 1.0937vw !important;
            min-width:8.541vw !important
        }
        .star-section {
            display: flex;
            align-items: center;
        }
    }
    .reviews .star-section .star1 {
        color: #E4E1D9 !important;
        width: 1.5vw;
        height: 1.5vw;
    }
    .reviews .product-img {
        width: 4.5vw;
        height: 4.5vw;
        border-radius: 1.5vw;
    }
}