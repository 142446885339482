@import '../abstracts/variables';
.search-history {
  position: absolute;
  z-index: 9999;
  display: block;
  width: 98%;
  background: #fff;
  border-radius: 0px 0px 18px 18px !important;
  ul#use-autocomplete-demo-listbox{
    .MuiSvgIcon-root{
    vertical-align: middle !important;
    color: #BFBFBF !important;
    font-size: 22px !important;
    }
  }
  ul#use-autocomplete-demo-listbox li {
    padding: 0.5vw 0vw;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 0.9375vw;
    line-height: 1.0935vw;
    font-weight: normal;
    font-family: Asap;
    color: #2f2b23;
  }
  ul#use-autocomplete-demo-listbox {
    padding-left: 2vw;
    max-height: 260px;
    overflow-y: scroll;
    margin-top: 0px;
  }
  ul#use-autocomplete-demo-listbox li:before {
    content: ' ';
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    display: inline-flex;
    margin-right: 11px;
  }

  ul#use-autocomplete-demo-listbox li:after {
    content: ' ';
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-flex;
    position: absolute;
    right: 14px;
    cursor: pointer;
    filter: invert(1);
  }
  ul#use-autocomplete-demo-listbox:before {
    content: 'Recent Searches';
    padding: 1.666vw 1.666vw 0px 0vw;
    margin-left: 0vw;
    display: block;
    text-align: left;
    font-size: 0.8854vw;
    line-height: 1.04vw;
    font-weight: bold;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #2f2b23;
    opacity: 1;
    margin-bottom: 1.145vw;
  }
  .css-1m8pc1o li:active {
    background-color: transparent !important;
    background-color: #ffffff !important;
    color: #2f2b23 !important ;
  }
  .css-1m8pc1o {
    border: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
@media  (max-width:280px) {
.search-history {
  margin-top: -26px !important;
}
}
@media  (max-width:600px) {
  .search-history{
    width: 92%;
    margin-top: -20px;
    ul#use-autocomplete-demo-listbox:before {
      padding: 3.5vw 2.3vw;
      font-size: 3.5vw;
      line-height: 4vw;
  }
  ul#use-autocomplete-demo-listbox {
    padding-left: 3vw;
    padding-right: 3vw;
  }
  ul#use-autocomplete-demo-listbox li{
    padding: 1.5vw 0vw;
    font-size: 3.5vw;
    line-height: 4vw;
  }
  ul#use-autocomplete-demo-listbox .mobilesetfilter{
    padding-left:8px;
  }
  
}
}

@media (min-width:1921px) and (max-width:6000px) {
ul#use-autocomplete-demo-listbox{
  .MuiSvgIcon-root{
    font-size: 1.5vw !important;
  }
}
  .search-history ul#use-autocomplete-demo-listbox {
    padding-left: 2vw;
    padding-right: 2vw;
    max-height: 10vw;
  }

}