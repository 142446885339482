.checkoutheader {
    display: flex;
    justify-content: space-between;
    min-height: 5vw;
    background: #f5f4f3d1;
    align-items: center;
    padding: 0 3.8vw;

    img.headerlogo {
        width: 9.7vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        cursor: pointer;
    }
    .userprofiledetails  {
        font-size: 1.04vw;
        line-height: 1vw;
        font-weight: 500;
        font-family: "Asap";
        margin: 0;
        color: #2F2B23;
    }
    p{
        margin: 0;
        margin-bottom: 2px; 
    }
}


@media (min-width:280px) and (max-width:600px){
    .checkoutheader{
        min-height: 66px;
        padding: 0 18px;
        img.headerlogo {
            width: 131px;
        }
        .userprofiledetails{
            display: none;
        }
    }
}