// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
@import '../abstracts/variables';


.header {
    .makeStyles-drawerPaper-6 {
        width: 100% !important;
    }
    .navbar {
        margin-right: 0.5vw;
    }
    .tabshow {
        position: relative;
    }

    .total-price {
        font-family: $gt-font-regular;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-size: 1.145vw;
        line-height: 1.302vw;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
    }
    .toolbar-list1::-webkit-scrollbar {
        display: none;
      }
    
    .top-bar {
        text-align: center;
        padding: 0.625vw;
        background-size: cover;
        width: 100%;
        background-position: 50%;
        font-family: Asap;
        font-size: .937vw;
        line-height: 1.0937vw;
        letter-spacing: 0;
        color: #363240;
        opacity: 1;
        text-align: center;
        padding: 0.625vw;
        margin-bottom: 1.56vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        min-height: 3vw;
    }

    .top-bar b {
        font-weight: 700;
    }

    .top-bar p {
        font-size: $pera-fontsize18;
        line-height: $pera-lineheight21;
        color: #363240;
        margin: 0;
    }

    .top-bar img {
        width: 1.04vw;
        height: 1.04vw;
        margin-right: 0.52vw;
    }

    .logo-section {
        align-items: center;
    }

    .mainLogo img {
        cursor: pointer;
        width: 14.796vw;
        height: 3.6979vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        // image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .search-input-field {
        vertical-align: middle;
        font-weight: 400;
        font-size: 1.093vw;
        line-height: 1.25vw;
        color: #9b9b9b;
        color: #707070;
        outline: none;
        border: none;
        width: 100%;
        min-width: 120px;
        background: #f5f5f5 0 0 no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        padding: 0.755vw 1.432vw 0.755vw 1.64vw;
        font-family: $asap-font-regular;
    }

    //login user 
    .user-icon5 {
        // border-radius: 50%;
        border: none;
        height: 2.76vw;
        background-color: transparent;
        vertical-align: middle;
        padding: 0px;

    }

    .profile-logged {
        width: 15px !important;
        margin-top: -10px;
        height: 16px !important;


    }

    .user-menu {
        position: absolute;
        background: #eee;
        right: -40px;
        top: 3.6rem;
        display: none;
        min-width: 12.572vw;
        padding: 14px 14px 20.49px;
        border-radius: 2.5vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        z-index: 11;
    }

    .user-icon {
        border-radius: 50%;
        width: 1.666vw;
        height: 1.65vw;
        background: transparent;
    }

    .user-name {
        text-align: left;
        color: #2F2B23;
        opacity: 1;
        margin-left: 10px;
        cursor: pointer;
    }

    .user-icon1 {
        width: 1.25vw;
        height: 1.145vw;
        background-color: #CECECE;
        color: #CECECE;
        border-radius: 10px;
        border: none;
    }

    .user-icon2 {
        width: 0.98vw;
        height: 1.145vw;
        background-color: #CECECE;
        color: #CECECE;
        border-radius: 10px;
        border: none;
    }

    .user-name1 {
        text-align: left;
        font-family: "Asap";
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        letter-spacing: 0px;
        color: #848484;
        opacity: 1;
        margin-left: 0.625vw;
        cursor: pointer;
    }

    .card-margin {
        margin-bottom: 0.9vw;
        align-items: center;
    }

    .user-icon1 {
        width: 1.25vw;
        height: 1.145vw;
        background-color: #CECECE;
        color: #CECECE;
        border-radius: 10px;
        border: none;
    }

    .myDIV:hover .user-menu {
        display: block;
    }

    .myDIV {
        position: relative;
        display: inline-block;
    }

    .user-menu:before {
        content: ' ';
        display: block;
        border-style: solid;
        /* border-width: 1.7em 6.7em 0.8em 1.7em; */
        border-color: #ffffff26;
        position: absolute;
        // left: 28%;
        width: 80%;
        height: 3vw;
        /* margin-left: 6.3em; */
        z-index: 199999999999999;
        top: -6.8em;
    }

    .user-menu:before {
        top: -1.8em;
    }

    .logged-btn {

        background: #363240 0% 0% no-repeat padding-box;
        width: auto;
        height: auto;
        padding: 7px 12px;
        border-radius: 2.5vw;
        font-family: "Asap";
        margin-left: 2.04vw;
        font-style: normal;
        font-weight: normal;
        font-size: 0.7vw;
        border: none;
        cursor: pointer;
        text-align: left;
        color: #ffffffed;
        min-width: 5.8vw;

    }

    .myDIV:hover+.hide {
        display: block;
        color: red;
    }

    .search-icon {
        vertical-align: middle;
        margin-left: -6%;
        margin-right: 0.8854vw;
        color: #707070;
        object-fit: cover;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        height: 1.25vw;
    }

    .basket-icon .card-img {
        width: 25px;
        height: 25px;
        cursor: pointer;
        display: inline-block;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .numbers {
        text-align: left;
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: .885vw;
        line-height: 1.04vw;
        letter-spacing: 0;
        color: #2f2b23;
        opacity: 1;
        vertical-align: middle;
        padding-left: 0.3vw;
    }

    .card-login-btn {
        display: flex;
    }

    .cart-menu {
        position: absolute;
        right: 8vw;
        top: 12vw;
        display: none;
        width: 19.791vw;
        background: #fff 0 0 no-repeat padding-box;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 1.197vw;
        opacity: 1;
        z-index: 9999;

        &::before {
            content: " ";
            display: block;
            border-color: rgba(255, 255, 255, 0.14902);
            position: absolute;
            right: 0%;
            margin-left: 0;
            z-index: 199999999999999;
            top: -4.8vw;
            width: 100%;
            height: 7vw;
        }
    }

    .backet-card {
        padding: 1.26vw 1.775vw 1.415vw;
        background: #363240 0 0 no-repeat padding-box;
        opacity: 1;
    }

    .basket-icon {
        margin-left: 10px;
        display: flex;
        align-items: center;

        &:hover .cart-menu {
            display: block;
        }
    }

    .backet-img {
        width: 3.02vw;
        height: 2.2916vw;
        vertical-align: middle;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .basket-text {
        letter-spacing: 0;
        color: #f5f4f3;
        opacity: 1;
        font-family: $gt-font-regular;
        font-weight: 400;
        font-style: normal;
        font-size: 1.25vw;
        line-height: 1.45vw;
        margin-left: 2.057vw;
    }

    .basket-total {
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-size: 1.145vw;
        line-height: 1.302vw;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
        font-family: $gt-font-regular;
    }

    .product-img {
        border-radius: 2.083vw;
        cursor: pointer;
        height: 7.26vw;
        width: 6.927vw;
        object-fit: cover;
        object-position: top;
    }

    .line {
        border: 1px solid #e4e1d9;
        ;
    }

    .name {
        text-align: left;
        font-family: $gt-font-regular;
        ;
        font-weight: 600;
        font-style: normal;
        font-variant: normal;
        font-size: 0.8vw;
        line-height: 1.04vw;
        letter-spacing: 0px;
        color: #868686;
        opacity: 1;
        margin-bottom: 0.657vw;
        // width: 130px;
        width: 100%;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    .rate {
        text-align: left;
        font-family: $gt-font-regular;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        font-size: 1.145vw;
        line-height: 1.302vw;
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
    }

    .basket-cart-part {
        padding: 1.493vw 1.4375vw 1.3411vw;
    }

    .basket-area {
        justify-content: space-between;
        display: flex;
        margin-bottom: 1.79vw;
    }

    .backet-total {
        font-family: Asap;
        font-weight: 600;
        font-style: normal;
        font-variant: normal;
        font-size: .885vw;
        line-height: 1.04vw;
        letter-spacing: 0;
        color: #707070;
        opacity: 1;
    }

    .btn-align {
        text-align: center;
    }

    .checkout-btn,
    .shopping-btn {
        text-align: center;
        font-family: Asap;
        font-size: .937vw;
        line-height: 1.0937vw;
        color: #fff;
        border-radius: 2.5vw;
        border: none;
        cursor: pointer;
        width: 10.833vw;
        height: 3.177vw;
        text-transform: capitalize;
    }

    .shopping-btn {
        background: #2f2b23 0 0 no-repeat padding-box;

        &:hover {
            background: #2f2b23 0 0 no-repeat padding-box;
        }

    }

    .checkout-btn {
        font-weight: 400;
        font-style: normal;
        font-variant: normal;
        letter-spacing: 0;
        background: #949d8c 0 0 no-repeat padding-box;
        opacity: 1;
        margin-top: 0.614vw;

        &:hover {
            background: #949d8c 0 0 no-repeat padding-box;
        }

    }

    //navigation bar css
    .toolbar-list1 .topimgwidth{
        display: flex;
        justify-content: space-between;
        margin-top: 1.699vw;
        overflow-x: scroll;
    }

    .navbar,
    .subnav {
        overflow: initial;
        width: max-content;
    }

    .subnav .nav-text1 {
        border: none;
        outline: none;
        background-color: inherit;
        text-align: left;
        font-size: 1.03vw;
        line-height: 1.17vw;
        letter-spacing: 0;
        color: #2f2b23;
        opacity: 1;
        opacity: .9;
        font-family: $gt-font-regular;
        padding-bottom: 0.26vw;
    }

    .displayset-mobile {
        display: flex;
        // overflow-x: scroll;
        // position: relative;
        // width: 100%;
    }

    .displayset-mobile::-webkit-scrollbar {
        display: block;
        width: 5px;
        height: 8px;
        background-color: #e7e7e759;
    }

    .navbar a:hover,
    .subnav:hover .nav-text1 {
        border-bottom: 4px solid #949d8c;
        opacity: .9;
    }

    .mobile-view-menu {
        display: none;
    }

    .subnav:hover .subnav-content {
        display: block;
        z-index: 20000000000000000;
        opacity: 1;
    }

    .bor-pad {
        padding-left: 2.7312vw !important;
        border-left: 3px solid rgb(245, 244, 243);
    }

    .subnav-content {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        background-color: #fff;
        width: 100%;
        z-index: 999999;
        padding: 2.89vw 7.76vw;
    }

    .top-heading1 {
        font-size: 1.197vw;
        line-height: 1.354vw;
        margin-bottom: 1.841vw;
        font-family: $gt-font-regular;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0;
        color: #949d8c;
        opacity: 1;
        cursor: pointer;
    }

    .top-heading {
        font-size: 1.56vw;
        line-height: 1.822vw;
        margin-bottom: 1.841vw;
        font-family: $gt-font-regular;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0;
        color: #949d8c;
        opacity: 1;
    }

    .option-heading {
        font-family: Asap;
        font-weight: 400;
        font-style: normal;
        font-size: .937vw;
        line-height: 1.0937vw;
        letter-spacing: 0;
        color: #707070;
        opacity: 1;
        margin-bottom: 0.729vw;
        cursor: pointer;
    }

    .image-container {
        // width: 10.05vw;
        width: 20%;
        padding: 0px 16px 0px 0px;
        margin-top: 6px;
        cursor: pointer;
        // min-height: 130px;
        min-width: 20%;
        // margin-right: 1.4vw;
        display: inline-block !important;
        position: relative;
    }

    .img-row {
        height: 7.6vw;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
    }

    .product-name,
    .product-price {
        font-family: Asap;
        font-style: normal;
        opacity: 1;
    }

    .product-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
        font-size: .989vw;
        line-height: 1.145vw;
        color: #2f2b23;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .product-price {
        font-weight: 700;
        font-variant: normal;
        font-size: 1.097vw;
        line-height: 1.25vw;
        letter-spacing: 0;
        color: #949d8c;
    }

    .preloved-img {
        height: 6.458vw;
        margin-left: -30px;
    }

    .desktop-grid {
        display: flex;
        padding-left: 1.5vw !important;
        border-left: 3px solid #f5f4f3;
        ;
    }

    .desktoprow {
        margin-right: 5vw;
    }

    .desktopleftrow {
        display: flex;
    }

    .desktopleftpad {
        margin-right: 2vw;
    }
}
@media (min-width:250px) and (max-width: 280px) {
    .header {
        .logged-btn{
            zoom:0.6;
            margin-bottom: 14px;
        }
        .mainLogo img {
            width: 100px!important;
        }
        .menu-icon img{
            width: 30px !important;
            height: 22px !important;
        }
        .search-input-field {
            border-radius: 40px !important;
            font-size: 12px !important;
        }
        .search-icon {
            height: 18px !important;
        }
        .mobileshow.tabhide{
            margin-bottom: 18px !important;
        }
        .card-login-btn {
            align-items: flex-start !important;
    }
    .basket-icon .card-img {
        width: 20px;
        height: 20px;
    }
    .backet-card {
        padding: 10px 10px 10px 20px !important;
    }
    .basket-text {
        font-size: 16px !important;
    }
    .basket-cart-part{
        .css-mhc70k-MuiGrid-root > .MuiGrid-item{
            max-height: 170vw !important;
        }
    }
    .checkout-btn, .shopping-btn {
        font-size: 10px !important;
        padding: 8px 14px !important; 
        min-width: 130px;
    }
}
}
@media only screen and (max-width: 600px) {
    .header {
        .cart-menu::before {
            top: -7.5vw;
            width: 100%;
            height: 10vw;
            border: none !important;
        }

        .product-img {
            width: 20.927vw;
            height: 20.26vw;
            // object-fit: cover;
            border-radius: 20px;
        }

        .name {
            font-size: 12px;
            line-height: 15px;
            cursor: pointer;
            white-space: break-spaces;
            overflow: hidden;
            text-overflow: ellipsis;
            // width: 120px;
            width: 100%;
            color: #707070;
            opacity: 1;
            margin-bottom: 0.657vw;
            font-weight: 500;
        }

        .rate {
            font-size: 12px;
            line-height: 15px;
            font-weight: 500;
            margin-bottom: 0.657vw;
        }

        .line {

            margin-bottom: 10px !important;
            margin: 16% 0 5%;
        }

        .logged-btn {
            background: #363240 0% 0% no-repeat padding-box;
            width: auto;
            height: auto;
            font-size: 8px;
            border-radius: 20px;
            padding: 7px 8px !important;
            min-width: 64px;
            margin-right: 5px;
        }

        .header .logo-section {
            align-items: unset;
        }

        .user-icon5 {
            height: auto;
        }

        .top-bar {
            padding: 10px 0;
            margin-bottom: 10px;
            font-size: 11px;
            line-height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 40px;
        }


        .top-bar img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }

        .top-bar b {
            align-items: center;
        }

        .menu-icon
            .MuiSvgIcon-root {
                fill: currentColor;
                color:#2F2B23 !important;
                width: 8.2vw;
                height: auto;
            cursor: pointer;
            image-rendering: -moz-crisp-edges;
            image-rendering: -o-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
        }

        .mainLogo img {
            width: 33vw;
            height: auto;
            min-width: 110px;
            min-height: 9vw;
        }

        .mainLogo {
            display: flex;
            justify-content: center;
        }

        .basket-icon {
            margin-left: 4px;
        }

        .card-login-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: end;
        }

        .numbers {
            opacity: 1;
            vertical-align: middle;
            font-size: 12px;
            line-height: 11px;
            margin-bottom: 10px;
        }

        .logo-section {
            align-items: unset;
            padding-top: 5px;
        }

        .user-icon {
            width: 35px;
            height: 35px;
        }

        .user-name {
            font-size: 18px;
            line-height: 20px;
            white-space: break-spaces;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 165px;
        }

        .user-icon1 {
            width: 20px;
            margin-top: 5px;
            height: 20px
        }

        .user-name1 {
            font-size: 14px;
            line-height: 30px;
        }

        .search-icon {
            vertical-align: middle;
            margin-top: -79px;
            margin-left: 16px;
            height: 21px;
        }

        .search-input-field {
            min-width: 177px;
            padding: 10.1px;
            padding-left: 45px;
            border-radius: 40px;
            font-size: 16px;
            line-height: 18px;
            min-height: 48px;
        }

        .toolbar-list1 {
            display: none;
        }

        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            height: auto;
            max-height: 19vw;
        }

        .css-isbt42>.MuiGrid-item {
            height: auto;
            max-height: 18vw;
        }

        .backet-card {
            padding: 10px 40px 10px 20px;
            display: flex;
           align-items: center;
        }

        .backet-img {
            width: 13.02vw;
            height: 9.02vw;
        }

        .cart-menu {
            top: 22vw;
            right: 5vw;
            z-index: 9999;
            width: 70vw;
            border-radius: 18px;
        }

        .basket-text {
            font-size: 20px;
            line-height: 26px;
            margin-left: 15px;
        }

        .basket-cart-part {
            padding: 10px 20px 20px 20px;
        }

        .backet-total {
            font-size: 15px;
            line-height: 20px;
        }


        .checkout-btn,
        .shopping-btn {
            font-size: 15px;
            line-height: 20px;
            width: auto;
            height: auto;
            border-radius: 35px;
            padding: 14px 18px;
            margin-top: 20px;
        }

        .checkout-btn {
            padding: 14px 27px;
        }

        .total-price {
            font-size: 13px;
            line-height: 18px;
        }
    }

    .mobilemenu {
        border-top: 1px solid #e0e0e0;

        .MuiPaper-elevation1 {
            box-shadow: none;
        }

        ul {
            list-style-type: none;
            padding: 0px !important;
            margin: 0px !important;
        }

        .MuiAccordionDetails-root {
            display: inherit;
        }

        .MuiTypography-body1 {
            font-size: 18px !important;
            line-height: 23px !important;
            color: #2f2b23 !important;
        }

        .MuiAccordionSummary-content {
            margin: 20px 0;
        }

        .MuiAccordionSummary-root {
            padding: 0px 20px 0 35px;
           border-top: 1px solid #CCC;
        }

        .MuiSvgIcon-root {
            width: 10vw;
            height: 8vw;
            font-size: 6.5vw;
            transform: rotate(270deg);
        }

        .MuiAccordion-root.Mui-expanded {
            margin: 0px 0;
        }

        .MuiPaper-elevation1.Mui-expanded {
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }

        .MuiTypography-body1 .Mui-expanded {
            border: none;

            svg.MuiSvgIcon-root {
                transform: rotate(180deg);
            }
        }

        li {
            padding: 10px 20px;
        }
    }
}

@media only screen and (max-width: 370px) {
    .header {
        .css-isbt42>.MuiGrid-item {
            height: auto;
            max-height: 19.5vw;
        }
    }
}

@media (min-width:700px) and (max-width:920px) {
    .header {
        .top-bar {
            min-height: 4.5vw;
        }
        .numbers{
            font-size: 12px;
            line-height: 14px;
        }
        .basket-icon{
            align-items: flex-start;
        }
        .cart-menu::before {
            top: -8vw;
            width: 100%;
            height: 9vw;
            border: none !important;
        }

        .cart-menu {
            position: absolute;
            right: 4vw;
            top: 15vw;
            width: 35vw;
        }

        .name {
            // width: 111px;
            width: 100%;
            font-size: 14px;
            line-height: 16px;
        }

        .rate {
            font-size: 12px;
            line-height: 16px;
        }

        .backet-img {
            width: 8.02vw;
            height: 5.2916vw;
        }

        .product-img {
            width: 9.927vw;
            height: 9.26vw;
        }

        .backet-total,
        .total-price {
            font-size: 14px;
            line-height: 18px;
        }

        .checkout-btn,
        .shopping-btn {
            width: auto;
            height: auto;
            font-size: 12px;
            padding: 13px 10px;
            margin: 1%;
        }

        .basket-text {
            font-size: 16px;
            line-height: 18px;
            margin-left: 37px;
        }
    }
}

@media (min-width:1000px) and (max-width:1300px) {
    .header {

        // .name{
        //     width: 100px;
        // }
        .user-icon {
            border-radius: 50%;
            width: 2vw;
            height: 2.2vw;
        }

        .cart-menu::before {
            top: -6.8vw;
            width: 100%;
            height: 8vw;
            border: none !important;
        }

        .cart-menu {
            position: absolute;
            right: 6vw;
            top: 13vw;
        }
    }
}

@media (min-width: 601px) and (max-width: 1280px) {
    .header {
        .user-icon {
            border-radius: 50%;
            width: 2vw;
            height: 2.2vw;
        }
        .subnav .nav-text1{
            color:#45423B;
            font-size: 1.24vw;
        }
    }
    .header .mainLogo img {
        width: 90%;
        height: auto;
    }
    .header .search-input-field{
        padding: 1vw 1.8vw;
        font-size: 1.2vw;
        line-height: 1.5vw;
    }
    .signin .sign-in{
        height: 2.9vw !important;
        font-size: 1vw !important;
    }
    .header .toolbar-list1 {
        overflow: scroll;
    }

    .header .navbar,
    .header .subnav {
        overflow: initial;
        margin-right: 10px;
        width: max-content;
        margin-bottom: 5px;
    }

    .header .logged-btn {
        font-size: 0.75vw;
        min-width: 6.8vw;
    }

    .header .user-icon5 {
        height: auto;
    }

}


@media only screen and (max-width: 413px) {
    .header {
        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            height: auto;
            max-height: 20vw;
        }

        // .mainLogo img {
        //     width: 100% !important;
        // }
    }
}

@media (min-width:601px) and (max-width: 959px) {
    .header {
        .subnav .nav-text1 {
            font-size: 1.2vw;
        }

        .search-input-field {
            font-size: 12px;
            line-height: 15px;
            min-width: 100%;
            width: 100%;
            padding: 1vw 1.432vw 1vw 1.64vw;
        }

        .profile-logged {
            width: 14px !important;
            margin-top: -10px;
            height: 14px !important;
            padding-left: 2px;
        }

        .logged-btn {
            font-size: 0.9vw;
            line-height: 1vw;
            width: 8vw;
            height: auto;
            padding: 7px 7px;
        }

        .user-icon5 {
            height: auto;
        }

        .user-menu {
            min-width: 18.572vw;
        }

        .user-icon1 {
            width: 16px;
            height: 16px;
        }

        .user-name,
        .user-name1 {
            font-size: 12px;
            line-height: 15px;
        }

        // .signintext {
        //     display: none !important;
        // }

        .card-login-btn {
            display: flex;
            align-items: center;
        }

        // .img-row {
        //     height: 11.6vw;
        // }

        // .image-container {
        //     min-width: 130px;
        //     min-height: 130px;
        // }

        .option-heading,
        .product-name,
        .product-price {
            font-size: 1.2vw;
            line-height: 1.4vw;
        }

        .top-heading1 {
            font-size: 1.5vw;
            line-height: 1.8vw;
        }
    }
}
@media (min-width:1000px) and (max-width:1300px) {
    .header{
        .numbers{
            font-size: 11px;
            line-height: 13px;
        }
    }
}
@media (min-width:1800px) and (max-width: 2000px) {
    .header {
        .logged-btn {
            padding: 7px 13px 7px 12px;
            font-size: 0.8vw;
            min-width: 5.7vw;
        }

        .profile-logged {
            width: 22px !important;
            margin-top: -10px;
            height: 24px !important;
        }

        .user-menu:before {

            height: 2vw;
        }

        .cart-menu::before {
            top: -5.2vw;
            width: 100%;
            height: 6vw;
            border: none !important;
        }

        .cart-menu {
            top: 11.2vw;
        }
    }

}

@media (min-width:2001) and (max-width: 3000px) {
    .header {
        .cart-menu::before {
            top: -5.8vw;
            width: 100%;
            height: 6vw;
            border: none !important;
        }
        .navbar {
            margin-right: 1vw;
        }
        .cart-menu {
            right: 15vw;
            top: 11vw;
        }
    }

}

@media (min-width:3001px) {
    .header {
        .img-row {
            border-radius: 1vw;
        }
        .navbar {
            margin-right: 1vw;
        }
        .cart-menu::before {
            top: -5.8vw !important;
            width: 100% !important;
            height: 6vw !important;
            border: none !important;
        }

        .cart-menu {
            right: 11vw !important;
            top: 10.8vw !important;
        }
    }

}

@media only screen and (min-width: 1921px) {
    .header {
        .user-icon {
            border-radius: 50%;
            width: 2vw;
            height: 2.2vw;
        }

        .img-row {
            border-radius: 1vw;
        }
   
        // .image-container {
        //     min-width: 10vw;

        // }

        .MuiSvgIcon-root {
            width: 1vw;
            height: 1.5vw;
        }

        .crossbuttonsection {}

        .user-menu {
            padding: 1.5vw 1vw;
            right: -121px;
            top: 8.6rem;
        }

        .user-icon1 {
            border-radius: 50px;
        }

        .subnav .nav-text1 {
            font-size: 0.833vw;
        }

        .name {
            width: auto;
        }

        .logo-section {
            align-items: unset;
        }

        .search-icon {
            // vertical-align: middle;
            margin-left: -3vw;
            // margin-top: -3.9vw;
        }

        .search-input-field {
            padding: 0.755vw 1.432vw 0.755vw 2.64vw;
        }

        .basket-icon .card-img {
            width: 1.5vw;
            margin-right: 0.2vw;
            height: 1.5vw;
        }

        .basket-icon {
            margin-left: 0.8vw;
        }

        .mainLogo img {
            width: 11.796vw;
            // height: 3.5979vw;
            height: auto;
        }

        .logged-btn {
            padding: 7px 1px 7px 20px;
            font-size: 0.82vw;
        }

        .profile-logged {
            width: 1.2vw !important;
            height: 1.22vw !important;
        }

        .user-menu:before {
            top: -5.8em;
            height: 3.5vw;
        }

        .cart-menu::before {
            content: " ";
            display: block;
            border-color: rgba(255, 255, 255, 0.14902);
            border-style: solid;
            border: none !important;
            position: absolute;
            right: 0%;
            margin-left: 0;
            z-index: 199999999999999;
            top: -3.2vw;
            width: 100%;
            height: 4vw;
        }

        .cart-menu {
            position: absolute;
            right: 10vw;
            top: 8vw;
        }
    }
}

@media only screen and (min-width: 4000px) {
    .header {
        .user-menu:before {
            top: -2.8em;
            height: 2.5vw;
        }

        .logged-btn {
            padding: 12px 16px 12px 50px;
            font-size: 0.7vw;
        }

    }
}

@media (min-width: 280px) and (max-width: 400px) {
    .header .cart-menu::before {
        top: -6.5vw;
    }

    .header .cart-menu {
        top: 28vw;
    }
}

@media (min-width: 280px) and (max-width: 350px) {
    .header {
        .card-login-btn {
            justify-content: end;
        }

        .padd-leftremove {
            padding-left: 0 !important;
        }

        .css-mhc70k-MuiGrid-root>.MuiGrid-item {
            height: auto;

            max-height: 23vw;

        }
    }
}