@import "../abstracts/variables";

.resendpassword {
    .css-ypiqx9-MuiDialogContent-root {
        padding: 1.88vw 2.239vw 2.565vw;
    }

    .css-1ty026z {
        padding: 1.88vw 2.239vw 2.565vw;
    }

    padding: 0.6328vw 1.0135vw 1.315vw;

    .password-heading {
        margin-bottom: 1.673vw;
        opacity: $opcity;
        color: rgba(110, 111, 109, 0.89);
        font-weight: 600;
        font-size: 1.67vw;
    }
    .input-error-message {
        margin-top: 0vw; 
        display: flex;
        justify-content: center;
        margin-bottom: 1.04vw;
    }
    .buy-btn.lightbtn {
        background-color: #949D8C;
        font-size: 1.0937vw !important;
        line-height: 1.25vw !important;
        color: #FFFFFF !important;
    }
    .buy-btn.lightbtn:hover {
        background-color: #949D8C;
        font-size: 1.0937vw !important;
        line-height: 1.25vw !important;
        color: #FFFFFF !important;
    }
    .resend-para {
        color: #707070;
        margin-bottom: 1.908vw;
        opacity: $opcity;
    }

    .send-linkbtn-part {
        display: flex;
        justify-content: center;
    }

    .input-field {
        margin-bottom: 1.194vw;
        font-size: 1.093vw;
        line-height: 1.25vw;
    }
}
@media only screen and (max-width:280px) {
.resendpassword .css-ypiqx9-MuiDialogContent-root {
    padding: 27px 12px 24px !important;
}
}

@media only screen and (max-width:920px) {
    .resendpassword {
        .input-field {
            margin-bottom: 6.5px;
            font-size: 18px;
            line-height: 20px;
        }
        .css-ypiqx9-MuiDialogContent-root {
            padding: 27px 29px 24px;
        }

        .css-1ty026z {
            padding: 27px 29px 24px;
        }

        .password-heading {
            font-size: 23px;
            line-height: 26px;
            margin-bottom: 18.27px;
        }

        .resend-para {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 18.27px;
        }

        .buy-btn.lightbtn {
            background-color: #949D8C;
            font-size: 16px !important;
            height: auto;
            width: auto;
            border-radius: 30px !important;
            padding: 11.5px 36px !important;
            line-height: 18px !important;
        }
    }
}