@import '../abstracts/variables';

.customeraccount {
    .customer-profile-section {
        padding: 2.473vw 0 2.727vw;
        background: #F5F4F3 0% 0% no-repeat padding-box;
        opacity: 1;
        margin-top: 0.91145vw;
        align-items: center;
        margin-bottom: 1.5625vw;
    }

    .quantity .MuiSelect-select {
        align-items: center;
        display: flex;
        justify-content: center;
        padding-right: 18px !important;
        text-align: center;
    }

    .basket {
        border-top: 0px;
    }

    .user-icon {
        width: 8.906vw;
        height: 8.906vw;
        border-radius: 50%;
    }

    .customer-name {
        font-family: $gt-font-regular;
        font-size: 2.3958vw;
        line-height: 2.76vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        margin-bottom: 0.52vw;
    }

    .purchase {
        font-family: $asap-font-regular;
        font-size: 1.145vw;
        line-height: 1.3541vw;
        font-weight: bold;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
    }

    .email-btn {
        background: #363240 0% 0% no-repeat padding-box;
        border-radius: $btn-border-radius;
        opacity: $opcity;
        vertical-align: middle;
        padding: 6px 12px;
        border: $btn-border;
        margin-left: 1.56vw;
        padding: 0.629vw 1.69vw;
    }

    .graycolor949d8ce3 {
        font-weight: 500;
    }

    .img {
        width: 1.77vw;
        height: 1.7vw;
        align-items: center;
        display: flex;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .number-card {
        position: relative;
        top: -1vw;
        right: 13.5%;
        background: #949D8C 0% 0% no-repeat padding-box;
        border-radius: 19px;
        opacity: 1;
        width: 1.979vw;
        height: 1.927vw;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
    }

    .num {
        color: #fff2f2;
        font-weight: 600;
        font-family: "Asap";

    }

    .back-button-section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .basket-btn,
    .buy-btn {
        color: #F5F4F3 !important;
    }

    .buy-btn {
        margin-left: 1.5625vw !important;
    }

    .user-account-profile {
        display: flex;
        align-items: center;
    }

    .credit-section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.822vw;
    }

    .credit-heading {
        text-align: left;
        font-size: 3.4375vw;
        line-height: 4.0104vw;
        font-weight: normal;
        font-family: $gt-font-regular;
        opacity: $opcity;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        letter-spacing: $letter-spacing;
        color: $color707070;
    }

    .btn-section {
        display: flex;
        justify-content: flex-end;

    }

    .available {
        font-size: 1.1458vw;
        line-height: 1.345vw;
        font-weight: bold;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        margin-left: 1.04vw;
        vertical-align: middle;
    }

    .acordionbox.MuiPaper-elevation1 {
        box-shadow: none;

    }

    .myordertable,
    .saveditemtable {
        width: 100%;
    }

    .product-text {
        font-size: 1.1458vw;
        line-height: 1.25vw;
        font-family: $gt-font-medium;
        color: #707070;
        opacity: 1;
        margin-right: 0.5vw;
        width: 6.8vw;
        word-wrap: break-word;
    }

    .table_btn {
        text-align: right;
    }

    .lightbtn,
    .darkbtn {
        min-width: 9.375vw !important;
        padding: 0.7552vw 1.1875vw !important;
        color: #d7d6d7 !important;
        text-transform: inherit !important;
    }
    .btnsettable{
        .darkbtn {
            color: #F5F4F3 !important;
        }
    }
    .customer-profile-section {

        .lightbtn,
        .darkbtn {
            color: #f5f4f3 !important;
            // font-size: 1.6vw !important;
            // padding: 1.2vw !important;
        }
    }

    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(0deg) !important;
    }

    .expandIconWrapper .MuiSvgIcon-root,
    .collapsIconWrapper .MuiSvgIcon-root {
        font-size: 2.5rem;
    }

    img.myorderimg {
        width: 6.40625vw;
        height: 6.71875vw;
        object-fit: cover;
        box-shadow: 3px 3px 12px #00000029;
        border-radius: 1.04vw;
        opacity: 1;
        object-fit: cover;
       object-position: top;
    }

    .myorderseeall .myordertable tr {
        display: block;
    }

    .css-46bh2p-MuiCardContent-root {
        padding: 0px !important;
    }

    .myorderseeall {
        padding: 0 0;
        margin-top: 0vw;
        border-top: none;
    }

    .myordertable {
        tbody {
            width: 100%;
            display: block;
        }

        tr {
            padding: 1.5vw;
            display: block;
        }

        tr:nth-child(odd) {
            background-color: #f5f4f3;
        }

        .lightbtn {
            margin-bottom: 15px;
        }

        .myorderimg {
            margin-right: 1.5625vw;
        }

        .table_btn {
            width: 25%;
        }
    }

    .MuiAccordionDetails-root {
        padding: 0px;
    }

    .saveditemtable {
        tbody {
            width: 100%;
            display: block;
        }

        tr {
            padding: 1.5vw;
            display: block;
            border-top: 1px solid rgb(214, 214, 214);
        }

        .lightbtn {
            margin-left: 1.1vw !important;
            background: #E4E1D9 !important;
            color: #2F2B23 !important;
            min-width: 10.5vw !important;
        }

        .table_quantity .css-1869usk-MuiFormControl-root {
            min-width: auto;
        }

        .myorderimg {
            margin-right: 1.5625vw;
        }
    }

    .see-all a {
        text-align: center;
        font-size: 0.9895vw;
        line-height: 1.1458vw;
        font-weight: bold;
        font-variant: normal;
        font-style: normal;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #363240;
        opacity: 1;
        margin: 1.4vw 0;

        cursor: pointer;
        width: 100%;
        display: block;
    }

    .MuiAccordionDetails-root {
        display: block;
    }

    .mysubscriptions {
        padding: 4vw 0;

        .mysubscriptionstable {
            margin-top: 2vw;
            display: block;
            width: 100%;

            tbody {
                width: 100%;
                display: block;
            }

            tr {
                padding: 1.5vw;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid rgb(214, 214, 214);
            }

            .myorderimg {
                margin-right: 1.5625vw;
            }
        }

        .price {
            font-size: 1.302vw;
            line-height: 1.5104vw;
            font-weight: normal;
            font-variant: normal;
            font-style: normal;
            font-family: $gt-font-medium;
            letter-spacing: 0px;
            color: #949D8C;
            opacity: 1;
        }

        .id-text {
            text-align: left;
            font-size: 1.1458vw;
            line-height: 1.302vw;
            font-weight: normal;
            font-variant: normal;
            font-style: normal;
            font-family: $gt-font-medium;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;
            margin-bottom: 0.255vw;
        }

        .id-price {
            text-align: left;
            font-size: 0.9375vw;
            line-height: 1.09375vw;
            font-weight: bold;
            font-variant: normal;
            font-style: normal;
            font-family: "Asap";
            letter-spacing: 0px;
            color: #949D8C;
            opacity: 1;
        }

        .graycolor949d8ce3 {
            text-align: left;
        }
    }

    .yourbasket {
        .section_full_width tr {
            display: block;
            border-top: 1px solid rgb(214, 214, 214);
        }

        .trending-heading {
            text-align: left;
        }

        .item-card {
            .lightbtn {
                margin-left: 0px !important;
            }
        }
    }

    .setlingmentbtn {
        display: flex;

    }

    .mobilemyhidebtn {
        display: revert !important;
    }

    .mobilemyshowbtn {
        display: none !important;
        justify-content: space-evenly;
    }

    //Empty card code 
    .emptybasket .empty-heading {
        border-top: 0px;
        padding-top: 0vw;
        text-align: left;
        color: $color707070;
    }

    .emptybasket .main-heading40 {
        font-size: 1.14vw;
        line-height: 1.30vw;
    }

    .emptybasket .empty-para {
        padding: 0px;
        text-align: left;
    }

    .emptybasket {
        padding: 1.5vw 20px;
    }

    .emptybasket .btn-part {
        text-align: left;
        margin-bottom: 0px;
    }

    .emptybasket .btn-part .buy-btn {
        margin-left: 0vw !important;
    }

    .mysubscriptionsseeall .all-screen-container {
        max-width: 100% !important;
    }

    .mysubscriptionsseeall .main-heading40 {
        text-align: left !important;
    }

    .mysubscriptionsseeall {
        .emptybasket {
            margin-top: 2.5vw;
            padding: 0px;
            padding-top: 2.5vw;
            border-top: 1px solid #d6d6d6;
            border-bottom: 1px solid #d6d6d6;
        }

    }
}

@media(max-width:395px) {
    .customeraccount {
        .customer-name {
            font-size: 17px !important;
            font-weight: 600;
        }
    }
}
@media (max-width:280px) {
    .customeraccount{
        .see-all a{
            margin-bottom: 8vw !important;
        }
        .credit-heading {
          
            font-size: 34px !important;
            line-height: 38px !important;
        }
    .basket .product-name, .basket .product-name-color {
    font-size: 14px !important;
    line-height: 17px !important;
    }
.basket .price-text, .basket .size-text {
    font-size: 15px !important;
    line-height: 18px !important;
}
.basket .basket-btn.darkbtn {
    background-color: #363240;
    font-size: 12px !important;
    line-height: 18px !important;
}
.mysubscriptionsseeall .main-heading40 {
   margin-bottom: 5vw !important;
}
.yourbasket .trending-heading {
    margin-bottom: 5vw !important;
}
    }
}
@media(max-width:600px) {
    .customeraccount {
      .user-icon {
            width: 55px;
            height: 55px;
      }
        .credit-section {
            flex-direction: column !important;
        }
            .main-heading40{
              margin: 0 !important;
            }
            .css-1qw96cp{
                padding: 0 !important;
            }
        .redeemfriend {
            width: 100%;
            display: block;
            min-width: 100%;
            text-align: center;
        }

        .btn-section {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        .user-icon-section {
            text-align: right;
        }

        .customer-name {
            font-size: 23px;
            line-height: 26px;
            color: #707070;
            margin-bottom: 0.52vw;
        }

        .purchase {
            font-size: 14px;
            line-height: 18px;
            color: #707070;
        }

        .customer-profile-section {
            padding-top: 30px;
        }

        .email-btn {
            padding: 2.25vw 4.5vw !important;
            border-radius: 50px;

            .img {
                width: 5.5vw;
                height: 5vw;
            }
        }

        .number-card {
            position: relative;
            top: -3vw;
            right: 52.5%;
            background: #949D8C 0% 0% no-repeat padding-box;
            border-radius: 40px !important;
            opacity: 1;
            min-width: 7vw;
            text-align: center;
            width: auto;
            display: flex;
            height: 7vw;
            align-items: center;
            text-align: center;
            vertical-align: middle;
            border-radius: 50%;
            justify-content: center;
        }

        .num {
            padding: 0.6vw 2vw;
        }

        .lightbtn,
        .darkbtn {
            min-width: 9.375vw !important;
            font-size: 4.2vw !important;
            padding: 3vw 5vw !important;
        }

        .mobilesection {
            text-align: center;
        }

        .setlingmentbtn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btncenter {
            text-align: center;
        }

        .credit-heading {
            text-align: center;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;

            .available {
                font-size: 16px;
                line-height: 18px;
                color: #707070;
                margin-left: 20px;
            }
        }

        .see-all a {
            font-size: 4vw;
            line-height: 10vw;
        }

        table.myordertable {
            .mobilemyhidebtn {
                display: none !important;
            }

            .mobilemyshowbtn {
                display: flex !important;
                justify-content: space-evenly;
            }

            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: block;
            }

            td.table_product_img {
                width: 30%;
                float: left;
            }

            td.table_product_name {
                width: 70%;
                float: right;
            }

            td.table_btn {
                width: 100%;
                clear: both;
                text-align: center;

            }

            .myorderimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 4vw;
                width: 100%;
            }

            tr {
                padding: 5vw 2vw;
            }

        }

        .saveditemtable {

            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: block;
            }

            .table_product_img {
                width: 30%;
                float: left;
            }

            .table_product_name,
            .table_quantity,
            .table_btn {
                width: 70%;
                float: right;
                text-align: left;
            }

            .myorderimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 4vw;
                width: 100%;
            }

            tr {
                padding: 5vw 2vw;
                clear: both;
            }

            .see-all {
                clear: both;
            }

        }

        .mysubscriptionstable {

            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: block;
            }

            .table_product_img {
                width: 30%;
                float: left;
            }

            .table_product_name {
                width: 70%;
                float: right;
                text-align: left;
                min-height: 27vw;
            }

            .orderid,
            .paymentdate,
            .paymentdate {
                width: 33.33%;
                float: left;
            }

            .table_btn {
                width: 100%;
                clear: both;
                text-align: center;

            }

            .myorderimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 2vw;
                width: 100%;
            }

            .price {
                font-size: 5vw;
                line-height: 6vw;
            }

            tr {
                // padding: 5vw 2vw !important;
                clear: both;
                display: block !important;
            }

            .see-all {
                clear: both;
            }

            .id-text,
            .id-price {
                font-size: 4vw;
                line-height: 5vw;
                text-align: center;
            }

        }

        .emptybasket .empty-heading {
            font-size: 18px !important;
            line-height: 21px !important;
        }

        .emptybasket .empty-para {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

@media (min-width:601px) and (max-width:959px) {
    .customeraccount {
       .saveditemtable .lightbtn {
            margin-left: 1.1vw !important;
            background: #E4E1D9 !important;
            color: #2F2B23 !important;
            min-width: 11vw !important;
            padding: 1.2vw 1.2vw !important;
        }
        .customer-profile-section {

            .lightbtn,
            .darkbtn {
                color: #f5f4f3 !important;
                font-size: 1.6vw !important;
                padding: 1.2vw !important;
            }
        }
    .num {
            font-size: 14px;
        }
        .credit-section{
            .lightbtn,
            .darkbtn {
            font-size: 1.8vw !important;
            line-height: 2vw !important;
            padding: 1.2vw 2vw !important;
            min-width: 14vw !important;
        }
    }
        .lightbtn,
        .darkbtn {
            font-size: 1vw !important;
            line-height: 2vw !important;
            padding: 1.2vw 1vw !important;
            min-width: auto !important;
        }

        .available {
            font-size: 14px;
            line-height: 16px;
        }

        .alignsection {
            align-items: center;
        }

        .trending-heading {
            margin: 1vw 0 !important;
        }

        img.myorderimg {
            width: 8.2vw;
            height: 8.2vw;
        }

        .see-all a {
            font-size: 2.2vw;
            line-height: 4vw;
        }

        .myordertable {
            .table_product_img {
                width: 20%;
            }

            .product-text {
                font-size: 2vw;
              line-height: 3vw;
                width: 100%;
            }

            .lightbtn,
            .darkbtn {

                margin: 7px 0 !important;
            }

        }

        .saveditemtable {
            .table_product_img {
                width: 20%;
            }

            .product-text {
                font-size: 1.5vw;
                line-height: 1.8vw;
                width: 100%;
            }

            .table_product_name {
                width: 38%;
            }

            .table_quantity .quantity {
                display: flex !important;
            }

        }

        .mysubscriptionstable {
            tr {
                padding: 2vw 0 !important;
            }

            .lightbtn,
            .darkbtn {

                margin: 7px 0 !important;
            }

            .table_product_img {
                width: 20%;
            }

            .product-text {
                font-size: 2vw;
                line-height: 3vw;
                width: 100%;
            }

            .table_product_name {
                width: 20%;
            }

            .table_quantity .quantity {
                display: flex !important;
            }

            .table_btn {
                width: 20%;
            }

            .price {
                font-size: 2.5vw;
                line-height: 3vw;
            }

            .id-text,
            .id-price {
                font-size: 1.5vw;
                line-height: 1.6;

            }
        }
    }
}
@media (min-width:960px) and (max-width:1280px) {
    .customeraccount {
        .number-card{
    width: 2.3vw;
    height: 2.5vw;
        }
        .see-all a{
            font-size: 14px;
            line-height: 16px;
        }
    } 
}
@media (min-width:1930px) and (max-width:6000px) {
    .customeraccount {
        // .product-text {
        //     width: 231px;
        // }
        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 5vw;
            padding: 0 1vw;
        }
        .expandIconWrapper .MuiSvgIcon-root,
        .collapsIconWrapper .MuiSvgIcon-root {
            font-size: 2.5vw !important;
            width: 2.5vw !important;
            height: 2.5vw !important;
        }

        .user-icon {
            width: 90%;
            height: 6.906vw;
            ;
            border-radius: 50%;
        }

        .MuiPaper-rounded {
            border-radius: 2.5vw !important;
        }

        .email-btn {
            width: 4.5vw;
            height: 3vw;
            padding: 0;
        }

        .number-card {
            right: 18.5%;
        }

        .img {
            display: initial;
        }

        .num {
            width: 2vw;
            padding-top: 0.4vw;
            font-size: 0.95vw;
        }

        .lightbtn,
        .darkbtn {
            min-width: 10vw !important;
            font-size: 0.9vw !important;
            line-height: 1vw !important;
        }

        .customer-profile-section {

            .lightbtn,
            .darkbtn {
                min-width: 7vw !important;
            }
        }

        .buy-btn {
            margin-left: 0.8vw !important;
        }

        img.myorderimg {
            width: 4.40625vw;
            height: 4.71875vw;
        }

        .product-text {
            font-size: 0.833vw;
            line-height: 1.4vw;
            margin-right: 0.5625vw;
            width: 4.8vw;
        }

        .lightbtn,
        .darkbtn {
            padding: 0.7552vw 1.1875vw !important;
        }

        .myordertable {

            .lightbtn,
            .darkbtn {
                min-width: 8vw !important;
                font-size: 0.9vw !important;
                line-height: 1vw !important;
            }
        }

        .saveditemtable {
            .lightbtn {
                margin-left: 0.5vw !important;
                min-width: 8.5vw !important;
            }
        }

        .quantity {
            text-align: center;
            display: flex;
            font-size: 0.9vw;
        }

        .quantity-btn-multi {
            width: 3.5vw;
            height: 2vw;
            margin-left: 0;
            font-size: 0.9vw;
        }

        .trending-heading {
            font-size: 1.9vw;
            line-height: 2.2vw;

        }

        .mysubscriptionstable {
            tr {
                padding: 1.5vw 0 !important;
            }

            .table_btn {
                width: 20%;
            }

            .lightbtn {
                margin-top: 1vw;
            }

            .table_product_name {
                width: 25%;
            }
        }
    }
}


@media (min-width:4000px) and (max-width:6000px) {
    .customeraccount {
        .user-icon {
            height: 7.906vw;
        }
    }
}