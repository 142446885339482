@import '../abstracts/variables';

.mysaveditemsseeall{
    padding: 4vw 0;

    .graycolor949d8ce3{
        margin-bottom: 2vw;
    }
    .myordertable,
    .saveditemtable {
        width: 100%;
    }

    .product-text {
        font-size: 1.1458vw;
        line-height: 1.25vw;
        font-family: $gt-font-medium;
        color: #707070;
        opacity: 1;
        margin-right: 1.5625vw;
        
    width: 145px;

    }

    .table_btn {
        text-align: right;
    }

    .lightbtn,
    .darkbtn {
        min-width: 9.375vw !important;
    }

    img.myorderimg {
        width: 6.40625vw;
        height: 6.71875vw;
        object-fit: cover;
        box-shadow: 3px 3px 12px #00000029;
        border-radius: 1.04vw;
        opacity: 1;
    }

    .css-46bh2p-MuiCardContent-root {
        padding: 0px !important;
    }

    .saveditemtable {
        
        tbody {
            width: 100%;
            display: block;
        }
        tr {
            padding: 1.5vw;
            display: flex;
            border-top: 1px solid #d6d6d6;
            justify-content: space-between;
            align-items: center;
        }

        .lightbtn {
            margin-left: 1.1vw !important;
            background: #E4E1D9 !important;
            color: #2F2B23 !important;
            min-width: 10.5vw !important;
        }

        .table_quantity .css-1869usk-MuiFormControl-root {
            min-width: auto;
        }

        .myorderimg {
            margin-right: 1.5625vw;
        }
    }
}

@media (min-width:280px) and (max-width:600px) {
    .mysaveditemsseeall{
        .lightbtn,
        .darkbtn {
            min-width: 9.375vw !important;
            font-size: 4.2vw !important;
            padding: 2vw 5vw !important;
        }

        .mobilesection {
            text-align: center;
        }

        .setlingmentbtn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btncenter {
            text-align: center;
        }

        .credit-heading {
            text-align: center;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;

            .available {
                font-size: 16px;
                line-height: 18px;
                color: #707070;
                margin-left: 20px;
            }
        }
       
        .saveditemtable {
            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: block;
            }

            .table_product_img {
                width: 30%;
                float: left;
            }

            .table_product_name,.table_quantity,.table_btn {
                width: 70%;
                float: right;
                text-align: left;
            }
            .myorderimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 4vw;
                width: 100%;
            }
            tr {
                padding: 5vw 2vw;
                clear: both;
                display: inline-block;
            }
            .see-all{
                clear: both;
            }

        }
    }
}

@media (min-width:601px) and (max-width:959px) {
    .mysaveditemsseeall{
        .lightbtn,.darkbtn{
            font-size: 1.8vw !important;
            line-height: 2vw !important;
            padding: 1.2vw 2vw !important;
            min-width: 15vw !important;
        }
        .available{
            font-size: 14px;
            line-height: 16px;
        }
        .alignsection{
            align-items: center;
        }
        .trending-heading{
            margin:1vw 0 !important;
        }
        img.myorderimg {
            width: 13.2vw;
            height: 13.2vw;
        }
        .saveditemtable{
            .table_product_img{
                width:20%;
            }
            .product-text{
                font-size: 2vw;
                line-height: 3vw;
            }
            .table_product_name{
                width:38%;
            }
            .table_quantity .quantity{
                display: flex !important;
            }
            
        }
    }
}
@media (min-width:1930px) and (max-width:6000px) {
    .mysaveditemsseeall{
    .number-card {
        right: 62.5%;
    }
    .num {
        width: 2vw;
        padding-top: 0.4vw;
        font-size: 0.95vw;
    }
    .lightbtn,.darkbtn {
        min-width: 10vw !important;
        font-size: 0.9vw !important;
        line-height: 1vw !important;
    }
    
    img.myorderimg {
        width: 4.40625vw;
        height: 4.71875vw;
    }
    .product-text{
        font-size: 0.833vw;
        line-height: 1.4vw;
        margin-right: 0.5625vw;
    }
    .quantity {
        text-align: center;
        display: flex;
        font-size: 0.9vw;
    }
    .quantity-btn-multi{
        width: 3.5vw;
        height: 2vw;
        margin-left: 0;
        font-size: 0.9vw;
    }
    .trending-heading{
        font-size: 1.9vw;
        line-height: 2.2vw;

    }
    .saveditemtable{
        .lightbtn {
            margin-left: 0.5vw !important;
            min-width: 8.5vw !important;
        }
    }
}
}