@import '../abstracts/variables';



.customeremptybasket {
    .customeraccount .buy-btn {
        margin-left: 0px !important;
    }
  
    img.myimg {
        width: 7.5vw;
        height: 5.62vw;
        opacity: 1;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .table_product_name {
        width: 27%;
    }
    .basket {

        .table_btn .darkbtn,
        .table_btn .lightbtn {
            width: auto;
        }
    }
    .image-table {
        width: 12%;
    }

    .table_btn {
        width: 27%;
        text-align: left;
    }

    .table_btn1 {
        width: 15%;
    }

    .card-part {
        align-items: center;
    }

    table,
    tbody,
    tr {
        display: block;
        width: 100%;
    }

    .lightbtn,
    .darkbtn {
        margin-left: 1vw;
    }

    .product-text {
        letter-spacing: 0px;
        color: $color707070;
        opacity: 1;
        font-size: 1.14vw;
        line-height: 1.5vw;
        font-family: $gt-font-regular;
        font-weight: bold;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        margin-bottom: 1.25vw;
        width: 100%;
    }

    .product-para {
        letter-spacing: 0px;
        color: $color707070;
        opacity: 1;
        font-size: 0.7812vw;
        line-height: 0.885vw;
        font-family: $asap-font-regular;
        font-weight: bold;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
    }

    .customer-card {
        background: #363240;
        border-radius: 40px;
        padding: 2.185vw 3.593vw 3.177vw;
    }

    .zero-item {
        text-align: center;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 1.14vw;
        line-height: 1.5vw;
        font-family: $asap-font-regular;
        font-weight: bold;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        margin-bottom: 0.98vw;
    }

    .zero-price {
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        font-size: 2.70vw;
        line-height: 3.125vw;
        font-family: $gt-font-regular;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        margin-bottom: 1.0937vw;
        text-align: center;
    }

    .heading20 {
        color: #FFFFFF;
        margin-bottom: 2.5vw;
        text-align: center;
    }

    .btn-section-empty-side{
      display: flex;
      justify-content: center;
        align-items: center;
    }

    .product-btn {
        color: #363240 !important;
        min-width: 13.4375vw !important;
        height: 2.76vw !important;
    }

}
@media (max-width:280px) {
.customeremptybasket button.lightbtn, .customeremptybasket button.darkbtn {
    margin-left:0vw !important;
}
}
@media (max-width: 600px) {
    .customeraccount .yourbasket .trending-heading {
        text-align: center;
    }
    .customeremptybasket {

        table,
        tbody,
        tr,
        td {
            width: 100%;
            display: block;
        }

        .table_btn,
        .image-table,
        .table_product_name {
            width: 100% !important;
            align-items: center;
            text-align: center;
        }

        .product-text {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 23px;
            width: 100%;
        }

        .product-para {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 23px;
        }

        img.myimg {
            width: 144px;
            height: 108px;
        }

        .lightbtn,
        .darkbtn {
            margin-left: 7vw !important;
            font-size: 16px !important;
            line-height: 22px !important;
        }

        .zero-item {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 10px;
        }

        .zero-price {
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 17px;
        }

        .heading20 {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 17px;
        }

        .product-btn,
        .retailers-btn {
            font-size: 16px !important;
            line-height: 18px !important;
            border-radius: 20px !important;
            padding: 16px 27px !important;
        }

        .customer-card {
            padding: 23px 23px 43px;
            margin-bottom: 100px;
        }
    }
}