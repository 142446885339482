@import "../abstracts/variables";
.Add-Address {
  .voucher-text {
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.03125vw;
    font-family: $gt-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 1.691vw;
  }
  .voucher-para {
    text-align: center;
    font-size: 1.19375vw;
    line-height: 1.375vw;
    font-family:$asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 1.1802vw;
  }
  .purchase-btn {
    padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
  }
  .input-field {
    width: 100%;
  }
  .postcode {
    width: 50%;
}
  .MuiPaper-rounded {
    border-radius: 40px;
    width: 100%;
  }
  .cancel-submit-btn {
    display: flex;
    justify-content: space-between;
  }
}

.AddAddress {
  .refer-friend-btn {
    background: #949d8c;
    border-radius: 2.5vw;
    font-family: $asap-font-regular;
    color: rgba(255, 255, 255, 0.929412);
    min-width: 10.989vw;
   min-height: 2.7vw;
    margin-left: 0.8854vw;
    font-size: 1.09375vw;
    line-height: 0.937vw;
    padding-top: 0px;
    text-transform: capitalize;
  }
}

@media  (max-width: 280px) {
.Add-Address{
  .basket-btn, .buy-btn{
    min-width: 97px !important;
  }
}
}

@media  (max-width: 600px) {

    .Add-Address {

        .voucher-text {
            font-size: 23px;
            line-height: 26px;
            margin-bottom: 25px;
        }
       
        .input-field {
            font-size: 16px;
            line-height: 15px;
            padding-left: 19.71px;
            height: 41px;
            border-radius: 40px;
        }
        .postcode {
          width: 50%;
      }
        .MuiPaper-rounded {
          border-radius: 40px;
          width: 100%;
        }
        .purchase-btn {
            width: auto;
            height: auto;
        }
        
      }
}

@media (min-width:600px) and (max-width: 920px) {

    .Add-Address {
        .voucher-text {
            font-size: 23px;
            line-height: 26px;
            margin-bottom: 25px;
        }
       
       
        .input-field {
            font-size: 16px;
            line-height: 15px;
            padding-left: 19.71px;
            height: 41px;
            border-radius: 40px;
        }
        .postcode {
          width: 50%;
      }
        .MuiPaper-rounded {
          border-radius: 40px;
          width: 100%;
        }
        .purchase-btn {
            width: auto;
            height: auto;
        }
        
      }
}

@media (min-width:920px) and (max-width: 1280px) {

    .Add-Address {
        .voucher-text {
            font-size: 28px;
            line-height: 35px;
        }
        .basket-btn.darkbtn, .buy-btn.lightbtn {
          padding: 12px 27px !important;
        }
        
        .input-field {
            height: 45px;
            font-size: 14px;
            line-height: 18px;
            border-radius: 40px;
            padding-left: 19.71px;
        }
        .postcode {
          width: 50%;
      }
        .MuiPaper-rounded {
          border-radius: 40px;
          width: 100%;
        }
        .purchase-btn {
            width: auto;
            height: auto;
        }
        
      }
}