.featuredCollectionsNew {
   margin-top: 7.1875vw;
   // margin-top: 0.64vw;
   background: #E2EDF4;

   .mobilebgnew {
      display: none;
   }

   .desktopbgnew {
      background: url('../../assets/images/home/newbgline.png');
      background-size: 100%;
      background-position-y: 10vw;
      background-repeat: no-repeat;
      //  min-height: 35.49vw;
   }

   .sectionpadding2p5 {
      padding: 2.466vw 0 4.5vw;
   }

   .main-heading40.trending-heading {
      text-align: center !important;
      margin-bottom: 4.910vw !important;
   }
   .slick-active:nth-child(3n) {
      margin-top: 8.65vw !important;
   }

}

//card section 
.featurecardnew {
   padding: 0 8% 0 8%;

   .favcard {
   
      width: 15.4vw;
      height: 15.97vw;
      // width: 17vw;
      // height: 18vw;
      margin-bottom: 0.598vw;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      object-fit: fill;
      
      cursor: pointer;
   }

   .favproduct {
      font-size: 1.5625vw;
      line-height: 1.7906vw;
      font-family: "Asap";
      font-weight: 400;
      text-align: center;
      color: #000000;
      // padding-left: 2vw;
      margin-left: -4vw;
      text-transform: initial;
   }
}

@media (min-width:250px) and (max-width:600px) {
   .featuredCollectionsNew {
      position: relative;
      overflow: hidden;

      .slick-active:nth-child(3n) {
         margin-top: 35px !important;
      }

      .main-heading40.trending-heading {
         margin-bottom: 40px !important;
      }

      .mobilebgnew {
         position: absolute;
         object-fit: cover;
         position: absolute;
         width: 100%;
         bottom: 0;
         display: block;
         height: 114%;
      }

      .desktopbgnew {
         background: none;
      }
   }

   .featurecardnew {
      .favcard {
         height: 138px;
         width: 129px;
         margin-bottom: 5.8px;
      }

      .favproduct {
         font-size: 13.06px;
         line-height: 14.97px;
         margin-left: 0vw;
      }
   }

}

@media (min-width:1930px) and (max-width:6000px) {
   .featurecardnew {
      .favcard {
         width: 11.4vw;
         height: 11.97vw;
      }
      .favproduct {
         margin-left: 0;
      }
   }
}