@import '../abstracts/variables';

.Add-Card {
    .select-box-card {
        background: #F5F4F3 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        width: 25.01vw;
        height: 3.177vw;
        font-size: 0.9375vw;
        line-height: 1.25vw;
        font-family: "ASAP";
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #707070;
        border: none;
        padding-left: 1.157vw;
        margin-bottom: 0.648vw;
    }

    .payment-text {
        padding-top: 6px;
        padding-left: 0.52vw;
        font-size: 1.04vw;
        line-height: 1.197vw;
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
    }

    .Add-text {
        text-align: center;
        font-size: 1.7708vw;
        line-height: 2.03125vw;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        margin-bottom: 0.691vw;
    }

    input.input-box {
        border: none;
        font-size: 16px;
        padding-bottom: 7px;
        // color: #b2b2b2;
        color: rgba(0, 0, 0, 0.6);
    }

    .input-field {
        width: 100%;
    }

    .cardmonth {
        // border: 1px solid #bbbbbb;
        background: #F5F4F3 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        border: none;
        //    padding: 0.833vw 1.83vw;
        width: 10vw;
        height: 3.177vw;
        // padding-top: 1.05vw;
        padding-top: 0.7vw;
        padding-left: 1.05vw;
        margin-bottom: 0.7812vw;

        input {
            text-align: left;
            font-size: 1.0937vw;
            line-height: 1.25vw;
            font-family: "Asap";
            font-style: normal;
            font-variant: normal;
            font-style: normal;
            letter-spacing: 0px;
            color: #707070;
            background: transparent;
        }
    }

    .select-box {
        background: transparent;
        border: none;
        color: #707070;
    }

    select.select-box:focus-visible {
        border: none;
        outline: none;
    }

    .cardmonth input {
        border: 0;
    }

    .expiry-date-cvv {
        display: flex;
        justify-content: space-between;
    }

    .cardName {
        display: flex;
        justify-content: center;
    }

    .purchase-btn {
        text-align: center;
        padding-top: 12px;
    }

    .date {
        padding-bottom: 10px;
    }

    .cvv {
        padding-bottom: 10px;
    }
}

@media (max-width: 600px) {

    .Add-Card {
        .select-box-card {
            font-size: 18px;
            line-height: 21px;
            padding-left: 20px;
            margin-bottom: 6.5px;
            width: 100%;
            height: 51px;
            border-radius: 20px;

        }

        .cardmonth {
            font-size: 18px;
            line-height: 21px;
            padding-left: 20px;
            margin-bottom: 6.5px;
            width: 100%;
            height: 50px;
            border-radius: 20px;
            align-items: center;
            display: flex;
        }

        .expiry-date-part {
            width: 60%;
        }

        .Add-text {
            font-size: 28px;
            line-height: 33px;
            margin-bottom: 5.691vw;
        }

        input.input-box {
            font-size: 18px;
        }

        .payment-text {
            font-size: 15px;
            line-height: 24px;
            padding-left: 12px;
            padding-top: 0px;
        }

        .input-field {
            height: 50px;
        }

        .card-cvv {
            padding-left: 21px;
        }

        .cardName {
            padding-top: 31px;
        }
    }
}

@media (min-width:600px) and (max-width: 920px) {

    .Add-Card {
        .Add-text {
            font-size: 4.7708vw;
            line-height: 7.03125vw;
            margin-bottom: 5.691vw;
        }

        input.input-box {
            font-size: 18px;
        }

        .input-field {
            height: 50px;
            font-size: 16px;
        }

        .card-cvv {
            padding-left: 21px;
        }

        .cardName {
            padding-top: 31px;
        }

    }
}

@media (min-width:920px) and (max-width: 1280px) {

    .Add-Card {
        .cardmonth {
            width: 12vw;
            height: 50px;
            padding-top: 1.2vw;
        }

        .Add-text {
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 30px;
        }
        
        input.input-box {
            font-size: 16px;
        }

        .input-field {
            height: 45px;
            font-size: 14px;
        }
        .payment-text{
            font-size: 14px;
        }

        .card-cvv {
            padding-left: 21px;
        }

        .cardName {
            padding-top: 31px;
        }

    }
}

@media (min-width:1921px) and (max-width: 6000px) {

    .Add-Card {

        .cardName {
            align-items: center;
        }

        .select-box-card {

            width: 100%;
        }

        .cardmonth {
            width: 13vw;
            height: 3.177vw;
            padding-top: 0.2vw;
            padding-left: 3.05vw;
            margin-bottom: 0.7812vw;

            span {
                font-size: 1.5vw;
                padding: 0 23px;
                // padding: 0 10px;

            }
        }

        .MuiSvgIcon-root {
            font-size: 1vw;
            width: 2vw;
            height: 1.5vw;
        }

        .purchase-btn {
            padding-top: 2vw;
        }

        .date,
        .cvv {
            font-size: 1vw;
            line-height: 2vw;
            padding-left: 0.5vw;
        }

        .select-box {
            font-size: 1vw;
            line-height: 1.5vw;
            width: 3vw;

        }

        .select-box option {
            font-size: 15px;
        }
    }
}