@import '../abstracts/variables';

.searchproduct {
    padding: 3vw 0;

    .headbtn-section {
        display: flex;
        justify-content: space-between;
        margin-top: 3.802vw;
        margin-bottom: 3.466vw;
        align-items: baseline;
    }
    .products .headbtn-section{
        margin-top:2vw;
    }
    .productcard {
        width: 33.33%;
        display: inline-block;
        margin-bottom: 20px;
    }

    .productcard .catbtn {
        display: block !important;
    }

    .productcard .button-section {
        display: none !important;
    }

    .productcard .title-price {
        display: block !important;
    }

    .productcard:hover .img-row1 {
        opacity: 1;
    }

    .displayresult .display-title {
        color: #999999;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        margin-top: 1.8229vw;
        font-family: "Asap";
        margin-left: 1.582vw;
        font-weight: 500;
    }

    .MuiTypography-body1 {
        font-size: 0.8854vw;
    }
}
@media (min-width:280px) and (max-width:600px) {
    .searchproduct {
        .skeleton{
            width: 50% !important;
        }
    }
}
@media (min-width:280px) and (max-width:319px) {
    .searchproduct {
        .productcard {
            width: 100% !important;
            display: inline-block;
        }
    }
}

@media (min-width:280px) and (max-width:920px) {
    .searchproduct {
        .productcard {
            width: 50%;
            display: inline-block;
        }

        .headbtn-section {
            display: flex;
            justify-content: center;
        }

        .search-heading {
            font-size: 14px;
            line-height: 16px;
        }

        .product-btn,
        .retailers-btn {
            font-size: 27px !important;
            line-height: 30px !important;
            background: transparent !important;
            border-radius: 0 !important;
            margin: auto 14px !important;
        }

        .product-btn {
            border-bottom: 3px solid #ccc;
        }

        .retailers-btn {
            color: #949D8C !important;
            border-bottom: 3px solid #949D8C;
        }

        .trending-heading {
            display: none;
        }

        .displayresult {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mobilefilter {
            color: #707070;
            font-size: 12px;
            visibility: visible;
            margin-left: 5px;
        }

        .displayresult .display-title {
            visibility: visible;
            color: #707070;
            font-size: 14px;
            line-height: 16px;
            font-family: "Asap";
            margin-right: 10px;
        }

        .mobilefilter {
            display: flex;
            align-items: center;
        }

        .productcard .catbtn {
            display: none !important;
        }
    }
}

@media (min-width:280px) and (max-width:324px) {
    .searchproduct {

        .product-btn,
        .retailers-btn {
            font-size: 23px !important;
            line-height: 26px !important;
            background: transparent !important;
            border-radius: 0 !important;
            margin: auto 10px !important;
        }
    }
}

@media (min-width:601px) and (max-width:920px) {
    .searchproduct {
        .productcard {
            width: 33.33%;
            display: inline-block;
            margin-bottom: 20px;
        }

       
        .main-heading40 {
            margin: 0vw 0 !important;
        }

        .displayresult {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mobilefilter {
            color: #707070;
            font-size: 12px;
            visibility: visible;
            margin-left: 5px;
        }

        .displayresult .display-title {
            visibility: visible;
            color: #707070;
            font-size: 14px;
            line-height: 16px;
            font-family: "Asap";
            margin-right: 10px;
        }

        .mobilefilter {
            display: flex;
            align-items: center;
        }

        .productcard .catbtn {
            display: none !important;
        }

        .search-heading {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

@media (min-width:901px) and (max-width:1280px) {
    .searchproduct {
        .MuiAccordionSummary-root {
            padding: 0px;
        }

        .MuiAccordionDetails-root {
            padding: 8px 0px 16px;
        }

        .MuiTypography-body1 {
            font-size: 1.04vw;
        }

        ul.catfilter li p.catname {
            font-size: 0.8vw;
        }
    }
}

@media (min-width:1930px) {
    .searchproduct {
        .MuiSvgIcon-root {
            fill: currentColor;
            width: 1.5vw;
            height: 1.5vw;
        }

        .filters .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
            width: 1.5vw;
            height: 1.5vw;
            border: 0.5vw solid gray;
        }
    }
}