.storeskeleton{
    .topbradcrm{
        width:20%;
        margin-top: 1.5vw;
    }
    .storeimg{
        width: 14vw;
        height: 6vw;
        border-radius: 2.08vw;
        margin-right: 2vw;
    }
    .img-pname{
        display: flex;
        align-items: center;
    }
    .pnameskeleton{
        width:18vw;
        height:3vw;
    }
    .taglineskeleton {
        width: 75%;
    }
    .storebtn{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .skiptext{
        width:15%
    }
    .allbtn{
        width:8vw;
        height:3.5vw;
        margin-left: 1.5vw;
        border-radius: 2.08vw;
    }
    .abouthead{
        width:50%;
        height:3vw;
    }
    .subhead{
        width:80%;
        height:2.5vw;
    }
    .aboutimg{
        height: 28vw;
        width: 100%;
        border-radius: 2.08vw;
    }
    .gridset{
        margin-top: 2.5vw;
    }
}