@import '../../abstracts/variables';

.singleproductskeleton{
    .bradcrum{
        width:40%;
        margin-bottom: 2vw;
        margin-left: 2vw;
    }
    .mainimg{
        width: 100%;
        height:45.156vw;
        border-radius: 2.0833vw;
    }
    .loopskeleton{
        display: inline-block;
        width:25%;
        padding:1.5%;
    }
    .imgcard{
        border-radius: 2.0833vw;
        height: 10.31vw;
    }
   .css-mhc70k-MuiGrid-root > .MuiGrid-item{
    padding-right: 16px;
   }
    .saller-stock{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sellerskeleton{
        width: 25%;
    }
    .stockskeleton{
        width: 15%;
    }
    .productname-price{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.5vw;
    }
    .productnameskeleton{
        width: 65%;
    }
    .productname{
        height: 3.5vw;
    }
    .priceskeleton{
        width: 21%;
        height: 4vw;
    }
    .peraskeleton,.variantskeleton{
        margin-top: 1.5vw;
    }
    .variantheading{
        width:30%;
    }
    .variantcat{
        margin-top:1.5vw;
    }
    .varientloop{
        display: inline-block;
        width:12.2%;
        padding:0.8%;
    }
    .variantimg{
        width: 100%;
        height: 4.5vw !important;
    }
    .selectboxskeleton{
        margin-top: 2vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .titleskeleton{
        width:30%;
    }
    .selectbox{
        width:60%;
        height:3.5vw;
        border-radius: 2.08vw;
    }
    .buttonsection{
        margin-top: 3vw;
        display: flex;
        align-items: center;
    }
    .btnskeleton{
        width:25%;
        height: 3.5vw;
        border-radius: 2.08vw;
        margin-right: 1.5vw;
    }
    .productdescription{
        margin-top: 6vw;
    }
    .headingskeleton{
        display: flex;
        align-items: center;
    }
    .pdtitle{
        width:20%;
        height:3vw;
        margin-right: 2vw;
        margin-bottom: 2vw;
    }
}
@media (min-width:280px) and (max-width:600px) {
    .singleproductskeleton{
        .bradcrum {
            width: 60%;
            margin-bottom: 2vw;
            margin-left: 0vw;
        }
        .mainimg {
            width: 100%;
            height: 92.156vw;
            border-radius: 6.0833vw;
            // margin-left: 2.5%;
            // margin-left: 16px;
            margin-bottom: 10px;
        }
        .loopskeleton {
            display: inline-block;
            width: 33%;
            padding: 2%;
        }
        .imgcard {
            border-radius: 4.5vw;
            height: 29.31vw;
        }
        .productname-price {
            display: block;
            margin-top: 3.5vw;
        }
        .productname {
            height: 9.5vw;
        }
        .priceskeleton {
            width: 33%;
            height: 12vw;
        }
        .variantheading {
            width: 45%;
            height: 11vw;
        }
        .varientloop {
            display: inline-block;
            width: 24.2%;
            padding: 1%;
        }
        .variantimg {
            width: 100%;
            height: 20.5vw !important;
        }
        .selectboxskeleton {
            display: block;
        }
        .selectbox {
            width: 100%;
            height: 12vw;
            border-radius: 12.08vw;
            margin-top: 10px;
        }
        .pdtitle {
            width: 70%;
            height: 14vw;
            margin-bottom: 0vw;
            margin: 0 auto;
            border-radius: 12vw;
        }
        .buttonsection {
            margin-top: 10vw;
            display: block;
            align-items: center;
        }
        .btnskeleton {
            width: 100%;
            height: 12.5vw;
            border-radius: 15.08vw;
            margin-bottom: 15px;
        }
        .headingskeleton {
            display: block;
            align-items: center;
        }
    }
}