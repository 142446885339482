.productSearchResult{
    .search-heading{
    font-weight: normal;
    color: #707070;
}
   .search-result-text{
    font-weight: 600;
    color: #707070;
    font-style: italic;
   } 
}