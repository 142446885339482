.subcategoryaccordion{
    .MuiAccordionDetails-root{
        display: block;
    }
    .filterbody{
        width:100%;
    }
    .accordianbox.MuiPaper-elevation1 {
        box-shadow: none !important;
      }
      .inneraccord.filterbody{
        margin-left: 15px;
      }
      .filterheading{
        .valuefix span{
            padding-left:10px;
        }
      }
    .accordianbox:last-child{
        border:none !important
    }
    .valuefix{
        padding-left:10px;
    }
    .radiogroupfixed{
        flex-direction: row !important;
    }
    .filterbody label {
        width: 100%;
        margin-bottom: 0.8vw;
        font-family: "Helvetica Neue";
        letter-spacing: 0px;
        color: #707070;
        font-weight: 500;
        margin-left: 0px;
    
      }
      .MuiAccordionSummary-content.Mui-expanded{
        margin:0 !important;
      }
      .filterbody label span.MuiRadio-root {
        margin-right: 6px;
        font-size: 0.8854vw !important;
        line-height: 1.04vw;
      }
    
      .MuiRadio-root{
        width: 1.5vw;
        height: 1.5vw;
      }
      .MuiRadio-root.Mui-checked {
        color: #ffffff;
        border: 5px solid gray;
        border-radius: 40px;
        width: 1.5vw;
        height: 1.5vw;
    
        .MuiSvgIcon-fontSizeMedium {
          display: none;
        }
       
        
      }
}