.mobilecheckoutemail {
  margin-top: 21px;
  padding: 0 45px;

  .email-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #2f2b23;
    margin-bottom: 38px;
  }
  legend span {
    font-size: 14px;
    line-height: 16px;
  }

  .MuiInputLabel-root {
    font-size: 16px;
    line-height: 27px;
    font-family: 'Asap';
    left: 7px;
  }

  .MuiInputLabel-root.Mui-focused {
    color: #777777 !important;
    font-size: 16px;
    line-height: 27px;
    font-family: 'Asap';
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2f2b23 !important;
    border: 1px solid !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-input {
    // min-width: 100%;
    min-height: 56px;
    padding: 0 20px;
  }

  .MuiOutlinedInput-root {
    border-radius: 15px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .legend {
    height: auto;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #a6a194;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9f9f9f;
    font-family: 'Asap';
  }

  ::placeholder {
    color: #9f9f9f;
    font-family: 'Asap';
  }

  .continuing-text {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5px;
    line-height: 25px;
    color: #a0a0a0f0;
    margin-top: 32px;
    margin-bottom: 54px;

    span {
      text-decoration-line: underline;
      color: #2f2b23;
    }
  }

  .darkbtn {
    min-height: 63px;
    font-size: 16px !important;
    min-width: 100% !important;
    padding: 17px 86px !important;
    border-radius: 35px;
    color: #ffffff !important;
    background-color: #2f2b23;
    font-weight: bold !important;
  }

  .inputicon .MuiSvgIcon-root {
    display: none;
  }

  .btn-section-new {
    text-align: center;
    margin-bottom: 80px;
  }
}

@media (min-width: 264px) and (max-width: 390px) {
  .mobilecheckoutemail {
    margin-top: 21px;
    padding: 0 30px;

    .email-text {
      font-weight: 500;
      font-size: 26px;
    }
  }
}
