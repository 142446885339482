@import '../abstracts/variables';

.slick-next:before,
.slick-prev:before {
  color: #707070 !important;
}

.hidedot .slick-dots {
  display: none !important;
}

.productcard {
  padding: 0 0.85vw;
  padding-bottom: 1.04vw;

  .basket-btn,
  .buy-btn {
    color: #F5F4F3 !important;
  }

  .img-row1 {
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 20.78vw;
    width: 100%;
    min-height: 150px;
    border-radius: 2.0833vw;
    object-fit: cover;
    object-position: top;
  }

  .img-background-row {
    margin-bottom: 2vw;
    position: relative;
  }

  .product-name1,
  .product-price1 {
    font-family: Asap;
    font-size: 1.254vw;
    font-style: normal;
    font-variant: normal;
    text-align: left;
    opacity: 1;
  }

  .product-name1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.45vw;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0.485vw;
  }

  .star-section {
    display: flex;
    align-items: center;

    .star-rating {
      .MuiSvgIcon-root {
        width: 1.5vw;
        height: 1.5vw;
      }
    }
  }

  .addnewstar {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .newdelivery {
    margin-top: 0.5vw;
  }

  .seller-heading {
    text-align: left;
    color: #949d8c;
    cursor: pointer;
  }

  .newdatetext {
    font-weight: bold;
  }

  .review {
    font-family: 'Asap';
    font-weight: normal;
    font-family: $asap-font-regular;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    opacity: $opcity;
    letter-spacing: $letter-spacing;
    color: $color707070;
    font-size: 0.9291vw;
    line-height: 0.833vw;
    padding-left: 0.52vw;
  }

  .product-price1 {
    line-height: 1.56vw;
    // font-weight: 700;
    letter-spacing: 0;
    font-weight: 600;
    color: #9CA595;
  }

  .button-section,
  .catbtn {
    display: none !important;
  }

  &:hover .title-price {
    display: none;
  }

  &:hover .button-section {
    display: flex !important;
    transition: 0.3s;
  }

  &:hover .img-row1 {
    opacity: 0.3;
    transition: 0.3s;
  }

  &:hover .catbtn {
    display: block !important;
  }
}

@media only screen and (max-width: 280px) {
  .productcard {
    .img-row1 {
      height: 90vw !important;
    }
  }
}

@media only screen and (max-width: 372px) {
  .productcard {
    .review {
      margin-left: 6px !important;
    }

    div.star-section {
      .star-rating {
        .MuiSvgIcon-root {
          width: 0.88rem;
          height: 1.2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 919px) {
  .hidedot .slick-dots {
    display: block !important;
  }

  .trendingcollection,
  .singleproductdetails,
  .popular-product-section,
  .newcheckout,
  .wishlist,
  .basket {
    .hidedot .slick-dots {
      display: none !important;
    }
  }

  .shopbyvalue .hidedot .slick-dots {
    display: none !important;
  }

  .productcard {
    padding: 0 5.5px;

    .seller-heading {
      font-size: 14px;
      line-height: 18px;
    }

    .review {
      font-size: 12px;
      line-height: 14px;
      margin-left: 10px;
    }

    .star-section {
      margin-top: 5px;

      .star-rating {
        .MuiSvgIcon-root {
          width: 1.1rem;
          height: 1.2rem;
        }
      }
    }

    .addnewstar {
      display: block;
    }

    .img-row1 {
      height: 40vw;
      border-radius: 16.8039px;
    }

    &:hover .catbtn {
      display: none !important;
    }

    &:hover .button-section {
      display: none !important;
    }

    &:hover .title-price {
      display: block;
    }

    &:hover .img-row1 {
      opacity: 1;
    }

    .product-name1 {
      font-size: 16px;
      line-height: 18px;
      // font-weight: 500;
      font-family: 'Asap';
      margin-bottom: 5px;
      font-weight: bold;
    }

    .product-price1 {
      font-size: 16px;
      line-height: 18px;
    }
   
  }
  .homepage27{
    .productcard{
      padding: 0 2.8px;
    }
    .productcard .img-row1 {
      height: 45.5vw;
      border-radius: 16.8039px;
    }
    .productcard .product-name1 {
      font-size: 16px;
      line-height: 18px;
      font-family: 'Asap';
      margin-bottom: 5px;
      font-weight: 500;
      color: #434139;
    }
  }
}

@media (min-width: 701px) and (max-width: 959px) {
  .productcard .img-row1 {
    height: 22vw !important;
  }
}

@media (min-width: 920px) and (max-width: 1280px) {
  .productcard {
    .img-row1 {
      height: 25.87vw;
    }

    padding: 0 1.5vw;

    .product-name1,
    .product-price1 {
      font-size: 2.2vw;
      line-height: 2.6vw;
    }

    :hover .catbtn {
      font-size: 1.4vw !important;
    }

    .product-name1 {
      margin-bottom: 1.2vw;
    }
  }
}

@media only screen and (min-width: 1930px) {
  .productcard {
    padding: 0 0.5vw;

    .star-section {
      margin-top: 5px;

      .star-rating {
        .MuiSvgIcon-root {
          width: 1.2vw;
          height: 1.2vw;
        }
      }
    }

    .img-row1 {
      height: 15vw !important;
    }

    .basket-btn,
    .buy-btn {
      font-size: 0.85vw !important;
      min-width: 7vw !important;
      padding: 0 !important;
      /* padding: 0.5vw; */
      margin-right: 0.5vw;
    }

    .product-name1,
    .product-price1 {
      font-family: Asap;
      font-size: 1.04vw;
      font-style: normal;
      font-variant: normal;
      text-align: left;
      opacity: 1;
    }
  }

  .dynamiccontent {

    .basket-btn,
    .buy-btn {
      font-size: 0.85vw !important;
      min-width: auto !important;
      padding: 0.29vw 1.3vw !important;
    }
  }
}

@media only screen and (max-width:1200px) {
  .productcard {

    .star-section,
    .newdelivery {
      display: none;
    }
  }
}