@import '../abstracts/variables';

.checkout { 
    margin-top: 19px;
    border-top: 4px solid #F5F4F3;

    .basketarea {
        padding-right: 60px;
    }
    .product-text{
        color: $color707070;
        font-family: $gt-font-regular;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    .inputicon{
        .MuiOutlinedInput-input{
            padding: 16.5px 48px 17.5px 10px;
        }
    }
    .basket-heading {
        text-align: center;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        letter-spacing: $letter-spacing;
        color: #949d8ceb;
        margin-bottom: 2.33437vw;
        margin-top: 2.5vw;
    }
    .checkouttable{
        width: 100%;
        display: block;
        tbody{
            width: 100%;
            display: block;   
        }
    }
.checkouttable tr{
    border-top: 1px solid #E4E1D9;
    display: inline-table;
    width: 100%;
}
    .checkoutimg {
        width: 7.86vw;
        height: 7.86vw;
        border-radius: 1.7vw;
        margin: 0.98vw 0px;
        cursor: pointer;
        object-position: top;
        object-fit: cover;
    }

    .table_product_img {
        width: 18%;
    }

    .table_product_name {
        width: 17%;
    }
 
    .table_message {
        width: 12%;
        text-align: center;
    }
    .table_price{
        width: 13%;
        text-align: center; 
    }

    .table_product-size {
        width: 14%;
    }
  .table_product_input{
width: 20%;
}
.table_button{
width: 10%;
}
    .variant-name {
        color: $color707070;
        text-align: center;
    }
.size-text{
    color: #949D8C;
    font-weight: bold;
}
    .option-heading {
        color: #949D8C;
        font-weight: bold;
        text-align: center;
    }

    .product-name1 {
        color: #707070;

    }

    .quantity1 {
        color: #707070;
        opacity: 0.9;
    }

    .quantity {
        text-align: center;
        font-size: 1.0937vw;
        line-height: 1.25vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: 0px;
        color: $color707070;
        opacity: 0.9;
    }

    .price-text {
        font-size: 1.302vw;
        line-height: 1.51vw;
        font-weight: 600;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: #949D8C;
        opacity: 1;
        text-align: center;
    }

    .item-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 3px 3px 12px #00000029;
        border-radius: 2.5vw;
        opacity: 1;
        padding: 2.5vw 2.0833vw 1.9140vw;
        text-align: center;
        margin-left: 1.04vw;
        .paddingbottom{
            display: flex;
    justify-content: space-between;
        }
        .basket-btn.darkbtn {
            padding: 0.74739vw 2.43177vw 0.763vw 2.44791vw !important;
            color: #ffffffe0 !important;
            font-size: 1.09vw !important;
        }
    }
    .addbill {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1vw;
    }
    .item-heading {
        text-align: center;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        font-weight: bold;
        font-style: normal;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: #949d8ce3;
        opacity: $opcity;
        margin-bottom: 1.9328vw;
    }

    .address {
        text-align: left;
        color: #363240;
    }
    .shipping-section{
        clear: both;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1.2vw 0;
        border-top: 1px solid #E4E1D9;
     
        .price-text{
            width: 12%;
            margin-left: 2vw;
        }
    }
    .editimg {
        width: 28px;
        height: 21px;
        cursor: pointer;
        object-fit: cover;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .centertext {
        align-items: center;
    }

    .paddingbottom {
        margin-bottom: 2.385vw;
    }
    .shipping-section{
text-align: end;
    }
    .shipping-section1{
        margin-top: 1.04vw;
        margin-bottom: 1.04vw;
        border-top: 1px solid #E4E1D9 ;
    }
    .voucher-section{
        display: flex;
        padding-right: 19px;
        padding-top: 1.25vw;
        border-top: 1px solid #E4E1D9;
        align-items: baseline;
        form{
            display: flex;
        }
        padding-bottom: 0.6vw;
        .buy-btn.lightbtn{
            color: #f4f5f6 !important;
        }
    }
    .voucher-text{
        color: #949D8C;
        margin-right: 1.15625vw;
        font-weight: 600;
    }
    .input-field{
        width: 17.5vw;
        height: 2.76vw;
        padding-left: 1.419vw;
        margin-right: 1.10989vw;
    }
    .light-btn{
        color: #ffffff;
    }
    .mobilecardimgnum,.mobilecard{
        display: flex;
        justify-content: space-between;
    }
    .mobileshipping{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .MuiSvgIcon-root {
            width: 1.5vw;
            height: 1.5vw
        }
    }
    .shipping-text {
        text-align: left;
        font-size: 0.677vw;
        line-height: 0.7812vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: #363240;
        opacity: 1;
    }

    .plus-img {
        width: 1.51vw;
        height: 1.4583vw;
        color: #949D8C;
        vertical-align: middle;
        cursor: pointer;
        object-fit: contain;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .billing-text {
        text-align: center;
        font-size: 0.7291vw;
        line-height: 0.8333vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: #363240;
        opacity: $opcity;
        padding-left: 0.24vw;
        cursor: pointer
    }

    .line {
        border: 1px solid #F5F4F3;
        opacity: 1;
        margin-bottom: 1.04vw;
        margin-top: 1.04vw;
    }

    .payment-method {
        text-align: center;
        font-weight: bold;
        color: #949d8ce3;
        margin-bottom: 1.04vw;
    }

    .cardimg {
        width: 3.6979vw;
        height: 2.1354vw;
        border: 0.4166vw;
        object-fit: cover;
        margin-right: 1vw;
    }
    .cardimgvisa {
        width: 3.6979vw;
        height: 2.1354vw;
        border: 0.4166vw;
        object-fit: contain;
        margin-right: 1vw;
    }
    .name-heading {
        font-size: 0.7291vw;
        line-height: 0.833vw;
        font-weight: bold;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        text-align: left;

    }
    .card-number {
        font-weight: bold;
        font-style: normal;
        color: #707070;
        text-align: left;
    }
   .edit-section{
    display: flex;
    justify-content: end;
   }
   .item-text {
    text-align: center;
    font-size: 0.937vw;
    line-height: 1.0937;
    font-weight: 500;
    font-style: normal;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: #363240;
    opacity: $opcity;
    margin-bottom: 0.364vw;
   }
   .multiple-text{
    color: #363240;
    margin-bottom: 1.2515vw;
   }
   .credit-text {
    text-align: left;
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-weight: bold;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: #949d8cbf;
    opacity: $opcity;
    margin-bottom: 0.930729vw;
}
.credit-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.04vw;
    align-items: center;
}
.available-text{
    color: #363240;
    opacity: 1;
    margin-left: 0.5vw;
    padding-top: 0.3vw;
    font-size: 0.833vw;
    line-height: 1.09375vw;
}
.card-price{
    color: #707070;  
    font-weight: bold;
}
.checkbox-part{
    display: flex;
    align-items: center;
}
.total-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.764vw;
}
.total-text {
    font-size: 1.197vw;
    line-height: 1.3541vw;
    font-weight: bold;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: $color707070;
    opacity: $opcity;
  
}
.total-price {
    text-align: center;
    font-size: 1.302vw;
    line-height: 1.51vw;
    font-weight: 600;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family:$gt-font-regular;
    letter-spacing: 0px;
    color: #949D8C;
    opacity: 1;
}
.shipping-charge{
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-weight: normal;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: 0.9;
}
.sub-total{
    font-size: 1.1979vw;
    line-height: 1.3541vw;
    font-weight: bold;
    font-style: $btn-font-style;
    font-variant: $btn-font-variant;
    font-family: $asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;

}
.mobileshow{
    display: none;
}
}
@media (max-width:280px) {
    .checkout{
        .basket-heading.main-heading40{
            margin-bottom: 10vw !important;
        }
        .item-card {
            padding: 30px 22px !important;
        }
        .voucher-section{
            padding-right:0px !important;
            .buy-btn{
                min-width: 80px !important;
                min-height: 40px;
            }
        }
    }
}

@media(max-width:600px) {
    .checkout{
        .basketarea {
            padding-right: 0px;
        }
        .basket-heading.main-heading40{
            margin-bottom: 7vw !important;
        }
        .mobilehide{
            display: none !important;
        }
         .voucher-section form {
            display: flex;
            justify-content: space-between;
        }
       .mobilediv{
            display: block;
        }
        .mobileshipping{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .MuiSvgIcon-root {
                width: 5vw;
                height: 5vw
            }
        }
        .item-card .basket-btn.darkbtn {
            padding: 0.74739vw 2.43177vw 0.763vw 2.44791vw !important;
            color: #ffffffe0 !important;
            font-size: 16px !important;
            line-height: 18px !important;
            padding: 13px 27px !important;
        }
        .credit-heading {
            text-align: center;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;

            .available {
                font-size: 16px;
                line-height: 18px;
                color: #707070;
                margin-left: 20px;
            }
        }
        .css-mhc70k-MuiGrid-root>.MuiGrid-item,.css-1oqqzyl-MuiContainer-root{
            padding: 0 !important;
        }
        .css-1qsxih2{
            padding: 0 !important;
        }
        .css-isbt42 > .MuiGrid-item{
            padding-left: 0px;
        }
        .checkouttable {
            .mobilemyhidebtn{
                display:none !important;
            }
            .mobilemyshowbtn{
                display: flex !important;
                justify-content: space-evenly;
            }
            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: flow-root;
            }

            .table_product_img {
                width: 25%;
                float: left;
            }

            .table_product_name,.table_product-size,.table_message {
                width: 73%;
                float: right;
                margin-bottom: 10px;
            }

            .orderid,.paymentdate,.paymentdate{
                width: 50%;
                float: left;
                margin:3vw 0;
            }
            .table_btn {
                width: 100%;
                clear: both;
                text-align: center;

            }
            .product-name1,.quantity1,.variant-name,.option-heading,.quantity {
                font-size: 4vw;
                line-height: 5vw;
                text-align: left;
                
            }
            .table_product-size{
                display: block;
            }
            .variant-name{
                margin-bottom: 14px;
            }
            .option-heading,.num-btn{
                margin-left:10px;
            }
            .price-text{
                font-size: 5.5vw;
                line-height: 7vw;
                text-align: left;
            }
            
             
            .checkoutimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 4vw;
            }
            tr {
                padding: 5vw 3.5vw 2vw;
                border-top: 1px solid #E4E1D9;
            }

        }
        .shipping-charge,.sub-total{
            font-size: 5.2vw ;
            line-height: 6vw ;
            text-align: left;
        }
        .price-text{
            font-size: 5.5vw;
            line-height: 6.5vw;
            text-align: right;
        }
        .shipping-section{
            clear: both;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4vw 0;
            border-top: 1px solid #E4E1D9;
            margin: 0 3%;
        }
        .mobilerevers{
            flex-flow: column-reverse;
            margin-left: 0 !important;
            width: 100%;
        }
        .voucher-section{
            display: block;
            margin: 9vw 2% 10vw;
            border-top: 0;
        }
        .shipping-section .price-text {
            width: 25%
        }
        .input-field {
            width:100%;
            height: 41px;
            padding-left: 4vw;
            margin-right: auto;
        }
        
        .voucher-text {
            font-size: 17px;
            line-height: 21px;
            text-align: center;
            margin-bottom: 18.6px;
            margin-top: 24px;
        }
        .mobilediv{
            // display: flex;
            // justify-content: space-between;
            .lightbtn{
                margin:0px !important;
                padding: 2.5vw 9vw !important;
                font-size: 4vw !important;
                line-height: 5vw!important;
                color:#fff !important;
                min-width: 110px !important;
            }
        }
        .item-card {
            padding: 30px 40px;
            border-radius: 40px;
            margin: 0 3%;
        }
        .item-heading,.payment-method,.credit-text{
            font-size: 5vw;
            line-height: 7vw;
            margin-bottom: 8vw;
        }
        .address{
            font-size: 4vw;
            line-height: 5vw;
            width: 220px;
            margin-bottom:4vw;
        }
        .paddingbottom{
            display: flex;
            justify-content: space-between;
        }
        .mobileshipping{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .shipping-text{
            font-size: 3.5vw;
            line-height: 4.5vw;
        }
        .addbill{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5vw 0;
        }
        .billing-text{
            font-size: 3vw;
            line-height: 4vw;
        }
        .plus-img{
            cursor: pointer;
            width: 7.5vw;
            height: 7.5vw;
            margin-right: 8px;
        }
        .cardimg{
            width: 12.6979vw;
            height: 9.1354vw;
            margin-right: 18px;
        }
        .cardimgvisa{
            width: 12.6979vw;
            height: 9.1354vw;
            margin-right: 18px;
        }
        .mobilecard{
            display: flex;
            justify-content: space-between;
        }
        .mobilecardimgnum{
            display: flex;
            align-items: center;
        }
        .name-heading,.card-number{
            font-size: 3vw;
            line-height: 4vw;
            margin-bottom: 1vw;
        }
        .item-text,.multiple-text{
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 2vw;
        }
        .available-text{
            font-size: 4vw;
            line-height: 5vw;
            margin-left: 10px;
        }
        .credit-text {
            margin-bottom: 2vw;
        }  
        .credit-section {
            margin-bottom: 20px;
        }      
        .card-price{
            font-size: 4.8vw;
            line-height: 6vw;
        }
        .total-text{
            font-size: 7vw;
            line-height: 9vw;
        }
        .total-price{
            font-size: 6.5vw;
            line-height: 8vw;
        }
        .total-section,.credit-text,.payment-method{
            margin-top:25px;
        }
    }
}

@media (min-width:601px) and (max-width:959px) {
    .checkout{
        .mobileshow{
            display: none  !important;
        }

        .credit-heading {
            text-align: center;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;

            .available {
                font-size: 16px;
                line-height: 18px;
                color: #707070;
                margin-left: 20px;
            }
        }
        .css-mhc70k-MuiGrid-root>.MuiGrid-item,.css-1oqqzyl-MuiContainer-root{
            padding: 0 !important;
        }

       
        .checkouttable {
           
            .product-name1,.quantity1,.variant-name,.option-heading,.quantity {
                line-height: 2.5vw;
                font-size: 1.6vw;
                text-align: left;
                
            }
            .table_product-size{
                display: flex;
            }
            .option-heading,.num-btn{
                margin-left:10px;
            }
            .price-text{
                font-size: 2.5vw;
                line-height: 3vw;
                text-align: left;
            }
            .table_product_img{
                width:14%;
            }
             
            .checkoutimg {
                width: 7.86vw;
                height: 7.86vw;
                border-radius: 1.7vw;
                margin: 0.98vw 0px;
                cursor: pointer;
                object-fit: cover;
                object-position: top;
            }

            .product-text {
                line-height: 2.5vw;
                font-size: 1.8vw;
                margin-bottom: 2.2vw;
            }
            tr {
                padding: 5vw 2vw;
                border-top: 1px solid #E4E1D9;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

        }
        .shipping-charge,.sub-total{
            font-size: 2.0937vw;
            line-height: 3.25vw;
            text-align: left;
        }
        .price-text{
            font-size: 2.302vw;
    line-height: 2.51vw;
            text-align: right;
        }
        .shipping-section{
            clear: both;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 1.2vw 0;
            border-top: 1px solid #E4E1D9;
            .price-text{
                width: 25%;
            }
        }
        
        .voucher-section{
            display: flex;
            margin-top: 0vw;
            margin-bottom: 0vw;
        }
        .input-field {
            width: 17.5vw;
    height: 4.76vw;
    padding-left: 1.419vw;
    margin-right: 1.10989vw;
    margin-bottom: 0;
        }
        .voucher-text {
            font-size: 1.5vw;
            line-height: 2.1979vw;
        }
        .lightbtn{
            margin:0px !important;
            padding: 1vw 3vw !important;
            font-size: 1.5vw !important;
            line-height: 2.5vw !important;
            color:#fff !important;
        
        }
        .item-card{
            width: 75%;
            padding: 70px 25px 70px;
            margin: 0 auto;
            margin-top: 50px;
        }
        .item-heading,.payment-method,.credit-text{
            font-size: 2.5vw;
    line-height: 3.5vw;
            margin-bottom: 4vw;
        }
        .address{
            font-size: 1.9vw;
            line-height: 2.2vw;
            width: 220px;
            margin-bottom:2vw;
        }
        .paddingbottom{
            display: flex;
            justify-content: space-between;
        }
        .mobileshipping{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .shipping-text{
            font-size: 1.9vw;
            line-height: 2.2vw;
        }
        .addbill{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 5vw 0;
        }
        .billing-text{
            font-size: 1.9vw;
            line-height: 2.2vw;
        }
        .plus-img{
            cursor: pointer;
            width: 4.5vw;
            height: 4.5vw;
            margin-right: 8px;
        }
        .cardimg{
            width: 25%;
            height: auto;
            border: 0.4166vw;
            object-fit: contain;
            margin-right: 8vw;
        }
        .cardimgvisa{
            width: 25%;
            height: auto;
            border: 0.4166vw;
            object-fit: contain;
            margin-right: 8vw;
        }
        .mobilecard{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .mobilecardimgnum{
            display: flex;
            align-items: center;
        }
        .name-heading,.card-number{
            font-size: 1.9vw;
            line-height: 4.2vw;
            margin-bottom: 1vw;
            text-align: center;
        }
        .item-text,.multiple-text{
            font-size: 1.9vw;
            line-height: 2.2vw;
                margin-bottom: 1vw;
        }
        .available-text{
            font-size: 1.9vw;
            line-height: 2.2vw;
        }
        .card-price{
            font-size: 2.2vw;
            line-height: 2.6vw;
        }
        .total-text{
            font-size: 2.2vw;
            line-height: 2.6vw;
        }
        .total-price{
            font-size: 2.302vw;
    line-height: 2.51vw;
        }
        .total-section,.credit-text,.payment-method{
            margin-top:25px;
        }
    }
}


@media (min-width:1930px) and (max-width:3000px) {
    .checkout{
        .product-text{
            text-align: left;
            font-size: 0.833vw;
        
        }
        .buy-btn.lightbtn {
            padding: 0.7vw 2.05vw !important;
            font-size: 1.04vw !important;
            background-color: #949D8C;

        }
    }
}
@media (min-width:3001px) {
    .checkout{
        .addbill{
            margin:1vw auto;
        }
        .mobilecard{
            margin:1vw auto;
        }
        .product-text{
            text-align: left;
            font-size: 0.833vw;
        
        }
        .price-text{
            text-align: right;
        }
        .shipping-section .price-text{
            text-align: right;
        }
        .editimg {
            width: 1.6vw;
            height: 1.5vw;
        }
        .card-number, .name-heading{
            padding-left: 0.6vw;
        }
        .buy-btn.lightbtn {
            padding: 0.8vw 2.5vw !important;
            font-size: 1.08vw !important;
            background-color: #949D8C;
        }
        .checkoutimg{
            width: 4.40625vw;
            height: 4.71875vw;
            border-radius: 1vw;
        }
        .price-text, .quantity, .product-name1, .quantity1, .option-heading, .shipping-charge, .sub-total{
            font-size: 0.833vw;
        }
        .item-card {
            padding: 2.5vw 1.0833vw 1.914vw;
        }
        .MuiSvgIcon-root {
            width: 1.5vw;
            height: 1.5vw;
        }
    }   
}
