@import '../abstracts/variables';

.myorderseeall{
     padding: 3vw 0 0;
     margin-top: 0.9895vw;
    border-top: 4px solid #F5F4F3;
  .darkbtn {
        margin-top: 10px;
    }
    .myordertable,
    .saveditemtable {
        width: 100%;
    }
    .graycolor949d8ce3{
        font-weight: 500;
    }
    .product-text {
        font-size: 1.1458vw;
        line-height: 1.25vw;
        font-family: $gt-font-medium;
        color: #707070;
        opacity: 1;
        margin-right: 1.5625vw;
    }

    .table_btn {
        text-align: right;
    }

    .lightbtn,
    .darkbtn {
        min-width: 9.375vw !important;
    }

    img.myorderimg {
        width: 6.40625vw;
        height: 6.71875vw;
        object-fit: cover;
        box-shadow: 3px 3px 12px #00000029;
        border-radius: 1.04vw;
        opacity: 1;
        object-fit: cover;
    object-position: top;
    }

    .css-46bh2p-MuiCardContent-root {
        padding: 0px !important;
    }

    .myordertable {
        td.table_product_name {
            width: 13%;
        }
        td.table_product_img{
            width: 10%;
            // width: 6.625vw;
            // height: 6.71875vw;
        }
        tbody {
            width: 100%;
            display: block;
        }
        tr {
            padding: 1.5vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }


        .myorderimg {
            margin-right: 1.5625vw;
        }

        .table_btn {
            width: 13%;
            .darkbtn{
                margin-left:1.2vw !important ;
                margin-bottom: 1.134375vw;
            }
        }
    }
    .id-text {
        text-align: left;
        font-size: 1.1458vw;
        line-height: 1.302vw;
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-family: $gt-font-medium;
        letter-spacing: 0px;
        color: #707070;
        opacity: 1;
        margin-bottom: 0.255vw;
    }

    .id-price {
        text-align: left;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        font-weight: bold;
        font-variant: normal;
        font-style: normal;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #949D8C;
        opacity: 1;
    }
    .mobilemyhidebtn{
        display:revert !important;
    }
    .mobilemyshowbtn{
        display: none !important;
        justify-content: space-evenly;
    }

}

@media (min-width:280px) and (max-width:600px) {
    .myorderseeall{
        .lightbtn,
        .darkbtn {
            min-width: 9.375vw !important;
            font-size: 4.2vw !important;
            padding: 3vw 5vw !important;
        }

        .mobilesection {
            text-align: center;
        }

        .setlingmentbtn {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btncenter {
            text-align: center;
        }

        .credit-heading {
            text-align: center;
            font-size: 51px;
            line-height: 59px;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;

            .available {
                font-size: 16px;
                line-height: 18px;
                color: #707070;
                margin-left: 20px;
            }
        }
       
        .myordertable {
            .mobilemyhidebtn{
                display:none !important;
            }
            .mobilemyshowbtn{
                display: flex !important;
                justify-content: space-evenly;
            }
            table,
            tbody,
            tr,
            td {
                width: 100%;
                display: block;
            }

            td.table_product_img {
                width: 30%;
                float: left;
            }

            td.table_product_name, td.table_quantity {
                width: 70%;
                float: right;
            }

            td.orderid,td.paymentdate,td.paymentdate{
                width: 33%;
                float: left;
                margin:3vw 0;
            }
            .table_btn {
                width: 100%;
                clear: both;
                text-align: center;

            }
            .id-text,.id-price{
                font-size: 4vw;
                line-height: 5vw;
                
            }
             
            .myorderimg {
                width: 20.40625vw;
                height: 20.71875vw;
                border-radius: 11px;
                cursor: pointer;
            }

            .product-text {
                font-size: 4vw;
                line-height: 5vw;
                margin-bottom: 4vw;
            }
            tr {
                padding: 5vw 2vw;
            }

        }
    }
}

@media (min-width:601px) and (max-width:959px) {
    .myorderseeall{
        .lightbtn,.darkbtn{
            font-size: 1vw!important;
            line-height: 1vw!important;
            min-width: 8vw!important;
            padding: 1.2vw 1vw!important;
        }
        .available{
            font-size: 14px;
            line-height: 16px;
        }
        .alignsection{
            align-items: center;
        }
        .trending-heading{
            margin:1vw 0 !important;
        }
        img.myorderimg {
            width: 13.2vw;
            height: 13.2vw;
        }
        .myordertable{
            .table_product_img{
                width:20%;
            }
            .product-text{
                font-size: 1.5vw;
                line-height: 1.8vw;
            }
            .lightbtn,.darkbtn{
              
                margin: 7px 0 !important;
            }
            .id-text,.id-price {
                font-size: 1.5vw ;
                line-height: 1.6 ;
                padding-left:2vw;
           
        }
        .css-1869usk-MuiFormControl-root{
            min-width: auto;
        }
        .quantity-btn-multi{
            margin-left:0  !important;
        }
        }
    }
}
@media (min-width:1930px) and (max-width:6000px) {
    .myorderseeall{
    .number-card {
        right: 62.5%;
    }
    .num {
        width: 2vw;
        padding-top: 0.4vw;
        font-size: 0.95vw;
    }
    .lightbtn,.darkbtn {
        min-width: 10vw !important;
        font-size: 0.9vw !important;
        line-height: 1vw !important;
    }
    
    img.myorderimg {
        width: 4.40625vw;
        height: 4.71875vw;
    }
    .product-text{
        font-size: 0.833vw;
        line-height: 1.4vw;
        margin-right: 0.5625vw;
    }
    .quantity {
        text-align: center;
        align-items: center;
        display: flex;
        font-size: 0.9vw;
    }
    .quantity-btn-multi{
        width: 3.5vw;
        height: 2vw;
        margin-left: 0;
        font-size: 0.9vw;
    }
    .trending-heading{
        font-size: 1.9vw;
        line-height: 2.2vw;

    }
    .myordertable{
        .table_btn .buy-btn,.table_btn .darkbtn {
            margin-left: 0.8vw !important;
        }
        .lightbtn,.darkbtn {
            min-width: 8vw !important;
            font-size: 0.9vw !important;
            line-height: 1vw !important;
        }
        .lightbtn{
            margin-bottom: 15px;
        }
        .table_btn {
            width: 15%;
        }
    }
}
}
@media (min-width:601px) and (max-width:959px) {
    .myorderseeall{
   img.myorderimg {
            width: 8.40625vw;
            height: 8.71875vw;
    }   
}
}
