// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
@import '../abstracts/variables';

.basket-btn,
.buy-btn {
  border-radius: $btn-border-radius !important;
  color: $gray-btn-color !important;
  font-family: $asap-font-regular !important;
  font-size: 1.05vw !important;
  opacity: $opcity;
  font-style: $btn-font-style;
  font-variant: $btn-font-variant;
  text-transform: capitalize !important;
  // padding:0.729vw 1.165vw !important;
  padding: 0.9vw 1.165vw !important;
  line-height: 1.25vw !important;
  font-weight: normal !important;
  min-width: 7.986vw !important;
  letter-spacing: 0px !important;
  min-height: 2.864vw;
}

.basket-btn.darkbtn {
  background-color: $btn-background-black ;

  &:hover {
    background-color: $btn-background-black ;
  }
}

.button-section {
  display: flex;
  justify-content: space-between;
}

.buy-btn.lightbtn {
  background-color: $btn-background-gray;

  &:hover {
    background-color: $btn-background-gray;
  }
}

.catbtn {
  background: #F4F5F6 !important;
  border-radius: 2.5vw !important;
  opacity: 1;
  z-index: 999;
  color: #000000 !important;
  width: auto !important;
  position: absolute !important;
  top: 0.8vw;
  right: 0.8vw;
  font-family: "Asap" !important;
  font-weight: 500 !important;
  font-style: normal;
  font-size: 1.0937vw !important;
  line-height: 1.25vw !important;
  text-align: center;
  text-transform: capitalize !important;
  padding: 0.701vw 0.81vw !important;
  display: none;
}

.product-btn,
.retailers-btn {

  border-radius: $btn-border-radius !important;
  font-family: $asap-font-regular !important;
  font-size: 1.0937vw !important;
  opacity: $opcity;
  font-style: $btn-font-style;
  font-variant: $btn-font-variant;
  text-transform: capitalize !important;
  padding: 0.9vw 1.165vw !important;
  line-height: 1.25vw !important;
  font-weight: normal !important;
  min-width: 8.541vw !important;
}

.product-btn {
  background-color: $background-F5F5F5 !important;
  color: #CCCCCC !important;
  margin-right: 1.04vw !important;
}

.retailers-btn {
  background-color: $btn-background-gray !important;
  color: #F5f4f3 !important;
}

@media only screen and (max-width: 600px) {

  .basket-btn,
  .buy-btn {
    opacity: 1;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 24px !important;
    line-height: 27px !important;
    border-radius: 40px !important;
    margin: 15px 0 !important;
    padding: 13px 33px !important;
  }

  .catbtn {
    font-size: 16px !important;
    line-height: 21px !important;
    border-radius: 20px !important;
    padding: 10px 13px !important;
  }

  .homepage27 .basket-btn,
  .buy-btn {
    font-size: 11.99px !important;
    line-height: 13.74px !important;
    // margin: 16px 0 !important;
    // padding: 3vw 5vw !important;
    padding: 8.64px 20px !important;
    min-height: 31.65px !important;
    min-width: 85.8px !important;
    margin: 16px 10px 16px 0px !important;
  }

  .homepage27 {
    .latestArrivals {

      .basket-btn,
      .buy-btn {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

    }
  }
}



@media (min-width:601px) and (max-width: 920px) {

  .basket-btn,
  .buy-btn {
    opacity: 1;
    width: auto;
    height: auto;
    text-align: center;
    font-size: 18px !important;
    line-height: 19px !important;
    border-radius: 40px !important;
    margin: 15px 0 !important;
    padding: 12px 23px !important;
  }
}


@media only screen and (max-width: 413px) {

  .shopcollectionsection {

    .basket-btn,
    .buy-btn {
      margin: 0px 0 15px !important;
    }
  }

  .basket-btn,
  .buy-btn {
    font-size: 6vw !important;
    line-height: 7vw !important;
    border-radius: 40px !important;
    margin: 15px 0 !important;
    padding: 3vw 5vw !important;
    min-width: 125px !important;
  }
}

@media only screen and (min-width: 1921px) {

  .basket-btn,
  .buy-btn {
    font-size: 0.8vw !important;
    // min-width: auto !important;
    padding: 0.729vw 1.6vw !important;
  }
}

@media only screen and (min-width: 3000px) {

  .basket-btn,
  .buy-btn {
    padding: 0.29vw 1vw !important;

  }
}

@media (min-width:1000px) and (max-width:1300px) {

  .basket-btn.darkbtn,
  .buy-btn.lightbtn {
    font-size: 13px !important;
    line-height: 16px !important;
    padding: 12px 20px !important;
  }
}