.main-heading40.trending-heading{
    letter-spacing: 0px;
    color: #0a0a0a;
    margin-bottom:1.04vw;
    margin-top: 0px;
    font-weight:normal;
    font-family: 'GT-Super-Text-Regular';
}


