.notfound {
    padding-top: 6vw;
    width: 80%;
    margin: auto;
    text-align: center;
    padding-bottom: 7.5vw;

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    
    .basket-btn.darkbtn{
        margin-top: 4vw !important;

    }
}

@media (min-width:280px) and (max-width:600px) {
    .notfound {
        padding-top: 50px;
        width: 90%;
        margin: auto;
        text-align: center;
        padding-bottom: 50px;
    }
}

@media (min-width:1930px) and (max-width:6000px) {
    .notfound {     
    padding-top: 5vw;
        p {
            margin-top: 1vw;
            margin-bottom: 1vw;
            font-size: 1.2vw;
        }
        h1 {
            font-size: 4vw;
            margin-bottom: 3vw;
        }
        .basket-btn.darkbtn {
            margin-top: 3vw;
            font-size: 1vw !important;
            line-height: 2vw !important;
            background-color: #363240;
        }
    }
}