.subtableskeleton{
    .tbimg{
        width:10vw;
        height:10vw;
        border-radius: 2.08vw;
    }
    .proname{
        .name{
            width:12vw;
            height:2.5vw;
        }
        .price{
            width: 8vw;
            height: 2.5vw;
        }
    }
    .provariant{
        .title{
            width: 8vw;
            height: 2.5vw;
        }
        .value{
            width:7vw;
            height: 2vw;
        }
    }
    .probtn{
        width: 30%;
    }
    .btnset{
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .btnbox{
        width:12vw;
        height: 3.5vw;
        border-radius: 2.08vw;
        margin-left:1vw;
        
    }
}

@media (min-width:280px) and (max-width:600px) {
    .subtableskeleton {

        table,
        tr,
        td {
            width: 100%;
            display: block;
        }

        .proimg {
            width: 24% !important;
            float: left;
        }

        .proname,
        .proqty
         {
            width: 76% !important;
            float: right;
        }
        .tbimg {
            width: 18vw;
            height: 18vw;
            border-radius: 4.08vw;
        }
        .proname{
            height: 22vw;
        }
        .proname .price {
            width: 23vw;
            height: 6.5vw;
            margin-bottom: 2.5vw;
        }
        .provariant{
            width:33%;
            float: left;
            margin-bottom: 3.5vw;
            .title {
            width: 20vw;
            height: 5.5vw;
        }
        .value {
            width: 15vw;
            height: 5vw;
        }
        }
        
        .proname .name {
            width: 57vw;
            height: 5.5vw;
        }
        .provariant{
            margin-bottom: 2.5vw;
        }
        .probtn{
            display: flex;
        }
        .proqty{
            margin-bottom: 2.5vw;
        }
        .probtn{
            width: 100%;
        }
        .btnbox {
            width: 38vw;
            height: 9vw;
            border-radius: 12.08vw;
            margin-right:3vw;
        }
    }
}