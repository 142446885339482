@import '../abstracts/variables';

.image-banner-home {
  .slick-arrow {
    display: none !important;
  }
  .mainbannerimg{
    width:100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .image-section{
    position: absolute;
    top: 0;
  }
  
.bannercursor{cursor: pointer;}
  .img-part {
   
    // background: url("../../assets/images/homePage/banner-image-1.png");
    opacity: 1;
    background-size: cover;
    height: 24.47vw;
    font-size: 34px;
    line-height: 39px;
    margin-top: 0.833vw;
    background-color: #f0f0f0;
  }
  .image-section {
    padding: 2.75vw 0 2.75vw 0vw;
  }

  .buy-heading {
    text-align: left;
    font-size: 3.541vw;
    line-height: 4.11vw;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: $gt-font-regular;
    letter-spacing: 0px;
    color: #2f2b23;
    margin-bottom: 1.61vw;
    padding-right: 218px;
  }

  .buy-para {
    text-align: left;
    font-weight: normal;
    color: #2f2b23;
    opacity: 1;
    margin-bottom: 3.385vw;
    padding-right: 231px;
    margin-top: 0;
  }

  .browse-btn {
    text-align: center;
    font-size: 1.093vw;
    line-height: 1.25vw;
    font-weight: normal;
    width: auto;
    height: auto;
    padding: 0.833vw 2.03vw;
    background: #949D8C;
    border-radius: 2.5vw;
    border: none;
    color: #FFF;
    min-width: 12vw;
    padding: 0.833vw 2.03vw;
  }

  .slick-dots {
    bottom: 7px;
    width: 100%;
    margin: 0 0px 0px -4vw;
    text-align: end;
  }

  .slick-dots li {
    margin: 0px;
  }

  .slick-dots li.slick-active button:before {
    color: #ffffff;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #ffff;
  }

  .slick-dots li button:before {
    font-size: 0.729vw;
    line-height: 0.729vw;
    opacity: 1;
    color: #00000029;
  }
}

@media only screen and (max-width: 600px) {
  .image-banner-home {
    .slick-dots li button:before {
      font-size: 12px;
      line-height: 13px;
    }
    .browse-btn {
      font-size: 20px;
      line-height: 24px;
      border-radius: 40px;
      min-width: 120px;
      padding: 10px 30px;
    }
    .mainbannerimg {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      display: block;
  }
    .img-part {
      min-height: 312px;
    }

    .buy-heading {
      font-size: 36px;
      line-height: 38px;
      margin-bottom: 20px;
      padding-right: 0;
    }

    .buy-para {
      margin-bottom: 20px;
      padding-right: 0;
      font-size: 18px;
      line-height: 18px;
    }
  }
}

@media only screen and (max-width: 413px) {
  .image-banner-home {
    .image-section {
      padding: 7.75vw 0 2.75vw 0vw;
    }

    .img-part {
      min-height: 81vw;
    }

    .buy-heading {
      font-size: 8vw;
      line-height: 9vw;
      margin-bottom: 4vw;
      padding-right: 0;
    }

    .buy-para {
      margin-bottom: 20px;
      padding-right: 0;
      font-size: 4vw;
      line-height: 5vw;
    }
  }
}

@media (min-width: 601px) and (max-width: 959px) {
  .image-banner-home {
    .buy-heading {
      font-size: 30px;
      line-height: 35px;
    }

    .buy-para {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 2vw;
    }
    .img-part {
      height: 28.47vw;
    }
  }
}
@media (min-width: 920px) and (max-width: 1280px) {
  .image-banner-home{
    .img-part{
      margin-top: 0px;
      height: 33.8vw;
    }
  }
}
@media only screen and (min-width: 1931px) {
  .image-banner-home .slick-dots li {
    width:2vw;
    height: 1vw;
}

}