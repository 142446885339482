.login-checkout-new {
    .login-section {
        max-width: 33%;
        margin: auto;
        padding-top: 7.1527vw;
        text-align: center;
        padding-bottom: 35.069vw;

    }
.MuiCheckbox-root {
    padding: 0 !important;
}
    ::-webkit-input-placeholder {
        /* Edge */
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    ::placeholder {
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    .MuiInputLabel-formControl {
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    .logocheckout {
        width: 10.2vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        cursor: pointer;
        margin-bottom: 0.85vw;
    }

    .login-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8055vw;
        line-height: 2.0833vw;
        color: #000000;
        margin-bottom: 3.888vw;
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root, .MuiFormControl-root {
        min-width: 85%;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        margin-bottom: 0.75vw;
    }

    .text-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.66666vw;
        padding-left: 16px;

        // svg.MuiSvgIcon-root {
        //     color: #fff;
        //     border: 1px solid #8b8b8b;
        //     border-radius: 4px;
        //     width: 22px;
        //     height: 22px;
        //     margin-right: 5px;
        // }

        // .Mui-checked svg {
        //     border: none;
        //     border-radius: 4px;
        //     color: #8b8b8b;
        //     width: 24px;
        //     height: 24px;
        //     font-size: 24px;
        // }
    }

    .login-checkout {
        margin-bottom: 1.3888vw;
    }

    .forgot-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.1158vw;
        line-height: 1.3194vw;
        border-bottom: 0.1px solid #A0A0A0;
        /* text-decoration: underline; */
        color: #A0A0A0;
        opacity: 0.7;
        letter-spacing: 0.2px;
        line-height: 1.3194vw;
        color: #A0A0A0;
        cursor: pointer;
    }

    .keep-text .MuiTypography-body1 {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.1458vw;
        line-height: 1.3194vw;
        color: #2F2B23;
        padding-left: 0.5vw;
    }

    .logging-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.1458vw;
        line-height: 1.594vw;
        color: #a0a0a0c7;
        letter-spacing: 0.2px;
        margin-bottom: 3.1944vw;

        span {
            // text-decoration-line: underline;
            border-bottom: 1px solid #2F2B23;
            color: #2F2B23;
            cursor: pointer;
            margin-left: 5px;
        }
    }

    .darkbtn {
        min-width: 22.2916vw !important;
        min-height: 4.375vw;
        font-size: 1.111vw !important;
        margin-bottom: 0.5vw;
        color: #FFFFFF !important;
        background-color: #2F2B23;
        font-weight: 500 !important;
        text-transform: inherit !important;
        letter-spacing: 0.2px;
    }

    // .inputiconme {
    //     position: absolute;
    //     margin-top: 1vw;
    //     right: 2vw;
    //     color: #74CE83 !important;
    //   }

      .inputiconme{
        display: none;
    }
       
    .MuiInputLabel-root {
        font-size: 1.12vw;
        line-height: 1.5vw;
        left: 7px;
    }

    .MuiInputLabel-root.Mui-focused {
        color: #777777 !important;
        font-size: 1.1vw;
        line-height: 1.4vw;
        
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #2F2B23 !important;
        border: 1px solid !important;
        border-width: 1px !important;
    }

    .MuiOutlinedInput-input {
        min-width: 17vw;
        min-height: 3.55vw;
        padding: 0 20px;
        font-family: "Asap";
    }
    legend{
        span{
            font-size: 13px;
        }
    }
    .MuiOutlinedInput-root {
        border-radius: 1.0416vw;
        min-height: 3.8vw;
    }

    .legend {
        height: auto;
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #A6A194;
        border-radius: 1.04vw;
    }

    // .inputicon .MuiSvgIcon-root {
    //     display: none;
    // }

    .inputicon {
        position: relative;
    }

    .visibility-icon {
        left: 84% !important;
        position: absolute;
        top: 1.2vw;
        svg{
            font-size: 1.6vw;
        }
    }
}
@media (min-width: 930px) and (max-width: 1280px) {
    .login-checkout-new {
// .MuiInputLabel-root {
//     font-size: 1.22vw;
//     line-height: 2.2vw;
//     left: 1px;
// }
.MuiInputLabel-root {
    font-size: 1.12vw;
    line-height: 1.5vw;
    left: 7px;
}
.MuiOutlinedInput-input {
    min-height: 4.8vw;
}
.MuiInputLabel-root.MuiInputLabel-shrink{
  font-size: 1.2vw;
line-height: 1vw !important;
left: 2px;
top: 4px;
}
legend span {
    font-size: 9px;
}
.visibility-icon svg {
    font-size: 2.2vw;
}
.visibility-icon {
    top: 1vw;
}
    }
}
@media (min-width: 2000px) {
    .login-checkout-new {
        .login-section {
            max-width: 43%;
        }
        // .MuiOutlinedInput-input {
        //     min-width: 100%;
        // }
        legend{
            span{
                font-size: 1vw;
            }
        }
        input:-internal-autofill-selected {
            background-color: transparent;
        }
        input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 46px white inset !important;
    color: #949d8c;
}
        .text-section {
            svg.MuiSvgIcon-root {
                width: 1.5em;
                height: 1.5em;
            }

            .Mui-checked svg {
                width: 1.5em;
                height: 1.5em;

            }
        }

        .MuiSvgIcon-root {
            fill: currentColor;
            width: 1.5vw;
            height: 1.5vw;
        }
   
        .MuiOutlinedInput-input {
            font-size: 1vw;
            padding-left: 1vw;
        }

        .darkbtn {
            font-size: 1.05vw !important;
        }
        .MuiInputLabel-root {

            font-size: 1.12vw;

            line-height: 2.5vw;

            left: 7px;

        }

        .MuiInputLabel-root.MuiInputLabel-shrink{

        font-size: 1.12vw;

        line-height: 1.2vw !important;

        left: 7px;

    }
    }
}
@media (min-width: 2800px)  and (max-width:3990px){
    .login-checkout-new {
        .login-section {
            max-width: 45%;
        }
    }
}

@media (min-width: 4000px){
    .login-checkout-new {
        .login-section {
            max-width: 36%;
        }
    }
}