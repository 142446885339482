// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
@import '../abstracts/variables';

.shopbyvalue {
    .shop-para {
        text-align: center;
        color: #84848496;
        opacity: 1;
        padding: 1.33vw 23.49vw 1.995vw;
    }

    .blackcolor2F2B23 {
        font-weight: 600;
    }

    .shopbyvaluecard .shop-icon {
        margin-bottom: 0.781vw;
    }

    .sectionpadding3p5vw {
        padding: 2.7505vw 0 2.9281vw;
    }
}



//dynamicContent
.dynamiccontent {
    .headingmarbtm {
        margin-bottom: 3.075vw;
    }
}


//shop collection section

.shopcollectionsection {
    .shopcollection {
        padding: 0 0.5vw;
    }
}

// favouritebrands
.favouritebrands {
    background-color: #F5F4F2;

    .mobilebgfav {
        display: none;
    }

    .shopcollection {
        padding: 0 0.85vw;
    }

    .desktopbgfav {
        background: url('../../assets/images/home/Favbrandline.png');
        background-size: 100%;
        background-position-y: 12vw;
        background-repeat: no-repeat;
        //    min-height: 35.49vw;
    }

    .fav-para {
        font-family: $asap-font-regular;
        font-size: 1.25vw;
        font-weight: 400;
        line-height: 1.5104vw;
        letter-spacing: 0em;
        text-align: left;
        color: #50565C;
        padding-right: 49vw;
        margin: 0 0 4.835vw;
    }

    .sectionpadding3p5vw {
        /* padding: 3.5vw 0; */
        padding: 4.2vw 0 4.2vw;
    }

    .trending-heading {
        margin-bottom: 1.875vw;
    }
}

//from the gram

.fromthegram {
    .shopcollection {
        padding: 0 0.85vw;
    }

    .shopcollection .img-row {
        height: 12.6vw;

    }

    .shopcollection .unpublished {
        display: none;
    }

    .sectionpadding3p5vw {
        padding: 4.75vw 0 8.565vw;
    }

    .trending-heading {
        margin-bottom: 3.3177vw;
    }
}

//latest blog

.blogsection {
    .trending-heading {
        margin-bottom: 2.7635vw;
        margin-left: 2vw;
    }
}

//shop by value 
.shopbyvaluecard {
    .shop-icon {
        width: 7.9vw;
        height: 8.02vw;
        margin: 0 auto;
        border-radius: 50%;
    }

    .icon-name {
        text-align: center;
        font-size: 0.968vw;
        line-height: 1.3vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: #302d37;
        opacity: $opcity;
    }

    .icon-name {
        color: #949d8cf2;
        ;
    }


}

.shopbyvalue {
    background-size: cover;
    width: 100%;
    background-position: center;
    object-fit: cover;

    .graycolor949d8ce3 {
        font-weight: 500 !important;
    }

    .blackcolor2F2B23 {
        //  font-weight: 600 !important;
    }
}

//testimonials
.testimonials {
    padding: 3.836vw 0 4.595vw;

    .MuiSvgIcon-root {
        color: #363240 !important;
    }

    .slick-list {
        margin: 0 30px;
    }

    .trending-heading {
        margin-bottom: 3.443vw;

    }

    .slick-dots {
        display: none !important;
    }
}

//TestimonialsCard 
.testimonials-card {
    padding: 0px 1.3%;

    .MuiSvgIcon-root {
        width: 1.51vw;
        height: 1.406vw;
        margin-right: 0.05vw;
    }

    .star-section {
        margin-bottom: 1vw;
    }

    .trustpilot {
        height: 1.718vw;
        height: auto;
        object-fit: contain;
        width: 7.1458vw;
    }

    .card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 2.0833vw;
        opacity: 1;
        display: inline-block;
        margin-right: 0px !important;
        margin: 0px 1.3%;
        padding: 2.395vw 5.052vw 1.927vw 5.1vw;
        display: inline-block;
        min-height: auto;
    }

    .inner-card {
        text-align: center;
    }

    .name-text {
        text-align: $textcenter;
        font-size: 1.354vw;
        line-height: 1.562vw;
        font-weight: normal;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        // color: $blackcolor2F2B23;
        color: #0A0A0A;
        opacity: 1;
        margin-bottom: 1.02vw;
        text-transform: lowercase;
        margin-top: 0;
    }

    .name-text::first-letter {
        text-transform: uppercase;
    }

    .name-para {
        text-align: center;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        font-family: "Asap";
        // color: #2F2B23;
        color: #0A0A0A;
        opacity: 1;
        margin-bottom: 1.187vw;
        margin-top: 0;
    }

    .last-text {
        display: flex;
        justify-content: center;
    }
}

//news letter section
.newslettersection {
    .buy-btn.lightbtn {
        color: #fffffff2 !important;
    }

    .buy-btn.lightbtn:hover {
        color: #fffffff2 !important;
    }

    .close-section {
        display: flex;
        justify-content: flex-end;
    }

    .btn-email-section {
        display: flex;
        justify-content: space-between;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
        color: #949d8cba;
        font-weight: 500;
    }

    .MuiSvgIcon-root {
        color: #949d8cba;
        font-weight: 500;
    }

    .newsletter-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.01);
        border-radius: 2.083vw;
        opacity: 1;
        padding: 0.780vw 1.0088vw 1.177vw 0.890vw;
    }

    .newslatter-section {

        justify-content: end;
        position: fixed;
        bottom: 23px;
        right: 2.58vw;
        z-index: 1;
    }

    .news-heading {
        display: flex;
        justify-content: center;
        font-size: 1.3vw;
        line-height: 1.61vw;
        font-weight: 600;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        letter-spacing: 0.5px;
        color: #70706ee0;
        opacity: 0.9;
        margin-bottom: 0.463vw;
        margin-left: -16px;
    }

    .news-heading::first-letter {
        text-transform: uppercase !important;
    }

    .plant-heading {
        text-align: center;
        font-family: $asap-font-regular;
        font-size: 1.08vw;
        font-weight: 500;
        line-height: 1.0937vw;
        font-style: $btn-font-style;
        font-variant: $btn-font-variant;
        letter-spacing: $letter-spacing;
        color: #70706ed9;
        opacity: 0.8;
        margin-bottom: 1.1vw;

    }

    .input-field {
        width: 19.94vw;
        margin-right: 0.9822vw;
        font-size: 1.293vw;
        line-height: 1.25vw;
        font-weight: 500;
        margin-bottom: 0px;
        height: 2.9vw;
        color: #707070;
        opacity: 0.8;
    }

    .input-error-message {
        margin-left: 10px;
    }

}

@media only screen and (max-width: 280px) {
    .fromthegram .shopcollection .img-row {
        height: 80vw !important;
    }

    .shopbyvalue .shop-para {
        font-size: 14px !important;
        line-height: 18px !important;
    }

    .testimonials .trending-heading {
        font-size: 8vw !important;
        line-height: 9vw !important;
    }

    .testimonials-card .name-para {
        font-size: 14px !important;
        line-height: 18px !important;
    }
}

@media only screen and (max-width: 600px) {
    .favouritebrands .fav-para {
        font-size: 17px;
        line-height: 18.84px;
        padding: 0;
    }
    .testimonials-card {
        padding: 0px 2.5%;
    }
    .favouritebrands {
        position: relative;
        overflow: hidden;
    }

    .favouritebrands .mobilebgfav {
        object-fit: cover;
        position: absolute;
        width: 100%;
        bottom: 0;
        display: block;
        height: 66%;
    }

    .favouritebrands .desktopbgfav {
        background: none;
    }

    .scrollenable {
        .slick-list {
            overflow-x: scroll;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
        }

        .slick-track {
            // transform: none !important;
            transition: ease-in;
            // transform-origin: revert-layer !important;
        }

        .slick-list::-webkit-scrollbar {
            display: none;
        }
    }

    .testimonials {
        
        .trending-heading {
            font-size: 34px;
            line-height: 39px;
            text-align: center !important;
        }

        .slick-dots {
            display: block !important;
        }

        .slick-slider {
            margin-bottom: 0px;
        }

        .slick-dots li button:focus:before,
        .slick-dots li button:hover:before {
            opacity: .25;
            color: #000;
        }

        .slick-dots {
            bottom: -36px;
        }

        .MuiContainer-root {
            padding-left: 18px;
            padding-right: 20px;
            margin-bottom: 36px;
        }

        .slick-dots li button:before {
            font-size: 10px;
        }

        .slick-dots li {
            margin: 0px;
            width: 15px;
            height: 20px;
            margin: 0;
        }

        .slick-dots li.slick-active button:before {
            color: #2F2B23;
        }

        .slick-dots li.slick-active button:before {
            // opacity: 1;
            // color: #ffff;
            color: #2F2B23;
            // opacity: 0.75;
        }

        .slick-dots li button:before {
            color: #757061;
        }

    }

    //news letter
    .newslettersection {
        .newslatter-section {
            right: 2.58vw;
            left: 2.58vw;
        }

        .newsletter-card {
            padding: 19px 19px;
            border-radius: 20px;
        }

        .news-heading {
            font-size: 20px;
            line-height: 23px;

        }

        .plant-heading {
            font-size: 15px;
            line-height: 17px;
            margin-bottom: 15px;
        }

        .btn-email-section {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            align-items: baseline;
        }

        .newsletter-card .mat-icon {
            font-size: 18px;
            line-height: 18px;
        }

        .input-field {
            width: 55vw;
            padding: 11px 0px 11px 14px;
            border-radius: 20px;
            font-size: 16px;
            line-height: 18px;
            height: 43px;
        }

        .buy-btn.lightbtn {
            width: auto;
            height: auto;
            border-radius: 20px !important;
            font-size: 16px !important;
            padding: 11px 22px !important;
            line-height: 18px !important;
            min-width: 100px !important;
        }
    }
}

@media only screen and (max-width: 280px) {
    .newslettersection .buy-btn.lightbtn {
        font-size: 14px !important;
        padding: 11px 0px !important;
        line-height: 18px !important;
        min-width: 73px !important;
    }

    .newslettersection .news-heading {
        font-size: 16px;
        line-height: 20px;
    }

    .newslettersection .plant-heading {
        font-size: 13px;
        line-height: 15px;
    }
}

@media only screen and (max-width: 919px) {


    .shopbyvalue {
        background-color: #f0f0f0;

        .shop-para {
            padding: 20px 2px 28px;
            font-size: 17px;
            line-height: 24px;
            margin-top: 11px;
            font-weight: 400;
            color: #848484;
        }

        .shop-icon {
            width: 83px;
            height: 82px;
        }

        .icon-name {
            font-size: 12px;
            line-height: 14px;
            margin: 8px 0;
        }
    }

    //latest blog
    .blogsection {

        .slick-next,
        .slick-prev {
            top: 25% !important;
        }

        .trending-heading {
            text-align: center;
        }
    }


    //testimonials
    .testimonials {

        // padding-bottom: 60px;
        .slick-arrow {
            display: none !important;
        }

        .slick-list {
            margin: 0 0px;
        }
    }

    .testimonials-card {
        .card {
            margin: 0px;
            width: 100%;
            // padding: 25px 24.85px 30px !important;
            padding: 25px 36px 30px 38px!important;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 40px;
        }

        .MuiSvgIcon-root {
            font-size: 25px;
            margin-right: 0px;
            // margin-bottom: 10px;
            width: 25px;
            height: 25px;
        }

        .star-section {
            margin-bottom: 0vw;
        }

        .name-text {
            font-size: 16px;
            margin-bottom: 10.55px;
            line-height: 22px;
        }

        .name-para {
            text-align: center;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            font-family: $asap-font-regular;
            letter-spacing: 0px;
            color: #2F2B23;
            opacity: 1;
            // font-size: 17px;
            // line-height: 21px;
            // margin-bottom: 20px;
            font-weight: 500;
            font-size: 12.182px;
            line-height: 16px;
            color: #0A0A0A;
        }

        .last-text img {
            width: 38% !important;
            image-rendering: -moz-crisp-edges;
            image-rendering: -o-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;
            -ms-interpolation-mode: nearest-neighbor;
        }

    }

    // form the gram
    .fromthegram {

        .slick-next,
        .slick-prev {
            top: 50% !important;
        }

        .slick-arrow {
            top: 50% !important;
        }

        .shopcollection .img-row {
            height: 46vw;
            border-radius: 20px;
        }
    }

}

@media only screen and (max-width: 413px) {
    .shopbyvalue {
        .shop-para {
            font-size: 16px;
            line-height: 21px;
            padding: 20px 0 28px;
        }

        .shop-icon {
            width: 21vw;
            height: 21vw;
        }

        .icon-name {
            font-size: 12px;
            line-height: 14px;
            margin: 8px 0;
            color: #949d8cf2;
        }
    }
}

@media (min-width: 601px) and (max-width:919px) {
    .newslettersection {
        .news-heading {
            font-size: 14px;
            line-height: 18px;
        }

        .plant-heading {
            font-size: 12px;
            line-height: 14px;
        }

    }
}

@media (min-width: 1930px) and (max-width:3000px) {
    .homepage27 div.all-screen-container {
        max-width: 55% !important;
    }
}

@media only screen and (min-width: 1930px) {
    .shopbyvalue {
        .shop-para {
            padding: 1.33vw 12.49vw 1.995vw;
        }

        .shopbyvaluecard {
            padding: 6%;
        }

        .shop-icon {
            width: 100%;
            height: 5.5vw;;
            margin: 0 auto;
        }

    }

    //testimonial card
    .testimonials-card {
        padding: 0px 0.5vw;

        .card {
            margin: 0px 0%;
            padding: 2.395vw 1.052vw 1.927vw 1.1vw;
        }

        .MuiSvgIcon-root {
            width: 2vw;
            height: 2vw;
            margin-bottom: 1vw;
        }
    }

    //from the gram
    .fromthegram {
        .shopcollection {
            height: 12vw;
            border-radius: 2.083vw;
            padding: 0 0.5vw;
        }
    }

    .homepage27 {
        .all-screen-container {
            max-width: 2200px !important;
        }

        .productcard {
            // .img-row1 {
            //     height: 8vw !important;
            // }
        }

        .shopbyvalue {
            .shop-para {
                padding: 1.33vw 0vw 1.995vw;
            }

            .icon-name {
                font-size: 0.568vw;
                line-height: 0.68vw;
            }
        }

        .main-heading40 {
            font-size: 1.5vw;
            line-height: 2vw;
        }

        .basket-btn,
        .buy-btn,
        .dynamiccontent .basket-btn,
        .dynamiccontent .buy-btn {
            font-size: 0.7vw !important;
            line-height: 1.25vw !important;
            min-width: 5.5vw !important;
            min-height: 2.45vw;
        }

        .dynamiccontent .headingmarbtm {
            margin-bottom: 1.3vw;
        }

        .latestArrivals .latest-para {
            padding-right: 0vw;
        }

        .latestArrivals .shopbyvaluecard .shop-icon {
            height: 8.5vw;
            border-radius: 1.1vw;
        }

        .latest-section .MuiGrid-containe {
            align-items: center;
        }

        .latestArrivals .shopbyvaluecard .icon-name {
            line-height: 1.2vw;
            font-size: 0.9vw;
        }

        .our-story .about-para {
            margin-bottom: 2vw;
        }

        .shopcollection .img-row {
            height: 8.5vw !important;
            border-radius: 1vw;
        }

        .shopcollectionsection .shopcollection {
            padding: 0 0.2vw;
        }

        .shopcollection .unpublished {
            font-size: 0.56vw;
            max-width: 6vw;
            padding: 0.5vw 0vw;
            bottom: 2.2vw;
            line-height: 0.8vw;

        }

        .testimonials-card {
            padding: 0px 0.3vw;
        }

        .testimonials-card .name-para {
            text-align: center;
            font-size: 0.85vw;
            line-height: 1vw;
        }

        .testimonials-card .MuiSvgIcon-root {
            margin-bottom: 0;
        }

        .fromthegram .shopcollection {
            height: auto;
            border-radius: 1vw;
            padding: 0 0.2vw;
        }

        .blogsection .mobilesliderdesign {
            margin-top: 2vw;
        }

        .blogsection .trending-heading {
            margin-bottom: 0vw;
            margin-left: 1vw;
            color: #000000;
        }

        .blogcard .img-row {
            border-radius: 1.5833vw;
            height: 9vw;
        }

        .blogcard .card-heading {
            font-size: 1.1vw;
            line-height: 1.3vw;
            margin-top: 2vw;
            margin-bottom: 1.2744vw;
            padding: 0px 0vw;
        }

        .blogcard .card-para {
            padding: 0;
            font-size: 0.8vw;
            line-height: 1vw;
        }

        .testimonials .main-heading40.trending-heading {
            margin-bottom: 1.8vw;
        }

        .productcard .product-name1,
        .productcard .product-price1 {
            line-height: 1.1vw;
        }

        .latestArrivals .main-heading40.trending-heading {
            font-size: 1.4vw;
            margin-bottom: 1.5vw !important;
        }

        .latestArrivals .desktopbg {
            background-position-x: 38vw;
            background-position-y: 6vw;
            background-repeat: no-repeat;
            background-size: 45%;
        }

        .latestArrivals .shopbyvaluecard {
            padding: 0 0.2vw;
        }

        .our-story .about-section {
            padding: 3.932vw 0 3.78541vw;
        }

        .image-section-six {
            height: 20.802vw;
            margin: 2.125vw auto 1.7928vw auto;
        }

        .slick-prev:before,
        .slick-next:before {
            width: 0.6vw;
            height: 1vw;
        }

        .blogsection .slick-next,
        .blogsection .slick-prev {
            top: 25%;
        }

        .dynamiccontent {

            .slick-next,
            .slick-prev {
                top: 32%;
            }
        }

        .productcard .basket-btn,
        .productcard .buy-btn,
        .dynamiccontent .basket-btn,
        .dynamiccontent .buy-btn {
            min-height: 2.45vw;
            min-width: 5.5vw !important;
            font-size: 0.7vw !important;
            padding: 0 !important;
        }

    }

    .favouritebrands .fav-para {
        padding-right: 13vw;
    }
}

@media (min-width:920px) and (max-width: 1280px) {
    .favouritebrands {

        .slick-next,
        .slick-prev {
            top: 50%;
        }

    }

    .testimonials {

        .slick-next,
        .slick-prev {
            top: 50%;
        }
    }

    .shopbyvalue .shop-para {
        padding: 1.33vw 1.49vw 1.995vw;
    }

    .shopcollection {
        .img-row {
            height: 19.26vw !important;
        }
    }

    .shopcollectionsection {
        .shopcollection {
            padding: 0 1vw;

            .img-row {
                height: 19.26vw;
            }

        }
    }

    .newslettersection {
        .news-heading {
            font-size: 16px;
            line-height: 20px;
        }

        .newsletter-card {
            padding: 10px 12px;
        }

        .plant-heading {
            font-size: 12px;
            line-height: 16px;
        }
    }

    .homepage27 .basket-btn.darkbtn,
    .buy-btn.lightbtn {
        font-size: 1.5vw !important;
        line-height: 1.6vw !important;
        padding: 1.093vw 2.2vw !important;
    }

    .blogsection {

        .slick-next,
        .slick-prev {
            top: 30%;
        }
    }

    .shopbyvaluecard {
        .shop-icon {
            width: 12.9vw;
            height: 13.02vw;
        }

        .icon-name {
            font-size: 1.6vw !important;
            line-height: 2vw !important;
        }

    }

    .testimonials-card {
        .name-para {
            font-size: 1.66vw;
            line-height: 1.9vw;

        }

        .name-text {
            margin-bottom: 1.5vw;
            font-size: 1.86vw;
            line-height: 1.9vw;
        }

        .MuiSvgIcon-root {
            width: 2.2vw;
            height: 2.2vw;
            margin-right: 0.4vw;
        }
    }
}

@media (min-width:601px) and (max-width: 919px) {
    .fromthegram {
        .shopcollection .img-row {
            height: 24vw;
        }
    }

    .newslettersection {
        .btn-email-section {
            align-items: baseline;
        }

        .buy-btn.lightbtn {
            margin-left: 10px !important;
        }

        .input-field {
            height: 40px;
            font-size: 12px;
            line-height: 20px;
            width: 100%;
            padding-left: 24px;
        }
    }
}