@import '../abstracts/variables';

.swal2-container{
    z-index: 9999 !important;
    .swal2-popup{
        width: 33vw !important;
        border-radius: 2.0833vw !important;
        padding: 1vw;
        padding-bottom:2.5vw ;
        min-height: 18vw;
    }
    .swal2-title{
    text-align: center !important;
    font-size: 1.77vw !important;
    line-height: 2.031vw !important;
    font-family: $gt-font-regular !important;
    font-weight: bold !important;
    font-style: normal !important;
    font-variant: normal !important;
    letter-spacing: 0px !important;
    color: #707070 !important;
    opacity: 1;
    text-transform: capitalize !important;
    margin-bottom: 1.51833vw !important;
    }
    .swal2-html-container{
        font-size: 1.56vw !important;
        line-height: 2vw !important;
        font-family: $gt-font-regular !important;
        font-weight: normal;
        font-style: normal;
        font-variant: normal;
        letter-spacing: 0px;
        color: #707070;
        text-align: center;
        opacity: 1;
        margin: 0 2vw !important;
        margin-bottom: 10px !important;
    }
    .swal2-actions{
        flex-direction: row-reverse;
    justify-content: space-between;
    width: 88%;
    }
    .swal2-confirm{
        text-align: center !important;
    font-size: 1.0937vw !important;
    line-height: 1.25vw !important;
    font-family: "Asap" !important;
    font-style: normal;
    font-variant: normal;
    font-style: normal;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    background: #949D8C  !important;
    border-radius: 2.5vw !important;
    opacity: 1;
    width: 8.489vw !important;
    height: 2.656vw !important;
    cursor: pointer;
    border: none;
    }
    .swal2-cancel{
        text-align: center !important;
    font-size: 1.0937vw !important;
    line-height: 1.25vw !important;
    font-family: "Asap" !important;
    font-style: normal;
    font-variant: normal;
    font-style: normal;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    background: #363240 0% 0% no-repeat padding-box !important;
    border-radius: 2.5vw !important;
    opacity: 1;
    width: 8.489vw !important;
    height: 2.656vw !important;
    cursor: pointer!important;
    border: none;
    }
}

@media (max-width:600px) {
    .swal2-container{
    
        .swal2-popup{
            width: 90% !important;
            border-radius: 25px !important;
            padding: 2vw;
            padding-bottom:6.5vw ;
            min-height: 47vw;
        }
        .swal2-title{
        text-align: center !important;
        font-size: 5.5vw !important;
        line-height: 5.8vw !important;
        font-family: $gt-font-regular !important;
        font-weight: bold !important;
        font-style: normal !important;
        font-variant: normal !important;
        letter-spacing: 0px !important;
        color: #707070 !important;
        opacity: 1;
        text-transform: capitalize !important;
        margin-bottom: 3.51833vw !important;
        }
        .swal2-html-container{
            font-size: 3.7vw !important;
            line-height: 4.5vw !important;
            font-family: "GT-Super-Text-Medium" !important;
            font-weight: normal;
            font-style: normal;
            font-variant: normal;
            letter-spacing: 0px;
            color: #707070;
            text-align: center;
            opacity: 1;
            margin: 0 6vw !important;
            margin-bottom: 0px !important;
        }
        .swal2-actions{
            flex-direction: row-reverse;
        justify-content: space-between;
        width: 88%;
        }
        .swal2-confirm{
            text-align: center !important;
        font-size: 3.2vw !important;
        line-height: 3.8vw !important;
        font-family: "Asap" !important;
        font-style: normal;
        font-variant: normal;
        font-style: normal;
        letter-spacing: 0px;
        color: #FFFFFF !important;
        opacity: 1;
        background: #949D8C  !important;
        border-radius: 20px !important;
        opacity: 1;
        width: 22vw !important;
        height: 8vw !important;
        cursor: pointer;
        border: none;
        }
        .swal2-cancel{
            text-align: center !important;
            font-size: 3.2vw !important;
            line-height: 3.8vw !important;
        font-family: "Asap" !important;
        font-style: normal;
        font-variant: normal;
        font-style: normal;
        letter-spacing: 0px;
        color: #FFFFFF !important;
        opacity: 1;
        background: #363240 0% 0% no-repeat padding-box !important;
        border-radius: 20px !important;
        opacity: 1;
        width: 22vw !important;
        height: 8vw !important;
        cursor: pointer!important;
        border: none;
        }
    }
}