.registercheckout {
    .register-section {
        // max-width: 400px;
        // margin: auto;
        padding: 8.298vw 5.5vw 32.0833vw;
        // padding-top: 8.298vw;
        text-align: center;
        // padding-bottom: 32.0833vw;

    }

    .MuiCheckbox-root {
        padding: 0 !important;
    }


    ::-webkit-input-placeholder {
        /* Edge */
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    ::placeholder {
        color: #9F9F9F;
        font-family: "Asap";
        font-weight: 400;
    }

    .MuiInputLabel-formControl {
        color: #9F9F9F;
        font-family: "Asap";
    }

    // .inputiconme {
    //     position: absolute;
    //     margin-top: 1vw;
    //     right: 1vw;
    //     color: #74CE83 !important;
    // }
    .inputiconme {
        display: none;
    }

    .inputicon {
        min-height: 75px;
        position: relative;
    }

    .visibility-icon {
        position: absolute;
        left: 86%;
        top: 0.9vw;

    }

    // .inputicon .MuiSvgIcon-root {
    //     display: none;
    // }

    .MuiInputLabel-root {
        font-size: 1.12vw;
        line-height: 1.5vw;
        left: 7px;
    }

    .MuiInputLabel-root.Mui-focused {
        color: #777777 !important;
        font-size: 1.1vw;
        line-height: 1.4vw;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #2F2B23 !important;
        border: 1px solid !important;
        border-width: 1px !important;
    }

    .MuiOutlinedInput-input {
        min-width: 22.5vw;
        min-height: 3.88vw;
        padding: 0 10px;
        font-family: "Asap";
    }

    .MuiOutlinedInput-root {
        border-radius: 1.0416vw;
    }

    .legend {
        height: auto;
        span{
            font-size: 13px;
        }
    }

    .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #A6A194;
    }

    .logocheckout {
        width: 11vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        cursor: pointer;
        margin-bottom: 1.056805vw;
    }

    .register-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8055vw;
        line-height: 2.0833vw;
        color: #000000;
        margin-bottom: 1.25vw;
    }

    .register-para {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.25vw;
        line-height: 1.4583vw;
        text-align: center;
        color: #747474;
        padding: 0 21vw;
        margin-bottom: 2.8vw;
    }

    .field-section {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin-bottom: 0.8vw;
    }

    .field-section1 {
        display: flex;
        justify-content: space-between;
        // align-items: center;
        margin-bottom: 1.875vw;
    }

    .keep-text .MuiTypography-body1 {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.1111vw;
        line-height: 1.3194vw;
        // color:  #949494;
        color: #a0a0a0c7;
        letter-spacing: 0.2px;
        text-align: left;
        padding-left: 1.04vw;
    }

    .MuiSvgIcon-root {
        color: #a0a0a0c7;
        letter-spacing: 0.2px;
        width: 1.2em;
        height: 1.2em;
    }

    .login-checkout {
        width: 32%;
    }

    .MuiFormGroup-root .MuiFormControlLabel-root {
        display: flex;
        justify-content: center;
        // padding: 0 17vw;
        padding: 0 25vw 0 26.5vw;
        margin-right: 0;

        // svg.MuiSvgIcon-root {
        //     color: #fff;
        //     border: 1px solid #8b8b8b;
        //     border-radius: 4px;
        //     width: 22px;
        //     height: 22px;
        //     margin-right: 5px;
        // }

        // .Mui-checked svg {
        //     border: none !important;
        //     border-radius: 4px;
        //     color: #8b8b8b !important;
        //     width: 24px;
        //     height: 24px;
        //     font-size: 24px;
        // }
    }

    .logging-text {
        font-family: $asap-font-regular;
        font-style: normal;
        font-weight: 400;
        font-size: 1.1458vw;
        line-height: 1.3194vw;
        // color: #A0A0A0;
        color: #a0a0a0c7;
        letter-spacing: 0.2px;
        margin-top: 2.0833vw;
        margin-bottom: 2.5vw;

        span {
            // text-decoration-line: underline;
            border-bottom: 1px solid #2F2B23;
            color: #2F2B23;
            cursor: pointer;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .darkbtn {
        min-width: 22.2916vw !important;
        min-height: 4.375vw;
        margin-bottom: 0.5vw;
        letter-spacing: 0.2px !important;
        font-size: 1.11vw !important;
        font-weight: 500 !important;
        color: #FFFFFF !important;
        background-color: #2F2B23;

    }
}

@media (min-width:930px) and (max-width:1280px) {
    .registercheckout {
        .MuiSvgIcon-root {
            width: 1em;
            height: 1em;
        }
       
              div.all-screen-container, 
              .css-1oqqzyl-MuiContainer-root {
                max-width: 95% !important;
              }
        
        .MuiOutlinedInput-input {
            min-width: 21.5vw;
        }

        .inputicon {
            min-height: 58px;
            position: relative;
        }

        .login-checkout {
            width: 32%;
        }

        .MuiOutlinedInput-input {
            // min-width: 100%;
            min-height: 45px;
        }

        .MuiInputLabel-root.Mui-focused {
            color: #777777 !important;
            font-size: 16px;
            line-height: 27px;
            font-family: 'Asap';
        }

        .visibility-icon {
            position: absolute;
            left: 82%;
            top: 16%;
        }

        .register-section {
            padding: 8.298vw 7.5vw 32.0833vw;
        }
       
    }
}

@media (min-width:1930px) {
    .registercheckout {
        legend span{
            font-size: 1vw;
        }
        .MuiOutlinedInput-input {
            min-width: 17.5vw;
            font-size: 1.2vw;
    line-height: 1.5vw;
        }

        .inputicon {
            min-height: 5vw;
        }

        .visibility-icon {
            left: 84%;
            top: 1.2vw;

        }

        .MuiFormGroup-root .MuiFormControlLabel-root {
            padding: 0 20vw 0 23.5vw;
        }

        .register-section {
            padding: 8.298vw 2.7916vw 32.0833vw;
        }

        .register-para {
            padding: 0 14vw;
        }

        .MuiInputLabel-root {
            font-size: 1.12vw;
            line-height: 2vw;
        }

        .MuiFormGroup-root .MuiFormControlLabel-root {
            padding: 0 15vw 0 20.5vw;
        }

        .MuiSvgIcon-root {
            fill: currentColor;
            width: 1.35vw !important;
            height: 1.35vw !important;
        }

        .inputicon {
            .MuiSvgIcon-root {
                width: 1.5vw !important;
                height: 1.5vw !important;
            }
        }

        .darkbtn {
            font-size: 1.05vw !important;
        }
        .MuiInputLabel-root {
            font-size: 1.12vw;
            line-height: 2.5vw;
            left: 7px;
        }
        .MuiInputLabel-root.MuiInputLabel-shrink{
        font-size: 1.12vw;
        line-height: 1.2vw !important;
        left: 7px;
    }
    }
}

@media only screen and (min-width: 4000px) {
    .registercheckout {
        .all-screen-container {
            max-width: 2500px !important;
        }

        .registercheckout .register-para {
            padding: 0 12vw;
        }

        .MuiFormGroup-root .MuiFormControlLabel-root {
            padding: 0 13vw 0 18.5vw;
        }
    }
}