@import '../abstracts/variables';

.myaccountmessages {
    .message-section {
        padding: 2.60416vw 0vw 9.375vw;
    }

    .trending-heading {
        margin-bottom: 1.56vw;
    }

    .user-img {
        border-radius: 50%;
        width: 3.75vw;
        height: 3.75vw;
        margin: 0.7812vw 0px
    }

    .user-name {
        font-size: 1.45vw;
        line-height: 1.6vw;
        color: #707070;
        font-weight: bold;
        font-family: $asap-font-regular;
    }

    .item-text {
        font-size: 1.145vw;
        line-height: 1.3541vw;
        font-weight: bold;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 1;
    }

    .message-text1 {
        text-align: left;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        font-weight: bold;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 1;
    }

    .date-text {
        text-align: left;
        font-size: 0.9375vw;
        line-height: 1.09375vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: 1;
    }

    .table_product_img {
        width: 8%;
    }

    .table_product_name {
        width: 10%;
    }

    .table_product-size {
        width: 14%;
    }

    .table_message {
        width: 22%;
    }

    .table_date {
        width: 7%;
    }
}

@media (min-width:280px) and (max-width: 920px) {
    .myaccountmessages {
        .user-img {
            width: 50px;
            height: 50px;

        }

        .user-name {
            font-size: 19px;
            line-height: 22px;
            margin-top: 6px;
        }

        .message-text1, .date-text {
            font-size: 14px;
            line-height: 16px;
        }

        .table_product_img {
            width: 20%;
        }

        .table_product_name {
            width: 70%;
        }
    }
}