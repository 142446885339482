@import "../abstracts/variables";
.confirm-your-age {
  .Confirm-text {
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.03125vw;
    font-family: $gt-font-regular;
    letter-spacing: 0px;
    color: $color707070;
    margin-bottom: 0.6406vw;
  }
  .Confirm-para {
    text-align: center;
    font-size: 0.9375vw;
    line-height: 1.09375vw;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: $color707070;
    opacity: 1;
    padding: 0 5%;
    margin-bottom: 1.1802vw;
  }
  .Confirm-age-para {
    text-align: center;
    font-size: 0.9375vw;
    line-height: 1.09375vw;
    font-family: $asap-font-regular;
    letter-spacing: 0px;
    color: $color707070;
    opacity: 1;
    margin-bottom: 1.1802vw;
  }
  .Confirm-btn {
    text-align: center;
    padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
  }

  // .MuiPaper-rounded {
  //   border-radius: 40px;
  //   width: 27.447916vw;
  // }

  .verify-age {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .verify-your-age {
    font-size: 1.14583vw;
    font-weight: bold;
    line-height: 1.35416vw;
    font-family: $asap-font-regular;
    margin-bottom: 0.7vw;
  }
  .buy-btn.lightbtn{
color: #f5f4f3 !important;
text-transform: inherit !important;
  }
}

.ConfirmYourAge {
  .refer-friend-btn {
    background: #363240;
    border-radius: 2.5vw;
    font-family: $asap-font-regular;
    color: rgba(255, 255, 255, 0.929412);
    min-width: 10.989vw;
    min-height: 2.7vw;
    margin-left: 0.8854vw;
    font-size: 1.09375vw;
    line-height: 0.937vw;
    text-transform: capitalize;
  }
}

@media  (max-width: 600px) {
  .confirm-your-age {
    .Confirm-text {
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 13.13px;
    }
   .buy-btn.lightbtn {
      font-size: 16px !important;
      line-height: 18px !important;
    }
    .Confirm-para {
      font-size: 16px;
      line-height: 18px;  
    margin-bottom: 34.38px;
    }
    .Confirm-age-para {
      font-size: 15px;
      line-height: 17px;
      margin-bottom: 15px;
    }
    .Confirm-btn {
      text-align: center;
      padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 27.447916vw;
    }

    .verify-age {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .verify-your-age {
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 8.7px;
    }
  }
}

@media (min-width: 600px) and (max-width: 920px) {
  .confirm-your-age {
    .Confirm-text {
      font-size: 30px;
      line-height: 35px;
    }
    .Confirm-para {
      font-size: 20px;
      line-height: 23px;
    }
    .Confirm-age-para {
      font-size: 15px;
      line-height: 18px;
    }
    .Confirm-btn {
      text-align: center;
      padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
    }

    .MuiPaper-rounded {
      border-radius: 40px;
      width: 27.447916vw;
    }

    .verify-age {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .verify-your-age {
      font-size: 26px;
      line-height: 32px;
    }
    .buy-btn.lightbtn {
        background-color: #949D8C;
        font-size: 26px!important;
        line-height: 29px!important;
        border-radius: 48px!important;
    }
  }
}

@media (min-width: 920px) and (max-width: 1280px) {

    .confirm-your-age {
        .Confirm-text {
          font-size: 30px;
          line-height: 35px;
        }
        .Confirm-para {
          font-size: 20px;
          line-height: 23px;
        }
        .Confirm-age-para {
          font-size: 15px;
          line-height: 18px;
        }
        .Confirm-btn {
          text-align: center;
          padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
        }
    
        .MuiPaper-rounded {
          border-radius: 40px;
          width: 27.447916vw;
        }
    
        .verify-age {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .verify-your-age {
          font-size: 26px;
          line-height: 32px;
        }

        .buy-btn.lightbtn {
            background-color: #949D8C;
            font-size: 26px!important;
            line-height: 29px!important;
            border-radius: 48px!important;
        }
      }
}

@media only screen and (min-width:1930px) {
  .verify-age{
    .MuiSvgIcon-root{
      width: 1.5vw;
      height: 1.5vw;
    }
  }
  
}