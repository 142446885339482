@import "../abstracts//variables";

.raiseDisputedialogs {
    .voucher-text {
        text-align: center;
        font-size: 1.7708vw;
        line-height: 2.03125vw;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        text-transform: capitalize;
        margin-bottom: 1.51833vw;
        font-weight: bold;
    }
    label {
        cursor: pointer;
    }
    .imgbtn{
        position: relative;
    }
    .crossbtn{
        position: absolute;
        left: 5px;
        cursor: pointer;
    }
    .basket-btn, .buy-btn {
        border-radius: 0.5vw !important;
        opacity: 1;
    font-weight: bold !important;
        padding: 0.5vw 1vw !important;
        font-weight: 500 !important;
        min-width: 6.986vw !important;
        min-height: 2.64vw;
        margin-bottom: 15px !important;
        color: #000 !important;
    }
    .basket-btn.darkbtn{
        color: #000 !important;
        font-weight: bold !important; 
        background-color: #949d8c;
    }
    .darkbtn:hover {
        background-color: #949d8c;
        color: #000 !important;
        font-weight: bold !important;
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 0;
    }
    .selectbox .select-field {
        font-weight: bold;
        font-size: 1.4vw;
        font-family: 'Asap';
        color: #2F2B23;
        background-color: transparent;
        padding: 0;
        margin-bottom: 20px;
    }

    .wrong-text {
        font-weight: 500;
        font-size: 1.04vw;
        line-height: 1.25vw;
        font-family: 'Asap';
        color: #2F2B23;
        margin-bottom: 1.04vw;
    }

    .lightbtn {
        border-radius: 0.5vw !important;
        font-size: 1.09vw !important;
        line-height: 1.25vw !important;
        font-weight: 600 !important;
        color: #FFFFFF !important;
        padding: 0.416vw 0.3vw !important;
        margin-left: 0.3vw !important;
        margin-right: 0.3vw !important;
        margin-bottom: 1.04vw !important;
        min-width: 6.86vw !important;
        min-height: 2.64vw;
    }

    .btn-section {
        margin-bottom: 2vw;
    }

    .css-1hpqosy-MuiFormControlLabel-root .MuiFormControlLabel-label:checked {
        color: #FFFFFF;
        font-weight: bold;
    }

    // .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked{
    //      display: none !important;
    //  }

    .input-field-textarea {
        background: #f5f4f3 0% 0% no-repeat padding-box;
        // border-radius: 1.875vw;
        opacity: $opcity;
        padding: 1.00833vw 1.15937vw 0.917187vw;
        text-align: left;
        font-size: 1.09375vw;
        line-height: 1.25vw;
        font-family: $asap-font-regular;
        color: #707070;
        border: $btn-border;
        margin-bottom: 1.426vw;
        display: block;
        width: 100%;
        resize: none;
        margin-bottom: 1.04vw;
    }

    textarea.input-field-textarea:focus-visible {
        outline: none;
    }

    .attach-btn {
        background: #949d8c 0% 0% no-repeat padding-box;
        border-radius: 2.5vw;
        opacity: 1;
        text-align: center;
        font-size: 1.0937vw;
        line-height: 1.25vw;
        font-weight: normal;
        font-variant: normal;
        font-style: normal;
        font-family: "Asap";
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        border: none;
        cursor: pointer;
        // padding: 14.48px 28.41px 14.48px 28.5px;
        padding: 0.75416vw 1.4796vw 0.75416vw 1.48437vw;
        margin-top: 1.04vw;
        margin-bottom: 2vw;

    }

    .file-section {
        position: relative
    }

    .input-type-file-upload {
        display: none;
    }

    .lastbtn {
        text-align: center;

        .lightbtn {
            width: 100% !important;
        }

        .MuiPaper-elevation1 {
            box-shadow: none;
        }
    }
}





@media  (max-width: 920px) {
    .raiseDisputedialogs {
        .voucher-text {
            font-size: 28px;
            line-height: 33px;
        }

        .selectbox .select-field {
            font-size: 20px;
            line-height: 22px;
        }

        .wrong-text {
            font-weight: 500;
            font-size: 15px;
            line-height: 21px;
            margin-bottom: 10px;
        }

        .lightbtn {
            min-height: 2.64vw;
            font-size: 16px !important;
            line-height: 18px !important;
            padding: 7px 6px !important;
            margin-left: 3px !important;
            margin-right: 3px !important;
            margin-bottom: 0px !important;
        }

        .input-field-textarea {
            background: #f5f4f3 0% 0% no-repeat padding-box;
            opacity: 1;
            padding: 10px 10px;
            text-align: left;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 20px;
        }

        .pera-text18 {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 20px;
        }

        .attach-btn {
            text-align: center;
            font-size: 16px;
            line-height: 18px;
            padding: 8px 15px;
            border-radius: 20px;
        }

    }


}

@media (min-width: 600px) and (max-width: 920px) {

    .raiseDisputedialogs {
        .voucher-text {
            font-size: 28px;
            line-height: 33px;
        }


    }
}

@media (min-width: 920px) and (max-width: 1280px) {

    .raiseDisputedialogs {
        .voucher-text {
            font-size: 30px;
            line-height: 35px;
        }

    }

}