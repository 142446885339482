@import "../abstracts/variables";

.sellerheader {
    .header-seller {
        display: flex;
        justify-content: space-between;
        padding-right: 25px;
        padding-left: 25px;
    }

    .logo-img {
        width: 270px;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        cursor: pointer;
    }

    .category-section {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }

    .category-name {
        text-align: left;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0px;
        color: #2f2b23;
        opacity: 1;
        font-family: $asap-font-regular;
        padding-left: 25px;
    }

    .buy-btn.lightbtn {
        background-color: #949d8c;
        min-width: 141px !important;
        border-radius: 28px !important;
        color: #ffff;
        padding: 17px 41px !important;
        height: 51px !important;
        margin-left: 25px;
    }
    .desktopshow{display: block;
    
    }
    .desktophide{
        display: none;
    }
}

@media (min-width:280px) and (max-width:600px) {
    .sellerheader {
        .logo-img {
            width: 179px;
            height: 65px;
        }

        .menu-btn {
            float: right;
            width: 77px;
            height: 54px;
            background-color: #949d8c;
            border-radius: 39px;
            opacity: 1;
            color: #ffffff;
            right: 22px;
        }

        .css-zddlty-MuiButtonBase-root-MuiButton-root:hover {
            float: right;
            width: 77px;
            height: 54px;
            background-color: #949d8c;
            border-radius: 39px;
            opacity: 1;
            color: #ffffff;
            right: 22px;
        }

        img.icon-img {
            filter: invert(1);
        }
        .desktopshow{
            display: none;
        }
        .desktophide{
display: block;
        }
        .MuiPaper-rounded{
            border-radius: none !important;
        }
        .category-name {
            margin-bottom: 15px;
        }
    }
}