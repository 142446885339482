.saveaddressskeleton{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addline{
        width:16vw;
    }
    .saveaddright{
        display: flex;
        align-items: center;
    }
    .btnbox{
        width:8vw;
        height:3vw;
        border-radius: 2.08vw;
    }
    .editbox{
        width:2vw;
        height: 2vw;
        margin: 0 1vw;
    }
    .delbox{
        width:2vw;
        height: 3vw;
    }

}