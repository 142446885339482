@import '../abstracts/variables';

.signin {


    .sign-in {
        background: #363240 0 0 no-repeat padding-box;
        border-radius: 2.5vw;
        font-family: Asap;
        font-style: normal;
        font-weight: 400;
        border: none;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.929412);
        width: 5.989vw;
        height: 2.7vw;
        font-size: .833vw;
        line-height: .937vw;
        text-align: center;
        padding-top: 0px;
        text-transform: inherit;

        &:hover {
            background-color: $btn-background-black;
        }
    }

    .sign-in span {
        padding-right: 4px;
    }

    .profile {
        vertical-align: middle;
        width: 1.04vw;
        height: 1.1458vw;
        font-size: .989vw;
        opacity: .99;
        object-fit: cover;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
        z-index: 99;
    }
}

.signindialogs {
    .basket-btn.darkbtn {
        color: #FFFFFF !important;
        min-width: 9.895vw !important;
        min-height: 3.177vw;
    }

    .css-ypiqx9-MuiDialogContent-root {
        padding: 2.1125vw 2.26vw 1.531vw;
    }

    .css-1ty026z {
        padding: 2.1125vw 2.26vw 1.531vw;
    }

    .button-section-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.213vw;
        align-items: center;
    }

    .sign-heading {
        font-size: 1.7708vw;
        line-height: 2.03125vw;
        font-family: $gt-font-regular;
        font-variant: $btn-font-variant;
        font-style: normal;
        letter-spacing: $letter-spacing;
        color: #858585ed;
        opacity: $opcity;
    }

    .forgot-text {
        margin-top: 0.301vw;
        margin-bottom: 1.04vw;
        cursor: pointer
    }

    .login-btn-section {
        display: flex;
        justify-content: center;
        margin-bottom: 1.21vw;
    }

    fieldset {
        border: 2px solid transparent;
        border-top-color: #D5D5D5;
        ;
        box-sizing: border-box;
        grid-area: 1 / 1;
        width: inherit;
        margin-bottom: 0.25vw;
    }

    fieldset:nth-of-type(2) {
        transform: rotate(90deg);
    }

    legend {
        margin: auto;
        padding: 0 0.364vw;
        text-align: center;
        font-size: 1.093vw;
        line-height: 1.25vw;
        font-family: "Asap";
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
    }

    .para {
        color: #707070;
        margin-top: 1.04vw;
        margin-top: 1.04vw;
        padding: 0px 15px;
        font-size: 0.833vw;
        line-height: 0.9375vw;
        text-align: center;
    }
}

@media only screen and (max-width: 920px) {
    .signin {
        img.profile {
            // filter: invert(1);
            OPACITY: 1;
            // margin-top: -6px;
            width: 4.2vw !important; 
            height: 25px !important;
            height: auto !important;
        }

        .sign-in {
            padding: 0;
            background: none;
            color: #363240;
        }

        .sign-in:hover {
            background-color: transparent;
        }

    }

    .signindialogs {
        .sign-heading {
            font-size: 23px;
            line-height: 26px;
            font-family: 'GT-Super-Text-Medium';
        }

        .css-ypiqx9-MuiDialogContent-root {
            padding: 15px 29px 25px;
        }

        .css-1ty026z {
            padding: 15px 29px 25px;
        }

        .button-section-row {
            margin-bottom: 15.8px;
        }

        .buy-btn.lightbtn {
            background-color: #949D8C;
            font-size: 16px !important;
            height: auto;
            width: auto;
            border-radius: 45px !important;
            line-height: 18px !important;
            padding: 11.5px 39px !important;
        }

        .forgot-text {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8.95px;
            margin-top: 14px;
        }

        .basket-btn.darkbtn {
            background-color: #363240;
            padding: 11.5px 44.5px !important;
            border-radius: 48px !important;
            font-size: 16px !important;
            line-height: 18px !important;
            width: auto;
            height: auto;
            margin-bottom: 30.5px !important;
        }

        legend {
            padding: 0 2.364vw;
            font-size: 21px;
            line-height: 24px;
        }

        fieldset {
            margin-bottom: 20px;
        }

        .heading21 {
            font-size: 17px;
            line-height: 20px;
            margin-left: 5px;
        }

        .para {
            font-size: 15px;
            line-height: 17px;
            padding-left: 7px;
            margin-top: 15.25px;
        }
    }
}

@media only screen and (max-width: 413px) {
    .signin {
        .sign-in {
            width: auto;
            height: auto;
            min-width: auto;
        }

        img.profile {
            width: 4.8vw !important;
            margin-right: 10px;
        }

    }
}
@media only screen and (max-width: 280px) {
    .signin img.profile {
        width: 5.8vw !important;
    }
    .signindialogs {
    .css-ypiqx9-MuiDialogContent-root {
        padding: 15px 10px 25px !important;
    }
     .heading21 {
        font-size: 15px !important;
    }
    .para {
        font-size: 13px !important;
        line-height: 15px !important;
    }
}
    }
    
@media (min-width:601px) and (max-width: 959px) {
    .signin {
        .sign-in {
            width: auto;
            height: auto;
            padding: 6px 4px;
            min-width: 76px;
            min-height: 32px;
            font-size: 10px;
           color: #FFFFFF;
            background: #363240 0 0 no-repeat padding-box;
        }
        img.profile {
            width: 14px !important;
            height: 16px !important;
        }
     .sign-in:hover{
        background-color: #363240;
     }
    }
}
@media (min-width: 921px) and (max-width: 1280px){
    .signindialogs {
.input-field {
   height: 43px;  
   font-size: 14px;
}
.social-field{
    height: 43px; 
}
    }
}
@media (min-width:1930px) {
    .signindialogs {

        .buy-btn.lightbtn,
        .basket-btn.darkbtn {
            width: auto;
            height: auto;
            padding: 0.8vw 2.5vw !important;
            font-size: 1.04vw !important;
        }
    }
}