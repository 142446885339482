@import "../abstracts/variables";


.image-section-six{
    height: 38.802vw;
    display: flex;
    background-size: cover;
    width: 100%;
    background-position: center top;
    object-fit: cover;
    // padding-top: 90px;
    display: flex;
    justify-content: center;
    margin:  3.7928vw auto 3.7928vw auto;
    cursor: pointer;
    background-color: #f0f0f0;
    position: relative;

    
}
.imagesection {
  position: relative;
  .imagessectioncontent {
    width: 32%;
    min-height: 28vw;
    margin: 0 auto;
    background-size: contain !important;
    padding: 6vw 3vw 3vw;
    text-align: center;
    position: absolute;
    top: 14%;
    left: 34%;
    .main-heading40.trending-heading{
      color:#e5e5e5;
    }
    p.imagesectionpera{
      color:#e5e5e5;
      font-size: 0.9375vw;
      line-height: 1.2vw;
    }
}
}
@media (max-width:280px) {
  .imagesection{
  .image-section-six {
    min-height: 400px !important;
}
.imagessectioncontent{
  width: 98% !important;
  left:1% !important;
  padding: 6vw 5vw 3vw !important;
}
.buy-btn.lightbtn{
  margin-top: 0px !important;
}
  }
}
@media only screen and (max-width: 600px) {
    .mobilepading{
        padding:0  !important;
    }
  .imagesection{
    
    .image-section-six{
        min-height:500px;
    }
    .imagessectioncontent {
      width: 80%;
      min-height: 66%;
      margin: 0 auto;
      background-size: contain !important;
      padding: 6vw 3vw 3vw;
      text-align: center;
      position: absolute;
      top: 18%;
      left: 10%;
      p.imagesectionpera{
        color:#e5e5e5;
        font-size: 12px;
        line-height: 16px;
      }
  }
  }
}

@media (min-width:2000px) and (max-width:6000px) {
  .imagesection{
    .imagessectioncontent{ 
      top:8%;
      padding:2vw;
      p.imagesectionpera {
      color: #e5e5e5;
      font-size: 0.8vw;
      line-height: 1vw;
  }
}
  }
}