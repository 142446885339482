@import '../abstracts/variables';

.saved-addresses {
  
    .card-text {
        text-align: left;
        font-size: 1.979vw;
        line-height: 2.265vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $gt-font-regular;
        letter-spacing: $letter-spacing;
        color: #363240;
        opacity: $opcity;
        text-transform: capitalize;
    }

    .add-card-text {
        text-align: right;
        font-size: 1.3541vw;
        line-height: 1.56vw;
        font-family: $asap-font-regular;
    }

    .saved-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4.622vw 0 2.6041vw;
        border-bottom: 1px solid #F5F4F3;
    }

    .address-text {
        text-align: left;
        font-size: 1.432vw;
        line-height: 1.81vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: $letter-spacing;
        color: $color707070;
        opacity: $opcity;
        margin-bottom: 0.52vw;
        padding-right: 10.833vw;
        text-transform: capitalize;
    }

    .edit-icon {
        color: #707070;
        font-size: 1.61vw;
        margin-left: 1.04vw;
        width: 1.61vw;
        vertical-align: middle;
        cursor: pointer;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .delete-section {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .delete-icon {
        color: $color707070;
        font-size: 1.65vw;
        margin-left: 1.04vw;
        vertical-align: middle;
        cursor: pointer;
    }

    .plusimg {
        font-size: 2.8125vw;
        width: 2.166vw;
        height: 2.125vw;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .add-card {
        display: flex;
        align-items: center;
        cursor: pointer;

    }

    .customer-card-section {
        padding: 2.1875vw 1.302vw 2.1875vw;
        border-bottom: 1px solid #F5F4F3;
        align-items: center;
    }

    .add-card-text {
        text-align: center;
        font-size: 1.3541vw;
        font-weight: normal;
        font-variant: $btn-font-variant;
        font-style: $btn-font-style;
        font-family: $asap-font-regular;
        letter-spacing: 0px;
        color: #363240;
        opacity: 1;
        margin-left: 0.52vw;
    }

}

@media(max-width:395px) {
    .saved-addresses {
        .add-card-text {
            font-size: 16px !important;
            line-height: 20px !important;
            padding-left: 4px !important;
        }
        .plusimg {
            height: 27px !important;
            width: 27px !important;
        }
        .card-text {
            font-size: 24px !important;
            line-height: 30px !important;
            margin-bottom: 10px !important;
        }
    }
}
@media(max-width: 920px) {
    .saved-addresses {
        .delete-section{
            display: block;
            text-align: end;
        }
        .buy-btn.lightbtn {
            font-size: 16px !important;
            padding: 8px 16px !important;
        }
        .card-text {
            font-size: 27px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        .saved-card {
            align-items: flex-start;
        }

        .plusimg {
            width: 31px;
            height: 30px;
        }

        .add-card-text {
            font-size: 18px;
            line-height: 21px;
            padding-left: 23px;
            padding-left: 12px;
        }

        .card-section {
            width: 20.177vw;
            height: 12.6041vw;

        }

        .delete-icon {
            font-size: 25px;
        }

        .address-text {
            font-size: 18px;
            line-height: 23px;
        }

        .edit-icon {
            vertical-align: middle;
            width: 24px;
            line-height: 24px;
            font-size: 24px;
            
        }

        .css-1oqqzyl-MuiContainer-root {
            max-width: 100% !important;
        }
    }
}

