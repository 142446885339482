@import '../abstracts/variables';

.customer-section {
    padding: 2.473vw 0 2.73489vw 0;
    background: #F5F4F3 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-top: 0.9114vw;
    align-items: center;
 .centeralldata{
     align-items: center;
 }
    .user-icon {
        width: 3.90vw;
        height: 3.90vw;
        border-radius: 50%;
    }

    .customer-name {
        font-family: $gt-font-regular;
        font-size: 2.34345vw;
        line-height: 2.7604vw;
        color: $color707070;

    }

    .email-btn {
        background: #363240 0% 0% no-repeat padding-box;
        border-radius: $btn-border-radius;
        opacity: $opcity;
        vertical-align: middle;
        padding: 6px 12px;
        border: $btn-border;
        margin-left: 1.56vw;
        padding: 0.629vw 1.79vw;
    }

    .img {
        width: 1.77vw;
        height: 1.7vw;
        align-items: center;
        display: flex;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;
    }

    .basket-btn,
    .buy-btn {
        color: #F5F4F3 !important;
    }

    .number-card {
        position: relative;
        top: -1vw;
        right: 16.5%;
        background: #949D8C 0% 0% no-repeat padding-box;
        border-radius: 19px;
        opacity: 1;
        width: 1.979vw;
        height: 1.927vw;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
    }

    .user-icon-section {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .num {
        color: #fff2f2;
        font-weight: 600;
        font-family: "Asap";

    }

    .back-button-section {
        display: flex;
        justify-content: flex-end;
    }



    table {
        width: 93%;
        padding-left: 98px;
    }

    .user-img {
        border-radius: 50%;
        width: 3.75vw;
        height: 3.75vw;
        margin: 0.7812vw 0px;
    }

    .main-heading40 {
        text-align: left;
        font-size: 1.797vw;
        line-height: 2.2916vw;
        font-family: "GT Super Txt Trial";
        padding-left: 104px;
    }

}
@media (max-width: 280px){
    .customer-section {
        img.user-icon {
        width: 100%;
        height: auto;
        }
        div.customer-name {
            font-size: 20px;
            line-height: 24px;
        }
        div.back-button-section {
            display: flex;
            justify-content: space-around;
        }
        button.email-btn {
            padding: 6px 16px;
            .img {
                width: 20px;
                height: 20px;
            }
        }
        div.number-card {
            height: 22px;
            right: 20vw;
            top: -4vw;
            .num {
                font-size: 14px;
                line-height: 17px;
            }
        }
        button.basket-btn, button.buy-btn {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
}
@media (max-width: 920px) {
    .customer-section {
        padding: 42px 0 37.81px 0px;

        .user-icon {
            width: 55px;
            height: 55px
        }

        .customer-name {
            font-size: 23px;
            line-height: 26px;
        }

        .email-btn {
            border-radius: 20px;
            opacity: 1;
            padding: 6px 21px;
        }

        .img {
            width: 24px;
            height: 24px;
        }

        .number-card {
            padding: 3px 8px;
            width: auto;
            height: 28px;
            right: 104.5%;
            top: -3vw;
        }

        .num {
            font-size: 19px;
            line-height: 22px;
        }

        .basket-btn,
        .buy-btn {
            font-size: 17px !important;
            line-height: 20px !important;
        }

        .mobilesection {
            display: flex;
            justify-content: center;
        }

        .css-1oqqzyl-MuiContainer-root {
            max-width: 100% !important;
        }
    }
}

@media (min-width:600px) and (max-width: 920px) {
    .customer-section {
        .number-card {
            top: 63%;
            right: 10.5%;
        }
    }
}

@media (min-width:921px) and (max-width: 1280px) {
    .customer-section {
        .number-card {
            top: -1.5vw;
            right: 5.5%;
           width: 2.4vw;
            height: 2.3vw;
        }
    }
}

@media only screen and (min-width: 1930px) {
    .customer-section {
        .num {
            font-size: 1.4vw;
        }
        .number-card {
            right: 19.5%;
        }
    }
}