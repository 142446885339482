.gift-option-dialog {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1111vw;
  line-height: 1.25vw;
  // text-decoration-line: underline;
  // border-bottom: 0.9px solid #757575;
  border-bottom: 0.9px solid #afaeae;
  // color: #757575;
  color: #afaeae;
  cursor: pointer;
  margin-right: 0.95vw;
}

.MuiFormControlLabel-root {
  margin-right: 0px !important;
}

.addgiftmessage {
  .checkoutproductimg {
    width: 7.29vw;
    height: 7.43vw;
    cursor: pointer;
    object-position: top;
    object-fit: cover;
  }

  .image-product {
    display: flex;
    margin-bottom: 2.361vw;
  }

  .product-text-section {
    margin-left: 1.04vw;
  }

  .product-text {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 500;
    font-size: 1.66vw;
    line-height: 1.85vw;
    color: #2f2b23;
    margin-bottom: 0.9722vw;
    padding-top: 7px;
  }

  .product-price {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.25vw;
    line-height: 1.5979vw;
    color: #757575;
  }

  .add-message {
    font-family: $asap-font-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 1.354vw;
    line-height: 1.58vw;
    color: #757575;
    margin-bottom: 1.11vw;
  }

  .textarea-field {
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    width: 100%;
    resize: none;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 19px;
    padding: 5px 10px;
  }

  .textarea-field:focus-visible {
    outline: none;
  }
  textarea::placeholder {
    color: #949d8c;
    font-weight: 400;
  }
  textarea::-webkit-input-placeholder {
    color: #949d8c;
    font-weight: 400;
  }
  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #949d8c;
    font-weight: 400;
  }
  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #949d8c;
    font-weight: 400;
  }
  textarea:-ms-input-placeholder {
    color: #949d8c;
    font-weight: 400;
  }

  .gift-wrapping-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .wrapping-text {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3506vw;
    line-height: 1.145vw;
    color: #757575;

    span {
      margin-left: 9px;
    }
  }

  .personalisation-section {
    display: flex;
    justify-content: space-between;
    // margin-top: 0.7812vw;
    margin-top: 3.33vw;
  }

  .button-section-new {
    display: flex;
    justify-content: space-between;
    margin-top: 2.13541vw;
  }

  .retailers-btn,
  .product-btn {
    min-height: 2.91666vw !important;
    min-width: 11.6145vw !important;
    // margin: 0 1.2%;
    width: 100% !important;
  }

  .product-btn {
    color: #2f2b23 !important;
    margin-right: 0.2vw !important;
    background: #e9e8e5;
  }

  .MuiPaper-rounded {
    border-radius: 0.78125vw !important;
  }
  .MuiDialogContent-root {
    // padding: 46px 25px 30px !important;
    padding: 3.199vw 1.3vw 1.56vw !important;
  }
}

@media (max-width: 920px) {
  .addgiftmessage {
    .MuiDialogContent-root {
      padding: 36px 25px 30px !important;
    }
    .checkoutproductimg {
      width: 102px;
      height: 107px;
      margin-right: 10px;
    }

    .MuiPaper-rounded {
      border-radius: 15px !important;
    }

    .image-product {
      margin-bottom: 18px;
    }

    .product-text {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 15px;
    }

    .product-price {
      font-size: 16px;
      line-height: 19px;
    }

    .add-message,
    .wrapping-text {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }

    .personalisation-section {
      margin-top: 10px;
    }

    .button-section-new {
      margin-top: 40px;
    }

    .retailers-btn,
    .product-btn {
      min-height: auto !important;
      min-width: auto !important;
      padding: 15px 42px !important;
      font-size: 14px !important;
      border-radius: 15px !important;
      width: 100% !important;
      margin: 0% 1.5%;
    }
  }
}

@media (min-width: 1930px) {
  .addgiftmessage {
    .MuiDialogContent-root {
      padding: 2vw;
    }

    .textarea-field {
      min-height: 7vw;
    }
  }
}
