@import '../abstracts/variables';

.register-btn-section {
  .buy-btn.lightbtn {
    background-color: #949D8C;
    font-size: 1.09375vw !important;
    line-height: 1.25vw !important;
    color: #ffffff !important;
    min-width: 9.8958vw !important;
    min-height: 3.17vw;
    text-transform: inherit !important;
  }
}

.registerdialogs {
  .checkbox .Mui-checked .MuiSvgIcon-root {
    color: #0b0b0b;

    }
    .css-ypiqx9-MuiDialogContent-root {
      padding: 2.55vw 2.239vw 3.125vw;
    }


    .css-1ty026z {
      padding: 2.55vw 2.239vw 3.125vw;
    }

    .register-heading {
      margin-bottom: 1.915vw;
      color: #707070;
      opacity: $opcity;
    }

    .checkbox-section {
      display: flex;
      margin-top: 1.0135vw;
      margin-bottom: 1.498vw;
      align-items: center;
    }

    .register-para {
      font-size: 0.833vw;
      line-height: 0.937vw;
      color: #707070;
      opacity: $opcity;
      font-family: $asap-font-regular;
      font-weight: normal;
      font-style: $btn-font-style;
      font-variant: $btn-font-variant;
      letter-spacing: $letter-spacing;
      padding-left: 1.04vw;
    }

    .btn-section {
      display: flex;
      justify-content: center;
    }

    .checkbox .MuiSvgIcon-root {
      color: #F5F4F3;
      background: #F5F4F3;
      border-radius: 20px;
    }
  //   .checkbox {
  //     margin-top: 8px;
  // }
  }
// }

  @media only screen and (max-width: 920px) {

    .register-btn-section {
      .buy-btn.lightbtn {
        background-color: #949d8c;
        border-radius: 45px !important;
        font-size: 16px !important;
        height: auto;

        line-height: 18px !important;
        padding: 11.5px 39px !important;
        width: auto;
      }
    }

    .registerdialogs {
      .css-ypiqx9-MuiDialogContent-root {
        padding: 41px 29.82px 36.02px;
      }

      .css-1ty026z {
        padding: 41px 29.82px 36.02px;
      }

      .register-heading {
        font-size: 23px;
        line-height: 26px;
        margin-bottom: 28.77px;
      }

      .register-para {
        text-align: left;
        font-size: 16px;
        line-height: 18px;
        padding-left: 10.83px;
        margin-top: 19px;
      }

      .buy-btn.lightbtn {
        background-color: #949D8C;
        font-size: 16px !important;
        height: auto;
        width: auto;
        color: #ffffff !important;
        border-radius: 45px !important;
        line-height: 18px !important;
        padding: 11.5px 39px !important;
        margin-top: 28.77px !important;
      }
    }
  }
  @media only screen and (max-width: 280px) {
    .register-btn-section .buy-btn.lightbtn{
      padding: 10.5px 21px !important;
      font-size: 14px !important;
    }
    .registerdialogs .css-ypiqx9-MuiDialogContent-root {
      padding: 27px 10.82px 25.02px;
  }
    }

    @media (min-width: 921px) and (max-width: 1280px){
      .registerdialogs {
.input-field {
    width: 100%;
    height: 42px;
    font-size: 12px;
}
 .register-para {
  font-size: 12px;
  line-height: 15px;
}
    }
  }