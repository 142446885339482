@import "../abstracts//variables";
.redeem-gift-voucher {
  .voucher-text {
    text-align: center;
    font-size: 1.7708vw;
    line-height: 2.03125vw;
    font-family: $gt-font-regular;
    letter-spacing: $letter-spacing;
    color: #363240;
    opacity: $opcity;
    margin-bottom: 1.691vw;
  }
  .voucher-para {
    text-align: center;
    font-size: 1.19375vw;
    line-height: 1.375vw;
    font-family:$asap-font-regular;
    letter-spacing: $letter-spacing;
    color: $color707070;
    opacity: $opcity;
    margin-bottom: 1.1802vw;
  }
  .basket-btn.darkbtn {
    font-size: 1.1975vw !important;
    line-height: 1.3541vw !important;
    background-color: #363240;
    color: #FFFFFF !important;
}
  .purchase-btn {
    text-align: center;
    padding: 0.703125vw 1.4322vw 0.703125vw 1.4322vw;
  }

  .input-field {
    background: #f5f4f3 0% 0% no-repeat padding-box;
    border-radius: $btn-border-radius;
    width: 100%;
    height: 3.177vw;
    font-size: 1.09375vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
  }

  .MuiPaper-rounded {
    border-radius: 40px;
    width: 100%;
  }
}

.redeemfriend {
  .refer-friend-btn {
    background: #363240;
    border-radius: $btn-border-radius;
    font-family: $asap-font-regular;
    color: rgba(255, 255, 255, 0.929412);
    min-width: 10.989vw;
        min-height: 2.7vw;
    margin-left: 0.8854vw;
    font-size: 1.09375vw;
    line-height: 0.937vw;
    padding-top: 0px;
    text-transform: capitalize;
  }
}

@media  (max-width: 600px) {
  .redeem-gift-voucher {
    .basket-btn.darkbtn{
      font-size: 16px !important;
      line-height: 18px !important;
      border-radius: 20px !important;
    }
    .voucher-text {
      font-size: 23px;
      line-height: 26px;
      margin-bottom: 23.71px;
    }
    .voucher-para {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 0px;
    }
    .input-field {
      margin-bottom: 19.97px;
      font-size: 16px;
      line-height: 18px;
      border-radius: 20px;
      margin-top: 23.71px;
      padding-left: 19.71px;
      height: 41px;
    }
    .MuiPaper-rounded {
      border-radius: 40px;
      width: 27.447916vw;
    }
  }
  
}

@media (min-width: 600px) and (max-width: 920px) {
  .redeem-gift-voucher {
    .voucher-text {
      font-size: 26px;
      line-height: 29px;
      margin-bottom: 25.71px;
    }
    .voucher-para {
      font-size: 21px;
      line-height: 24px;
    }
    .input-field {
      margin-bottom: 19.97px;
      font-size: 18px;
      line-height: 21px;
      border-radius: 20px;
      margin-top: 23.71px;
      padding-left: 19.71px;
      height: 43px;
    }
    .MuiPaper-rounded {
      border-radius: 40px;
      width: 27.447916vw;
    }
  }
  
}

@media (min-width: 920px) and (max-width: 1280px) {
  .redeem-gift-voucher {
    .voucher-text {
      font-size: 24px;
    line-height: 30px;
    }
    .voucher-para {
      font-size: 16px;
      line-height: 22px;
    }
    .input-field {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 20px;
      margin-top: 14px;
      padding-left: 19.71px;
      height: 43px;
    }
    .MuiPaper-rounded {
      border-radius: 40px;
      width: 27.447916vw;
    }
  }
  
}