@import '../abstracts/variables';
.wishlist{
    padding: 3vw 0 0;
    margin-top: 0.9895vw;
    border-top: 1px solid #F5F4F3;
    .latestArrivals {

    .main-heading40.trending-heading{
        text-align: left ;
    }
    .darkbtn{
        width:auto;
    }
   
 
}
.size-text{
    color: #949D8C;
    font-weight: bold;
}
.emptybasket{
    .btn-part{display: none;}
    .lightbtn{
        width:auto;
    margin-bottom: 4.339vw;
    }
}
    .product-name{
    
    font-size: 1.02vw;
    line-height: 1.197vw;
    font-family: $gt-font-medium;
    padding-bottom: 0.6744vw;
    color: #707070;
    
    }
    .latestArrivals{
        margin-top:3.5vw;
        .desktopbg{
            background-position-y: 4vw;
            background-size: 76%;
        }
    }
    .item-card{
        .lightbtn {
            width: 100%;
            margin-bottom: 1vw;
        }
    } 
    tr{
        border-top:1px solid rgba(0, 0, 0, 0.12);
        display: block;
    } 
    
    .quantity .MuiSelect-select{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 18px !important;
    }
    .price-text{
    
    font-size: 1.3020vw;
    line-height: 1.510vw;
    font-family: $gt-font-regular;
    color: #949D8C;
    margin-bottom: 0.364vw;
    }
    .delivery-text{
    
    font-size: 0.77812vw;
    line-height: 0.855vw;
    font-family: $asap-font-regular;
    color: #949D8C; 
    margin-top: 0.5vw;  
    }
    .variant-name{
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-family: $asap-font-regular;
    color: #707070;
    margin-bottom: 0.3104vw;  
    }
    .option-heading{
    font-size: 1.04vw;
    line-height: 1.197vw;
    font-weight: bold;
    font-family: $asap-font-regular;
    color: #949D8C;  
    }
    .quantity{
   
    font-size: 1.0937vw;
    line-height: 1.25vw;
    font-family:$asap-font-regular;
    color: #707070;  
    }
   
    .item-card{
      
        background: #363240 0% 0% no-repeat padding-box;
        border-radius: 2.0833vw;
        width: 19.79vw;
       padding: 2.447vw 2.708vw 1.61vw;
       text-align: center;
       .lightbtn{
        line-height: 1.6vw !important;
       }
       
    }
    td.table_product_size_color {
        width: 12%;
        
    }
    .table_btn{
        text-align: right;
    }
    .item-heading{
      
        font-size: 1.145vw;
        line-height: 1.354vw;
        font-weight: bold;
        font-family: $asap-font-regular;
        color: #FFFFFF;
        margin-bottom: 0.26vw;
    }
    .price-heading{
    font-size: 2.708vw;
    line-height: 3.125vw;
    font-family: $gt-font-medium;
    color: #FFFFFF;
    }
    .delivery-heading{
        color: #949D8C;
        font-size: 0.937vw;
        line-height: 1.0937vw;
        font-family: $asap-font-regular; 
        margin-bottom: 0.9015vw;  
    }
    .total-text{
        font-size: 1.04vw;
        line-height: 1.197vw;
        font-family: $asap-font-regular; 
        color: #FFFFFF;
        margin-bottom: 1.269vw; 
    }
  
  .wishlist-img{
       width: 7.86vw; 
       height: 7.86vw;
        border-radius:1.04vw; 
       margin: 0.98vw 1.2vw 0.98vw 0vw;
       cursor: pointer;
       object-position: top;
       object-fit: cover;
    }
.table_checkbox{
    .MuiSvgIcon-root{
       font-size: 1.3rem;
    }
}
    td.table_delete_btn {
        color: #707070;
        cursor: pointer;
    }
    table.mat-elevation-z8{
        width: 100%;
    }
    table.mat-elevation-z8 td {
        padding: 5px;
    }
    td.table_product_name {
        width: 25%;
    }
 .darkbtn{
    width: auto;
    min-width: 11vw !important;
   color: #FFFFFF !important;
 }      
       
.lightbtn {
    width: auto;
    min-width: 11vw !important;
    margin-bottom: 0.3411vw;
    color:#F5F4F3 !important;
    text-transform: inherit !important;
}
.Add all to basket{
    width: 50px;
}
.product-btn {
    letter-spacing: 0px;
    width: 100%;
    height: 2.9vw;
    color:#2F2B23 !important;
    text-transform: inherit !important;
    
}
       
.trending-heading{
    text-align: center;
    color: #949D8C;
    margin-bottom: 2.734vw;
}

select.num-btn1 {
    border-radius: 2.5vw;
    width: 5.31vw;
    height: 2.7vw;
    text-align: center;
    font-size: 1.0937vw;
    font-family: $asap-font-regular;
    color: #707070;
    padding: 0.754vw 1.5vw 0.754vw 1.5vw;
    width: 5.3125vw;
    height: 2.7604vw;
    margin-left: 4.9vw;
   
}
.MuiSvgIcon-root{
    color: #707070;
}
}


@media(max-width:280px) {
    .wishlist{
         .wishlist-img {
            width: 100% !important;
            height: 50px !important;
         }
         .variant-name {
            font-size: 12px !important;
            line-height: 15px !important;
        }
        .product-name,.product-name-color {
            font-size: 15px !important;
        }
        .price-text, .size-text {
            font-size: 16px !important;
        }
        .item-card button.lightbtn,  .item-card button.product-btn {
            width: 75%;
            font: normal normal normal 14px/16px Asap !important;
        }
        .table_btn button.darkbtn, .table_btn button.lightbtn {
            font-size: 10px !important;
            line-height: 14px !important;
            height: 10.5vw !important;
        }
    }
}
@media(max-width:600px) {
    .wishlist{
        .innerpages{
            display: none ;
          }
          .latestArrivals .buttontext_section {
            justify-content: center;
        }
        .desktopbg{
            background:none;
        }
        .table_checkbox .MuiSvgIcon-root {
            font-size: 1.6rem;
        }        
     margin-top: 1vw; 
    border-top: none;
    .price-text{
        display: flex;
        align-items: center;
    }
        table.mat-elevation-z8,table.mat-elevation-z8 td,table.mat-elevation-z8 tr {
            width:100%;
            display: block;
        }
        table.mat-elevation-z8 tr{
            border-top: 2px solid #f5f5f5 !important;
            height: auto;
            clear: both;
        }
        .table_checkbox{
            width:8% !important;
            float: right;
            margin-top: 15px;
        }
        .table_product_img{
            width:24% !important;
            float: left;
        }
        .table_product_name{
            width:68% !important;
            float: right;
        }
        .table_product_size_color{
            .variant-name {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 14px;
            }
        }
        .table_product_size_color,.table_quantity,.table_btn{
            width:76% !important;
            float: right;
        }
        .table_delete_btn{
            width: 10% !important;
            float: left;
            position: relative;
            top: 2vw;
        }
        .wishlist-img{
            cursor: pointer;
            width: 67px;
            height: 69px;
            border-radius: 13px;
            margin: 15px 0px;
        }
        .product-name{
            font-size: 16px;
            line-height: 19px;
            margin-top: 15px;
            cursor: pointer;
        }
        .price-text{
            font-size: 18px;
            margin: 5px 0;
            font-weight: 700;
            text-align: left;
            line-height: 20px;
        }
        .delivery-text {
            font-size: 12px;
            line-height: 14px;
            font-weight: normal;
            margin-left:10px;
        }
        .multiquantity .quantity-btn-multi {
            width: 60px;
            height: 35px;
        }
        .variant-name {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 10px;
        }
        .option-heading {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 10px;
        }
       .table_btn .darkbtn,.table_btn .lightbtn{
            font-size: 3.2vw !important;
            line-height: 3.5vw !important;
            border-radius: 30px !important;
            height: 8.5vw;
            width: 30vw;
            min-width: 30vw !important;
            padding: 0px !important;
            margin-bottom: 10px !important;
            margin-top: 2px !important;
        }
        .btnsettable{
            display: flex;
            justify-content: space-between;
        }
        .item-card{
            padding: 34px 0px;
            width: 100%;
            border-radius: 20px;
            margin-bottom: 10vw;
        }
        .item-heading{
            font: normal normal bold 18px/21px Asap;
            margin-bottom: 10px;
        }
        .price-heading{
            font: normal normal normal 35px/43px GT-Super-Text-Regular;
            margin-bottom: 10px;
        }
        .delivery-heading{
            font: normal normal normal 16px/18px Asap;
            margin-bottom: 10px;
        }
        .total-text{
            font: normal normal normal 18px/21px Asap;
            margin-bottom: 10px;
        }
       .item-card .lightbtn,.item-card .product-btn{
            width: 60%;
            height: 38px;
            margin-top: 5px;
            font: normal normal normal 16px/18px Asap !important;
            border-radius: 30px !important;
        }
        .lightbtn{
            color:#FFFFFF !important;
        }
        .product-btn{
            color: #2F2B23 !important;
        }
    }
}
@media (min-width:600px) and (max-width:899px) {
    .wishlist{
        .table_btn .darkbtn,.table_btn .lightbtn{
            font-size: 12px !important;
            line-height: 20px !important;
            height: auto;
            width: 90%;
            margin: 4px 0 !important;
            padding: 5px !important;
        }
        .product-name{
            text-align: left;
            font-size: 14px;
            line-height: 18px;
            width: 90%;
            cursor: pointer;
        }
        .price-text{
            text-align: left;
            font-size: 18px;
            line-height: 20px;
        }
        .delivery-text {
            font-size: 10px;
            line-height: 12px;
            font-family: $asap-font-regular;
            color: #949D8C;
        }
         .variant-name,.option-heading {
            font-size: 12px;
            line-height: 14px;
        }
         .item-card {
            width: 70%;
            margin: 0 auto !important;
            padding: 6.447vw 2.708vw 6.61vw;
        }
        .table_quantity {
            max-width: 90px;
            text-align: center;
        }
        .css-1869usk-MuiFormControl-root{
            min-width: auto;
        }
        .item-card .product-btn, .item-card .lightbtn{
            font-size: 16px !important;
            line-height: 30px !important;
            height: auto;
            width: 60%;
            margin: 4px 0 !important;
            padding: 5px !important;
        }
        .item-card .product-btn{
            color: #2F2B23 !important;
        }
        .item-card .lightbtn{
            color: #fff !important;
        }
        .item-heading{
            font-size: 22px;
            line-height: 40px;
        }
        .price-heading{
            font-size: 30px;
            line-height: 50px;
        }
        .delivery-heading{
            font-size: 16px;
            line-height: 26px;
        }
        .total-text{
            font-size: 16px;
            line-height: 20px;
        }
    }
}
@media (min-width:900px) and (max-width:959px)  {
    .basket{
    .basket-btn, .buy-btn {
        opacity: 1;
        width: auto;
        height: auto;
        text-align: center;
        font-size: 12px !important;
        line-height: 15px !important;
        border-radius: 40px !important;
        margin: 4px 0 !important;
        padding: 10px 10px !important;
    }
}
}
@media (min-width:1900px) and (max-width:1930px)  {
    .wishlist{
        .table_btn .darkbtn,.table_btn .lightbtn{
            width:10vw;
        }
        .darkbtn{
            margin-left: 10px;
            margin-bottom: 10px;
        }
        .table_checkbox .MuiSvgIcon-root {
            font-size: 2vw;
        }
        .MuiSvgIcon-root {
            width: 2vw;
            height: 2vw;
        }
    }
}
@media (min-width:1930px) {
    .wishlist{
        .btnsettable{
            text-align: center;
        }
       .MuiSvgIcon-root{
           font-size: 1.5vw !important;
       } 
     .table_btn .lightbtn,.table_btn .darkbtn {
        font-size: 0.9vw !important;
        line-height: 1.25vw !important;
        height: 2.5vw;
        width: 8.1014vw;
        padding: 0px !important;
    }
    .wishlist-img{
        width: 5.5vw;
        height: 5.5vw;
    }
    .item-card .lightbtn {
        font-size: 1.0937vw !important;
    }
    }
}

@media (min-width: 2000px) and (max-width: 2599px){
    .wishlist{
        .latestArrivals .desktopbg {
            background-size: 68%;
            background-position-y: 4.5vw;
        }
    }
}

.pagination{
    clear: both ;
}