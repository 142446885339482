.tableskeleton {
    .tbimg {
        width: 10vw;
        height: 10vw;
        border-radius: 2.08vw;
    }

    .proname {
        .name {
            width: 12vw;
            height: 2.5vw;
        }

        .price {
            width: 8vw;
            height: 2.5vw;
        }
    }

    .vrtext {
        width: 8vw;
        height: 1vw;
    }

    .qty {
        display: flex;
        align-items: center;
    }

    .vrtext {
        width: 8vw;
        margin-right: 1vw;
    }

    .qttext {
        width: 6vw;
        height: 2vw;
        margin-right: 1vw;
    }

    .qtybox {
        width: 5vw;
        height: 3.5vw;
        border-radius: 2.08vw;
    }

    .btnbox {
        width: 12vw;
        height: 3.5vw;
        border-radius: 2.08vw;
        margin-bottom: 1vw;
    }

    .delbox {
        width: 2vw;
        height: 2.6vw;
        border-radius: 5px;
    }
}

@media (min-width:280px) and (max-width:600px) {
    .tableskeleton {

        table,
        tr,
        td {
            width: 100%;
            display: block;
        }

        .proimg {
            width: 24% !important;
            float: left;
        }

        .proname,
        .provariant,
        .proqty,
        .probtn {
            width: 76% !important;
            float: right;
        }
        .tbimg {
            width: 18vw;
            height: 18vw;
            border-radius: 4.08vw;
        }
        .proname .price {
            width: 23vw;
            height: 6.5vw;
            margin-bottom: 2.5vw;
        }

        .proname .name {
            width: 57vw;
            height: 5.5vw;
        }
        .provariant{
            margin-bottom: 2.5vw;
        }
        .vrtext {
            width: 57vw;
            height: 3vw;
        }
        .qttext{
            width: 20vw;
            height: 5vw;
            margin-right: 3vw;
        }
        .qtybox {
            width: 15vw;
            height: 8.5vw;
            border-radius: 8.08vw;
        }
        .probtn{
            display: flex;
        }
        .proqty{
            margin-bottom: 2.5vw;
        }
        .btnbox {
            width: 26vw;
            height: 9vw;
            border-radius: 12.08vw;
            margin-right: 2vw;
        }
        .delbtn{
            float: left;
    margin-top: -9vw;
        }
        .delbox {
            width: 5vw;
            height: 6.6vw;
            border-radius: 6px;
            margin-left: 6vw;
        }
    }
}