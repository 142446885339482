.shopbyvaluefilterskeleton{
    .filterskeletonset{
        display: inline-block;
        
    }
    .filtersklton{
        width:25%;
        padding:1.5%;
        display: inline-block;
    }
    .fltitle{
        width:100%;
    }
    .flicon{
        width: 100%;
        height: 4.5vw;
    }
    .filters .MuiAccordionDetails-root{
        display: block;
    }
}